import React from 'react'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {DepotEventApi} from "../../../../apiGenerator/axios";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface ParentEl {
  parcelIdent: string,
  warehousePlaceIdent: string,
  parcelsLeft: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
      }
    }),
);

const CompleteStorage = ({parcelIdent, warehousePlaceIdent, parcelsLeft}: ParentEl) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  function handleStorage(path: string) {
    new DepotEventApi().postCompleteParcelStorage({
      parcelIdent: parcelIdent,
      warehousePlaceIdent: warehousePlaceIdent
     }).then(() => history.push(path));
  }

  function handleCancel(path: string) {
      history.push(path);
  }

  return (
    <div style={{display: 'flex', alignItems: 'stretch', marginLeft: '10%'}}>
      <Button
          className={classes.button}
          variant="contained"
          color = "secondary"
          style={{
            marginBottom: "7px",
          }}
          onClick={() => handleCancel('warehouse')}
      >
          {t('buttons.cancelStorage')}
      </Button>
      <Button
          disabled={parcelsLeft===0}
          className={classes.button}
          variant="contained"
          color = "primary"
          style={{
            marginBottom: "7px",
          }}
          onClick={() => handleStorage('warehouse')}
      >
          {t('buttons.confirmAndStoreNextParcel')}
      </Button>
    </div>
  )
}

export default CompleteStorage
