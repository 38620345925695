import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import {DepotEventApi, Parcel, WarehousePlace} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import ScanOutboundParcel from "./step1/ScanOutboundParcel";
import ScanKepShelf from "./step2/ScanKepShelf";

interface ParentEl {
    parcels: Parcel[];
    warehousePlaces: WarehousePlace[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('outboundParcels.stepper.step1'),
        t('outboundParcels.stepper.step2')];
}

function getStepContent(step: number) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return '';
        default:
            return 'Unknown step';
    }
}

function getStepApp(step: number, parcels: Parcel[], scannedIndex: number, setScannedIndex: Function,
                    kepScanned: boolean, setKepScanned: Function, warehousePlaces: WarehousePlace[],
                    setWarehousePlaceIdent: Function, warehousePlaceIdent: string) {
    switch (step) {
        case 0:
            return <ScanOutboundParcel parcels={parcels}
                                       scannedIndex={scannedIndex}
                                       setScannedIndex={setScannedIndex} />
        case 1:
            return <ScanKepShelf parcel={parcels[scannedIndex]}
                                 kepScanned={kepScanned}
                                 setKepScanned={setKepScanned}
                                 warehousePlaces={warehousePlaces}
                                 setWarehousePlaceIdent={setWarehousePlaceIdent}
                                 warehousePlaceIdent={warehousePlaceIdent}/>

        default:
            return
    }
}

export default function OutboundParcelStepper({parcels, warehousePlaces}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [scannedIndex, setScannedIndex] = React.useState(-1);
    const [kepScanned, setKepScanned] = React.useState(false);
    const [warehousePlaceIdent, setWarehousePlaceIdent] = React.useState<string>(null);
    const steps = getSteps(t);
    const history = useHistory();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length-1){
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const nextAvailable = (activeStep: number) => {
        if(activeStep === 0 && scannedIndex !== -1)
            return true
        else return activeStep === 1 && kepScanned && parcels.length > 1;
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>
                            {label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                            {getStepApp(index, parcels, scannedIndex, setScannedIndex, kepScanned,
                                setKepScanned, warehousePlaces, setWarehousePlaceIdent, warehousePlaceIdent)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('buttons.back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!nextAvailable(activeStep)}
                                    >
                                        {activeStep === steps.length - 1 ?
                                            t('buttons.confirmAndStoreNextParcel') :
                                            t('buttons.next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
