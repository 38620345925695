import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {BiEnvelope} from "react-icons/all";
import {EfCaVehicle,VehicleInformation} from "../../../apiGenerator/axios";
import Button from "@material-ui/core/Button";
import "../vehicleHandling/VehicleHandlingStyles.css"
import {useTranslation} from "react-i18next";
//import VehicleImage from "../vehicleHandling/labelling/VehicleImage";

interface ParentEl {
    vehicle: EfCaVehicle;
    vehicleInformation: VehicleInformation;
    setScannedVehicleIndex: Function;
    setScannedVehicleInformationIndex: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
    }),
);

const VehicleInformationExpanded= ({vehicle, vehicleInformation, setScannedVehicleIndex, setScannedVehicleInformationIndex}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();

    /*{
        "ident": "string",
        "vehicleType": "string",
        "maxSpeed": 0,
        "maxWeight": 0,
        "boxTypes": [
            "string"
        ],
        "launch": "2022-06-07T14:16:58.552Z",
        "vehicleCapacity": 0,
        "batteryCapacity": 0,
        "ipAddress": "string",
        "power": 0,
        "unitVersionSW": "string",
        "unitVersionHW": "string",
        "smartourQualityReference": "string",
        "vehicleProfile": "string",
        "version": 0,
        "name": "string",
        "startDepot": "string",
        "endDepot": "string"
    }
    */
    let vehicleType = <div></div>
    let maxSpeed = <div></div>
    let maxWeight = <div></div>
    let boxTypes = <div></div>
    let launch = <div></div>
    let vehicleCapacity = <div></div>
    let batteryCapacity = <div></div>
    let ipAddress = <div></div>
    let power = <div></div>
    let unitVersionHW = <div></div>
    let unitVersionSW = <div></div>
    let smartourQualityReference = <div></div>
    let startDepot = <div></div>
    let endDepot = <div></div>
    let vehicleProfile = <div></div>
    let version = <div></div>

    if (vehicle !== undefined) {
        if (vehicle.vehicleType !== undefined) {
            vehicleType = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.vehicleType')}
                            </div>
                        {vehicle.vehicleType}
                    </div>
                </Grid>
            )
        }

        if (vehicle.maxSpeed !== undefined) {
            maxSpeed = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.maxSpeed')}
                            </div>
                        {vehicle.maxSpeed} m/s
                    </div>
                </Grid>
            )
        }

        if (vehicle.maxWeight !== undefined) {
            maxWeight = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.maxWeight')}
                            </div>
                        {vehicle.maxWeight} kg
                    </div>
                </Grid>
            )
        }
        if (vehicle.boxTypes !== undefined) {
            let boxTypesTmp = vehicle.boxTypes.join(", ");
            maxWeight = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.boxTypes')}
                            </div>
                        {boxTypesTmp}
                    </div>
                </Grid>
            )
        }

        if (vehicle.launch !== undefined) {
            launch = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.launch')}
                            </div>
                        {vehicle.launch}
                    </div>
                </Grid>
            )
        }

        if (vehicle.vehicleCapacity !== undefined) {
            vehicleCapacity = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.vehicleCapacity')}
                            </div>
                        {vehicle.vehicleCapacity}
                    </div>
                </Grid>
            )
        }

        if (vehicle.batteryCapacity !== undefined) {
            batteryCapacity = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.batteryCapacity')}
                            </div>
                        {vehicle.batteryCapacity} Wh
                    </div>
                </Grid>
            )
        }

        if (vehicle.ipAddress !== undefined) {
            ipAddress = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.ipAddress')}
                            </div>
                        {vehicle.ipAddress}
                    </div>
                </Grid>
            )
        }

        if (vehicle.power !== undefined) {
            power = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.power')}
                            </div>
                        {vehicle.power} W
                    </div>
                </Grid>
            )
        }

        if (vehicle.unitVersionHW !== undefined) {
            unitVersionHW = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.unitVersionHW')}
                            </div>
                        {vehicle.unitVersionHW}
                    </div>
                </Grid>
            )
        }

        if (vehicle.unitVersionSW !== undefined) {
            unitVersionSW = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.unitVersionSW')}
                            </div>
                        {vehicle.unitVersionSW}
                    </div>
                </Grid>
            )
        }

        if (vehicle.smartourQualityReference !== undefined) {
            smartourQualityReference = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.smartourQualityReference')}
                            </div>
                        {vehicle.smartourQualityReference}
                    </div>
                </Grid>
            )
        }

        if (vehicle.startDepot !== undefined) {
            startDepot = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.startDepot')}
                            </div>
                        {vehicle.startDepot}
                    </div>
                </Grid>
            )
        }

        if (vehicle.endDepot !== undefined) {
            endDepot = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.endDepot')}
                            </div>
                        {vehicle.endDepot}
                    </div>
                </Grid>
            )
        }

        if (vehicle.vehicleProfile !== undefined) {
            vehicleProfile = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.vehicleProfile')}
                            </div>
                        {vehicle.vehicleProfile}
                    </div>
                </Grid>
            )
        }

        if (vehicle.version !== undefined) {
            version = (
                <Grid container item xs={6} justifyContent="center" className="grid-gray">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.version')}
                            </div>
                        {vehicle.version}
                    </div>
                </Grid>
            )
        }
    }



    /*
    {
        "vehicleState": "Idle",
        "vehicleIdent": "string",
        "vehicleName": "string",
        "remainingRange": 0,
        "stateOfCharge": 0,
        "latitude": 0,
        "longitude": 0,
        "boxInfos": [
        {
            "boxLoad": "Empty",
            "boxIdent": "string",
            "boxName": "string"
        }
        ],
        "containedBoxOrderIdents": [
        "string"
        ],
        "containedPackageIdents": [
        "string"
        ],
        "plannedTourIdents": [
        "string"
        ],
        "locked": true
    }
  */

    let vehicleState = <div></div>
    let remainingRange = <div></div>
    let stateOfCharge = <div></div>
    let lat_long = <div></div>
    let boxInfos = <div></div>
    let containedBoxOrderIdents = <div></div>
    let containedPackageIdents = <div></div>
    let plannedTourIdents = <div></div>
    let locked = <div></div>

    if (vehicleInformation !== undefined) {

        if (vehicleInformation.vehicleState !== undefined) {
            vehicleState = (
                <Grid container item xs={6} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.vehicleState')}
                            </div>
                        {vehicleInformation.vehicleState}
                    </div>
                </Grid>
            )
        }

        if (vehicleInformation.remainingRange !== undefined) {
            remainingRange = (
                <Grid container item xs={6} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.remainingRange')}
                            </div>
                        {vehicleInformation.remainingRange}
                    </div>
                </Grid>
            )
        }

        if (vehicleInformation.stateOfCharge !== undefined) {
            stateOfCharge = (
                <Grid container item xs={6} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.stateOfCharge')}
                            </div>
                        {vehicleInformation.stateOfCharge}
                    </div>
                </Grid>
            )
        }

        if (vehicleInformation.latitude !== undefined && vehicleInformation.longitude !== undefined) {
            lat_long = (
                <Grid container item xs={6} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.lat_long')}
                            </div>
                        {vehicleInformation.latitude} °N {vehicleInformation.longitude} °E
                    </div>
                </Grid>
            )
        }

        if (vehicleInformation.boxInfos !== undefined && vehicleInformation.boxInfos.length !== 0) {
            boxInfos = (
                <Grid container item xs={12} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.boxInfos')}
                            </div>
                    </div>
                    {
                        vehicleInformation.boxInfos.map(function(box) {
                            return  (
                            <Grid container xs={12} justifyContent="center">
                            <Grid container item xs={4} justifyContent="center">
                                 <div className={'text'}>
                                    <div className={'headline'}>
                                        {t('vehicleHandling.vehicleinformation.boxLoad')}
                                    </div>
                                {box.boxLoad}
                                </div>
                            </Grid>

                            <Grid container item xs={4} justifyContent="center">
                                <div className={'text'}>
                                    <div className={'headline'}>
                                        {t('vehicleHandling.vehicleinformation.boxName')}
                                    </div>
                                {box.boxName}
                            </div>
                            </Grid>

                            <Grid container item xs={4} justifyContent="center">
                                <div className={'text'}>
                                    <div className={'headline'}>
                                        {t('vehicleHandling.vehicleinformation.boxIdent')}
                                    </div>
                                {box.boxIdent}
                                </div>
                            </Grid>
                            </Grid>)
                        })
                    }
                </Grid>
            )
        }

        if (vehicleInformation.containedBoxOrderIdents !== undefined && vehicleInformation.containedBoxOrderIdents.length !== 0) {
            let boxOrderIdentsTmp = vehicleInformation.containedBoxOrderIdents.join(", ");
            containedBoxOrderIdents = (
                <Grid container item xs={12} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.containedBoxOrderIdents')}
                            </div>
                            <Grid container item xs={12} justifyContent="center">
                                <div className={'text'}>
                                    {boxOrderIdentsTmp}
                                </div>
                            </Grid>
                    </div>
                </Grid>
            )
        }

        if (vehicleInformation.containedPackageIdents !== undefined && vehicleInformation.containedPackageIdents.length !== 0) {
            let packageIdentsTmp = vehicleInformation.containedPackageIdents.join(", ");
            containedPackageIdents = (
                <Grid container item xs={12} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.containedPackageIdents')}
                            </div>

                            <Grid container item xs={12} justifyContent="center">
                                <div className={'text'}>
                                    {packageIdentsTmp}
                                </div>
                            </Grid>
                    </div>
                </Grid>
            )
        }

        if (vehicleInformation.plannedTourIdents !== undefined && vehicleInformation.plannedTourIdents.length !== 0) {
            let tourIdentsTmp = vehicleInformation.plannedTourIdents.join(", ");
            plannedTourIdents = (
                <Grid container item xs={12} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.plannedTourIdents')}
                            </div>

                            <Grid container item xs={12} justifyContent="center">
                                <div className={'text'}>
                                    {tourIdentsTmp}
                                </div>
                            </Grid>
                    </div>
                </Grid>
            )
        }

        if (vehicleInformation.locked !== undefined) {
            locked = (
                <Grid container item xs={6} justifyContent="center" className="grid-green">
                    <div className={'text'}>
                            <div className={'headline'}>
                                {t('vehicleHandling.vehicleinformation.locked')}
                            </div>
                        {vehicleInformation.locked ? "True" : "False"}
                    </div>
                </Grid>
            )
        }

    }








    return (
        <div className={classes.root}>
         <Grid container spacing={2} justifyContent="center">
                {vehicleState}
                {remainingRange}
                {stateOfCharge}
                {lat_long}
                {boxInfos}
                {containedBoxOrderIdents}
                {containedPackageIdents}
                {plannedTourIdents}
                {locked}
            </Grid>
            <br/>
            <Grid container spacing={2} justifyContent="center">
                {vehicleType}
                {maxSpeed}
                {maxWeight}
                {boxTypes}
                {launch}
                {vehicleCapacity}
                {batteryCapacity}
                {ipAddress}
                {power}
                {unitVersionSW}
                {unitVersionHW}
                {smartourQualityReference}
                {startDepot}
                {endDepot}
                {vehicleProfile}
                {version}
            </Grid>
        </div>
    );
}
export default VehicleInformationExpanded


