import React from 'react'
import QrReader from "react-qr-reader";
import {Parcel} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    setScannedParcels: Function;
    scannedParcels: boolean[];
    parcels: Parcel[];
    setParcels: Function;
    rawParcels: Parcel[];
    setAllParcelsScanned: Function;
}

const SelfPickupParcelScanner = ({setScannedParcels, scannedParcels, parcels, setParcels, rawParcels, setAllParcelsScanned}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = rawParcels.findIndex(p => p.efeuPackageId === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                window.alert(t('scanners.parcel.error') + state.result);
                setState({
                    result: 'No result',
                    facingMode: "environment"
                });
            }
            else if (scannedIndex !== -1) {
                if (parcels.length === 0){
                    setParcels(rawParcels);
                    const scannedArray = new Array(rawParcels.length).fill(false);
                    setScannedParcels(scannedArray)
                }
                const newArray = scannedParcels.slice();
                newArray[scannedIndex] = true;
                setScannedParcels(newArray);
                setAllParcelsScanned(scannedParcels.every(v => v === true));
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default SelfPickupParcelScanner
