import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ChooseBox from "./step2-ChooseBox/ChooseBox";
import Parcels from "./step1-Register/Parcels";
import EditParcels from './step3-EditOrder/EditParcels';
import ConfirmOrders from './step4-ConfirmOrders/ConfirmOrders'
import Tasks from './Tasks';
import Task from './Task'
import TaskDetail from './step4-ConfirmOrders/TaskDetail'
import {Parcel,Commission} from '../../../../apiGenerator/axios'
import {Link} from "react-router-dom";
import {DepotEventApi,IntraLogAppApi,AdminAppApi,MountingDevice} from "../../../../apiGenerator/axios";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('commissioning.step1.headline'), t('commissioning.step2.headline'), t('commissioning.step3.headline'), t('commissioning.step4.headline'), t('commissioning.step5.headline')];
}

function getStepContent(t: any, step: number) {
    switch (step) {
        case 0:
            return t('commissioning.step1.description');
        case 1:
            return t('commissioning.step2.description');
        case 2:
            return '';
        case 3:
            return t('commissioning.step4.description');
        case 4:
            return t('commissioning.step5.description');
        default:
            return 'Unknown step';
    }
}

function noop() {
    return
}

function getStepApp(step: number, setStepCompleteFunction: Function, commissions: any, selectedCommissions: boolean[], activeCommission: number, parcels: any, parcelsScanned: boolean[][],
                    setParcelsScanned: Function, boxScanned: any, setBoxScanned: Function, boxes: string[], handleEdit: Function,
                    handleNextFunction: Function, handleNextSkip: Function, handleChange: Function, startCommission: Function, mounts: MountingDevice[], prematching: number[]) {
    if (commissions.length === 0) {
        return <Tasks tasks={commissions} selectedCommissions={selectedCommissions} handleChange={handleChange} startCommission={startCommission} mounts={mounts} prematching={prematching}
        />
    }
    switch (step) {
        case 0:
            return <Tasks tasks={commissions} selectedCommissions={selectedCommissions} handleChange={handleChange} startCommission={startCommission} mounts={mounts} prematching={prematching}
            />
        case 1:
            return <Parcels parcels={parcels} activeCommission={activeCommission}
                            setStepCompleteFunction ={setStepCompleteFunction}
                            parcelsScanned={parcelsScanned} setParcelsScanned={setParcelsScanned} handleNext={handleNextFunction} selectedCommissions={selectedCommissions}
            />
        case 2:
            return (<div>
                <TaskDetail task={commissions[activeCommission]} box={boxes[activeCommission]} parcels={parcels[activeCommission]} parcelsScanned={parcelsScanned[activeCommission]} handleEdit={noop} commissionIndex={activeCommission} editMode={false}
                />
                <ChooseBox boxScanned={boxScanned} setBoxScanned={setBoxScanned} commissionSize={commissions[activeCommission].boxSize}
                              setStepCompleteFunction ={setStepCompleteFunction} handleNextFunction={handleNextFunction} handleNextSkip={handleNextSkip} scannedBoxes={boxes} commissionIndex={activeCommission} prematching={prematching[activeCommission]}
                />
                </div>
            )
        case 3:
            return <EditParcels parcels={parcels} activeCommission={activeCommission}
            setStepCompleteFunction ={setStepCompleteFunction}
            parcelsScanned={parcelsScanned} setParcelsScanned={setParcelsScanned} handleNext={handleNextFunction} selectedCommissions={selectedCommissions}
            />
        case 4:
            return <ConfirmOrders parcels={parcels} parcelsScanned={parcelsScanned} tasks={commissions} boxes={boxes} handleEdit={handleEdit} selectedCommissions={selectedCommissions}
            />

        default:
            return
    }
}

interface ParentEl {
    commissions: any,
    mounts: MountingDevice[],
}

export default function CommissionStepper({commissions, mounts}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    commissions = commissions.sort((a: any, b: any) => {
        return new Date(a.commissioningTime).getTime() - new Date(b.commissioningTime).getTime()
    })

    const prematch = (selectedCommissions :boolean[]) => {
        var allDeselected = false
        var prematching = new Array(commissions.length).fill(-1);
        console.log(selectedCommissions)
        if (selectedCommissions.every(c => c === false)) {
            allDeselected = true
        }
        for (var i = 0; i < mounts.length; i++) {
            if (!mounts[i].boxAvailableForCommissioning) {
                continue
            }
            const size = mounts[i].boxType
            console.log(size)
            for (var j = 0; j < commissions.length; j++) {
                if (!allDeselected && !selectedCommissions[j]) {
                    continue
                }
                console.log(" " + commissions[j].boxSize)
                if (prematching[j] !== -1) {
                    continue
                }
                if (size.startsWith(commissions[j].boxSize)) {
                    console.log("assigned:", j, i)
                    prematching[j] = i
                    break
                }
            }
        }
        return prematching
    }


    console.log(commissions);

    const [activeStep, setActiveStep] = React.useState(0);
    const [stepsComplete, setStepComplete] = React.useState([false, false, false, true, true])
    var scanned: boolean[][] = [];
    commissions.forEach((c: { parcels: Parcel[]; }) => {
        scanned.push(new Array(c.parcels.length).fill(false));
    });
    const [parcelsScanned, setParcelsScanned] = React.useState(scanned);
    const [boxScanned, setBoxScanned] = React.useState({box: 'None', index: -1, boxAvailable: false})
    const steps = getSteps(t);
    const [activeCommission, setActiveCommission] = React.useState(0);
    const [selectedCommissions, setSelectedCommissions] = React.useState(new Array(commissions.length).fill(false));
    const [finishedCommissions, setFinishedCommissions] = React.useState(new Array(commissions.length).fill(true));
    const [boxes, setBoxes] = React.useState(new Array(commissions.length).fill(""));
    const [boxIndex, setBoxIndex] = React.useState(new Array(commissions.length).fill(-1));
    const [prematching, setPrematching] = React.useState(prematch(selectedCommissions));
    var parcels: Parcel[][] = [];
    commissions.forEach((c: { parcels: Parcel[]; }) => {
        parcels.push(c.parcels);
    });


    const setStepCompleteByIndex = (i: number, value: boolean) => {
        const newArray = stepsComplete.slice();
        newArray[i] = value
        setStepComplete(() => newArray);
    }

    const handleChange = (index: number) => {
        const newArray = selectedCommissions.slice()
        const newArray2 = finishedCommissions.slice()
        newArray[index] = !newArray[index]
        newArray2[index] = !newArray2[index]
        if (newArray.every(e => e === false)) {
            setStepCompleteByIndex(0, false);
        } else {
            setStepCompleteByIndex(0, true);
        }
        setSelectedCommissions(newArray);
        setFinishedCommissions(newArray2);
        var newComm = -1
        setActiveCommission(0)
        for (var i = 0; i < newArray.length; i++) {
            if (newArray[i] && newComm === -1) {
                newComm = i
                setActiveCommission(i)
                console.log(activeCommission)
            }
        }
        console.log(selectedCommissions);
        console.log(newArray)
        console.log(prematching)

        setPrematching(prematch(newArray))


        console.log(prematching)
    }

    const startCommission = (index: number) => {
        const newArray = new Array(commissions.length).fill(false)
        newArray[index] = true
        const newArray2 = new Array(commissions.length).fill(true)
        newArray2[index] = false
        setSelectedCommissions(newArray);
        setPrematching(prematch(newArray));
        setFinishedCommissions(newArray2);
        setActiveCommission(index);
        setStepCompleteByIndex(0, true);
        handleNext();
    }


    var preparedCommissions: Commission[] = [];
    var preparedBoxes: string[] = []

    //console.log(parcels);

    const handleNext = () => {

        console.log("handleNext")
        console.log(selectedCommissions);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        console.log(activeStep)
        if(activeStep === steps.length-1){
            // send all
            console.log(selectedCommissions)
            console.log(parcelsScanned)

            for (var i = 0; i < selectedCommissions.length; i++) {
                console.log(i, selectedCommissions[i])
                if (selectedCommissions[i]) {
                    console.log("Commission selected, checking packages")
                    var toRemove = []
                    for (var j = 0; j < parcelsScanned[i].length; j++) {
                        console.log(i, j, parcelsScanned[i][j])
                        if (!parcelsScanned[i][j]) {
                            console.log("Adding package to remove ", parcels[i][j].efeuPackageId)
                            toRemove.push(parcels[i][j].efeuPackageId)
                        }
                    }

                    if (toRemove.length > 0) {
                        console.log("Removing packages")
                        const toRemoveCopy = toRemove.slice()
                        new AdminAppApi().getPackage(toRemoveCopy[0]).then((res) =>
                            new AdminAppApi().postRemovePackagesFromOrder({"orderIdent": res.data.relatedBoxOrderIdent, "packageOrderIdents": toRemoveCopy})
                        )
                    }
                    preparedCommissions.push(commissions[i])
                    preparedBoxes.push(boxes[i])
                }
            }

            console.log("Prepared commissions", preparedCommissions)

            preparedCommissions.forEach((p, index) => new DepotEventApi().postCompleteCommissioning({boxIdent: preparedBoxes[index],
                    commissionIdent: p.id}))
        }
        if (activeStep === steps.length-3) {
            if (boxScanned.box === "None" || boxScanned.index === -1) {
                console.log("Not setting None box")
                return
            }
            const newArray = boxes.slice()
            newArray[activeCommission] = boxScanned.box
            setBoxes(newArray)
            const newArray2 = boxIndex.slice()
            newArray2[activeCommission] = boxScanned.index
            setBoxIndex(newArray2)
            console.log("Set box")
            setBoxScanned({box: 'None', index: -1, boxAvailable: false})
            console.log(newArray)
            setStepCompleteByIndex(activeStep, true)

        }
        if (activeStep === steps.length-4) {
            if (boxes[activeCommission] !== "") {
                setBoxScanned({box: boxes[activeCommission], index: boxIndex[activeCommission], boxAvailable: true})
            }
        }
        if (activeStep === steps.length-2) {
            const newArray = finishedCommissions.slice();
            newArray[activeCommission] = true;
            console.log(activeCommission)

            var newComm = -1
            for (i = 0; i < newArray.length; i++) {
                console.log(newArray[i], selectedCommissions[i])
                if(!newArray[i]) {
                    if (selectedCommissions[i]) {
                        if (newComm === -1) {
                            newComm = i
                        }
                    } else {
                        newArray[i] = true;
                    }
                }
            }
            if (newComm === -1) {
                setActiveCommission(0)
            } else {
                setActiveCommission(newComm)
            }
            setFinishedCommissions(newArray);
            console.log(newArray);
            console.log(activeCommission)
            if(!newArray.every(c => c === true)) {
                setStepComplete([true, false, false, true, true]);
                setActiveStep(2);
            } else {
                setActiveStep(4);
            }
        }
    };

    const handleEdit = (i: number) => {
        const newArray = finishedCommissions.slice();
            newArray[i] = false;
            setFinishedCommissions(newArray);
            console.log(newArray);
            setActiveCommission(i)
            setStepComplete([true, true, true, true, true]);
            setActiveStep(3);

    }


    const handleNextSkip = () => {

        console.log("handleNextSkip")
        console.log(selectedCommissions);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        console.log(activeStep)


        const newArrayBox = boxes.slice()
        newArrayBox[activeCommission] = boxScanned.box
        setBoxes(newArrayBox)
        console.log("Set box")
        setBoxScanned({box: 'None', index: -1, boxAvailable: false})
        console.log(newArrayBox)


        const newArray = finishedCommissions.slice();
        newArray[activeCommission] = true;
        console.log(activeCommission)

        var newComm = -1
        for (var i = 0; i < newArray.length; i++) {
            console.log(newArray[i], selectedCommissions[i])
            if(!newArray[i]) {
                if (selectedCommissions[i]) {
                    if (newComm === -1) {
                        newComm = i
                    }
                } else {
                    newArray[i] = true;
                }
            }
        }
        if (newComm === -1) {
            setActiveCommission(0)
        } else {
            setActiveCommission(newComm)
        }
        setFinishedCommissions(newArray);
        console.log(newArray);
        console.log(activeCommission)
        if(!newArray.every(c => c === true)) {
            setStepComplete([true, false, false, true, true]);
            setActiveStep(2);
        } else {
            setActiveStep(4);
        }



    }


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleBackToCom = () => {
        history.push('/commissioning/list');
    }



    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>{label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(t, index)}</Typography>
                            {getStepApp(index, setStepCompleteByIndex, commissions, selectedCommissions, activeCommission, parcels, parcelsScanned, setParcelsScanned,
                            boxScanned, setBoxScanned, boxes, handleEdit, handleNext, handleNextSkip, handleChange, startCommission, mounts, prematching)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('buttons.back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!stepsComplete[index]}
                                    >
                                        {activeStep === steps.length - 1 ?
                                            t('buttons.closeBoxAndComplete') : t('buttons.next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && finishedCommissions.every(c => c === true) && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>{t('commissioning.stepper.completeMessage')}</Typography>
                        <Button className={classes.button} variant="contained" color="primary" onClick={handleBackToCom}>
                            {t('buttons.backToCom')}
                        </Button>
                </Paper>
            )}
        </div>
    );
}
