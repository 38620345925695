import React from 'react'
import QrReader from "react-qr-reader";
import {MountingDevice} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";

interface ParentEl {
    boxScanned: boolean,
    setBoxScanned: Function,
    mountingDevices: MountingDevice[],
    commissionSize: string,
    scannedBoxes: string[],
    commissionIndex: number,
}

const BoxScanner = ({setBoxScanned, mountingDevices, commissionSize, scannedBoxes, commissionIndex}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);

    const throwAlert = (id: string, state: string) => {
        if (state === 'unavailable'){
            window.alert(t('scanners.box.error') + id + t('scanners.box.unavailable'))
        }
        else if (state === 'occupied'){
            window.alert(t('scanners.box.error') + id + t('scanners.box.occupied'))
        }
        else if (state === 'too small'){
            window.alert(t('scanners.box.error') + id + t('scanners.box.tooSmall'))
        }
        else if (state === 'too large'){
            window.alert(t('scanners.box.error') + id + t('scanners.box.tooLarge'))
        }

        setState({
            result: 'No result',
            facingMode: "environment"
        })
    }


    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = mountingDevices.findIndex(device => device.boxId === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                throwAlert(state.result, 'unavailable')
                setBoxScanned({box: 'None', index: -1, boxAvailable: false})
            }
            else if (scannedIndex !== -1) {
                const scannedBoxIndex = scannedBoxes.findIndex(b => b === state.result)
                if (mountingDevices[scannedIndex].boxAvailableForCommissioning === false || (scannedBoxIndex !== -1 && scannedBoxIndex !== commissionIndex)) {
                    throwAlert(state.result, 'occupied')
                    setBoxScanned({box: state.result, index: scannedIndex, boxAvailable: false})
                }
                else if(commissionSize === "LARGE" && mountingDevices[scannedIndex].boxType === "SMALL_PACKAGE_BOX"){
                    throwAlert(state.result, 'too small')
                    setBoxScanned({box: state.result, index: scannedIndex, boxAvailable: false})
                }
                else if(commissionSize === "SMALL" && mountingDevices[scannedIndex].boxType === "LARGE_PACKAGE_BOX"){
                    throwAlert(state.result, 'too large')
                    setBoxScanned({box: state.result, index: scannedIndex, boxAvailable: false})
                }
                else {
                    setBoxScanned({box: state.result, index: scannedIndex, boxAvailable: true})
                }
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerWithMargin">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%', marginTop: '10px' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default BoxScanner
