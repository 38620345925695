import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import {DepotEventApi, MountingDevice} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import ScanBox from "./step1/ScanBox";
import ConfirmRecyclableBox from "./step2/ConfirmRecyclableBox";

interface ParentEl {
    mounts: MountingDevice[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(2)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('recyclableBoxes.register.step1'), t('recyclableBoxes.register.step2')];
}

function getStepContent(step: number, t: any) {
    switch (step) {
        case 0:
            return ``;
         case 1:
             return '';
        default:
            return 'Unknown step';
    }
}

function getStepApp(step: number, mounts: MountingDevice[], scannedIndex: number, setScannedIndex: Function,
                    setBoxOpened: Function, handleNext: Function) {
    switch (step) {
        case 0:
            return <ScanBox mountingDevices={mounts} scannedIndex={scannedIndex} setScannedIndex={setScannedIndex}
                            setBoxOpened={setBoxOpened} handleNext={handleNext}/>
        case 1:
             return <ConfirmRecyclableBox/>

        default:
            return
    }
}

export default function RecyclableBoxesStepper({mounts}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [boxOpened, setBoxOpened] = React.useState(false);
    const [scannedIndex, setScannedIndex] = React.useState(-1);
    const steps = getSteps(t);

    const handleNext = () => {
        console.log(scannedIndex);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length-1){
            if(activeStep === steps.length-1){
                unpackBox(mounts[scannedIndex].boxId)
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const history = useHistory();
    function unpackBox(boxIdent: string | undefined) {
        if (boxIdent) {
            new DepotEventApi().postReceivePackageFromBox({
                boxIdent: boxIdent
            }).then(() => history.push(''));
        }
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>
                            {label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index, t)}</Typography>
                            {getStepApp(index, mounts, scannedIndex, setScannedIndex, setBoxOpened, handleNext)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('buttons.back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!boxOpened}
                                    >
                                        {activeStep === steps.length - 1 ?
                                            t('buttons.confirmAndBackToMain') :
                                            t('buttons.next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
