import React from 'react'
import {useTranslation} from "react-i18next";
import "./ReturnedPackages.css"

interface PackageIds {
    packageIds: string[]
}

const ReturnedPackages = ({packageIds} :PackageIds ) => {
    const {t} = useTranslation();

    return (
    <div>
        {packageIds.map(id =>
            <div className='packageListItemContainer'> 
                <p id={id}>{t("incomingBoxes.register.packageId")} {id}</p>
            </div>)}
    </div>
  )
}

export default ReturnedPackages;
