import React from 'react'
import CreatedParcelEl from "./CreatedParcelEl";

interface ParentEl {
    createdParcelIds: string[],
    setCreatedParcelIds: Function,
    setLabelPrinted: Function
}

const CreatedParcels = ({createdParcelIds, setCreatedParcelIds, setLabelPrinted }: ParentEl) => {
    return (
    <div>
      {createdParcelIds.map((parcelId: string)=> (
          <div key={parcelId} className="row">
            <CreatedParcelEl parcelId={parcelId} setCreatedParcelIds={setCreatedParcelIds}
                             parcelIds={createdParcelIds} setLabelPrinted={setLabelPrinted}/>
          </div>
      ))}
    </div>
  )
}

export default CreatedParcels
