import React from 'react'
import {Grid} from "@material-ui/core";
import {BoxOrderInformationAdmin} from "../../../apiGenerator/axios";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import "../parcelHandling/labelling/ParcelHandlingStyles.css"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";

interface ParentEl {
    boxOrderInformation: BoxOrderInformationAdmin
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            marginLeft: theme.spacing(0.3),
            marginTop: theme.spacing(2)
        },
    }),
);

const BoxOrderInformationInformationSummary = ({boxOrderInformation}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid container item spacing={0} xs={12} className='grid-yellow' justifyContent='center' >

                    {t('parcelHandling.labelling.boxOrderInformationSummary.info')}

            </Grid>
            <Grid container item xs={12} spacing={0} className='grid-gray' >
                <Grid container item xs={6} spacing={0}>
                    <Col>
                        <Row>
                            {t('parcelHandling.labelling.parcelSummary.to')}
                        </Row>
                        <Row className='smallFont'>
                            {boxOrderInformation.customerFirstName} {boxOrderInformation.customerLastName}
                        </Row>
                    </Col>

                </Grid>
                <Grid container item xs={6} spacing={0}>
                    <Col>
                        <Row>
                            {t('parcelHandling.labelling.boxOrderInformationSummary.orderIdent')}<br />
                        </Row>
                        <Row className='smallFont'>
                            {boxOrderInformation.ident}
                        </Row>
                    </Col>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BoxOrderInformationInformationSummary
