import React from 'react'
import {useTranslation} from "react-i18next";
import {IntraLogAppApi } from "../../../apiGenerator/axios";
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";
import {Async} from "react-async";
import {useLocation} from "react-router";
import DataRegistration from "./overview/DataRegistration";
import WareHousePlaceFetching from "./WarehousePlaceFetching";

const OutboundParcelFetching = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const getParcels = () =>
        new IntraLogAppApi().getOutboundParcels()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)

    return (
        <div>
            <AppBarCom showIcons={true}/>

            <NotificationFetching />
            <Async promiseFn = {getParcels}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        if(location.pathname === '/outboundParcels/overview')
                            return <div>
                                <div className="containerHeadline">{t('outboundParcels.overview.headline')}</div>
                                <DataRegistration parcels={data} />
                            </div>
                        else {
                            const registeredParcels = data.filter(parcel => parcel.kepProviders !== null && parcel.kepProviders.length > 0);
                            return <div>
                                <div className="containerHeadline">{t('outboundParcels.stepper.headline')}</div>
                                <WareHousePlaceFetching parcels={registeredParcels}/>
                            </div>
                        }

                }}
            </Async>

        </div>
    )
}

export default OutboundParcelFetching
