import React from 'react'
import QrReader from "react-qr-reader";
import {Parcel} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    parcels: Parcel [][];
    scannedStates: boolean[][];
    setScanned: Function;
    setStepComplete: Function;
    handleNext: Function;
    editMode: boolean;
    selectedCommissions: boolean[];
    activeCommission: number;
}

const ParcelScanner = ({parcels, scannedStates, setScanned, setStepComplete, handleNext, editMode, selectedCommissions, activeCommission}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);

    var scanDelay = 300;
    if (editMode) {
        scanDelay = 1500;
    }


    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            var commissionIndex = -1;
            var scannedIndex = -1
            for (var i = 0; i < parcels.length; i++) {
                console.log(parcels[i], state.result)
                scannedIndex = parcels[i].findIndex(parcel => parcel.efeuPackageId === state.result);
                if (scannedIndex !== -1) {
                    commissionIndex = i;
                    break;
                }
            }
            console.log(commissionIndex, scannedIndex)
            if (state.result !== 'No result' && scannedIndex === -1) {
                window.alert(t('scanners.parcel.error') + state.result + t('scanners.parcel.unavailable'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })

            }
            else if (scannedIndex !== -1 && !scannedStates[commissionIndex][scannedIndex] && !editMode) {
                const newArray = scannedStates.slice();
                newArray[commissionIndex][scannedIndex] = true
                console.log("Scanned")
                setScanned(() => newArray);
                var complete = true;
                for (var j = 0; j < selectedCommissions.length; j++) {
                    if (selectedCommissions[j]) {
                        if (!newArray[j].every(e => e === true)) {
                            complete = false;
                            break;
                        }
                    }
                }

                if (complete) {
                    setStepComplete(0, true);
                    handleNext();
                }
            }
            else if (scannedIndex !== -1 && editMode && commissionIndex === activeCommission) {
                const newArray = scannedStates.slice();
                newArray[commissionIndex][scannedIndex] = !newArray[commissionIndex][scannedIndex]
                setScanned(() => newArray);
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={scanDelay}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default ParcelScanner
