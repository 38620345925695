import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import {Contact, DepotEventApi, Parcel} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import ContactFetching from "../selfPickup/step1/ContactFetching";
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";
import LabelParcels from "./step2/LabelParcels";

interface ParentEl {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('selfDropoff.steps.step1'),
        t('selfDropoff.steps.step2')];
}

function getStepContent(step: number, t: any) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return '';
        default:
            return 'Unknown step';
    }
}

function getStepApp(step: number,
                    scannedContact: Contact,
                    setScannedContact: Function,
                    setLabelPrinted: Function) {
    switch (step) {
        case 0:
            return <ContactFetching scannedContact={scannedContact}
                                    setScannedContact={setScannedContact}/>
        case 1:
            return <LabelParcels setLabelPrinted={setLabelPrinted}
                                 scannedContact={scannedContact}/>
        default:
            return
    }
}

export default function SelfDropoffStepper({}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [scannedContact, setScannedContact] = React.useState<Contact>(null);
    const [labelPrinted, setLabelPrinted] = React.useState(false);
    const steps = getSteps(t);

    const history = useHistory();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length - 1) {
            history.push('');
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div>
            <AppBarCom showIcons={true}/>

            <NotificationFetching />
            <div className="containerHeadline">Paketabgabe</div>
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>
                                {label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index, t)}</Typography>
                                {getStepApp(index, scannedContact, setScannedContact, setLabelPrinted)}
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            {t('buttons.back')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={(activeStep === 0 && scannedContact === null) ||
                                            (activeStep === 1 && !labelPrinted)}
                                        >
                                            {activeStep === steps.length - 1 ?
                                                t('buttons.confirmAndBackToMain') :
                                                t('buttons.next')}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    );
}
