import React from 'react'
import QrReader from "react-qr-reader";
import {BoxInformation} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";

interface ParentEl {
    boxes: BoxInformation[],
    setScannedBoxIndex: Function
}

const BoxForActivationsScanner = ({boxes, setScannedBoxIndex}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);

    const throwAlert = (id: string, state: string) => {
        if (state === 'unavailable'){
            window.alert(t('scanners.box.error') + id + t('scanners.box.unavailable'))
        }

        setState({
            result: 'No result',
            facingMode: "environment"
        })
    }

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = boxes.findIndex(b => b.id === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                throwAlert(state.result, 'unavailable')
                setScannedBoxIndex(-1);
            }
            else if (scannedIndex !== -1) {
                if (boxes[scannedIndex].availableForActivation === true) {
                    setScannedBoxIndex(scannedIndex);
                }
                else {
                    throwAlert(state.result, 'unavailable')
                    setScannedBoxIndex(-1);
                }
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerWithMargin">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%', marginTop: '10px' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default BoxForActivationsScanner
