import React from 'react'
import {MountingDevice} from "../../../../apiGenerator/axios";
import MountingDeviceEl from "../../../components/mounting/MountingDeviceEl";
import InboundBoxScanner from "../../../components/scanner/InboundBoxScanner";
import Buttons from "./Buttons";
import InboundBoxesFetching from "./InboundBoxesFetching";

interface ParentEl {
    mountingDevices: MountingDevice[];
    setBoxOpened: Function;
    scannedIndex: number;
    setScannedIndex: Function;
    handleNext: Function;
}

const ScanBox = ({mountingDevices, setBoxOpened, scannedIndex, setScannedIndex, handleNext}: ParentEl) => {
    const mountAvailable: boolean[] = [];

    // Check for inbound boxes
    for (const index in mountingDevices) {
        const isInbound: any =  mountingDevices[index].boxIsInbound;
        if (isInbound != null) {
            mountAvailable[index] = isInbound;
        } else {
            mountAvailable[index] = false;
        }
    }
    const highlightIndex: number = scannedIndex !== -1 ? scannedIndex : -1;
    const scannerOrButtons = (scannedIndex !== -1 && mountAvailable[scannedIndex]) ?
        <Buttons setScannedIndex={setScannedIndex} setBoxOpened={setBoxOpened} handleNext={handleNext}
                 scannedBoxIdent={mountingDevices[scannedIndex].boxId}/> :
        <InboundBoxScanner mountingDevices={mountingDevices} setScanned={setScannedIndex} />


  return (
    <div>
        <div className="container">
            <div className="rowC">
                {mountingDevices.map((device: MountingDevice, i: number)=> (
                    <div key={device.id}>
                        <MountingDeviceEl number={i+1} boxType={device.boxType}
                                          highlightedIndex={highlightIndex} available={mountAvailable[i]}/>
                    </div>
                ))}
            </div>
        </div>
        {scannerOrButtons}
        <InboundBoxesFetching mounts={mountingDevices} scannedIndex={scannedIndex}/>
    </div>
  )
}

export default ScanBox
