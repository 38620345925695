import React from 'react'
import {Grid} from "@material-ui/core";
import {ImCheckboxUnchecked, ImCheckboxChecked, VscError} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import {IntraLogAppApi, Parcel} from "../../../../apiGenerator/axios";

interface ParentEl {
    parcel: Parcel,
    index: number,
    scannedParcels: boolean[],
    setScannedParcels: Function,
    setAtLeastOneScanned: Function
}

const ParcelEl = ({parcel, index, scannedParcels, setScannedParcels, setAtLeastOneScanned}: ParentEl) => {
    const removeParcel = () => {
        const arrayCopy = scannedParcels.slice();
        arrayCopy[index] = false;
        setScannedParcels(arrayCopy);
        const noneScanned = arrayCopy.every(b => b === false);
        setAtLeastOneScanned(!noneScanned);
    }

    let scanned = scannedParcels[index] === true;
    let style = scanned ? 'box-grid-green': 'box-grid-yellow'

    return (
        <div className='gridContainer'>
            <Grid container spacing={1} className={style} >
                <Grid container item xs={10} spacing={0}>
                    {parcel.efeuPackageId}
                </Grid>
                {scanned &&
                <Grid container item xs={1} spacing={0}>
                    <ImCheckboxChecked size={25} style={{ color: green[500], margin: "2px" }}/>
                </Grid>}
                {scanned &&
                <Grid container item xs={1} spacing={0}>
                    <VscError size={22} style={{ margin: 5, cursor: "pointer" }} onClick={removeParcel}/>
                </Grid>}

                {!scanned &&
                <Grid container item xs={1} spacing={0}>
                    <ImCheckboxUnchecked size={25} color="disabled" style={{ margin: "2px" }}/>
                </Grid>}
            </Grid>
        </div>
    )
}

export default ParcelEl
