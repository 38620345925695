import React from 'react'

const ConfirmRecyclableBox = () => {
    return (
        <div>
        </div>
    )
}

export default ConfirmRecyclableBox 
