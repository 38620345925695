import React from 'react'
import {BoxInformation} from "../../../../apiGenerator/axios";
import BoxEl from "../../boxActivation/step1/BoxEl";
import BoxForDeactivationScanner from "../../../components/scanner/BoxForDeactivationScanner";

interface ParentEl {
    boxes: BoxInformation[];
    setScannedBoxIndex: Function;
    scannedBoxIndex: number;
}

const ScanBoxForDeactivation = ({boxes, setScannedBoxIndex, scannedBoxIndex}: ParentEl) => {

    return (
        <div>
            <BoxForDeactivationScanner boxes={boxes} setBoxScanned={setScannedBoxIndex} />
            {scannedBoxIndex !== -1 && (
                <div key={boxes[scannedBoxIndex].id} className="row">
                    <BoxEl scannedBox={boxes[scannedBoxIndex]}/>
                </div>
            )}
        </div>
    )
}

export default ScanBoxForDeactivation
