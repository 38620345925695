import React, {useEffect, useState} from 'react'
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {
    StatusApi,
    IntraLogAppApi,
    ProcessMgmtApi
} from "../../apiGenerator/axios";
import AppBarCom from "../header/AppBarCom";
import NotificationFetching from "../notifications/NotificationFetching";
import ReactLoading from "react-loading";
import AppBarMain from "../header/AppBarMain";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const DemoComponents = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const [state, setState] = useState(-1);
    const [stateDone, setStateDone] = useState(false);
    const [done, setDone] = useState(true);

    useEffect(() => {
        const determineState = async () => {
            let currentState = 0;
            let tours = await new StatusApi().getTourStatusList();
            let commissionings = await new IntraLogAppApi().getComissionings();
            let parcels = await new IntraLogAppApi().getAllParcels();

            if (tours.data.length === 0 && parcels.data.length === 0) {
                currentState = 0;
            } else if (tours.data.length === 0 && commissionings.data.length === 0 && parcels.data.length > 0){
                currentState = 1;
            } else if (tours.data.length > 0 && (commissionings.data.length > 0 || parcels.data.length > 0)) {
                currentState = 2;
            } else if (tours.data.length > 0 && commissionings.data.length === 0 && parcels.data.length === 0) {
                currentState = 3;
            }
            setState(() => currentState);
            setStateDone(() => true);
        };
        determineState();
    }, [stateDone]);


    const resetData = () => {
        setDone(false);
        new IntraLogAppApi().postResetTestData().then(() => {
            setStateDone(() => false);
            setDone(true);
        });
    }

    const registerPackages = () => {
        setDone(false);
        new IntraLogAppApi().postTestRegisterPackages().then(() => {
            setStateDone(() => false);
            setDone(true);
        });
    }

    const planTours = () => {
        setDone(false);
        new ProcessMgmtApi().postStartPlanning().then(() => {
            setStateDone(() => false);
            setDone(true);
        });
    }

    const completeCommissioning = () => {
        setDone(false);
        new IntraLogAppApi().postCompleteTestCommissioning().then(() => {
            setStateDone(() => false);
            setDone(true);
        });
    }

    const completeStops = () => {
        setDone(false);
        new IntraLogAppApi().postCompleteTestCustomerStops().then(() => {
            setStateDone(() => false);
            setDone(true);
        });
    }

    return (
        <>
          {(!done || !stateDone) ? (
              <>
                  <AppBarMain />
                  <div style={{display: 'flex', marginTop: '10%', justifyContent: 'center'}}>
                      <ReactLoading
                          type={"spinningBubbles"}
                          color={"darkgreen"}
                          height={'20%'}
                          width={'20%'}
                      />
                  </div>

              </>
          ) : (
              <ul>
                  <div>
                      <AppBarCom showIcons={true}/>

                      <NotificationFetching />
                      <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => history.push('/')}
                      >
                          {t('buttons.backToMain')}
                      </Button>
                      <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={resetData}
                      >
                          {t('main.other.reset')}
                      </Button>
                      <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={registerPackages}
                          disabled={state !== 0}
                      >
                          {t('buttons.registerPackages')}
                      </Button>
                      <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={planTours}
                          disabled={state !== 1}
                      >
                          {t('buttons.plan')}
                      </Button>
                      <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={completeCommissioning}
                          disabled={state !== 2}
                      >
                          {t('buttons.completeCommissioningTest')}
                      </Button>
                      <Button
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={completeStops}
                          disabled={state !== 3}
                      >
                          {t('buttons.completeCustomerStopsTest')}
                      </Button>
                  </div>
              </ul>
          )}
        </>

  )
}

export default DemoComponents
