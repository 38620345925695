import React from "react";
import "./Main.css";
import Button from "./Button";
import { Link } from 'react-router-dom';
import {Grid} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AppBarCom from "../header/AppBarCom";
import NotificationFetching from "../notifications/NotificationFetching";

const Main = () => {
    const { t} = useTranslation();
    return (
        <div>
            <AppBarCom showIcons={true}/>

            <NotificationFetching />

            <div className="container">
                <div className="containerHeadline">{t('main.tasks.headline')}</div>

                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/commissioning/list">
                            <Button text={t('main.tasks.button1')}/>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/parcel-Handling/label">
                            <Button text={t('main.tasks.button2')}/>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/incomingBoxes">
                            <Button text={t('main.tasks.button3')}/>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/activateBox">
                            <Button text={t('main.tasks.activateBox')}/>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/outboundParcels/overview">
                            <Button text={t('main.tasks.button5')}/>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/storeBox">
                            <Button text={t('main.tasks.storeBox')}/>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/recyclableBox">
                            <Button text={t('main.tasks.recyclableBox')}/>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/deactivateBox">
                            <Button text={t('main.tasks.deactivateBox')}/>
                        </Link>
                    </Grid>
                </Grid>
            </div>
            <div className="container">
                <div className="containerHeadline">{t('main.other.headline')}</div>

                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/selfPickup">
                            <Button text={t('main.other.button1')}/>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/parcelInformation">
                            <Button text={t('main.other.button2')}/>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/boxInformation">
                            <Button text={t('main.other.button6')}/>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/vehicleInformation">
                            <Button text={t('main.other.button7')}/>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/kepPickup">
                            <Button text={t('main.other.button3')}/>
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/selfDropoff">
                            <Button text={t('main.other.button4')}/>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Link to="/demo">
                            <Button text={t('main.other.demo')} />
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/reset">
                            <Button
                                text={t('main.other.reset')}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Main
