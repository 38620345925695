import React from 'react'
import {Parcel} from "../../../../../apiGenerator/axios";
import ParcelEl from "./ParcelEl";
import ParcelScanner from "../../../../components/scanner/ParcelScanner";

interface ParentEl {
  parcels: Parcel[][];
  activeCommission: number;
  setStepCompleteFunction: Function;
  parcelsScanned: any;
  setParcelsScanned: Function;
  handleNext: Function;
  selectedCommissions: boolean[];
}

const Parcels = ({parcels, activeCommission, setStepCompleteFunction, parcelsScanned, setParcelsScanned, handleNext, selectedCommissions }: ParentEl) => {

  function parcelMap(parcels: Parcel[], commissionIndex: number) {
    if (selectedCommissions[commissionIndex]) {
      return parcels.map((parcel: Parcel, index: number) => (
        <div key={parcel.efeuPackageId} className="row">
          <ParcelEl parcel = {parcel} scanned={parcelsScanned[commissionIndex][index]}/>
        </div>
      ))
    } else {
      return <></>
    }

}
    return (
    <div>
      <ParcelScanner parcels={parcels} scannedStates={parcelsScanned} setScanned={setParcelsScanned}
                     setStepComplete={setStepCompleteFunction} handleNext={handleNext} editMode={false} selectedCommissions={selectedCommissions} activeCommission={-2}
      />
      {parcels.map((parcel: Parcel[], index: number)=> (
          parcelMap(parcel, index)
      ))}
    </div>
  )
}

export default Parcels
