import React from 'react'
import QrReader from "react-qr-reader";
import {BoxInformation} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    setScannedBoxIndex: Function;
    boxes: BoxInformation[]
}

const BoxInformationScanner = ({setScannedBoxIndex, boxes}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }

    const [state, setState] = React.useState(initialState);

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = boxes.findIndex(box => box.id === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                window.alert(t('scanners.box.error') + state.result + t('scanners.box.unavailable'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })

            }
            else if (scannedIndex !== -1) {
                setScannedBoxIndex(scannedIndex);
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default BoxInformationScanner
