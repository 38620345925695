import React, {useState} from 'react'
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {FiPrinter} from "react-icons/all";
import {DepotEventApi, MountingDevice} from "../../../../apiGenerator/axios";
import CreatedParcels from "./CreatedParcels";

interface ParentEl {
    setLabelPrinted: Function,
    mount: MountingDevice
}

const CheckAndLabel = ({setLabelPrinted, mount}: ParentEl) => {
    const {t} = useTranslation();
    const initialStringArray : string[] = [];
    const [createdParcelIds, setCreatedParcelIds] = useState(initialStringArray);
    const printLabel = () => {
        new DepotEventApi().postLabelOutboundParcel({contactId: mount.boxFromContactIdent}).then((res) => {
            if (res.data.packageOrderIdent){
                setCreatedParcelIds([...createdParcelIds, res.data.packageOrderIdent] );
            }
        });
        setLabelPrinted(() => true)
    }
    return (
        <div>
            <Button
                variant="contained"
                color = "primary"
                onClick ={() => printLabel()}
                style={{
                    margin: "7px",
                }}
            >
                <FiPrinter size={10} style={{marginRight: 10 }}/> {t('buttons.printLabel')}
            </Button>
            <CreatedParcels createdParcelIds={createdParcelIds}
                            setCreatedParcelIds={setCreatedParcelIds}
                            setLabelPrinted={setLabelPrinted}/>
        </div>
    )
}

export default CheckAndLabel
