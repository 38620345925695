import React from 'react'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

interface ParentEl {
    setScannedIndex: Function;
}

const Buttons = ({setScannedIndex}: ParentEl) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const chooseDifferentBox = () => {
        setScannedIndex(() => -1);
    }
  return (
    <div>
        <Button
            disabled={false}
            className={classes.button}
            onClick={chooseDifferentBox}
        >
            {t('buttons.scanDifferentMount')}
        </Button>
    </div>
  )
}

export default Buttons
