import React, {useState} from 'react'
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {IntraLogAppApi} from "../../apiGenerator/axios";
import AppBarCom from "../header/AppBarCom";
import NotificationFetching from "../notifications/NotificationFetching";
import ReactLoading from "react-loading";
import AppBarMain from "../header/AppBarMain";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '40%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const Reset = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const [done, setDone] = useState(true);

    const resetData = () => {
        setDone(false);
        new IntraLogAppApi().postResetIntraDemo().then(() => setDone(true));
    }
  return (
      <>
          {!done ? (
              <>
                  <AppBarMain />
                  <div style={{display: 'flex', marginTop: '10%', justifyContent: 'center'}}>
                      <ReactLoading
                          type={"spinningBubbles"}
                          color={"darkgreen"}
                          height={'20%'}
                          width={'20%'}
                      />
                  </div>

              </>
          ) : (
              <ul>
                  <div>
                      <AppBarCom showIcons={true}/>

                      <NotificationFetching />
                      <Button
                          variant="contained"
                          className={classes.button}
                          onClick={() => history.push('/')}
                      >
                          {t('buttons.backToMain')}
                      </Button>
                      <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={resetData}
                      >
                          {t('main.other.reset')}
                      </Button>
                  </div>
              </ul>
          )}
      </>

  )
}

export default Reset
