import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";
import "./ScannerStyles.css"

class ScannerDemo extends Component {
    state = {
        result: 'No result'
    }


    handleScan = (data: any) => {
        if (data) {
            this.setState({
                result: data,
                facingMode: "emvironment"
            })
        }
    }
    handleError = (err: any) => {
        console.error(err)
    }
    render() {
        return (
            <div>
                <AppBarCom showIcons={true}/>

                <NotificationFetching />
                <div className="scannerContainer">
                    <QrReader
                        delay={300}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{ width: '50%' }}
                        showViewFinder={false}
                    />
                </div>
                <p>{this.state.result}</p>
            </div>
        )
    }
}

export default ScannerDemo


