import React from 'react'
import { Grid} from "@material-ui/core";
import {ImCheckboxChecked, VscError} from "react-icons/all";
import {green} from "@material-ui/core/colors";

interface ParentEl {
    boxName: string,
    mountName: string,
    index: number,
    available: boolean
}

const BoxEl = ({boxName, mountName, index, available}: ParentEl) => {
    let style = 'box-grid-red'
    let icon = <VscError size={25} style={{margin: 0 }}/>
    if (available){
        style = 'box-grid-green'
        icon = <ImCheckboxChecked size={25} style={{ color: green[500], margin: 0 }}/>
    }
    return (
        <div className='gridContainer-box'>
            <Grid container spacing={1} className={style} style={{fontSize: '12px'}}>
                <Grid container item xs={5} spacing={0}>
                    Box: {boxName}
                </Grid>
                <Grid container item xs={6} spacing={0}>
                    Dock: {mountName}
                </Grid>
                <Grid container item xs={1} spacing={0}>
                    {icon}
                </Grid>
            </Grid>
        </div>
    )
}

export default BoxEl
