import React from 'react'
import {Grid} from "@material-ui/core";
import {ImCheckboxChecked} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import {BoxInformation} from "../../../../apiGenerator/axios";

interface ParentEl {
    scannedBox: BoxInformation
}

const BoxEl = ({scannedBox}: ParentEl) => {
    return (
        <div className='gridContainer'>
            <Grid container spacing={1} className='grid-green' >
                <Grid container item xs={9} spacing={0}>
                    {scannedBox.boxName}
                </Grid>
                <Grid container item xs={2} spacing={0}>
                    {scannedBox.boxType.substr(0,1)}
                </Grid>
                <Grid container item xs={1} spacing={0}>
                    <ImCheckboxChecked size={25} style={{ color: green[500], margin: "2px" }}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default BoxEl
