import React from 'react'
import {useTranslation} from "react-i18next";
import {IncomingBox, MountingDevice} from "../../../../apiGenerator/axios";
import ReturnedPackages from './ReturnedPackages';
import "./ReturnedPackages.css";

interface ParentEl {
    box: IncomingBox,
    mount: MountingDevice
}

const CheckReturnedPackages = ({box, mount}: ParentEl) => {
    const {t} = useTranslation();

    
    return (
        <div className='returnedPackagesContainer'>
            <h1>{t("incomingBoxes.register.failedDeliveries")}</h1>
            <ReturnedPackages packageIds={box.returnedPackageIds} />
        </div>
    )
}

export default CheckReturnedPackages