import * as React from 'react';
import {IntraLogAppApi, Commission} from "../../apiGenerator/axios";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import NotificationBar from "./NotificationBar"
import 'react-notifications/lib/notifications.css';
import {useTranslation} from "react-i18next";
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { GoPackage } from 'react-icons/go';



const NotificationFetching = () => {
    const baseApi = new IntraLogAppApi();
    const {t} = useTranslation()
    const loadUsers = () =>
            baseApi.getComissionings()
            .then(res => (res.data ? res.data : Promise.reject(res)))

    var timeoutID :NodeJS.Timeout = null
    const audio = React.useRef(null)
    const [commissions, setCommissions] = React.useState([])
    const [notificationTime, setNotificationTime] = React.useState(moment().add("16", "m"))

    const checkForNotifications = (future15m: moment.Moment, data: Commission[]) => {
        data.forEach((task) => {
            const ctime = moment(task.commissioningTime)
            const future16m = moment(future15m).add(1, "m")
            console.log(ctime.toISOString(), future15m.toISOString(), task.id)
            var playSound = false
            if (ctime.isSameOrAfter(future15m, "second") && ctime.isSameOrBefore(future16m, "second")) {
                console.log(moment().toISOString(), " Sending notification")
                const desc = <Grid container spacing={1} className='grid' >
                                <Grid container item xs={6} spacing={0}>
                                    {task.name}
                                </Grid>
                                <Grid container item xs={1} spacing={0}>
                                    {task.parcels?.length}
                                </Grid>
                                <Grid container item xs={1} spacing={0}>
                                    <GoPackage className="icon" size={27} />
                                </Grid>
                                <Grid container item xs={1} spacing={0}>
                                    {task.boxSize?.substring(0,1)}
                                </Grid>
                            </Grid>
                NotificationManager.info(t('notifications.commission15m'), desc, 10000)
                playSound = true
            }
            const muted = localStorage.getItem("muted")

            if (playSound && audio !== null && muted !== "true") {
                audio.current.play().then().catch(() => {})
                navigator.vibrate(200)
            }
        })
    }

    const scheduleNotifications = () => {
        console.log(moment().toISOString(), " Scheduling Notifications")
        loadUsers().then((data) => {
            const now = moment()
            const until_next_min = 60 - now.seconds()
            console.log(now.toISOString(), " now")
            setCommissions(data)
            const next_min = now.subtract(now.seconds(), "s").add(1, "m")
            const future15m = next_min.add("15", "m")
            setNotificationTime(future15m)

            console.log(next_min.unix().toString(), " Next schedule")
            const last_schedule = localStorage.getItem("LastSchedule")
            if (last_schedule !== null) {
                if (last_schedule === next_min.unix().toString()) {
                    console.log("Already scheduled for next minute, skipping schedule.")
                    return
                }
            }

            localStorage.setItem("LastSchedule", next_min.unix().toString())

            console.log("Until next min: ", until_next_min)
            timeoutID = setTimeout(() => checkForNotifications(future15m, data), until_next_min * 1000)
            console.log("Started timeout: ", timeoutID)

        })
    }

    React.useEffect(() => {
        // componentdidmount
            console.log("Notification component mounted")
            scheduleNotifications()
            const schedulerID = setInterval(() => scheduleNotifications(), 60000)
            console.log("Starting interval calls ", schedulerID)
            audio.current = new Audio("/shiny-ding.mp3")

        return () => {
            // componentwillunmount
            console.log("Notification component will be unmounted")
            if (schedulerID !== null) {
                console.log("Clearing interval for ID: ", schedulerID)
                clearInterval(schedulerID)
            }
        }
    }, [])

  return (
    <div>
        <NotificationContainer />
        <NotificationBar commissions={commissions} notificationTime={notificationTime}/>
    </div>
  );
};




export default NotificationFetching
