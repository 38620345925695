import React from 'react'
interface ParentEl {
    number: number
    available: boolean
    boxType: any
    highlightedIndex: number
    mountAvailable?: boolean
}

const MountingDeviceEl = ({number, boxType, available, highlightedIndex, mountAvailable}: ParentEl) => {
    let styling = "colR";
    if (highlightedIndex === number-1) {
        styling = "colR-highlighted"
    }

    let boxSizeString = "";
    let boxClass = "no-box";
    if (boxType === "SMALL_PACKAGE_BOX") {
        boxClass = "box-small";
        boxSizeString = "S";
    } else if (boxType === "LARGE_PACKAGE_BOX") {
        boxClass = "box-large";
        boxSizeString = "L";
    }

    if (available && boxClass !== "no-box") {
        boxClass += "-av";
    } else if (!available && boxClass !== "no-box"){
        boxClass += "-unav";
    }

    let mountClass = "mount";
    if (mountAvailable === true) {
        mountClass = "mount-av";
    }
    return (
        <div className={styling}>
            <div className={boxClass}>
                {boxSizeString}
            </div>
            <div className={mountClass}>
                {number}
            </div>
        </div>
    )
}

export default MountingDeviceEl
