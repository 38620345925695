
function checkLoggedIn() {
    return localStorage.getItem("token") != null;
}

async function logOut(history: any, expired: boolean) {
    localStorage.removeItem("token");
    if (expired) {
        localStorage.setItem("expired", "true");
    } else {
        localStorage.setItem("expired", "false");
    }
    history.push({pathname: `/login`});
}

function isSessionExpired() {
    let expired = localStorage.getItem("expired");
    return expired != null && expired === "true";
}

async function removeSessionExpired(setShowExpired: any) {
    localStorage.removeItem("expired");
    setShowExpired(false);
}

export {checkLoggedIn, logOut, isSessionExpired, removeSessionExpired}