import React from 'react'
import {GoPackage} from "react-icons/all";
import {Commission} from "../../../../apiGenerator/axios";
import {Grid} from "@material-ui/core";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import moment from 'moment';
import "../../../GlobalStyles.css"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            marginLeft: theme.spacing(0.3)
        },
    }),
);

interface ParentEl {
    task: Commission,
    selected: boolean,
    handleChange: Function,
    commissionIndex: number,
    startCommission: Function,
    prematching: number,
}

const Task = ({task, selected, handleChange, commissionIndex, startCommission, prematching}: ParentEl) => {
    const classes = useStyles();
    const now = moment()
    const ctime = moment(task.commissioningTime)
    const diff = moment.duration(ctime.diff(now))
    let diffInMinutes = diff.asMinutes()
    // while (diffInMinutes > 1000){
    //    diffInMinutes /= 10
    //}
    diffInMinutes = Math.round(diffInMinutes)
    var difftext = ""
    if (diffInMinutes < 10 && diffInMinutes > 0) {
        difftext = "" + diffInMinutes + " min"
    } else {
        difftext = "" + ctime.hour() + ":"  + (ctime.minute() < 10 ? "0" + ctime.minute() : ctime.minute())
    }

    function handleClick() {
        handleChange(commissionIndex);
    }

    function handleStart() {
        startCommission(commissionIndex);
    }


    let selectedCheckbox = <ImCheckboxUnchecked size={19} color="disabled" style={{ margin: "7px" }} onClick={handleClick}/>
    if (selected) {
        selectedCheckbox = <ImCheckboxChecked size={19} style={{ color: green[500], margin: "7px" }} onClick={handleClick}/>
    }

    // let selectedCheckbox = <input type="checkbox" checked={checked} onClick={handleClick} />

  return (

    <Grid container>
        <Grid container item xs={10} spacing={0} onClick={handleStart}>
        <div className='gridContainer'>
                <Grid container spacing={1} className='grid' >
                    <Grid container item xs={12} spacing={0}>
                        {task.name}
                    </Grid>
                </Grid>
                <Grid container spacing={1} className='grid'>
                    <Grid container item xs={1} spacing={0}>
                        {task.parcels?.length}
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        <GoPackage className="icon" size={27} />
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        {task.boxSize?.substr(0,1)}
                    </Grid>
                    <Grid container item xs={2} spacing={0} >
                        {difftext}
                    </Grid>
                    <Grid container item xs={7} spacing={0}>
                        {prematching > -1 ? "Dock " + (prematching + 1): "Kein Vorschlag"} {/* Erweitern auf keine box verfügbar*/}
                    </Grid>
                </Grid>
        </div>
        </Grid>
        <Grid container item xs={2} spacing={0} direction="column" alignItems="center" justifyContent="center">
            {selectedCheckbox}
        </Grid>
    </Grid>

  )
}

export default Task
