import React from 'react'
import {Grid} from "@material-ui/core";
import {Parcel} from "../../../apiGenerator/axios";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import "../parcelHandling/labelling/ParcelHandlingStyles.css"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";

interface ParentEl {
    parcel: Parcel
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            marginLeft: theme.spacing(0.3),
            marginTop: theme.spacing(2)
        },
    }),
);

const ParcelInformationSummary = ({parcel}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    let labelCheckbox = <ImCheckboxUnchecked size={19} color="disabled" style={{ margin: "7px" }}/>
    if (parcel.labelled){
        labelCheckbox = <ImCheckboxChecked size={19} style={{ color: green[500], margin: "7px" }}/>
    }
    return (
        <div className='gridContainer'>
            <Grid container spacing={1} className='grid-gray' >
                <Grid container item xs={4} spacing={0}>
                    <Col>
                        <Row>
                            {t('parcelHandling.labelling.parcelSummary.to')}
                        </Row>
                        <Row className='smallFont'>
                            {parcel.recipient?.firstName} {parcel.recipient?.lastName}
                        </Row>
                    </Col>

                </Grid>
                <Grid container item xs={4} spacing={0}>
                    {t('parcelHandling.labelling.parcelSummary.from')}<br />
                    <div className='smallFont'>
                        {parcel.sender?.firstName} {parcel.sender?.lastName}
                    </div>
                </Grid>
                <Grid container item xs={1} spacing={0}>
                </Grid>
                <Grid container item xs={2}  spacing={0}>
                    {t('parcelHandling.labelling.parcelSummary.label')}
                    {labelCheckbox}
                </Grid>

            </Grid>
        </div>
    )
}

export default ParcelInformationSummary
