import React, {useState} from 'react'
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {FiPrinter, GiBroom} from "react-icons/all";
import {Contact, DepotEventApi} from "../../../../apiGenerator/axios";
import CreatedParcels from "../../incomingBoxes/step2/CreatedParcels";

interface ParentEl {
    setLabelPrinted: Function,
    scannedContact: Contact
}

const LabelParcels = ({setLabelPrinted, scannedContact}: ParentEl) => {
    const {t} = useTranslation();
    const initialStringArray : string[] = [];
    const [createdParcelIds, setCreatedParcelIds] = useState(initialStringArray);
    const printLabel = () => {
        new DepotEventApi().postLabelOutboundParcel({contactId: scannedContact.ident}).then((res) => {
            if (res.data.packageOrderIdent){
                setCreatedParcelIds([...createdParcelIds, res.data.packageOrderIdent] );
            }
        });
        setLabelPrinted(() => true)
    }
  return (
    <div>
        <Button
            variant="contained"
            color = "primary"
            onClick ={() => printLabel()}
            style={{
                margin: "7px",
            }}
        >
            <FiPrinter size={10} style={{marginRight: 10 }}/> {t('buttons.printLabel')}
        </Button>
        <CreatedParcels createdParcelIds={createdParcelIds}
                        setCreatedParcelIds={setCreatedParcelIds}
                        setLabelPrinted={setLabelPrinted}/>
    </div>
  )
}

export default LabelParcels
