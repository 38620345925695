import React from 'react';
import 'react-notifications/lib/notifications.css';
import { useTranslation } from 'react-i18next';
import { Commission } from '../../apiGenerator/axios';
import moment from "moment";
import "../GlobalStyles.css";
import { Grid } from '@material-ui/core';
import { GoPackage } from 'react-icons/go';

interface ParentEl {
  commissions: Commission[],
  notificationTime: any
}

const NotificationBar = ({commissions, notificationTime} :ParentEl) => {
  const {t} = useTranslation()

  commissions = commissions.filter((task) => (moment(task.commissioningTime).isBefore(notificationTime, "minute")))
  var message = <></>
  if (commissions.length > 0) {
    message = <Grid className="grid-yellow">{t('notifications.barheadline')}</Grid>
  }
  const now = moment()

  return (
    <div>
      {message}
      {commissions.map((task) => {
        const ctime = moment(task.commissioningTime)
        const diff = moment.duration(ctime.diff(now))
        let diffInMinutes = diff.asMinutes()
        // while (diffInMinutes > 1000){
        //    diffInMinutes /= 10
        //}
        diffInMinutes = Math.round(diffInMinutes)
        var difftext = ""
        if (diffInMinutes < 10 && diffInMinutes > 0) {
            difftext = "" + diffInMinutes + " min"
        } else {
            difftext = "" + ctime.hour() + ":"  + (ctime.minute() < 10 ? "0" + ctime.minute() : ctime.minute())
        }
        return <Grid key={task.id} className="grid-yellow">
                <Grid container spacing={2} className='grid' justifyContent='center'>
                  <Grid container item xs={5} spacing={0}>
                      {task.name}
                  </Grid>
                  <Grid container item xs={1} spacing={0}>
                      {task.parcels?.length}
                  </Grid>
                  <Grid container item xs={1} spacing={0}>
                      <GoPackage className="icon" size={27} />
                  </Grid>
                  <Grid container item xs={1} spacing={0}>
                      {task.boxSize?.substr(0,1)}
                  </Grid>
                  <Grid container item xs={3} spacing={0} >
                        {difftext}
                  </Grid>
                </Grid>
              </Grid>

      }
    )}
  </div>
  )

}

export default NotificationBar;