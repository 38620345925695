import React from 'react'
import QrReader from "react-qr-reader";
import {BoxOrderInformationAdmin, Parcel} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    setScannedParcelIndex: Function;
    parcels: Parcel[];
    setScannedBoxOrderIndex: Function;
    boxOrderInformations: BoxOrderInformationAdmin[];
    setScannedParcelIdent: Function;
    deletedParcels: string[];
}

const ParcelInformationScanner = ({setScannedParcelIndex, parcels, setScannedBoxOrderIndex, boxOrderInformations, setScannedParcelIdent, deletedParcels}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }

    const [state, setState] = React.useState(initialState);

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = parcels.findIndex(parcel => parcel.efeuPackageId === state.result);
            const scannedBoxIndex = boxOrderInformations.findIndex(b => b.containedPackageIdents?.includes(state.result))
            if (scannedIndex === -1 && scannedBoxIndex === -1) {
                setScannedParcelIdent("");
            } else {
                setScannedParcelIdent(state.result);
            }

            if ((state.result !== 'No result' && scannedIndex === -1 && scannedBoxIndex === -1) || deletedParcels.includes(state.result)) {
                window.alert(t('scanners.parcel.error') + state.result + t('scanners.parcel.unavailable'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })

            }
            else if (scannedIndex !== -1) {
                setScannedParcelIndex(scannedIndex);
            }
            else if (scannedBoxIndex !== -1) {
                setScannedBoxOrderIndex(scannedBoxIndex);
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default ParcelInformationScanner
