import React from 'react'
import {KepProviderEnum, Parcel} from "../../../../apiGenerator/axios";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import ParcelEl from "./ParcelEl";
import KepPickupParcelScanner from "../../../components/scanner/KepPickupParcelScanner";

interface ParentEl {
    setScannedParcels: Function;
    scannedParcels: boolean[];
    kepParcels: Parcel[];
    setAtLeastOneScanned: Function;
    selectedKep: KepProviderEnum;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const ScanParcels = ({setScannedParcels, scannedParcels, kepParcels, setAtLeastOneScanned, selectedKep}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <div>
            {kepParcels.length > 0 &&
            <KepPickupParcelScanner setScannedParcels={setScannedParcels}
                                    scannedParcels={scannedParcels}
                                    parcels={kepParcels}
                                    setAtLeastOneScanned={setAtLeastOneScanned}
            />}
            {kepParcels.length === 0 && t('kepPickup.noPackages')}
            {kepParcels.map((parcel: Parcel, index: number)=> (
                <div key={parcel.efeuPackageId} className="row">
                    <ParcelEl parcel={parcel}
                              index={index}
                              scannedParcels={scannedParcels}
                              setScannedParcels={setScannedParcels}
                              setAtLeastOneScanned={setAtLeastOneScanned}/>
                </div>
            ))}
        </div>
    )
}

export default ScanParcels
