import React from "react";
import "./Button.css";
type Props = {
    text: string;
    style?: string
    color?: string;
    // onClick: () => void;
}

const Button: React.FC<Props> = ({ color,style, text }) => {
    return (
        <button
            // onClick={onClick}
            style={{ backgroundColor: color }}
            className={style}
        >
            {text}
        </button>
    )
}
Button.defaultProps = {
    color: 'darkgreen',
    style: 'btn'
}

export default Button
