import React from 'react'
import QrReader from "react-qr-reader";
import {Parcel} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    parcels: Parcel [];
    setScanned: Function;
}

const WarehouseParcelScanner = ({parcels, setScanned}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);


    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = parcels.findIndex(parcel => parcel.efeuPackageId === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                window.alert(t('scanners.warehouseParcel.error') + state.result +
                    t('scanners.warehouseParcel.sentenceEnd'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })

            }
            else if (scannedIndex !== -1) {
                setScanned(() => scannedIndex);
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '50%' }}
                  showViewFinder={false}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default WarehouseParcelScanner
