import * as React from 'react';
import AppBarCom from "../../../header/AppBarCom";
import NotificationFetching from "../../../notifications/NotificationFetching"
import {IntraLogAppApi, Commission, MountingDevice} from "../../../../apiGenerator/axios";
import CommissionStepper from "./CommissionStepper";
import "../../../GlobalStyles.css"

import Async from 'react-async';
import {useTranslation} from "react-i18next";

const CommissionsFetching = () => {
    interface ApiResponse {
        commissions: Commission[],
        mountingDevices: MountingDevice[]
    }
    const {t} = useTranslation();
    var resp:ApiResponse = {
        commissions: [],
        mountingDevices: []
    }
    const loadData = async () => {
        const commissionsPromise = new IntraLogAppApi().getComissionings()
        const mountingDevicesPromise = new IntraLogAppApi().getBoxMountingDevices()

        const responses = await Promise.allSettled([commissionsPromise, mountingDevicesPromise])

        for (var i = 0; i < responses.length; i++) {
            const response = responses[i]
            if (response.status === "rejected") {
                console.log(response.reason);
            }
            if (response.status === "fulfilled") {
                switch(i) {
                    case 0:
                        resp.commissions = response.value.data
                        break
                    case 1:
                        resp.mountingDevices = response.value.data
                        break
                }
            }
        }

        return resp
    }

  return (
    <div>
        <AppBarCom showIcons={true}/>

        <NotificationFetching />
        <div className="containerHeadline">{t('commissioning.main.headline')}</div>
        <Async promiseFn = {loadData}>
            {({ error, data, isLoading }) => {
                if (error) return "Could not fetch data"
                if (isLoading) return "Loading..."
                if (data)
                    return (
                        <div>
                            <CommissionStepper commissions = {data.commissions} mounts = {data.mountingDevices}/>
                        </div>
                    )
            }}
        </Async>
    </div>
  );
};

export default CommissionsFetching
