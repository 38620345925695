import React from 'react'
import {WarehousePlace} from "../../../../apiGenerator/axios";
import {Grid} from "@material-ui/core";
import {ImCheckboxChecked} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import WarehousePlaceScanner from "../../../components/scanner/WarehousePlaceScanner";
import {useTranslation} from "react-i18next";

interface ParentEl {
    warehousePlaces: WarehousePlace[];
    warehouseType: String;
    scannedIndex: number;
    setScannedIndex: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '7px',
            textDecoration: 'None'
        }
    }),
);

const ScanWarehousePlace = ({warehousePlaces, warehouseType,scannedIndex, setScannedIndex}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const warehousePlace: WarehousePlace = warehousePlaces[scannedIndex];
    if (scannedIndex > -1){
        return (
            <div>
                <div className='gridContainer'>
                    <Grid container spacing={1} className='grid-green' >
                        <Grid container item xs={7} spacing={0}>
                            {warehousePlace.warehouse}
                        </Grid>
                        <Grid container item xs={4} spacing={0}>
                            {warehousePlace.placeName}
                        </Grid>
                        <Grid container item xs={1} spacing={0}>
                            <ImCheckboxChecked size={20} style={{ color: green[500], margin: "2px" }}/>
                        </Grid>
                    </Grid>
                </div>
                <Button
                    disabled={false}
                    className={classes.button}
                    variant="contained"
                    color = "secondary"
                    style={{
                        marginBottom: "3px"
                    }}
                    onClick={() => setScannedIndex(() => -1)}
                >
                    {t('buttons.scanDifferentWarehousePlace')}
                </Button>
            </div>
        )
    }
    else {
        return (
            <div>
                <div style={{fontSize: "12px", marginBottom: '5%'}}>
                    {t('parcelHandling.warehouse.storeInWarehouse')}
                    {warehouseType}
                </div>
                <WarehousePlaceScanner warehousePlaces={warehousePlaces} setScanned={setScannedIndex}
                                       warehouseType={warehouseType} kepProviders={null}
                                       setWarehousePlaceIdent={null}/>
            </div>
        )
    }

}

export default ScanWarehousePlace
