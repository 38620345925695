import React from 'react'
import QrReader from "react-qr-reader";
import {Contact} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    contacts: Contact[];
    setScannedContact: Function;
}

const SelfPickupCodeScanner = ({contacts, setScannedContact}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = contacts.findIndex(c => c.ident === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                window.alert(t('scanners.selfPickupCode.error') + state.result
                    + t('scanners.selfPickupCode.notFound'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })

            }
            else if (scannedIndex !== -1){
                setScannedContact(() => contacts[scannedIndex]);
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
    return (
        <div>
            <div className="scannerContainer">
                <QrReader
                    delay={300}
                    resolution={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '50%' }}
                    showViewFinder={false}
                />
            </div>
            <p>{state.result}</p>
        </div>
    )
}

export default SelfPickupCodeScanner
