import React from 'react'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {IntraLogAppApi} from "../../../../apiGenerator/axios";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

interface ParentEl {
    setScannedIndex: Function;
    setBoxOpened: Function;
    handleNext: Function;
    scannedBoxIdent: any;
}

const Buttons = ({setScannedIndex, setBoxOpened, handleNext, scannedBoxIdent}: ParentEl) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const openBox = () => {
        new IntraLogAppApi().postOpenBoxIntra(scannedBoxIdent).then();
        setBoxOpened(() => true);
        handleNext();

    }

    const chooseDifferentBox = () => {
        setScannedIndex(() => -1);
        setBoxOpened(() => false);
    }
  return (
    <div>
        <Button
            disabled={false}
            className={classes.button}
            onClick={chooseDifferentBox}
        >
            {t('buttons.scanDifferentBox')}
        </Button>
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={openBox}
        > {t('buttons.openBox')}</Button>
    </div>
  )
}

export default Buttons
