import React from 'react'
import {GoPackage} from "react-icons/all";
import {Commission,Parcel} from "../../../../../apiGenerator/axios";
import ParcelEl from "../step1-Register/ParcelEl";
import {Box, Grid} from "@material-ui/core";
import {ImWrench} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import moment from 'moment';
import "../../../../GlobalStyles.css"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            marginLeft: theme.spacing(0.3)
        },
    }),
);

interface ParentEl {
    task: Commission,
    box: string,
    parcels: Parcel[],
    parcelsScanned: boolean[],
    handleEdit: Function,
    commissionIndex: number,
    editMode: boolean,
}

const TaskDetail = ({task, box, parcels, parcelsScanned, handleEdit, commissionIndex, editMode}: ParentEl) => {
    const classes = useStyles();
    const now = moment()
    const ctime = moment(task.commissioningTime)
    const diff = moment.duration(ctime.diff(now))
    let diffInMinutes = diff.asMinutes()
    // while (diffInMinutes > 1000){
    //    diffInMinutes /= 10
    //}
    diffInMinutes = Math.round(diffInMinutes)
    var difftext = ""
    if (diffInMinutes < 10 && diffInMinutes > 0) {
        difftext = "" + diffInMinutes + " min"
    } else {
        difftext = "" + ctime.hour() + ":"  + (ctime.minute() < 10 ? "0" + ctime.minute() : ctime.minute())
    }

    // let selectedCheckbox = <input type="checkbox" checked={checked} onClick={handleClick} />

    function parcelMap(parcels: Parcel[], parcelsScanned: boolean[]) {
        return parcels.map((parcel: Parcel, index: number) => (
          <div key={parcel.efeuPackageId} className="row">
            <ParcelEl parcel = {parcel} scanned={parcelsScanned[index]}/>
          </div>
        ))

    }

    function handleClick() {
        handleEdit(commissionIndex);
    }

    let editButton = <><Grid item xs={1} spacing={0}><ImWrench size={19} style={{margin: "3px"}} onClick={handleClick} /></Grid></>


  return (
    <div>

    <Grid container justifyContent='center'>
        <Grid container item xs={editMode ? 11 : 12} spacing={0}>
        <div className='gridContainer'>
                <Grid container spacing={0} className='grid' >
                    <Grid container item xs={editMode ? 11 : 12} spacing={0}>
                        {task.name}
                    </Grid>
                </Grid>
                <Grid container spacing={0} className='grid'>
                    <Grid container item xs={1} spacing={0}>
                        {task.parcels?.length}
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        <GoPackage className="icon" size={27} />
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        {task.boxSize?.substr(0,1)}
                    </Grid>
                    <Grid container item xs={3} spacing={0} >
                        {difftext}
                    </Grid>

                </Grid>
        </div>

        </Grid>

        {editMode && editButton}
    </Grid>

    <div className='gridContainer'>

        <Grid container item xs={11} spacing={0}>
            <Grid container spacing={0}>
                <Grid item xs={1} spacing={0}>
                    <GoPackage className="icon" size={27} />
                </Grid>
                <Grid item xs={10} spacing={0}>
                    {box}
                </Grid>
            </Grid>
        </Grid>
    </div>

    {parcelMap(parcels, parcelsScanned)}
    </div>
  )
}

export default TaskDetail
