import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {BoxOrderInformationAdmin, AdminAppApi} from "../../../apiGenerator/axios";
import Button from "@material-ui/core/Button";
import "../parcelHandling/labelling/ParcelHandlingStyles.css"
import {useTranslation} from "react-i18next";


interface ParentEl {
    boxOrderInformation: BoxOrderInformationAdmin;
    parcelIdent: string;
    deletedParcels: string[];
    setDeletedParcels: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },

    }),
);

const BoxOrderInformationInformationExpanded= ({boxOrderInformation, parcelIdent, deletedParcels, setDeletedParcels}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [buttonDisabled, setButtonDisabled] = React.useState(false)
    const deleteParcel = () => {
        new AdminAppApi().getPackage(parcelIdent).then((res) =>
            new AdminAppApi().postRemovePackagesFromOrder({"orderIdent": res.data.relatedBoxOrderIdent, "packageOrderIdents": [parcelIdent]})
        )
        setDeletedParcels((old: string[]) => old.concat(parcelIdent))
        setButtonDisabled(true)
    }


    return (
        <div className={classes.root}>
            <Grid container spacing={1} className='grid-gray' >
                <Grid container item xs={6} spacing={0}>
                    {t('parcelHandling.labelling.boxOrderInformationExpanded.assignedVehicle')}
                    <div className='smallFont'>
                        {boxOrderInformation.assignedVehicleIdent}
                    </div>
                </Grid>
                <Grid container item xs={6} spacing={0}>
                    {t('parcelHandling.labelling.boxOrderInformationExpanded.assignedTour')}<br />
                    <div className='smallFont'>
                        {boxOrderInformation.assignedTourIdent}
                    </div>
                </Grid>
            </Grid>
            <br/>

            <Grid container spacing={1}>
                <Grid container spacing={0} item xs={12} justifyContent="center">
                    {t('parcelHandling.labelling.boxOrderInformationExpanded.containedParcels')}
                </Grid>


                {boxOrderInformation.containedPackageIdents.map((parcel: any) => {
                    if (deletedParcels.includes(parcel)) {
                        return <></>
                    }
                    if (parcel === parcelIdent) {
                        return (
                            <Grid container spacing={1} xs={12} className='grid-green'>
                                <Grid container item xs={8}>
                                    {parcel}
                                </Grid>
                                <Grid container item xs={4}>
                                    <Button color="secondary" variant="contained" disabled={buttonDisabled} onClick={deleteParcel}>
                                        {t('parcelHandling.labelling.boxOrderInformationExpanded.deleteParcelFromOrder')}
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return (
                            <Grid container spacing={0} xs={12} className='grid-yellow'>
                                <Grid container item xs={12}>
                                    {parcel}
                                </Grid>
                            </Grid>
                        )
                    }
                })}
            </Grid>

        </div>
    );
}
export default BoxOrderInformationInformationExpanded


