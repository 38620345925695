import React from 'react'
import {Contact, IntraLogAppApi, Parcel} from "../../../apiGenerator/axios";
import Async from "react-async";
import {useTranslation} from "react-i18next";
import KepPickupStepper from "./KepPickupStepper";
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";

interface ParentEl {
}

const ParcelFetching = ({}: ParentEl) => {
    const {t} = useTranslation();
    const loadParcels = () =>
        new IntraLogAppApi().getKepParcels()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)
    return (
        <div>
            <AppBarCom showIcons={true}/>

            <NotificationFetching />
            <div className="containerHeadline">Pakete an KEP übergeben</div>
            <Async promiseFn = {loadParcels}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data";
                    if (isLoading) return "Loading...";
                    if (data.length == 0) return t('kepPickup.noPackages');
                    if (data.length > 0)
                        return (
                            <KepPickupStepper parcels={data}
                            />
                        );
                }}
            </Async>
        </div>
    )
}

export default ParcelFetching
