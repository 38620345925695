import React from 'react'
import {Parcel} from "../../../../apiGenerator/axios";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import OverviewParcelEl from "./OutboundOverviewParcelEl";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface ParentEl {
  parcels: Parcel [];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const DataRegistration = ({parcels}: ParentEl) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const registeredParcels = parcels.filter(parcel => parcel.kepProviders !== null && parcel.kepProviders.length > 0);
  const [viewedParcels, setParcels] = React.useState(parcels)
  return (
    <div>
      <div>
        {viewedParcels.map((parcel: Parcel)=> (
            <div key={parcel.efeuPackageId}>
              <OverviewParcelEl parcel={parcel} parcels={viewedParcels} setParcels={setParcels} />
            </div>
        ))}
      </div>
        <Button
            variant="contained"
            disabled={false}
            className={classes.button}
            onClick={() => history.push('')}
        >
            {t('buttons.backToMain')}
        </Button>
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push('/outboundParcels/storage')}
            disabled={registeredParcels.length === 0}
        >
            {t('buttons.nextPackageAv')}
        </Button>


    </div>
  )
}

export default DataRegistration
