import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from "../../images/efeu.jpg";
import './Header.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export default function AppBarMain() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className='toolbar'>
                    <img src={logo} className='logo' alt="Logo" />
                    <div className='efeu'>
                        efeu
                    </div>
                    <Typography variant="h6" className={classes.title}>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}
