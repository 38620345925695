import React from 'react'
import QrReader from "react-qr-reader";
import {Parcel} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    setScannedParcels: Function;
    scannedParcels: boolean[];
    parcels: Parcel[];
    setAtLeastOneScanned: Function;
}

const KepPickupParcelScanner = ({setScannedParcels, scannedParcels, parcels, setAtLeastOneScanned}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = parcels.findIndex(p => p.efeuPackageId === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                window.alert(t('scanners.parcel.error') + state.result);
                setState({
                    result: 'No result',
                    facingMode: "environment"
                });
            }
            else if (scannedIndex !== -1) {
                const newArray = scannedParcels.slice();
                newArray[scannedIndex] = true;
                setScannedParcels(newArray);
                setAtLeastOneScanned(true);
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default KepPickupParcelScanner
