import React from 'react'
import {Commission,Parcel} from "../../../../../apiGenerator/axios";
import TaskDetail from "./TaskDetail";
import {useTranslation} from "react-i18next";

interface ParentEl {
  tasks: Commission[],
  parcels: Parcel[][],
  parcelsScanned: boolean[][],
  boxes: string[],
  handleEdit: Function,
  selectedCommissions: boolean[],
}


const ConfirmOrders = ({tasks, parcels, parcelsScanned, boxes, handleEdit, selectedCommissions}: ParentEl) => {
  const {t} = useTranslation();




  if(tasks.length === 0) {
    return (<div>
      {t('commissioning.main.noCommissions')}
    </div>)
  } else {
    return (
        <>
          {tasks.map((task: any, index: number) => (
            selectedCommissions[index] ?
              <div>
                <div key={task.id} className='grid-black-empty row'>
                  <TaskDetail task={task} parcels={parcels[index]} parcelsScanned={parcelsScanned[index]} box={boxes[index]} handleEdit={handleEdit} commissionIndex={index} editMode={true} />
                </div>
                <br/>
              </div> : <></>
          ))}

        </>
    )
  }
}

export default ConfirmOrders
