import React from 'react'
import AppBarCom from "../../../header/AppBarCom";
import NotificationFetching from "../../../notifications/NotificationFetching"
import NewParcels from "./NewParcels";
import {IntraLogAppApi} from "../../../../apiGenerator/axios";
import Async from "react-async";
import {useTranslation} from "react-i18next";

const ParcelFetching = () => {
    const {t} = useTranslation();

    const getParcels = () =>
        new IntraLogAppApi().getParcels()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)

  return (
    <div>
        <AppBarCom showIcons={true}/>

        <NotificationFetching />
        <div className="containerHeadline">{t('parcelHandling.labelling.headline')}</div>
        <Async promiseFn = {getParcels}>
            {({ error, data, isLoading }) => {
                if (error) return "Could not fetch data"
                if (isLoading) return "Loading..."
                if (data)
                    return (
                        <div>
                            <NewParcels parcels={data} />
                        </div>
                    )
            }}
        </Async>

    </div>
  )
}

export default ParcelFetching

