import React from 'react'
import {IntraLogAppApi, AdminAppApi, Parcel, BoxOrderInformationAdmin, BoxOrderStateEnumAdmin} from "../../../apiGenerator/axios";
import Async from "react-async";
import {useTranslation} from "react-i18next";
import ParcelInformation from "./ParcelInformation";

interface ApiResponse {
    parcels: Parcel[],
    boxOrderInformations: BoxOrderInformationAdmin[]
}

const ParcelInformationFetching = () => {
    const {t} = useTranslation();

    const getParcels = () => {

        var resp:ApiResponse = {
            parcels: [],
            boxOrderInformations: []
        }

        return new IntraLogAppApi().getAllParcels()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => {
                resp.parcels = res.data
                return new AdminAppApi().getBoxOrderInformations()
                .then(res => (res.data ? res : Promise.reject(res)))
                .then(res => {
                    resp.boxOrderInformations = res.data.filter((b) => (b.orderState !== BoxOrderStateEnumAdmin.Finished))
                    return resp
                })
            })
    }

    return (
        <div>
            <Async promiseFn = {getParcels}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <div>
                                <ParcelInformation parcels={data.parcels} boxOrderInformations={data.boxOrderInformations} />
                            </div>
                        )
                }}
            </Async>

        </div>
    )
}

export default ParcelInformationFetching

