import React from 'react'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import SignaturePad from 'react-signature-pad-wrapper'
import Button from '@material-ui/core/Button';

interface ParentEl {
    setIsSigned: Function;
    signaturePad: SignaturePad;
    setSignaturePad: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const SignAndConfirm = ({setIsSigned, signaturePad, setSignaturePad}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const finishStroke = () => {
        setIsSigned(true);
    }

    const handleClear = () => {
        setIsSigned(false);
        signaturePad.instance.clear();
    }

    // @ts-ignore
    return (
        <div>
            <div>
                <SignaturePad width={500}
                              height={200}
                              ref={ref => (setSignaturePad(ref))}
                              options={{onEnd: finishStroke, backgroundColor: 'rgb(255, 255, 255)'}} /><br/>
            </div>
            <div>
                <Button
                    onClick={handleClear}
                    className={classes.button}
                >
                    {t('buttons.clearSignature')}
                </Button>
            </div>
        </div>
    )
}

export default SignAndConfirm
