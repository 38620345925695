import React from 'react'
import {IntraLogAppApi} from "../../../../../apiGenerator/axios";
import Async from "react-async";
import MountingDevices from "./MountingDevices";

const ChooseBox = ({boxScanned, setBoxScanned, commissionSize, setStepCompleteFunction, handleNextFunction, handleNextSkip, scannedBoxes, commissionIndex, prematching}: any) => {
    const baseApi = new IntraLogAppApi();
    const getMountingDevices = () =>
        baseApi.getBoxMountingDevices()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)
  return (
    <div>
        <Async promiseFn = {getMountingDevices}>
            {({ error, data, isLoading }) => {
                if (error)
                    return (
                        error
                    )
                if (isLoading) return "Loading..."
                if (data)
                    return (
                        <>
                        <MountingDevices mountingDevices={data} boxScanned={boxScanned} setBoxScanned={setBoxScanned}
                                         commissionSize={commissionSize} setStepComplete={setStepCompleteFunction}
                                         handleNext={handleNextFunction} handleNextSkip={handleNextSkip} scannedBoxes={scannedBoxes}
                                         commissionIndex={commissionIndex}
                        />
                        {prematching >= 0 ? <>Vorschlag: Dock {prematching + 1} <br/> ({data[prematching].boxId}) </> : <>Kein Vorschlag</>}
                        </>
                    )
            }}
        </Async>
    </div>
  )
}

export default ChooseBox
