import React from 'react';
import './App.css';
import Main from "./app/main/Main";
import {Route, Switch, useHistory} from "react-router-dom";
import CommissionsFetching from "./app/tasks/commissionHandling/assignmentCommissioning/CommissionsFetching";
import ScannerDemo from "./app/components/scanner/ScannerDemo";
import ParcelFetching from "./app/tasks/parcelHandling/labelling/ParcelFetching";
import TransitionParcelFetching from "./app/tasks/parcelHandling/warehouse/TransitionParcelFetching";
import MountingDevicesFetching from "./app/tasks/incomingBoxes/MountingDevicesFetching";
import OutboundParcelFetching from "./app/tasks/outboundParcels/OutboundParcelFetching";
import Reset from "./app/demo/Reset";
import Login from "./app/auth/Login";
import setupInterceptors from "./apiGenerator/axios/interceptors"
import {PrivateRoute} from "./app/auth/PrivateRoute";
import {checkLoggedIn} from "./app/auth/AuthUtils";
import SelfPickupStepper from "./app/tasks/selfPickup/SelfPickupStepper";
import KepParcelFetching from "./app/tasks/kepPickup/KepParcelFetching";
import StoredBoxesFetching from "./app/tasks/boxActivation/StoredBoxesFetching";
import BoxesForStorageFetching from "./app/tasks/boxStorage/BoxesForStorageFetching";
import SelfDropoffStepper from "./app/tasks/selfDropoff/SelfDropoffStepper";
import ParcelInformationFetching from "./app/tasks/parcelInformation/ParcelInformationFetching";
import VehicleInformationFetching from './app/tasks/vehicleinformation/VehicleInformationFetching';
import BoxInformationFetching from './app/tasks/boxInformation/BoxInformationFetching';
import BoxesForDeactivationFetching from "./app/tasks/boxDeactivation/BoxesForDeactivationFetching";
import RecyclableBoxFetching from "./app/tasks/recyclableBoxes/RecyclingBoxFetching";
import DemoComponents from "./app/demo/DemoComponents";

function App() {
    let history = useHistory()
    setupInterceptors(history);

    return (
      <div className="App">
          <Switch>
              <Route path="/login" exact component={Login}/>
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/" exact component={Main}/>
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/commissioning/list" component={CommissionsFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/scan" component={ScannerDemo} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/parcel-Handling/label" component={ParcelFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/parcel-Handling/warehouse" component={TransitionParcelFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/incomingBoxes" component={MountingDevicesFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/outboundParcels" component={OutboundParcelFetching}/>
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/outboundParcels/overview" component={OutboundParcelFetching}/>
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/reset" component={Reset} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/demo" component={DemoComponents} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/selfPickup" component={SelfPickupStepper} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/kepPickup" component={KepParcelFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/activateBox" component={StoredBoxesFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/deactivateBox" component={BoxesForDeactivationFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/storeBox" component={BoxesForStorageFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/selfDropoff" component={SelfDropoffStepper} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/parcelInformation" component={ParcelInformationFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/vehicleInformation" component={VehicleInformationFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/boxInformation" component={BoxInformationFetching} />
              <PrivateRoute isLoggedIn={checkLoggedIn} path="/recyclableBox" component={RecyclableBoxFetching} />

          </Switch>
      </div>
  )
}

export default App;
