/* tslint:disable */
/* eslint-disable */
/**
 * efeuCampus GmbH
 * Partners FZI, IFL, KIT, PTV Group
 *
 * The version of the OpenAPI document: Version 12.0.19.3
 * Contact: efeu-contact@fzi.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdditionalInfo
 */
export interface AdditionalInfo {
    /**
     * 
     * @type {string}
     * @memberof AdditionalInfo
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalInfo
     */
    value?: string | null;
}
/**
 * Represents the address of a geographical object. Where a string, such  as a city or street name, is available in multiple languages, it is  returned in the default language specified by the object\'s location,  i.e. in which country it is located. Because addressing schemes vary  strongly between countries, most fields are optional.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xlocate.Address
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Gets or sets the country. In a response, it is represented by its  full name (not abbreviated or encoded) unless a different  representation is requested via SearchOptions.outputCountryType.
     * @type {string}
     * @memberof Address
     */
    country?: string | null;
    /**
     * Gets or sets the name of the principal country subdivision which  the address belongs to (for example, a state in the USA, or a  canton in Switzerland).
     * @type {string}
     * @memberof Address
     */
    state?: string | null;
    /**
     * Gets or sets the name of the second-level country subdivision which  the address belongs to (for example, a county in the UK, or a  province in Italy).
     * @type {string}
     * @memberof Address
     */
    province?: string | null;
    /**
     * Gets or sets the postal code of the address.
     * @type {string}
     * @memberof Address
     */
    postalCode?: string | null;
    /**
     * Gets or sets the city of the address, i.e. the highest entity at  the communal level which the address belongs to.
     * @type {string}
     * @memberof Address
     */
    city?: string | null;
    /**
     * Gets or sets the district of the address, i.e. the entity below  city.
     * @type {string}
     * @memberof Address
     */
    district?: string | null;
    /**
     * Gets or sets the subdistrict of the address, i.e. the entity below  district.
     * @type {string}
     * @memberof Address
     */
    subdistrict?: string | null;
    /**
     * Gets or sets the street of the address.
     * @type {string}
     * @memberof Address
     */
    street?: string | null;
    /**
     * Gets or sets the house number of the address.
     * @type {string}
     * @memberof Address
     */
    houseNumber?: string | null;
}
/**
 * The scores for the fields of the result Address. Only present for  fields that were given in the input Address.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xlocate.AddressScores
 * @export
 * @interface AddressScores
 */
export interface AddressScores {
    /**
     * Gets or sets the score for the returned country.
     * @type {number}
     * @memberof AddressScores
     */
    country?: number | null;
    /**
     * Gets or sets the score for the returned state.
     * @type {number}
     * @memberof AddressScores
     */
    state?: number | null;
    /**
     * Gets or sets the score for the returned province.
     * @type {number}
     * @memberof AddressScores
     */
    province?: number | null;
    /**
     * Gets or sets the score for the returned postal code.
     * @type {number}
     * @memberof AddressScores
     */
    postalCode?: number | null;
    /**
     * Gets or sets the score for the returned city.
     * @type {number}
     * @memberof AddressScores
     */
    city?: number | null;
    /**
     * Gets or sets the score for the returned district.
     * @type {number}
     * @memberof AddressScores
     */
    district?: number | null;
    /**
     * Gets or sets the score for the returned subdistrict.
     * @type {number}
     * @memberof AddressScores
     */
    subdistrict?: number | null;
    /**
     * Gets or sets the score for the returned street name.
     * @type {number}
     * @memberof AddressScores
     */
    street?: number | null;
    /**
     * Gets or sets the score for the returned house number.
     * @type {number}
     * @memberof AddressScores
     */
    houseNumber?: number | null;
}
/**
 * AdminDeleteOrderRequest
 * @export
 * @interface AdminDeleteOrderRequest
 */
export interface AdminDeleteOrderRequest {
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof AdminDeleteOrderRequest
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets DeleteLinkedOrder
     * @type {boolean}
     * @memberof AdminDeleteOrderRequest
     */
    deleteLinkedOrder?: boolean;
    /**
     * Gets or Sets DeleteRelatedOrders
     * @type {boolean}
     * @memberof AdminDeleteOrderRequest
     */
    deleteRelatedOrders?: boolean;
    /**
     * Gets or Sets DeleteCollectedOrders
     * @type {boolean}
     * @memberof AdminDeleteOrderRequest
     */
    deleteCollectedOrders?: boolean;
}
/**
 * AdminModifyOrderRequest
 * @export
 * @interface AdminModifyOrderRequest
 */
export interface AdminModifyOrderRequest {
    /**
     * Gets or Sets ModifyOrderRequest
     * @type {ModifyOrderRequest}
     * @memberof AdminModifyOrderRequest
     */
    modifyOrderRequest?: ModifyOrderRequest | null;
    /**
     * Gets or Sets Plannable
     * @type {boolean}
     * @memberof AdminModifyOrderRequest
     */
    plannable?: boolean;
    /**
     * Gets or Sets PreAssignedVehicleIdent
     * @type {string}
     * @memberof AdminModifyOrderRequest
     */
    preAssignedVehicleIdent?: string | null;
}
/**
 * AdminRemovePackagesFromOrderRequest
 * @export
 * @interface AdminRemovePackagesFromOrderRequest
 */
export interface AdminRemovePackagesFromOrderRequest {
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof AdminRemovePackagesFromOrderRequest
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets PackageOrderIdents
     * @type {Array<string>}
     * @memberof AdminRemovePackagesFromOrderRequest
     */
    packageOrderIdents?: Array<string> | null;
}
/**
 * AdminReturnBoxRequest
 * @export
 * @interface AdminReturnBoxRequest
 */
export interface AdminReturnBoxRequest {
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof AdminReturnBoxRequest
     */
    orderIdent?: string | null;
}
/**
 * Describes the allowed orientations for items. Depending on how an item  is oriented, load will be applied on a different axis of the item if  something is stacked on top of it. See ItemOrientation.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xload.AllowedItemOrientations
 * @export
 * @interface AllowedItemOrientations
 */
export interface AllowedItemOrientations {
    /**
     * Gets or sets item are is allowed to be packed unrotated. Dimensions  stay (x,y,z).
     * @type {boolean}
     * @memberof AllowedItemOrientations
     */
    original?: boolean | null;
    /**
     * Gets or sets items are allowed to be packed rotated widthwise  (around x-axis) by 90 degrees. Dimensions would be (x,z,y).
     * @type {boolean}
     * @memberof AllowedItemOrientations
     */
    x?: boolean | null;
    /**
     * Gets or sets items are allowed to be packed rotated heightwise  (around y-axis) by 90 degrees. Dimensions would be (z,y,x).
     * @type {boolean}
     * @memberof AllowedItemOrientations
     */
    y?: boolean | null;
    /**
     * Gets or sets items are allowed to be packed rotated lengthwise  (around z-axis) by 90 degrees. Dimensions would be (y,x,z).
     * @type {boolean}
     * @memberof AllowedItemOrientations
     */
    z?: boolean | null;
    /**
     * Gets or sets items are allowed to be packed rotated width- then  heightwise (around x- then z-axis) by 90 degrees. Dimensions would  be (z,x,y).
     * @type {boolean}
     * @memberof AllowedItemOrientations
     */
    xz?: boolean | null;
    /**
     * Gets or sets items are allowed to be packed rotated length- then  heightwise (around its y- then z-axis) by 90 degrees. Dimensions  would be (y,z,x).
     * @type {boolean}
     * @memberof AllowedItemOrientations
     */
    yz?: boolean | null;
}
/**
 * Contains basic information about an alternative route for the current  route.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.AlternativeRoute
 * @export
 * @interface AlternativeRoute
 */
export interface AlternativeRoute {
    /**
     * Gets or sets the distance of the complete route.
     * @type {number}
     * @memberof AlternativeRoute
     */
    distance?: number;
    /**
     * Gets or sets the travel time for the complete route.
     * @type {number}
     * @memberof AlternativeRoute
     */
    travelTime?: number;
    /**
     * Gets or sets if set to true, indicates that this route contains a  violation for the chosen vehicle.
     * @type {boolean}
     * @memberof AlternativeRoute
     */
    violated?: boolean;
    /**
     * Gets or sets the encoded string describing the complete path of the  calculated route. Use this string as an input of another route  request to calculate the same route with different parameters.  It is only returned if requested by ResultFields.encodedPath.
     * @type {string}
     * @memberof AlternativeRoute
     */
    encodedPath?: string | null;
    /**
     * Gets or sets the polyline of the complete alternative route. This  polyline consists of all coordinates representing the alternative  route and can be used to draw the route into a map.  It is only returned if requested by ResultFields.polyline.
     * @type {EncodedGeometry}
     * @memberof AlternativeRoute
     */
    polyline?: EncodedGeometry | null;
}
/**
 * AppBarNumbers
 * @export
 * @interface AppBarNumbers
 */
export interface AppBarNumbers {
    /**
     * Gets or Sets Tasks
     * @type {number}
     * @memberof AppBarNumbers
     */
    tasks?: number;
    /**
     * Gets or Sets NewParcels
     * @type {number}
     * @memberof AppBarNumbers
     */
    newParcels?: number;
    /**
     * Gets or Sets NewBoxes
     * @type {number}
     * @memberof AppBarNumbers
     */
    newBoxes?: number;
    /**
     * Gets or Sets NewTransition
     * @type {number}
     * @memberof AppBarNumbers
     */
    newTransition?: number;
}
/**
 * AssignBoxToOrderEvent
 * @export
 * @interface AssignBoxToOrderEvent
 */
export interface AssignBoxToOrderEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof AssignBoxToOrderEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof AssignBoxToOrderEvent
     */
    orderIdent?: string | null;
}
/**
 * AssignedVehicleInformation
 * @export
 * @interface AssignedVehicleInformation
 */
export interface AssignedVehicleInformation {
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof AssignedVehicleInformation
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof AssignedVehicleInformation
     */
    longitude?: number;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof AssignedVehicleInformation
     */
    vehicleIdent?: string | null;
}
/**
 * AvailableBox
 * @export
 * @interface AvailableBox
 */
export interface AvailableBox {
    /**
     * Gets or Sets BoxType
     * @type {BoxTypeEnum}
     * @memberof AvailableBox
     */
    boxType?: BoxTypeEnum;
    /**
     * Gets or Sets BoxSize
     * @type {BoxSizeEnum}
     * @memberof AvailableBox
     */
    boxSize?: BoxSizeEnum;
    /**
     * Gets or Sets Width
     * @type {number}
     * @memberof AvailableBox
     */
    width?: number;
    /**
     * Gets or Sets Length
     * @type {number}
     * @memberof AvailableBox
     */
    length?: number;
    /**
     * Gets or Sets Height
     * @type {number}
     * @memberof AvailableBox
     */
    height?: number;
}
/**
 * BitArrayExtended
 * @export
 * @interface BitArrayExtended
 */
export interface BitArrayExtended {
    /**
     * Gets or Sets DayOfWeek
     * @type {DayOfWeek}
     * @memberof BitArrayExtended
     */
    dayOfWeek?: DayOfWeek | null;
    /**
     * Gets or Sets Schedules
     * @type {Array<boolean>}
     * @memberof BitArrayExtended
     */
    schedules?: Array<boolean> | null;
}
/**
 * Size of an item or bin. See ItemPosition for a description of the  coordinate system.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xload.BoxDimensions
 * @export
 * @interface BoxDimensions
 */
export interface BoxDimensions {
    /**
     * Gets or sets extent along x-axis (width). Rounded to nearest full  centimeter internally.
     * @type {number}
     * @memberof BoxDimensions
     */
    x?: number;
    /**
     * Gets or sets extent along y-axis (height). Rounded to nearest full  centimeter internally.
     * @type {number}
     * @memberof BoxDimensions
     */
    y?: number;
    /**
     * Gets or sets extent along z-axis (length). Rounded to nearest full  centimeter internally.
     * @type {number}
     * @memberof BoxDimensions
     */
    z?: number;
}
/**
 * BoxInDepotResponse
 * @export
 * @interface BoxInDepotResponse
 */
export interface BoxInDepotResponse {
    /**
     * Gets or Sets BoxFound
     * @type {boolean}
     * @memberof BoxInDepotResponse
     */
    boxFound?: boolean;
    /**
     * Gets or Sets InDepot
     * @type {boolean}
     * @memberof BoxInDepotResponse
     */
    inDepot?: boolean;
}
/**
 * BoxInformation
 * @export
 * @interface BoxInformation
 */
export interface BoxInformation {
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof BoxInformation
     */
    id?: string | null;
    /**
     * Gets or Sets AvailableForDeactivation
     * @type {boolean}
     * @memberof BoxInformation
     */
    availableForDeactivation?: boolean;
    /**
     * Gets or Sets AvailableForStorage
     * @type {boolean}
     * @memberof BoxInformation
     */
    availableForStorage?: boolean;
    /**
     * Gets or Sets AvailableForActivation
     * @type {boolean}
     * @memberof BoxInformation
     */
    availableForActivation?: boolean;
    /**
     * Gets or Sets BoxType
     * @type {string}
     * @memberof BoxInformation
     */
    boxType?: string | null;
    /**
     * Gets or Sets BoxName
     * @type {string}
     * @memberof BoxInformation
     */
    boxName?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof BoxInformation
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets MountName
     * @type {string}
     * @memberof BoxInformation
     */
    mountName?: string | null;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof BoxInformation
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets VehicleName
     * @type {string}
     * @memberof BoxInformation
     */
    vehicleName?: string | null;
    /**
     * Gets or Sets OpenStatus
     * @type {boolean}
     * @memberof BoxInformation
     */
    openStatus?: boolean;
    /**
     * Gets or Sets BoxOrderIdents
     * @type {Array<string>}
     * @memberof BoxInformation
     */
    boxOrderIdents?: Array<string> | null;
    /**
     * Gets or Sets CurrentBoxOrderIdent
     * @type {string}
     * @memberof BoxInformation
     */
    currentBoxOrderIdent?: string | null;
    /**
     * Gets or Sets PackageIdents
     * @type {Array<string>}
     * @memberof BoxInformation
     */
    packageIdents?: Array<string> | null;
}
/**
 * BoxInformationAdmin
 * @export
 * @interface BoxInformationAdmin
 */
export interface BoxInformationAdmin {
    /**
     * Gets or Sets BoxLoad
     * @type {BoxLoadEnum}
     * @memberof BoxInformationAdmin
     */
    boxLoad?: BoxLoadEnum;
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof BoxInformationAdmin
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets BoxName
     * @type {string}
     * @memberof BoxInformationAdmin
     */
    boxName?: string | null;
}
/**
 * Defines BoxLoadEnum
 * @export
 * @enum {string}
 */
export enum BoxLoadEnum {
    Empty = 'Empty',
    Full = 'Full'
}

/**
 * BoxOrderInformationAdmin
 * @export
 * @interface BoxOrderInformationAdmin
 */
export interface BoxOrderInformationAdmin {
    /**
     * Gets or Sets CustomerProcess
     * @type {CustomerProcessEnum}
     * @memberof BoxOrderInformationAdmin
     */
    customerProcess?: CustomerProcessEnum | null;
    /**
     * Gets or Sets OrderType
     * @type {OrderTypeEnum}
     * @memberof BoxOrderInformationAdmin
     */
    orderType?: OrderTypeEnum | null;
    /**
     * Gets or Sets OrderMode
     * @type {OrderModeEnum}
     * @memberof BoxOrderInformationAdmin
     */
    orderMode?: OrderModeEnum | null;
    /**
     * Gets or Sets OrderState
     * @type {BoxOrderStateEnumAdmin}
     * @memberof BoxOrderInformationAdmin
     */
    orderState?: BoxOrderStateEnumAdmin | null;
    /**
     * Gets or Sets BoxType
     * @type {BoxTypeEnum}
     * @memberof BoxOrderInformationAdmin
     */
    boxType?: BoxTypeEnum | null;
    /**
     * Gets or Sets BoxSize
     * @type {BoxSizeEnum}
     * @memberof BoxOrderInformationAdmin
     */
    boxSize?: BoxSizeEnum | null;
    /**
     * Gets or Sets Ident
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    ident?: string | null;
    /**
     * Gets or Sets EstimatedDeliveryTimeWindow
     * @type {TimeWindow}
     * @memberof BoxOrderInformationAdmin
     */
    estimatedDeliveryTimeWindow?: TimeWindow | null;
    /**
     * Gets or Sets LinkedBoxOrderIdent
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    linkedBoxOrderIdent?: string | null;
    /**
     * Gets or Sets PickupContactIdent
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    pickupContactIdent?: string | null;
    /**
     * Gets or Sets DeliveryContactIdent
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    deliveryContactIdent?: string | null;
    /**
     * Gets or Sets CustomerFirstName
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    customerFirstName?: string | null;
    /**
     * Gets or Sets CustomerLastName
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    customerLastName?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets SyncMeetingPointIdent
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    syncMeetingPointIdent?: string | null;
    /**
     * Gets or Sets ContainedPackageIdents
     * @type {Array<string>}
     * @memberof BoxOrderInformationAdmin
     */
    containedPackageIdents?: Array<string> | null;
    /**
     * Gets or Sets AssignedVehicleIdent
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    assignedVehicleIdent?: string | null;
    /**
     * Gets or Sets AssignedTourIdent
     * @type {string}
     * @memberof BoxOrderInformationAdmin
     */
    assignedTourIdent?: string | null;
}
/**
 * Defines BoxOrderStateEnumAdmin
 * @export
 * @enum {string}
 */
export enum BoxOrderStateEnumAdmin {
    New = 'NEW',
    Modified = 'MODIFIED',
    Readyforplanning = 'READYFORPLANNING',
    Inplanning = 'INPLANNING',
    Planned = 'PLANNED',
    Fixed = 'FIXED',
    Commissioned = 'COMMISSIONED',
    Pickedup = 'PICKEDUP',
    Arrived = 'ARRIVED',
    Delivered = 'DELIVERED',
    Finished = 'FINISHED',
    Unscheduled = 'UNSCHEDULED',
    Replacedbytransportorder = 'REPLACEDBYTRANSPORTORDER',
    Irrelevantbyminimization = 'IRRELEVANTBYMINIMIZATION'
}

/**
 * BoxRequest
 * @export
 * @interface BoxRequest
 */
export interface BoxRequest {
    /**
     * Gets or Sets BoxType
     * @type {BoxTypeEnum}
     * @memberof BoxRequest
     */
    boxType?: BoxTypeEnum;
    /**
     * Gets or Sets BoxSize
     * @type {BoxSizeEnum}
     * @memberof BoxRequest
     */
    boxSize?: BoxSizeEnum;
    /**
     * Gets or Sets OrderMode
     * @type {OrderModeEnum}
     * @memberof BoxRequest
     */
    orderMode?: OrderModeEnum;
    /**
     * Gets or Sets DesiredTimeWindow
     * @type {TimeWindow}
     * @memberof BoxRequest
     */
    desiredTimeWindow?: TimeWindow | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof BoxRequest
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets SyncMeetingPointIdent
     * @type {string}
     * @memberof BoxRequest
     */
    syncMeetingPointIdent?: string | null;
}
/**
 * BoxRequestResponse
 * @export
 * @interface BoxRequestResponse
 */
export interface BoxRequestResponse {
    /**
     * Gets or Sets DeliveryOrderIdent
     * @type {string}
     * @memberof BoxRequestResponse
     */
    deliveryOrderIdent?: string | null;
    /**
     * Gets or Sets PickupOrderIdent
     * @type {string}
     * @memberof BoxRequestResponse
     */
    pickupOrderIdent?: string | null;
}
/**
 * Defines BoxSizeEnum
 * @export
 * @enum {string}
 */
export enum BoxSizeEnum {
    Small = 'SMALL',
    Large = 'LARGE'
}

/**
 * Defines BoxState
 * @export
 * @enum {string}
 */
export enum BoxStateEnum {
    Stored = 'Stored',
    Idle = 'Idle',
    InUse = 'InUse',
    Deactivated = 'Deactivated'
}

/**
 * BoxStatus
 * @export
 * @interface BoxStatus
 */
export interface BoxStatus {
    /**
     * Gets or Sets BoxState
     * @type {BoxStateEnum}
     * @memberof BoxStatus
     */
    boxState?: BoxStateEnum;
    /**
     * Gets or Sets BoxLoad
     * @type {BoxLoadEnum}
     * @memberof BoxStatus
     */
    boxLoad?: BoxLoadEnum;
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof BoxStatus
     */
    id?: string | null;
    /**
     * Gets or Sets Timestamp
     * @type {string}
     * @memberof BoxStatus
     */
    timestamp?: string;
    /**
     * Gets or Sets VehicleId
     * @type {string}
     * @memberof BoxStatus
     */
    vehicleId?: string | null;
    /**
     * Gets or Sets MountId
     * @type {string}
     * @memberof BoxStatus
     */
    mountId?: string | null;
    /**
     * Gets or Sets OrderId
     * @type {string}
     * @memberof BoxStatus
     */
    orderId?: string | null;
    /**
     * Gets or Sets IsInbound
     * @type {boolean}
     * @memberof BoxStatus
     */
    isInbound?: boolean | null;
}
/**
 * Defines BoxTypeEnum
 * @export
 * @enum {string}
 */
export enum BoxTypeEnum {
    Package = 'PACKAGE',
    Waste = 'WASTE'
}

/**
 * CancelPickupOrderRequest
 * @export
 * @interface CancelPickupOrderRequest
 */
export interface CancelPickupOrderRequest {
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof CancelPickupOrderRequest
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof CancelPickupOrderRequest
     */
    boxIdent?: string | null;
}
/**
 * CheckCustomerRequest
 * @export
 * @interface CheckCustomerRequest
 */
export interface CheckCustomerRequest {
    /**
     * Gets or Sets FirstName
     * @type {string}
     * @memberof CheckCustomerRequest
     */
    firstName?: string | null;
    /**
     * Gets or Sets LastName
     * @type {string}
     * @memberof CheckCustomerRequest
     */
    lastName?: string | null;
    /**
     * Gets or Sets ContactIdent
     * @type {string}
     * @memberof CheckCustomerRequest
     */
    contactIdent?: string | null;
}
/**
 * CheckCustomerResponse
 * @export
 * @interface CheckCustomerResponse
 */
export interface CheckCustomerResponse {
    /**
     * Gets or Sets Valid
     * @type {boolean}
     * @memberof CheckCustomerResponse
     */
    valid?: boolean;
    /**
     * Gets or Sets FirstName
     * @type {string}
     * @memberof CheckCustomerResponse
     */
    firstName?: string | null;
    /**
     * Gets or Sets LastName
     * @type {string}
     * @memberof CheckCustomerResponse
     */
    lastName?: string | null;
    /**
     * Gets or Sets ContactIdent
     * @type {string}
     * @memberof CheckCustomerResponse
     */
    contactIdent?: string | null;
}
/**
 * Attributes which describe the combined transport properties of the  segment in detail.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.segments.CombinedTransportAttributes
 * @export
 * @interface CombinedTransportAttributes
 */
export interface CombinedTransportAttributes {
    /**
     * Gets or sets the name of the combined transport in the selected  language. Usually the connection has an ID. In rare cases combined  transports without ID could exist. In such cases the field is  missing.
     * @type {string}
     * @memberof CombinedTransportAttributes
     */
    name?: string | null;
    /**
     * Gets or sets the type of combined transport. If this information is  missing in the digital map the field  xroute.UnspecifiedMapDataLimitation is generated and the field is  set to BOAT (see segments.CombinedTransportType). Possible values  include: \'BOAT\', \'RAIL\'
     * @type {CombinedTransportType}
     * @memberof CombinedTransportAttributes
     */
    type?: CombinedTransportType;
}
/**
 * Defines values for CombinedTransportType.
 * @export
 * @enum {string}
 */
export enum CombinedTransportType {
    Boat = 'BOAT',
    Rail = 'RAIL'
}

/**
 * Commission
 * @export
 * @interface Commission
 */
export interface Commission {
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof Commission
     */
    id?: string | null;
    /**
     * Gets or Sets Name
     * @type {string}
     * @memberof Commission
     */
    name?: string | null;
    /**
     * Gets or Sets Parcels
     * @type {Array<Parcel>}
     * @memberof Commission
     */
    parcels?: Array<Parcel> | null;
    /**
     * Gets or Sets BoxSize
     * @type {string}
     * @memberof Commission
     */
    boxSize?: string | null;
    /**
     * Gets or Sets CommissioningTime
     * @type {string}
     * @memberof Commission
     */
    commissioningTime?: string;
}
/**
 * CompleteCommissioningEvent
 * @export
 * @interface CompleteCommissioningEvent
 */
export interface CompleteCommissioningEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof CompleteCommissioningEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets CommissionIdent
     * @type {string}
     * @memberof CompleteCommissioningEvent
     */
    commissionIdent?: string | null;
}
/**
 * CompleteOutboundParcelStorageEvent
 * @export
 * @interface CompleteOutboundParcelStorageEvent
 */
export interface CompleteOutboundParcelStorageEvent {
    /**
     * Gets or Sets ParcelIdent
     * @type {string}
     * @memberof CompleteOutboundParcelStorageEvent
     */
    parcelIdent?: string | null;
    /**
     * Gets or Sets WarehousePlaceIdent
     * @type {string}
     * @memberof CompleteOutboundParcelStorageEvent
     */
    warehousePlaceIdent?: string | null;
}
/**
 * CompleteParcelStorageEvent
 * @export
 * @interface CompleteParcelStorageEvent
 */
export interface CompleteParcelStorageEvent {
    /**
     * Gets or Sets ParcelIdent
     * @type {string}
     * @memberof CompleteParcelStorageEvent
     */
    parcelIdent?: string | null;
    /**
     * Gets or Sets WarehousePlaceIdent
     * @type {string}
     * @memberof CompleteParcelStorageEvent
     */
    warehousePlaceIdent?: string | null;
}
/**
 * ConfirmKepPickupEvent
 * @export
 * @interface ConfirmKepPickupEvent
 */
export interface ConfirmKepPickupEvent {
    /**
     * Gets or Sets KepProvider
     * @type {KepProviderEnum}
     * @memberof ConfirmKepPickupEvent
     */
    kepProvider?: KepProviderEnum;
    /**
     * Gets or Sets PackageIdents
     * @type {Array<string>}
     * @memberof ConfirmKepPickupEvent
     */
    packageIdents?: Array<string> | null;
    /**
     * Gets or Sets SignatureBase64
     * @type {string}
     * @memberof ConfirmKepPickupEvent
     */
    signatureBase64?: string | null;
}
/**
 * ConfirmSelfPickupEvent
 * @export
 * @interface ConfirmSelfPickupEvent
 */
export interface ConfirmSelfPickupEvent {
    /**
     * Gets or Sets ContactIdent
     * @type {string}
     * @memberof ConfirmSelfPickupEvent
     */
    contactIdent?: string | null;
    /**
     * Gets or Sets PackageIdents
     * @type {Array<string>}
     * @memberof ConfirmSelfPickupEvent
     */
    packageIdents?: Array<string> | null;
    /**
     * Gets or Sets SignatureBase64
     * @type {string}
     * @memberof ConfirmSelfPickupEvent
     */
    signatureBase64?: string | null;
}
/**
 * Contact
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * Gets or Sets Ident
     * @type {string}
     * @memberof Contact
     */
    ident?: string | null;
    /**
     * Gets or Sets FirstName
     * @type {string}
     * @memberof Contact
     */
    firstName?: string | null;
    /**
     * Gets or Sets LastName
     * @type {string}
     * @memberof Contact
     */
    lastName?: string | null;
    /**
     * Gets or Sets Address
     * @type {ContactAddress}
     * @memberof Contact
     */
    address?: ContactAddress | null;
}
/**
 * ContactAddress
 * @export
 * @interface ContactAddress
 */
export interface ContactAddress {
    /**
     * Gets or Sets Street
     * @type {string}
     * @memberof ContactAddress
     */
    street?: string | null;
    /**
     * Gets or Sets HouseNumber
     * @type {string}
     * @memberof ContactAddress
     */
    houseNumber?: string | null;
    /**
     * Gets or Sets ZipCode
     * @type {string}
     * @memberof ContactAddress
     */
    zipCode?: string | null;
    /**
     * Gets or Sets City
     * @type {string}
     * @memberof ContactAddress
     */
    city?: string | null;
    /**
     * Gets or Sets State
     * @type {string}
     * @memberof ContactAddress
     */
    state?: string | null;
    /**
     * Gets or Sets Country
     * @type {string}
     * @memberof ContactAddress
     */
    country?: string | null;
}
/**
 * A coordinate is defined by its x and y values, specifying the  geographical point in a two-dimensional coordinate system, and its  z-value, specifying the elevation.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.geometry.Coordinate
 * @export
 * @interface Coordinate
 */
export interface Coordinate {
    /**
     * Gets or sets the value in x-direction (west to east), longitude for  WGS84.
     * @type {number}
     * @memberof Coordinate
     */
    x?: number;
    /**
     * Gets or sets the value in y-direction (south to north), latitude  for WGS84.
     * @type {number}
     * @memberof Coordinate
     */
    y?: number;
    /**
     * Gets or sets the elevation value. In responses such as  xroute.RouteResponse.polyline the z-coordinate is available only on  request.
     * @type {number}
     * @memberof Coordinate
     */
    z?: number | null;
}
/**
 * Specifies monetary costs in a certain currency. For example, toll costs  that have to be paid for a route.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.types.Cost
 * @export
 * @interface Cost
 */
export interface Cost {
    /**
     * Gets or sets the amount of the costs in the specified currency.
     * @type {number}
     * @memberof Cost
     */
    amount?: number;
    /**
     * Gets or sets the currency code.
     * @type {string}
     * @memberof Cost
     */
    currency?: string | null;
}
/**
 * Specifies monetary costs in a certain currency that apply in a certain  country.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.CountryCost
 * @export
 * @interface CountryCost
 */
export interface CountryCost {
    /**
     * Gets or sets the country in which the cost has to be paid.
     * @type {string}
     * @memberof CountryCost
     */
    country?: string | null;
    /**
     * Gets or sets the amount that has to be paid in the local currency  of the country.
     * @type {Cost}
     * @memberof CountryCost
     */
    cost?: Cost | null;
    /**
     * Gets or sets the amount that has to be paid converted to the  specified currency. It is only returned if a currency is specified  in the route options. It is still returned in case the requested  currency and the local currency of the country are the same.
     * @type {Cost}
     * @memberof CountryCost
     */
    convertedCost?: Cost | null;
}
/**
 * CustomerInfoAdmin
 * @export
 * @interface CustomerInfoAdmin
 */
export interface CustomerInfoAdmin {
    /**
     * Gets or Sets FirstName
     * @type {string}
     * @memberof CustomerInfoAdmin
     */
    firstName?: string | null;
    /**
     * Gets or Sets LastName
     * @type {string}
     * @memberof CustomerInfoAdmin
     */
    lastName?: string | null;
    /**
     * Gets or Sets CustomerIdent
     * @type {string}
     * @memberof CustomerInfoAdmin
     */
    customerIdent?: string | null;
}
/**
 * CustomerPlaceWasteEvent
 * @export
 * @interface CustomerPlaceWasteEvent
 */
export interface CustomerPlaceWasteEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof CustomerPlaceWasteEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof CustomerPlaceWasteEvent
     */
    orderIdent?: string | null;
}
/**
 * Defines CustomerProcessEnum
 * @export
 * @enum {string}
 */
export enum CustomerProcessEnum {
    Packagepickup = 'PACKAGEPICKUP',
    Packagedelivery = 'PACKAGEDELIVERY',
    Unknown = 'UNKNOWN'
}

/**
 * CustomerReceivePackageEvent
 * @export
 * @interface CustomerReceivePackageEvent
 */
export interface CustomerReceivePackageEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof CustomerReceivePackageEvent
     */
    boxIdent?: string | null;
}
/**
 * CustomerReuseBoxEvent
 * @export
 * @interface CustomerReuseBoxEvent
 */
export interface CustomerReuseBoxEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof CustomerReuseBoxEvent
     */
    boxIdent?: string | null;
}
/**
 * CustomerReuseBoxResponse
 * @export
 * @interface CustomerReuseBoxResponse
 */
export interface CustomerReuseBoxResponse {
    /**
     * Gets or Sets Success
     * @type {boolean}
     * @memberof CustomerReuseBoxResponse
     */
    success?: boolean;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof CustomerReuseBoxResponse
     */
    orderIdent?: string | null;
}
/**
 * CustomerSendPackageEvent
 * @export
 * @interface CustomerSendPackageEvent
 */
export interface CustomerSendPackageEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof CustomerSendPackageEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof CustomerSendPackageEvent
     */
    orderIdent?: string | null;
}
/**
 * CustomerSettings
 * @export
 * @interface CustomerSettings
 */
export interface CustomerSettings {
    /**
     * Gets or Sets DefaultOrderMode
     * @type {OrderModeEnum}
     * @memberof CustomerSettings
     */
    defaultOrderMode?: OrderModeEnum | null;
    /**
     * Gets or Sets SyncAvailabilityTimeSlots
     * @type {Array<DayTimeSlots>}
     * @memberof CustomerSettings
     */
    syncAvailabilityTimeSlots?: Array<DayTimeSlots> | null;
    /**
     * Gets or Sets AsyncAvailabilityTimeSlots
     * @type {Array<DayTimeSlots>}
     * @memberof CustomerSettings
     */
    asyncAvailabilityTimeSlots?: Array<DayTimeSlots> | null;
    /**
     * Gets or Sets FirstName
     * @type {string}
     * @memberof CustomerSettings
     */
    firstName?: string | null;
    /**
     * Gets or Sets LastName
     * @type {string}
     * @memberof CustomerSettings
     */
    lastName?: string | null;
    /**
     * Gets or Sets ContactIdent
     * @type {string}
     * @memberof CustomerSettings
     */
    contactIdent?: string | null;
    /**
     * Gets or Sets Email
     * @type {string}
     * @memberof CustomerSettings
     */
    email?: string | null;
}
/**
 * Defines DayOfWeek
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

/**
 * Defines DayOfWeekEnum
 * @export
 * @enum {string}
 */
export enum DayOfWeekEnum {
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY'
}

/**
 * DayTimeSlot
 * @export
 * @interface DayTimeSlot
 */
export interface DayTimeSlot {
    /**
     * Gets or Sets Start
     * @type {TimeOfDay}
     * @memberof DayTimeSlot
     */
    start?: TimeOfDay | null;
    /**
     * Gets or Sets End
     * @type {TimeOfDay}
     * @memberof DayTimeSlot
     */
    end?: TimeOfDay | null;
}
/**
 * DayTimeSlots
 * @export
 * @interface DayTimeSlots
 */
export interface DayTimeSlots {
    /**
     * Gets or Sets DayOfWeek
     * @type {DayOfWeekEnum}
     * @memberof DayTimeSlots
     */
    dayOfWeek?: DayOfWeekEnum;
    /**
     * Gets or Sets TimeSlots
     * @type {Array<DayTimeSlot>}
     * @memberof DayTimeSlots
     */
    timeSlots?: Array<DayTimeSlot> | null;
}
/**
 * DeactivateBoxEvent
 * @export
 * @interface DeactivateBoxEvent
 */
export interface DeactivateBoxEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof DeactivateBoxEvent
     */
    boxIdent?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DefaultOrderMode {
    Undefined = 'UNDEFINED',
    Synchron = 'SYNCHRON',
    Asynchron = 'ASYNCHRON',
    Selfservice = 'SELFSERVICE'
}

/**
 * DeleteBoxRequest
 * @export
 * @interface DeleteBoxRequest
 */
export interface DeleteBoxRequest {
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof DeleteBoxRequest
     */
    orderIdent?: string | null;
}
/**
 * DepartFromMountWaitingChargingEvent
 * @export
 * @interface DepartFromMountWaitingChargingEvent
 */
export interface DepartFromMountWaitingChargingEvent {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof DepartFromMountWaitingChargingEvent
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof DepartFromMountWaitingChargingEvent
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof DepartFromMountWaitingChargingEvent
     */
    orderIdent?: string | null;
}
/**
 * DepotAvailabilityTimeSlots
 * @export
 * @interface DepotAvailabilityTimeSlots
 */
export interface DepotAvailabilityTimeSlots {
    /**
     * Gets or Sets TimeSlots
     * @type {Array<DayTimeSlots>}
     * @memberof DepotAvailabilityTimeSlots
     */
    timeSlots?: Array<DayTimeSlots> | null;
}
/**
 * DepotLocation
 * @export
 * @interface DepotLocation
 */
export interface DepotLocation {
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof DepotLocation
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof DepotLocation
     */
    longitude?: number;
    /**
     * Gets or Sets Street
     * @type {string}
     * @memberof DepotLocation
     */
    street?: string | null;
    /**
     * Gets or Sets City
     * @type {string}
     * @memberof DepotLocation
     */
    city?: string | null;
    /**
     * Gets or Sets ZipCode
     * @type {string}
     * @memberof DepotLocation
     */
    zipCode?: string | null;
    /**
     * Gets or Sets HouseNumber
     * @type {string}
     * @memberof DepotLocation
     */
    houseNumber?: string | null;
    /**
     * Gets or Sets AddressId
     * @type {string}
     * @memberof DepotLocation
     */
    addressId?: string | null;
}
/**
 * DepotReceivePackageEvent
 * @export
 * @interface DepotReceivePackageEvent
 */
export interface DepotReceivePackageEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof DepotReceivePackageEvent
     */
    boxIdent?: string | null;
}
/**
 * DepotRemoveWasteEvent
 * @export
 * @interface DepotRemoveWasteEvent
 */
export interface DepotRemoveWasteEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof DepotRemoveWasteEvent
     */
    boxIdent?: string | null;
}
/**
 * DepotSendPackageEvent
 * @export
 * @interface DepotSendPackageEvent
 */
export interface DepotSendPackageEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof DepotSendPackageEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof DepotSendPackageEvent
     */
    orderIdent?: string | null;
}
/**
 * 
 * @export
 * @interface DetailInfo
 */
export interface DetailInfo {
    /**
     * 
     * @type {string}
     * @memberof DetailInfo
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailInfo
     */
    detail?: string | null;
}
/**
 * Dimensions
 * @export
 * @interface Dimensions
 */
export interface Dimensions {
    /**
     * Gets or Sets Lenght
     * @type {number}
     * @memberof Dimensions
     */
    lenght?: number;
    /**
     * Gets or Sets Width
     * @type {number}
     * @memberof Dimensions
     */
    width?: number;
    /**
     * Gets or Sets Height
     * @type {number}
     * @memberof Dimensions
     */
    height?: number;
    /**
     * Gets or Sets Weight
     * @type {number}
     * @memberof Dimensions
     */
    weight?: number;
}
/**
 * Represents the summary of a route which has been calculated using the  ETACalculationOptions.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.ETACalculationReport
 * @export
 * @interface ETACalculationReport
 */
export interface ETACalculationReport {
    /**
     * Gets or sets if set to true, indicates that the route has been  recalculated, ignoring the given PathWaypoint.
     * @type {boolean}
     * @memberof ETACalculationReport
     */
    newRoute?: boolean | null;
    /**
     * Gets or sets if set to true, indicates that the travel time of a  segment has been recalculated because it exceeded 2h30.
     * @type {boolean}
     * @memberof ETACalculationReport
     */
    travelTimeExceeded?: boolean | null;
}
/**
 * 
 * @export
 * @interface EfCaAddress
 */
export interface EfCaAddress {
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    label?: string | null;
    /**
     * Values:   - DEPOT   - PLACE   - CUSTOMER
     * @type {string}
     * @memberof EfCaAddress
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    city2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    province?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddress
     */
    state?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaAddress
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaAddress
     */
    latitude?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaAddress
     */
    boxMountingDeviceIds?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaAddress
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaAddressResp
 */
export interface EfCaAddressResp {
    /**
     * 
     * @type {Array<EfCaAddress>}
     * @memberof EfCaAddressResp
     */
    addresses?: Array<EfCaAddress> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaAddressResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaAddressResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaAddressResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaApartment
 */
export interface EfCaApartment {
    /**
     * 
     * @type {string}
     * @memberof EfCaApartment
     */
    ident?: string | null;
    /**
     * Refers to the \"Ident\" of an EC_Address/EC_AddressDB object  Ein Apartment hat genau eine Addresse
     * @type {string}
     * @memberof EfCaApartment
     */
    addressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaApartment
     */
    info?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaApartment
     */
    contactIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaApartment
     */
    boxMountingDeviceIds?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaApartment
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaApartmentResp
 */
export interface EfCaApartmentResp {
    /**
     * 
     * @type {Array<EfCaApartment>}
     * @memberof EfCaApartmentResp
     */
    apartments?: Array<EfCaApartment> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaApartmentResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaApartmentResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaApartmentResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaBoxMountingDevice
 */
export interface EfCaBoxMountingDevice {
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDevice
     */
    ident?: string | null;
    /**
     * PUBLIC, PRIVATE
     * @type {string}
     * @memberof EfCaBoxMountingDevice
     */
    type?: string | null;
    /**
     * Default = 1
     * @type {number}
     * @memberof EfCaBoxMountingDevice
     */
    mountCapacity?: number | null;
    /**
     * longitude => x (Germany 8.2)
     * @type {number}
     * @memberof EfCaBoxMountingDevice
     */
    longitude?: number;
    /**
     * latitude => y (Germany 52.3)
     * @type {number}
     * @memberof EfCaBoxMountingDevice
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDevice
     */
    info?: string | null;
    /**
     * IFL Data for planning: local x-Coordinate
     * @type {number}
     * @memberof EfCaBoxMountingDevice
     */
    localXCoordinate?: number | null;
    /**
     * IFL Data for planning: local y-Coordinate
     * @type {number}
     * @memberof EfCaBoxMountingDevice
     */
    localYCoordinate?: number | null;
    /**
     * IFL Data for planning: SEW Mount Ident
     * @type {number}
     * @memberof EfCaBoxMountingDevice
     */
    sewMountIdent?: number | null;
    /**
     * 
     * @type {EfCaChargingStation}
     * @memberof EfCaBoxMountingDevice
     */
    chargingStation?: EfCaChargingStation | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaBoxMountingDevice
     */
    slotSchedulerIdents?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaBoxMountingDevice
     */
    version?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDevice
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaBoxMountingDeviceResp
 */
export interface EfCaBoxMountingDeviceResp {
    /**
     * 
     * @type {Array<EfCaBoxMountingDevice>}
     * @memberof EfCaBoxMountingDeviceResp
     */
    boxMountingDevices?: Array<EfCaBoxMountingDevice> | null;
    /**
     * 
     * @type {Array<EfCaBoxMountingDeviceSlot>}
     * @memberof EfCaBoxMountingDeviceResp
     */
    boxMountingDeviceSlots?: Array<EfCaBoxMountingDeviceSlot> | null;
    /**
     * 
     * @type {Array<EfCaBoxMountingDeviceScheduler>}
     * @memberof EfCaBoxMountingDeviceResp
     */
    boxMountingDeviceSchedulers?: Array<EfCaBoxMountingDeviceScheduler> | null;
    /**
     * 
     * @type {Array<EfCaSlotReservations>}
     * @memberof EfCaBoxMountingDeviceResp
     */
    slotReservations?: Array<EfCaSlotReservations> | null;
    /**
     * 
     * @type {Array<EfCaScheduledBoxMountingSlot>}
     * @memberof EfCaBoxMountingDeviceResp
     */
    scheduledBoxMountingSlots?: Array<EfCaScheduledBoxMountingSlot> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaBoxMountingDeviceResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaBoxMountingDeviceResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaBoxMountingDeviceScheduler
 */
export interface EfCaBoxMountingDeviceScheduler {
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceScheduler
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceScheduler
     */
    boxMountingDeviceId?: string | null;
    /**
     * ToDo: MountingSlot muss für den PTV Service allgemein werden
     * @type {string}
     * @memberof EfCaBoxMountingDeviceScheduler
     */
    slotDescription?: string | null;
    /**
     * 
     * @type {{ [key: string]: PTVSlotReservation; }}
     * @memberof EfCaBoxMountingDeviceScheduler
     */
    ptvSlotReservations?: { [key: string]: PTVSlotReservation; } | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaBoxMountingDeviceScheduler
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaBoxMountingDeviceSlot
 */
export interface EfCaBoxMountingDeviceSlot {
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    bockIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    orderIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    end?: string;
    /**
     * Dauer in Sekunden
     * @type {number}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    startFrame?: number;
    /**
     * Dauer in Sekunden
     * @type {number}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    middleFrame?: number;
    /**
     * Dauer in Sekunden
     * @type {number}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    endFrame?: number;
    /**
     * RESERVED  FIXED
     * @type {string}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    state?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaBoxMountingDeviceSlot
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaBuilding
 */
export interface EfCaBuilding {
    /**
     * 
     * @type {string}
     * @memberof EfCaBuilding
     */
    ident?: string | null;
    /**
     * Default, HUB, Swimmingpool, ...
     * @type {string}
     * @memberof EfCaBuilding
     */
    type?: string | null;
    /**
     * Refers to the \"Ident\" of an EC_Address/EC_AddressDB object
     * @type {string}
     * @memberof EfCaBuilding
     */
    addressId?: string | null;
    /**
     * Refers to the \"Ident\" of an ECaSyncMeetingPoint/EfCaSyncMeetingPointDB object
     * @type {Array<string>}
     * @memberof EfCaBuilding
     */
    syncMeetingPointIds?: Array<string> | null;
    /**
     * Refers to the \"Ident\" of an EC_Apartment/EfCaApartmentDB object
     * @type {Array<string>}
     * @memberof EfCaBuilding
     */
    apartmentIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaBuilding
     */
    contactIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaBuilding
     */
    boxMountingDeviceIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaBuilding
     */
    chargingStationIds?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaBuilding
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaBuildingResp
 */
export interface EfCaBuildingResp {
    /**
     * 
     * @type {Array<EfCaBuilding>}
     * @memberof EfCaBuildingResp
     */
    buildings?: Array<EfCaBuilding> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaBuildingResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaBuildingResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaBuildingResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaChangePasswordRequest
 */
export interface EfCaChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof EfCaChangePasswordRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaChangePasswordRequest
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaChangePasswordRequest
     */
    oldPassword?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaChargingStation
 */
export interface EfCaChargingStation {
    /**
     * Unique identifier of the docking station.    Refers to \"ID_Charging_Station\"
     * @type {string}
     * @memberof EfCaChargingStation
     */
    ident?: string | null;
    /**
     * No reference to the document
     * @type {string}
     * @memberof EfCaChargingStation
     */
    info?: string | null;
    /**
     * No reference to the document
     * @type {string}
     * @memberof EfCaChargingStation
     */
    type?: string | null;
    /**
     * No reference to the document
     * @type {number}
     * @memberof EfCaChargingStation
     */
    chargingCapacity?: number;
    /**
     * Supported Types BIG_VEHICLE_BOT, DEFAULT_VEHICLE_BOT, ...    No reference to the document
     * @type {Array<string>}
     * @memberof EfCaChargingStation
     */
    supportedVehicleTypes?: Array<string> | null;
    /**
     * Software Version der Dockingstation
     * @type {string}
     * @memberof EfCaChargingStation
     */
    unitVersionSW?: string | null;
    /**
     * Hardware Version der Dockingstation
     * @type {string}
     * @memberof EfCaChargingStation
     */
    unitVersionHW?: string | null;
    /**
     * Longitude    No reference to the document
     * @type {number}
     * @memberof EfCaChargingStation
     */
    latitude?: number;
    /**
     * Latitude    No reference to the document
     * @type {number}
     * @memberof EfCaChargingStation
     */
    longitude?: number;
    /**
     * The Version ot the actually stored DockingStation. This parameter is used to secure updates triggered from different users.
     * @type {number}
     * @memberof EfCaChargingStation
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaChargingStationResp
 */
export interface EfCaChargingStationResp {
    /**
     * 
     * @type {Array<EfCaChargingStation>}
     * @memberof EfCaChargingStationResp
     */
    chargingStations?: Array<EfCaChargingStation> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaChargingStationResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaChargingStationResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaChargingStationResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaConfig
 */
export interface EfCaConfig {
    /**
     * 
     * @type {string}
     * @memberof EfCaConfig
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConfig
     */
    key?: string | null;
    /**
     * URL, STRING_VALUE, TABLE_CONFIGURATION, XSERVER2_CONFIG
     * @type {string}
     * @memberof EfCaConfig
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConfig
     */
    stringValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConfig
     */
    url?: string | null;
    /**
     * 
     * @type {EfCaXServerConfig}
     * @memberof EfCaConfig
     */
    xServerConfig?: EfCaXServerConfig | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaConfig
     */
    version?: number | null;
}
/**
 * Contains building, address or contact id(s)
 * @export
 * @interface EfCaConnectionIds
 */
export interface EfCaConnectionIds {
    /**
     * 
     * @type {string}
     * @memberof EfCaConnectionIds
     */
    addressId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConnectionIds
     */
    apartmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConnectionIds
     */
    buildingId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConnectionIds
     */
    contactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConnectionIds
     */
    syncMeetingPointId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaConnectionIds
     */
    boxMountingDeviceIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaConnectionIds
     */
    chargingStationId?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaContact
 */
export interface EfCaContact {
    /**
     * In diesem Fall ist der Ident und die Email gleich
     * @type {string}
     * @memberof EfCaContact
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaContact
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaContact
     */
    lastname?: string | null;
    /**
     * Wunsch kam vom FZI 27.10.2020  https://lsogit.fzi.de/efeu/efeuportal/-/issues/17   UNDEFINED = 0,   SYNCHRON = 1,   ASYNCHRON = 2,   SELFSERVICE = 3
     * @type {DefaultOrderMode}
     * @memberof EfCaContact
     */
    defaultOrderMode?: DefaultOrderMode;
    /**
     * 
     * @type {Array<EfCaSlotsPerDay>}
     * @memberof EfCaContact
     */
    syncAvailabilityTimeSlots?: Array<EfCaSlotsPerDay> | null;
    /**
     * 
     * @type {Array<EfCaSlotsPerDay>}
     * @memberof EfCaContact
     */
    asyncAvailabilityTimeSlots?: Array<EfCaSlotsPerDay> | null;
    /**
     * Used to mark for example holidays, no service needed.
     * @type {Array<EfCaDateTimeSlot>}
     * @memberof EfCaContact
     */
    noAvailabilities?: Array<EfCaDateTimeSlot> | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaContact
     */
    birthday?: string;
    /**
     * Diese Email muss zum efeuUser passen
     * @type {string}
     * @memberof EfCaContact
     */
    email?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaContact
     */
    boxMountingDeviceIds?: Array<string> | null;
    /**
     * Mögliche Keys     - AddressRef (single)   - CalendarRef (single) => Urlaubszeiten, sonstige Abwesenheitszeiten   - MobilePhone (multi)   - Phone (multi)
     * @type {Array<AdditionalInfo>}
     * @memberof EfCaContact
     */
    additionalInfos?: Array<AdditionalInfo> | null;
    /**
     * https://lsogit.fzi.de/efeu/efeuportal/-/issues/17  EfCaContact: \"OrderAggregationAllowed\" Bool, Neues Feld: Einstellung für das Zusammenfassen   von Bestellungen auf Contact-Ebene. Default-Wert soll bei Allowed (1, true) sein.
     * @type {boolean}
     * @memberof EfCaContact
     */
    orderAggregationAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EfCaContact
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaContactResp
 */
export interface EfCaContactResp {
    /**
     * 
     * @type {Array<EfCaContact>}
     * @memberof EfCaContactResp
     */
    contacts?: Array<EfCaContact> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaContactResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaContactResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaContactResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaCoordinate
 */
export interface EfCaCoordinate {
    /**
     * 
     * @type {number}
     * @memberof EfCaCoordinate
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaCoordinate
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaCoordinate
     */
    height?: number;
}
/**
 * 
 * @export
 * @interface EfCaDateTimeSlot
 */
export interface EfCaDateTimeSlot {
    /**
     * 
     * @type {string}
     * @memberof EfCaDateTimeSlot
     */
    timeZoneById?: string | null;
    /**
     * Format
     * @type {string}
     * @memberof EfCaDateTimeSlot
     */
    start?: string;
    /**
     * Format
     * @type {string}
     * @memberof EfCaDateTimeSlot
     */
    end?: string;
}
/**
 * 
 * @export
 * @interface EfCaImageDocumentation
 */
export interface EfCaImageDocumentation {
    /**
     * 
     * @type {string}
     * @memberof EfCaImageDocumentation
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaImageDocumentation
     */
    orderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaImageDocumentation
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaImageDocumentation
     */
    img_byte_arr?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaImageDocumentation
     */
    version?: number | null;
    /**
     * This parameter is mapped to the UpdateTime in the database.  <br>https://lsogit.fzi.de/efeu/efeuportal/-/issues/54
     * @type {string}
     * @memberof EfCaImageDocumentation
     */
    lastUpdate?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaKepProviderResp
 */
export interface EfCaKepProviderResp {
    /**
     * 
     * @type {Array<EfCaServiceProvider>}
     * @memberof EfCaKepProviderResp
     */
    kepProviders?: Array<EfCaServiceProvider> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaKepProviderResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaKepProviderResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaKepProviderResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaLogin
 */
export interface EfCaLogin {
    /**
     * 
     * @type {string}
     * @memberof EfCaLogin
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaLogin
     */
    password?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaLoginResp
 */
export interface EfCaLoginResp {
    /**
     * 
     * @type {EfCaUser}
     * @memberof EfCaLoginResp
     */
    user?: EfCaUser | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaLoginResp
     */
    token?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaLoginResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaLoginResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaLoginResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaModelCollector
 */
export interface EfCaModelCollector {
    /**
     * The parameter \"Idents\" contains the unique id(s) of objects. Which object  depends on the called method.
     * @type {Array<string>}
     * @memberof EfCaModelCollector
     */
    idents?: Array<string> | null;
    /**
     * 
     * @type {Array<EfCaAddress>}
     * @memberof EfCaModelCollector
     */
    addresses?: Array<EfCaAddress> | null;
    /**
     * 
     * @type {Array<EfCaApartment>}
     * @memberof EfCaModelCollector
     */
    apartments?: Array<EfCaApartment> | null;
    /**
     * 
     * @type {Array<EfCaTransportBox>}
     * @memberof EfCaModelCollector
     */
    transportBoxes?: Array<EfCaTransportBox> | null;
    /**
     * 
     * @type {Array<EfCaTransportBoxType>}
     * @memberof EfCaModelCollector
     */
    transportBoxTypes?: Array<EfCaTransportBoxType> | null;
    /**
     * 
     * @type {Array<EfCaBuilding>}
     * @memberof EfCaModelCollector
     */
    buildings?: Array<EfCaBuilding> | null;
    /**
     * 
     * @type {Array<EfCaChargingStation>}
     * @memberof EfCaModelCollector
     */
    chargingStations?: Array<EfCaChargingStation> | null;
    /**
     * 
     * @type {Array<EfCaContact>}
     * @memberof EfCaModelCollector
     */
    contacts?: Array<EfCaContact> | null;
    /**
     * 
     * @type {Array<EfCaUser>}
     * @memberof EfCaModelCollector
     */
    users?: Array<EfCaUser> | null;
    /**
     * 
     * @type {Array<EfCaServiceProvider>}
     * @memberof EfCaModelCollector
     */
    serviceProviders?: Array<EfCaServiceProvider> | null;
    /**
     * 
     * @type {Array<EfCaBoxMountingDevice>}
     * @memberof EfCaModelCollector
     */
    boxMountingDevices?: Array<EfCaBoxMountingDevice> | null;
    /**
     * 
     * @type {Array<EfCaOrder>}
     * @memberof EfCaModelCollector
     */
    orders?: Array<EfCaOrder> | null;
    /**
     * 
     * @type {Array<EfCaImageDocumentation>}
     * @memberof EfCaModelCollector
     */
    imageDocumentation?: Array<EfCaImageDocumentation> | null;
    /**
     * 
     * @type {Array<WebAPIOrder>}
     * @memberof EfCaModelCollector
     */
    webApiOrders?: Array<WebAPIOrder> | null;
    /**
     * 
     * @type {Array<EfCaSyncMeetingPoint>}
     * @memberof EfCaModelCollector
     */
    syncMeetingPoints?: Array<EfCaSyncMeetingPoint> | null;
    /**
     * 
     * @type {Array<EfCaVehicle>}
     * @memberof EfCaModelCollector
     */
    vehicles?: Array<EfCaVehicle> | null;
    /**
     * 
     * @type {Array<EfCaTourFixation>}
     * @memberof EfCaModelCollector
     */
    tourFixations?: Array<EfCaTourFixation> | null;
    /**
     * 
     * @type {Array<EfCaSlotReservation>}
     * @memberof EfCaModelCollector
     */
    slotReservations?: Array<EfCaSlotReservation> | null;
    /**
     * 
     * @type {Array<EfCaSlotReservationRequest>}
     * @memberof EfCaModelCollector
     */
    slotReservationRequests?: Array<EfCaSlotReservationRequest> | null;
    /**
     * 
     * @type {Array<EfCaScheduledBoxMountingSlot>}
     * @memberof EfCaModelCollector
     */
    scheduledBoxMountSlots?: Array<EfCaScheduledBoxMountingSlot> | null;
    /**
     * 
     * @type {Array<EfCaWarehousePlace>}
     * @memberof EfCaModelCollector
     */
    warehousePlaces?: Array<EfCaWarehousePlace> | null;
}
/**
 * 
 * @export
 * @interface EfCaNewPasswordRequest
 */
export interface EfCaNewPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof EfCaNewPasswordRequest
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaNewPasswordRequest
     */
    password?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaOrder
 */
export interface EfCaOrder {
    /**
     * 
     * @type {string}
     * @memberof EfCaOrder
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrder
     */
    userCreationTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrder
     */
    userUpdateTime?: string | null;
    /**
     * Kennung des Paket Lieferanten (KEP) oder Entsorger
     * @type {Array<string>}
     * @memberof EfCaOrder
     */
    providerIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrder
     */
    trackingId?: string | null;
    /**
     * Einen Parameter, der angibt, ob eine Order von einem Kunden erzeugt ist oder nicht. Bzw. besser ausgedrückt,   ob es sich um eine Abholung eines Pakets beim Kunden handelt oder eine Zustellung eines Pakets.
     * @type {boolean}
     * @memberof EfCaOrder
     */
    customerGeneratedOrder?: boolean;
    /**
     * PACKAGE_DELIVERY, PACKAGE_PICKUP, WASTE_PICKUP  Dieser Parameter gibt an zu welchem Prozesstyp der Auftrag aus Kundensicht gehört.
     * @type {string}
     * @memberof EfCaOrder
     */
    customerProcess?: string | null;
    /**
     * Dieser Parameter wird verwendet um einem Auftrag ein Fahrzeug zuzuteilen. Das wird z.B. fuer Ladeaufträge benoetigt.
     * @type {string}
     * @memberof EfCaOrder
     */
    preassignedVehicleId?: string | null;
    /**
     * Flen              AuftragsId z.B von Amazon
     * @type {string}
     * @memberof EfCaOrder
     */
    externalOrderId?: string | null;
    /**
     * DELIVERY, PICKUP, TRANSPORT, OTHERS (sonstige),   PERSONAL_PICKUP, PERSONAL_DELIVERY (Selbstabholung bzw. hinbringen)  ELECTRICAL_CHARGE (elektrisches laden?)
     * @type {string}
     * @memberof EfCaOrder
     */
    orderType?: string | null;
    /**
     * Transport (SYNCHRON oder ASYNCHRON)  Personal Pick-Up(nur für  Personal Delivery
     * @type {string}
     * @memberof EfCaOrder
     */
    orderMode?: string | null;
    /**
     * WASTE (Wertstoff)  WASTE_BOX  PACKAGE (Paket)  PACKAGE_BOX (kann die Sammelaufträge enthalten)  OTHER (???) Dummy Auftrag
     * @type {string}
     * @memberof EfCaOrder
     */
    orderUnit?: string | null;
    /**
     * SINGLE=false, MULTIPLE=true
     * @type {boolean}
     * @memberof EfCaOrder
     */
    orderAggregation?: boolean | null;
    /**
     * Darf das Paket gemeinsam mit anderen versendet werden  Anforderung von FZI
     * @type {boolean}
     * @memberof EfCaOrder
     */
    orderAggregationAllowed?: boolean;
    /**
     * If OrderAggregation= true (MULTIPLE), this List contains the contained package orders    Sammelauftragsbildung
     * @type {Array<string>}
     * @memberof EfCaOrder
     */
    collectedOrderIds?: Array<string> | null;
    /**
     * Finden des Box Auftrages, welches Paket ist in welchem Boxauftrag?  Welches Paket wird mit welchem Box Auftrag geliefert?
     * @type {Array<string>}
     * @memberof EfCaOrder
     */
    relatedBoxOrderIds?: Array<string> | null;
    /**
     * Nur BoxOrders können Main sein
     * @type {boolean}
     * @memberof EfCaOrder
     */
    mainOrder?: boolean | null;
    /**
     * Vorgänger oder Nachfolger Box Auftrag, immer gesetzt???
     * @type {string}
     * @memberof EfCaOrder
     */
    linkedBoxOrderId?: string | null;
    /**
     * Keine Bewegungsdaten     NEW: Auftrag wurde von der \"Automatischen Übergabe Stelle angelegt\"   READY: Depotmitarbeiter hat eingelagert   IN_PROCESS: ProcessManagement arbeitet daran   FINISHED,      eventuell einen INVALID (ev. noch unvollständig) , DISRUPTION  JSt: ToDo: 28.05.2020 In Abstimmung mit Anna fällt dieser Status später weg. Es wir beim  anlegen ein Aufruf in die \"StatusDB\" gemacht und dort dann der Status verwaltet
     * @type {string}
     * @memberof EfCaOrder
     */
    state?: string | null;
    /**
     * Die Bilder sind in eine eigene Klasse ausgelagert, damit man noch eine Beschreibung zum Bild hinzufügen kann.    z.B.   \"Schaden an der linken Ecke\"  \"Empfänger nicht lesbar\"  <br>https://lsogit.fzi.de/efeu/efeuportal/-/issues/43
     * @type {Array<string>}
     * @memberof EfCaOrder
     */
    imageIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrder
     */
    description?: string | null;
    /**
     * <br>undefind=0  <br>InBound=1  <br>Outbound=2  <br>Internal=3
     * @type {number}
     * @memberof EfCaOrder
     */
    packageMode?: number;
    /**
     * GLASS, GROUP
     * @type {Array<SpecialCode>}
     * @memberof EfCaOrder
     */
    specialCodes?: Array<SpecialCode> | null;
    /**
     * 
     * @type {EfCaStorage}
     * @memberof EfCaOrder
     */
    pickup?: EfCaStorage | null;
    /**
     * 
     * @type {EfCaStorage}
     * @memberof EfCaOrder
     */
    delivery?: EfCaStorage | null;
    /**
     * 
     * @type {EfCaQuantities}
     * @memberof EfCaOrder
     */
    quantities?: EfCaQuantities | null;
    /**
     * 
     * @type {EfCaDateTimeSlot}
     * @memberof EfCaOrder
     */
    orderTimeSlot?: EfCaDateTimeSlot | null;
    /**
     * 
     * @type {Array<EfCaDateTimeSlot>}
     * @memberof EfCaOrder
     */
    pickupTimeSlots?: Array<EfCaDateTimeSlot> | null;
    /**
     * 
     * @type {Array<EfCaDateTimeSlot>}
     * @memberof EfCaOrder
     */
    deliveryTimeSlots?: Array<EfCaDateTimeSlot> | null;
    /**
     * Externe Adresse des Empfängers im Outbound Fall
     * @type {EfCaAddress}
     * @memberof EfCaOrder
     */
    externalRecipientAddress?: EfCaAddress | null;
    /**
     * 
     * @type {Array<EfCaScheduledBoxMountingSlot>}
     * @memberof EfCaOrder
     */
    mountDeviceSlots?: Array<EfCaScheduledBoxMountingSlot> | null;
    /**
     * https://lsogit.fzi.de/efeu/efeuportal/-/issues/17
     * @type {number}
     * @memberof EfCaOrder
     */
    deliveryAttempts?: number;
    /**
     * Pakete, die zu groß sind, können nur selbst abgeholt werden.  <br> https://lsogit.fzi.de/efeu/efeuportal/-/issues/54 
     * @type {boolean}
     * @memberof EfCaOrder
     */
    onlySelfService?: boolean | null;
    /**
     * 
     * @type {EfCaOrderEvaluation}
     * @memberof EfCaOrder
     */
    orderEvaluation?: EfCaOrderEvaluation | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaOrder
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaOrderDependentIds
 */
export interface EfCaOrderDependentIds {
    /**
     *  AddressId, ApartmentId, BuildingId,    ContactId, SyncMeetingPointId, BoxMountingDeviceIds,    ChargingStationId, TransportBoxId     JSt: ToDo: Durch einen Type ersetzen
     * @type {string}
     * @memberof EfCaOrderDependentIds
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrderDependentIds
     */
    ident?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaOrderEvaluation
 */
export interface EfCaOrderEvaluation {
    /**
     * 
     * @type {string}
     * @memberof EfCaOrderEvaluation
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrderEvaluation
     */
    orderIdent?: string | null;
    /**
     * 
     * @type {Array<EfCaDateTimeSlot>}
     * @memberof EfCaOrderEvaluation
     */
    plannedTimeSlots?: Array<EfCaDateTimeSlot> | null;
    /**
     * 
     * @type {Array<EfCaDateTimeSlot>}
     * @memberof EfCaOrderEvaluation
     */
    shiftedTimeSlots?: Array<EfCaDateTimeSlot> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaOrderEvaluation
     */
    reasonForUnplanning?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrderEvaluation
     */
    deliveryTimeActual?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrderEvaluation
     */
    startOfTourForOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrderEvaluation
     */
    customerActionFinished?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaOrderEvaluation
     */
    orderOnTimeWithinFirstDeliverySlot?: boolean | null;
}
/**
 * 
 * @export
 * @interface EfCaOrderResp
 */
export interface EfCaOrderResp {
    /**
     * 
     * @type {Array<EfCaOrder>}
     * @memberof EfCaOrderResp
     */
    orders?: Array<EfCaOrder> | null;
    /**
     * Zum hinzufügen von Inhalten ausschlieslich \"AddImageDocumentation\" verwenden!
     * @type {Array<EfCaImageDocumentation>}
     * @memberof EfCaOrderResp
     */
    imageDocumentations?: Array<EfCaImageDocumentation> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaOrderResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaOrderResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaOrderResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaPlanningResp
 */
export interface EfCaPlanningResp {
    /**
     * 
     * @type {Array<EfCaTour>}
     * @memberof EfCaPlanningResp
     */
    plannedTours?: Array<EfCaTour> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaPlanningResp
     */
    deletedTours?: Array<string> | null;
    /**
     * 
     * @type {Array<EfCaOrder>}
     * @memberof EfCaPlanningResp
     */
    orders?: Array<EfCaOrder> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaPlanningResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaPlanningResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaPlanningResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaQuantities
 */
export interface EfCaQuantities {
    /**
     * Unit cm
     * @type {number}
     * @memberof EfCaQuantities
     */
    length?: number;
    /**
     * Unit cm
     * @type {number}
     * @memberof EfCaQuantities
     */
    width?: number;
    /**
     * Unit cm
     * @type {number}
     * @memberof EfCaQuantities
     */
    height?: number;
    /**
     * Unit gr
     * @type {number}
     * @memberof EfCaQuantities
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface EfCaRegisterResp
 */
export interface EfCaRegisterResp {
    /**
     * 
     * @type {EfCaUser}
     * @memberof EfCaRegisterResp
     */
    user?: EfCaUser | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRegisterResp
     */
    firstLoginPassword?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaRegisterResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaRegisterResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaRegisterResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaRegisterUser
 */
export interface EfCaRegisterUser {
    /**
     * 
     * @type {string}
     * @memberof EfCaRegisterUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRegisterUser
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRegisterUser
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRegisterUser
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRegisterUser
     */
    birthday?: string;
}
/**
 * 
 * @export
 * @interface EfCaResetPasswordRequest
 */
export interface EfCaResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof EfCaResetPasswordRequest
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaResetPasswordRequest
     */
    dateTime?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaResetPasswordRequest
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaRight
 */
export interface EfCaRight {
    /**
     * As example ADDRESS
     * @type {string}
     * @memberof EfCaRight
     */
    section?: string | null;
    /**
     * Methoden  - PostAddAddresses  - FindAddressesByFinder  - PutAddress  - DeleteAddress
     * @type {Array<string>}
     * @memberof EfCaRight
     */
    functions?: Array<string> | null;
}
/**
 * Anwender Funktionalität wird als Rolle bezeichnet. Es wird z.B. die Rolle   - SystemManager   - User   - Dispatching   - ...  geben.    Jede Rolle beeinhaltet Rechte (Gruppen der API z.B. Address) und jedes Recht hat   für jede Methode eine Funktion
 * @export
 * @interface EfCaRole
 */
export interface EfCaRole {
    /**
     * 
     * @type {string}
     * @memberof EfCaRole
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRole
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRole
     */
    description?: string | null;
    /**
     * 
     * @type {Array<EfCaRight>}
     * @memberof EfCaRole
     */
    rights?: Array<EfCaRight> | null;
    /**
     * 
     * @type {Array<EfCaConfig>}
     * @memberof EfCaRole
     */
    configs?: Array<EfCaConfig> | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaRole
     */
    version?: number | null;
}
/**
 * The default constructor creates the List<EfCaRole>  Roles
 * @export
 * @interface EfCaRoleResp
 */
export interface EfCaRoleResp {
    /**
     * 
     * @type {Array<EfCaRole>}
     * @memberof EfCaRoleResp
     */
    roles?: Array<EfCaRole> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaRoleResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaRoleResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaRoleResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaRouteCalculation
 */
export interface EfCaRouteCalculation {
    /**
     * 
     * @type {string}
     * @memberof EfCaRouteCalculation
     */
    tourIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaRouteCalculation
     */
    tourExtId?: string | null;
    /**
     * 
     * @type {RoutingResultFields}
     * @memberof EfCaRouteCalculation
     */
    resultFields?: RoutingResultFields | null;
}
/**
 * 
 * @export
 * @interface EfCaRouteCalculationStartEnd
 */
export interface EfCaRouteCalculationStartEnd {
    /**
     * 
     * @type {number}
     * @memberof EfCaRouteCalculationStartEnd
     */
    startLat?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaRouteCalculationStartEnd
     */
    startLon?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaRouteCalculationStartEnd
     */
    endLat?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaRouteCalculationStartEnd
     */
    endLon?: number;
    /**
     * 
     * @type {string}
     * @memberof EfCaRouteCalculationStartEnd
     */
    vehicleIdent?: string | null;
    /**
     * 
     * @type {RoutingResultFields}
     * @memberof EfCaRouteCalculationStartEnd
     */
    resultFields?: RoutingResultFields | null;
}
/**
 * In dieser Klasse wird gespeichert welche Slots und Positionen für eine Transportkiste   reserviert wurden.    !!!! Nach der Planung kann es nur noch ein Eintrag sein, ausser bei großen Boxen => sind es 2!!!!  oder in EfCaScheduledBoxMountTray umbenennen drawer
 * @export
 * @interface EfCaScheduledBoxMountingSlot
 */
export interface EfCaScheduledBoxMountingSlot {
    /**
     * External Id of the customer.
     * @type {string}
     * @memberof EfCaScheduledBoxMountingSlot
     */
    ident?: string | null;
    /**
     * Id des Übergabebocks
     * @type {string}
     * @memberof EfCaScheduledBoxMountingSlot
     */
    customerIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaScheduledBoxMountingSlot
     */
    calendarWeek?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaScheduledBoxMountingSlot
     */
    day?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaScheduledBoxMountingSlot
     */
    startIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaScheduledBoxMountingSlot
     */
    endIndex?: number;
    /**
     * Concatenation of scheduler and customer external info information.
     * @type {string}
     * @memberof EfCaScheduledBoxMountingSlot
     */
    externalSlotReservationInfo?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaServiceProvider
 */
export interface EfCaServiceProvider {
    /**
     * 
     * @type {string}
     * @memberof EfCaServiceProvider
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaServiceProvider
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaServiceProvider
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaServiceProvider
     */
    version?: number | null;
}
/**
 * This clazz is used to query the reservations of a specified Scheduler of a BoxMountingDevice
 * @export
 * @interface EfCaSlotReservation
 */
export interface EfCaSlotReservation {
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotReservation
     */
    schedulerIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotReservation
     */
    calendarWeek?: string | null;
    /**
     * Wenn CalendarWeek gesetzt, wird Start ignoriert
     * @type {string}
     * @memberof EfCaSlotReservation
     */
    start?: string;
}
/**
 * Diese Klasse wird dazu verwendet am Reservierungsanfragen zu übergeben
 * @export
 * @interface EfCaSlotReservationRequest
 */
export interface EfCaSlotReservationRequest {
    /**
     * Die Reservierung wird für diese Id angefragt/angelegt. Der Parameter wird von der Slot Reservierung nicht verwendet,   sondern nur in der Response zurückgegeben. Der Aufrufende kennt den Type und kann dort, nach seinen Bedürfnissen, das Ergebnis   verwenden.    <br>Für den aufrufenden sollte diese ID eindeutig sein
     * @type {string}
     * @memberof EfCaSlotReservationRequest
     */
    customerIdent?: string | null;
    /**
     * UNDEFINED, SINGLE, ALL => ALL wird verwendet wenn an EINER Stelle ALLE Scheduler für den gleichen Zeitpunkt reserviert werden sollen.    JSt: ToDo: Abstimmen wie man das am besten formulieren kann, damit es für ein Slot Reservierungssystem passt
     * @type {SlotSelection}
     * @memberof EfCaSlotReservationRequest
     */
    slotSelection?: SlotSelection;
    /**
     *  Dieser Parameter gibt an wie viele Reservierungen gemacht werden sollen.      Wird nur verwendet, wenn die Anzahl der BockIds > 1 ist. Die Reservierungen werden dann auch auf verschiedene Böcke verteilt.  Kann nur kleiner oder gleich wie die Anzahl der BockIds sein
     * @type {number}
     * @memberof EfCaSlotReservationRequest
     */
    reservationsRequestCount?: number;
    /**
     * Diese Liste gibt an, an welchen Stellen (Schedulern) im efeucampus Umfeld eine Reservierung versucht werden soll.    Wie viele Reservierungen gemacht werden hängt von dem Parameter \"ReservationsRequestCount\"
     * @type {Array<string>}
     * @memberof EfCaSlotReservationRequest
     */
    schedulerIds?: Array<string> | null;
    /**
     * Zeitschlitz (Dauer) in Sekunden, falls leer Start - End => default
     * @type {number}
     * @memberof EfCaSlotReservationRequest
     */
    timeSlot?: number;
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotReservationRequest
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotReservationRequest
     */
    end?: string;
}
/**
 * Diese Klasse enthält für einen Slot     - den Scheduler  - und die aufbereiteten PtvAppointmentEntries
 * @export
 * @interface EfCaSlotReservations
 */
export interface EfCaSlotReservations {
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotReservations
     */
    boxMountingDeviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotReservations
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotReservations
     */
    calendarWeek?: string | null;
    /**
     * 
     * @type {PTVSlotReservation}
     * @memberof EfCaSlotReservations
     */
    scheduler?: PTVSlotReservation | null;
    /**
     * 
     * @type {Array<PtvAppointmentEntry>}
     * @memberof EfCaSlotReservations
     */
    appointmentEntries?: Array<PtvAppointmentEntry> | null;
}
/**
 * 
 * @export
 * @interface EfCaSlotsPerDay
 */
export interface EfCaSlotsPerDay {
    /**
     * 
     * @type {string}
     * @memberof EfCaSlotsPerDay
     */
    timeZoneById?: string | null;
    /**
     * 0 = Sunday
     * @type {number}
     * @memberof EfCaSlotsPerDay
     */
    day?: number;
    /**
     * 
     * @type {EfCaTimeSlot}
     * @memberof EfCaSlotsPerDay
     */
    timeSlots?: EfCaTimeSlot | null;
}
/**
 * 
 * @export
 * @interface EfCaStorage
 */
export interface EfCaStorage {
    /**
     * z.B. Lagerplatz im Depot, Regal
     * @type {string}
     * @memberof EfCaStorage
     */
    storageInfo?: string | null;
    /**
     * Zeit in Sekunden
     * @type {number}
     * @memberof EfCaStorage
     */
    serviceTime?: number;
    /**
     * 
     * @type {EfCaStorageType}
     * @memberof EfCaStorage
     */
    storageType?: EfCaStorageType | null;
    /**
     * DEPRICATED use List of EfCaOrderDependentIds OrderDependentIds
     * @type {EfCaConnectionIds}
     * @memberof EfCaStorage
     */
    storageIds?: EfCaConnectionIds | null;
    /**
     * 
     * @type {Array<EfCaOrderDependentIds>}
     * @memberof EfCaStorage
     */
    orderDependentIds?: Array<EfCaOrderDependentIds> | null;
}
/**
 * DEPOT, BOCK     Wo befindet sich das Paket / Auftrag?     Übergabebock    Regalplatz im Lager
 * @export
 * @interface EfCaStorageType
 */
export interface EfCaStorageType {
    /**
     * DEPOT, TransportBoxType,
     * @type {string}
     * @memberof EfCaStorageType
     */
    storage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaStorageType
     */
    storageId?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaSyncMeetingPoint
 */
export interface EfCaSyncMeetingPoint {
    /**
     * 
     * @type {string}
     * @memberof EfCaSyncMeetingPoint
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaSyncMeetingPoint
     */
    info?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaSyncMeetingPoint
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaSyncMeetingPoint
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaSyncMeetingPoint
     */
    version?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaSyncMeetingPoint
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaSyncMeetingPointResp
 */
export interface EfCaSyncMeetingPointResp {
    /**
     * 
     * @type {Array<EfCaSyncMeetingPoint>}
     * @memberof EfCaSyncMeetingPointResp
     */
    syncMeetingPoints?: Array<EfCaSyncMeetingPoint> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaSyncMeetingPointResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaSyncMeetingPointResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaSyncMeetingPointResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaSystemResp
 */
export interface EfCaSystemResp {
    /**
     * 
     * @type {Array<EfCaConfig>}
     * @memberof EfCaSystemResp
     */
    configs?: Array<EfCaConfig> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaSystemResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaSystemResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaSystemResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaTenant
 */
export interface EfCaTenant {
    /**
     * 
     * @type {string}
     * @memberof EfCaTenant
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaTenant
     */
    eMail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaTenant
     */
    description?: string | null;
    /**
     * 
     * @type {Array<EfCaConfig>}
     * @memberof EfCaTenant
     */
    configs?: Array<EfCaConfig> | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaTenant
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaTenantResp
 */
export interface EfCaTenantResp {
    /**
     * 
     * @type {Array<EfCaTenant>}
     * @memberof EfCaTenantResp
     */
    tenants?: Array<EfCaTenant> | null;
    /**
     * 
     * @type {EfCaUser}
     * @memberof EfCaTenantResp
     */
    tenantAdminUser?: EfCaUser | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaTenantResp
     */
    firstLoginPassword?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaTenantResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaTenantResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaTenantResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaTimeSlot
 */
export interface EfCaTimeSlot {
    /**
     * Format 12:15
     * @type {string}
     * @memberof EfCaTimeSlot
     */
    start?: string | null;
    /**
     * Format 17:00
     * @type {string}
     * @memberof EfCaTimeSlot
     */
    end?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaTour
 */
export interface EfCaTour {
    /**
     * 
     * @type {EfCaTourHeader}
     * @memberof EfCaTour
     */
    tourHeader?: EfCaTourHeader | null;
    /**
     * 
     * @type {Array<EfCaTourStop>}
     * @memberof EfCaTour
     */
    tourStops?: Array<EfCaTourStop> | null;
}
/**
 * 
 * @export
 * @interface EfCaTourActionPoint
 */
export interface EfCaTourActionPoint {
    /**
     * 
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    coordinateType?: string | null;
    /**
     * Gets or Sets PosInTour
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    posInTour?: number;
    /**
     * Gets or Sets OrderExtId1
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    orderExtId1?: string | null;
    /**
     * Gets or Sets ArrivalTime
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    arrivalTime?: string;
    /**
     * Gets or Sets StartServiceTime
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    startServiceTime?: string;
    /**
     * Gets or Sets EndServiceTime
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    endServiceTime?: string;
    /**
     * Gets or Sets OpeningHoursTolerance
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    openingHoursTolerance?: number;
    /**
     * Gets or Sets Delay
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    delay?: number;
    /**
     * Gets or Sets DepartureTime
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    departureTime?: string;
    /**
     * Gets or Sets Earliness
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    earliness?: number;
    /**
     * Gets or Sets IdlePeriod
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    idlePeriod?: number;
    /**
     * Gets or Sets ServicePeriod
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    servicePeriod?: number;
    /**
     * Gets or Sets WaitingPeriod
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    waitingPeriod?: number;
    /**
     * Gets or Sets Action
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    action?: string | null;
    /**
     * Gets or Sets StartingTime
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    startingTime?: string;
    /**
     * Gets or Sets EndingTime
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    endingTime?: string;
    /**
     * Gets or Sets LocationExtId1
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    locationExtId1?: string | null;
    /**
     * Gets or Sets LocationExtId2
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    locationExtId2?: string | null;
    /**
     * Gets or Sets LocationName
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    locationName?: string | null;
    /**
     * Gets or Sets Country
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    country?: string | null;
    /**
     * Gets or Sets State
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    state?: string | null;
    /**
     * Gets or Sets Postcode
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    postcode?: string | null;
    /**
     * Gets or Sets City
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    city?: string | null;
    /**
     * Gets or Sets District
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    district?: string | null;
    /**
     * Gets or Sets Street
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    street?: string | null;
    /**
     * Gets or Sets HouseNo
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    houseNo?: string | null;
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    longitude?: number;
    /**
     * Gets or Sets GeocodingClassification
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    geocodingClassification?: string | null;
    /**
     * Gets or Sets Weight
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    weight?: number;
    /**
     * Gets or Sets Volume
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    volume?: number;
    /**
     * Gets or Sets LoadingMeter
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    loadingMeter?: number;
    /**
     * Gets or Sets Quantity1
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    quantity1?: number;
    /**
     * Gets or Sets Quantity2
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    quantity2?: number;
    /**
     * Gets or Sets Quantity3
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    quantity3?: number;
    /**
     * Gets or Sets Quantity4
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    quantity4?: number;
    /**
     * Gets or Sets Quantity5
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    quantity5?: number;
    /**
     * Gets or Sets Quantity6
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    quantity6?: number;
    /**
     * Gets or Sets Quantity7
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    quantity7?: number;
    /**
     * Gets or Sets SplitAction
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    splitAction?: string | null;
    /**
     * Gets or Sets SplitSequence
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    splitSequence?: number;
    /**
     * Gets or Sets SplitAssortmentGroupExtId
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    splitAssortmentGroupExtId?: string | null;
    /**
     * Gets or Sets SplitBaseOrderExtId
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    splitBaseOrderExtId?: string | null;
    /**
     * Gets or Sets SplitInfo
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    splitInfo?: string | null;
    /**
     * Gets or Sets SplitResult
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    splitResult?: string | null;
    /**
     * Gets or Sets SplitRole
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    splitRole?: string | null;
    /**
     * Gets or Sets NumberOfSplits
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    numberOfSplits?: number;
    /**
     * Gets or Sets GroupedOrderExtId
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    groupedOrderExtId?: string | null;
    /**
     * Gets or Sets GroupedOrderRole
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    groupedOrderRole?: string | null;
    /**
     * Gets or Sets GroupedOrderPartCount
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    groupedOrderPartCount?: number;
    /**
     * Gets or Sets GroupedOrderSequence
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    groupedOrderSequence?: number;
    /**
     * Gets or Sets Num1
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num1?: number;
    /**
     * Gets or Sets Num2
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num2?: number;
    /**
     * Gets or Sets Num3
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num3?: number;
    /**
     * Gets or Sets Num4
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num4?: number;
    /**
     * Gets or Sets Num5
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num5?: number;
    /**
     * Gets or Sets Num6
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num6?: number;
    /**
     * Gets or Sets Num7
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num7?: number;
    /**
     * Gets or Sets Num8
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num8?: number;
    /**
     * Gets or Sets Num9
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num9?: number;
    /**
     * Gets or Sets Num10
     * @type {number}
     * @memberof EfCaTourActionPoint
     */
    num10?: number;
    /**
     * Gets or Sets Text1
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text1?: string | null;
    /**
     * Gets or Sets Text2
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text2?: string | null;
    /**
     * Gets or Sets Text3
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text3?: string | null;
    /**
     * Gets or Sets Text4
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text4?: string | null;
    /**
     * Gets or Sets Text5
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text5?: string | null;
    /**
     * Gets or Sets Text6
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text6?: string | null;
    /**
     * Gets or Sets Text7
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text7?: string | null;
    /**
     * Gets or Sets Text8
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text8?: string | null;
    /**
     * Gets or Sets Text9
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text9?: string | null;
    /**
     * Gets or Sets Text10
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    text10?: string | null;
    /**
     * Gets or Sets StableSequenceNumber
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    stableSequenceNumber?: string | null;
    /**
     * Gets or Sets OldStableSequenceNumber
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    oldStableSequenceNumber?: string | null;
    /**
     * Gets or Sets Province
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    province?: string | null;
    /**
     * Gets or Sets LocationFunction
     * @type {string}
     * @memberof EfCaTourActionPoint
     */
    locationFunction?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaTourEmission
 */
export interface EfCaTourEmission {
    /**
     * Gets or Sets Ammonia
     * @type {number}
     * @memberof EfCaTourEmission
     */
    ammonia?: number;
    /**
     * Gets or Sets Benzene
     * @type {number}
     * @memberof EfCaTourEmission
     */
    benzene?: number;
    /**
     * Gets or Sets CarbonDioxideEquivalent
     * @type {number}
     * @memberof EfCaTourEmission
     */
    carbonDioxideEquivalent?: number;
    /**
     * Gets or Sets CarbonDioxide
     * @type {number}
     * @memberof EfCaTourEmission
     */
    carbonDioxide?: number;
    /**
     * Gets or Sets CarbonMonoxide
     * @type {number}
     * @memberof EfCaTourEmission
     */
    carbonMonoxide?: number;
    /**
     * Gets or Sets FossilCarbonDioxide
     * @type {number}
     * @memberof EfCaTourEmission
     */
    fossilCarbonDioxide?: number;
    /**
     * Gets or Sets Fuel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    fuel?: number;
    /**
     * Gets or Sets Hydrocarbons
     * @type {number}
     * @memberof EfCaTourEmission
     */
    hydrocarbons?: number;
    /**
     * Gets or Sets HydrocarbonsWithoutMethane
     * @type {number}
     * @memberof EfCaTourEmission
     */
    hydrocarbonsWithoutMethane?: number;
    /**
     * Gets or Sets Lead
     * @type {number}
     * @memberof EfCaTourEmission
     */
    lead?: number;
    /**
     * Gets or Sets Methane
     * @type {number}
     * @memberof EfCaTourEmission
     */
    methane?: number;
    /**
     * Gets or Sets NitrogenDioxide
     * @type {number}
     * @memberof EfCaTourEmission
     */
    nitrogenDioxide?: number;
    /**
     * Gets or Sets NitrogenOxides
     * @type {number}
     * @memberof EfCaTourEmission
     */
    nitrogenOxides?: number;
    /**
     * Gets or Sets NitrousOxide
     * @type {number}
     * @memberof EfCaTourEmission
     */
    nitrousOxide?: number;
    /**
     * Gets or Sets ParticleNumber
     * @type {number}
     * @memberof EfCaTourEmission
     */
    particleNumber?: number;
    /**
     * Gets or Sets Particles
     * @type {number}
     * @memberof EfCaTourEmission
     */
    particles?: number;
    /**
     * Gets or Sets SulphurDioxide
     * @type {number}
     * @memberof EfCaTourEmission
     */
    sulphurDioxide?: number;
    /**
     * Gets or Sets Toluene
     * @type {number}
     * @memberof EfCaTourEmission
     */
    toluene?: number;
    /**
     * Gets or Sets Xylene
     * @type {number}
     * @memberof EfCaTourEmission
     */
    xylene?: number;
    /**
     * Gets or Sets CENCO2TankToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    cencO2TankToWheel?: number;
    /**
     * Gets or Sets CENCO2WheelToTank
     * @type {number}
     * @memberof EfCaTourEmission
     */
    cencO2WheelToTank?: number;
    /**
     * Gets or Sets CENCO2WheelToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    cencO2WheelToWheel?: number;
    /**
     * Gets or Sets CENEnergyTankToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    cenEnergyTankToWheel?: number;
    /**
     * Gets or Sets CENEnergyWheelToTank
     * @type {number}
     * @memberof EfCaTourEmission
     */
    cenEnergyWheelToTank?: number;
    /**
     * Gets or Sets CENEnergyWheelToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    cenEnergyWheelToWheel?: number;
    /**
     * Gets or Sets FRDCREECO2TankToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    frdcreecO2TankToWheel?: number;
    /**
     * Gets or Sets FRDCREECO2WheelToTank
     * @type {number}
     * @memberof EfCaTourEmission
     */
    frdcreecO2WheelToTank?: number;
    /**
     * Gets or Sets FRDCREECO2WheelToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    frdcreecO2WheelToWheel?: number;
    /**
     * Gets or Sets N2OTankToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    n2OTankToWheel?: number;
    /**
     * Gets or Sets CH4TankToWheel
     * @type {number}
     * @memberof EfCaTourEmission
     */
    cH4TankToWheel?: number;
}
/**
 * 
 * @export
 * @interface EfCaTourFixation
 */
export interface EfCaTourFixation {
    /**
     * 
     * @type {string}
     * @memberof EfCaTourFixation
     */
    planningId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaTourFixation
     */
    tourId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaTourFixation
     */
    fix?: boolean;
}
/**
 * 
 * @export
 * @interface EfCaTourHeader
 */
export interface EfCaTourHeader {
    /**
     * Gets or Sets ExtId1
     * @type {string}
     * @memberof EfCaTourHeader
     */
    extId1?: string | null;
    /**
     * Gets or Sets StartDateTime
     * @type {string}
     * @memberof EfCaTourHeader
     */
    startDateTime?: string;
    /**
     * Gets or Sets EndDateTime
     * @type {string}
     * @memberof EfCaTourHeader
     */
    endDateTime?: string;
    /**
     * Gets or Sets LatestTourStart
     * @type {string}
     * @memberof EfCaTourHeader
     */
    latestTourStart?: string;
    /**
     * Gets or Sets StartOfDepotAvailability
     * @type {string}
     * @memberof EfCaTourHeader
     */
    startOfDepotAvailability?: string;
    /**
     * Gets or Sets EndOfDepotAvailability
     * @type {string}
     * @memberof EfCaTourHeader
     */
    endOfDepotAvailability?: string;
    /**
     * Gets or Sets PrecombinedTourId
     * @type {string}
     * @memberof EfCaTourHeader
     */
    precombinedTourId?: string | null;
    /**
     * Gets or Sets CustomerTourExtId
     * @type {string}
     * @memberof EfCaTourHeader
     */
    customerTourExtId?: string | null;
    /**
     * Gets or Sets ReturnState
     * @type {string}
     * @memberof EfCaTourHeader
     */
    returnState?: string | null;
    /**
     * Gets or Sets OrderCount
     * @type {number}
     * @memberof EfCaTourHeader
     */
    orderCount?: number;
    /**
     * Gets or Sets TourPointCount
     * @type {number}
     * @memberof EfCaTourHeader
     */
    tourPointCount?: number;
    /**
     * Gets or Sets Note
     * @type {string}
     * @memberof EfCaTourHeader
     */
    note?: string | null;
    /**
     * Gets or Sets LocationFromToCity
     * @type {string}
     * @memberof EfCaTourHeader
     */
    locationFromToCity?: string | null;
    /**
     * Gets or Sets VehicleExtId1
     * @type {string}
     * @memberof EfCaTourHeader
     */
    vehicleExtId1?: string | null;
    /**
     * Gets or Sets VehicleLicensePlate
     * @type {string}
     * @memberof EfCaTourHeader
     */
    vehicleLicensePlate?: string | null;
    /**
     * Gets or Sets HaulierExtId1
     * @type {string}
     * @memberof EfCaTourHeader
     */
    haulierExtId1?: string | null;
    /**
     * Gets or Sets CodriverNeeded
     * @type {boolean}
     * @memberof EfCaTourHeader
     */
    codriverNeeded?: boolean;
    /**
     * Gets or Sets Earliness
     * @type {number}
     * @memberof EfCaTourHeader
     */
    earliness?: number;
    /**
     * Gets or Sets TotalDistance
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalDistance?: number;
    /**
     * Gets or Sets DriveDistance
     * @type {number}
     * @memberof EfCaTourHeader
     */
    driveDistance?: number;
    /**
     * Gets or Sets EmptyDistance
     * @type {number}
     * @memberof EfCaTourHeader
     */
    emptyDistance?: number;
    /**
     * Gets or Sets RouteDistance
     * @type {number}
     * @memberof EfCaTourHeader
     */
    routeDistance?: number;
    /**
     * Gets or Sets ContinuationDistance
     * @type {number}
     * @memberof EfCaTourHeader
     */
    continuationDistance?: number;
    /**
     * Gets or Sets TotalDistanceNoReturnDepot
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalDistanceNoReturnDepot?: number;
    /**
     * Gets or Sets TotalDuration
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalDuration?: number;
    /**
     * Gets or Sets DriveDuration
     * @type {number}
     * @memberof EfCaTourHeader
     */
    driveDuration?: number;
    /**
     * Gets or Sets EmptyDuration
     * @type {number}
     * @memberof EfCaTourHeader
     */
    emptyDuration?: number;
    /**
     * Gets or Sets RouteDuration
     * @type {number}
     * @memberof EfCaTourHeader
     */
    routeDuration?: number;
    /**
     * Gets or Sets ContinuationDuration
     * @type {number}
     * @memberof EfCaTourHeader
     */
    continuationDuration?: number;
    /**
     * Gets or Sets TotalDurationNoReturnDepot
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalDurationNoReturnDepot?: number;
    /**
     * Gets or Sets TotalDrivingTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalDrivingTime?: number;
    /**
     * Gets or Sets DrivingTimeFactor
     * @type {number}
     * @memberof EfCaTourHeader
     */
    drivingTimeFactor?: number;
    /**
     * Gets or Sets TotalHandlingTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalHandlingTime?: number;
    /**
     * Gets or Sets TotalIdleTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalIdleTime?: number;
    /**
     * Gets or Sets TotalLoadingTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalLoadingTime?: number;
    /**
     * Gets or Sets TotalUnloadingTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalUnloadingTime?: number;
    /**
     * Gets or Sets TotalBreakTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalBreakTime?: number;
    /**
     * Gets or Sets TotalRestTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalRestTime?: number;
    /**
     * Gets or Sets TotalTurnaroundTime
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalTurnaroundTime?: number;
    /**
     * Gets or Sets TourRestPeriod
     * @type {number}
     * @memberof EfCaTourHeader
     */
    tourRestPeriod?: number;
    /**
     * Gets or Sets WaitingPeriod
     * @type {number}
     * @memberof EfCaTourHeader
     */
    waitingPeriod?: number;
    /**
     * Gets or Sets ShiftPotentialSeconds
     * @type {number}
     * @memberof EfCaTourHeader
     */
    shiftPotentialSeconds?: number;
    /**
     * Gets or Sets MergedViolations
     * @type {string}
     * @memberof EfCaTourHeader
     */
    mergedViolations?: string | null;
    /**
     * Gets or Sets Weight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    weight?: number;
    /**
     * Gets or Sets Volume
     * @type {number}
     * @memberof EfCaTourHeader
     */
    volume?: number;
    /**
     * Gets or Sets LoadingMeter
     * @type {number}
     * @memberof EfCaTourHeader
     */
    loadingMeter?: number;
    /**
     * Gets or Sets Quantity1
     * @type {number}
     * @memberof EfCaTourHeader
     */
    quantity1?: number;
    /**
     * Gets or Sets Quantity2
     * @type {number}
     * @memberof EfCaTourHeader
     */
    quantity2?: number;
    /**
     * Gets or Sets Quantity3
     * @type {number}
     * @memberof EfCaTourHeader
     */
    quantity3?: number;
    /**
     * Gets or Sets Quantity4
     * @type {number}
     * @memberof EfCaTourHeader
     */
    quantity4?: number;
    /**
     * Gets or Sets Quantity5
     * @type {number}
     * @memberof EfCaTourHeader
     */
    quantity5?: number;
    /**
     * Gets or Sets Quantity6
     * @type {number}
     * @memberof EfCaTourHeader
     */
    quantity6?: number;
    /**
     * Gets or Sets Quantity7
     * @type {number}
     * @memberof EfCaTourHeader
     */
    quantity7?: number;
    /**
     * Gets or Sets RestCapacityWeight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacityWeight?: number;
    /**
     * Gets or Sets RestCapacityVolume
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacityVolume?: number;
    /**
     * Gets or Sets RestCapacityLoadingMeter
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacityLoadingMeter?: number;
    /**
     * Gets or Sets RestCapacity1
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacity1?: number;
    /**
     * Gets or Sets RestCapacity2
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacity2?: number;
    /**
     * Gets or Sets RestCapacity3
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacity3?: number;
    /**
     * Gets or Sets RestCapacity4
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacity4?: number;
    /**
     * Gets or Sets RestCapacity5
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacity5?: number;
    /**
     * Gets or Sets RestCapacity6
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacity6?: number;
    /**
     * Gets or Sets RestCapacity7
     * @type {number}
     * @memberof EfCaTourHeader
     */
    restCapacity7?: number;
    /**
     * Gets or Sets SumWeight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumWeight?: number;
    /**
     * Gets or Sets SumVolume
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumVolume?: number;
    /**
     * Gets or Sets SumLoadingMeter
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumLoadingMeter?: number;
    /**
     * Gets or Sets SumQuantity1
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumQuantity1?: number;
    /**
     * Gets or Sets SumQuantity2
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumQuantity2?: number;
    /**
     * Gets or Sets SumQuantity3
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumQuantity3?: number;
    /**
     * Gets or Sets SumQuantity4
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumQuantity4?: number;
    /**
     * Gets or Sets SumQuantity5
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumQuantity5?: number;
    /**
     * Gets or Sets SumQuantity6
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumQuantity6?: number;
    /**
     * Gets or Sets SumQuantity7
     * @type {number}
     * @memberof EfCaTourHeader
     */
    sumQuantity7?: number;
    /**
     * Gets or Sets PickupWeight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupWeight?: number;
    /**
     * Gets or Sets PickupVolume
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupVolume?: number;
    /**
     * Gets or Sets PickupLoadingMeter
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupLoadingMeter?: number;
    /**
     * Gets or Sets PickupQuantity1
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupQuantity1?: number;
    /**
     * Gets or Sets PickupQuantity2
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupQuantity2?: number;
    /**
     * Gets or Sets PickupQuantity3
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupQuantity3?: number;
    /**
     * Gets or Sets PickupQuantity4
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupQuantity4?: number;
    /**
     * Gets or Sets PickupQuantity5
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupQuantity5?: number;
    /**
     * Gets or Sets PickupQuantity6
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupQuantity6?: number;
    /**
     * Gets or Sets PickupQuantity7
     * @type {number}
     * @memberof EfCaTourHeader
     */
    pickupQuantity7?: number;
    /**
     * Gets or Sets DeliveryWeight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryWeight?: number;
    /**
     * Gets or Sets DeliveryVolume
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryVolume?: number;
    /**
     * Gets or Sets DeliveryLoadingMeter
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryLoadingMeter?: number;
    /**
     * Gets or Sets DeliveryQuantity1
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryQuantity1?: number;
    /**
     * Gets or Sets DeliveryQuantity2
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryQuantity2?: number;
    /**
     * Gets or Sets DeliveryQuantity3
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryQuantity3?: number;
    /**
     * Gets or Sets DeliveryQuantity4
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryQuantity4?: number;
    /**
     * Gets or Sets DeliveryQuantity5
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryQuantity5?: number;
    /**
     * Gets or Sets DeliveryQuantity6
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryQuantity6?: number;
    /**
     * Gets or Sets DeliveryQuantity7
     * @type {number}
     * @memberof EfCaTourHeader
     */
    deliveryQuantity7?: number;
    /**
     * Gets or Sets PercentWeight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentWeight?: number;
    /**
     * Gets or Sets PercentVolume
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentVolume?: number;
    /**
     * Gets or Sets PercentLoadingMeter
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentLoadingMeter?: number;
    /**
     * Gets or Sets PercentQuantity1
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentQuantity1?: number;
    /**
     * Gets or Sets PercentQuantity2
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentQuantity2?: number;
    /**
     * Gets or Sets PercentQuantity3
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentQuantity3?: number;
    /**
     * Gets or Sets PercentQuantity4
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentQuantity4?: number;
    /**
     * Gets or Sets PercentQuantity5
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentQuantity5?: number;
    /**
     * Gets or Sets PercentQuantity6
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentQuantity6?: number;
    /**
     * Gets or Sets PercentQuantity7
     * @type {number}
     * @memberof EfCaTourHeader
     */
    percentQuantity7?: number;
    /**
     * Gets or Sets MinHeight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    minHeight?: number;
    /**
     * Gets or Sets MaxHeight
     * @type {number}
     * @memberof EfCaTourHeader
     */
    maxHeight?: number;
    /**
     * Gets or Sets MinLength
     * @type {number}
     * @memberof EfCaTourHeader
     */
    minLength?: number;
    /**
     * Gets or Sets MaxLength
     * @type {number}
     * @memberof EfCaTourHeader
     */
    maxLength?: number;
    /**
     * Gets or Sets MinWidth
     * @type {number}
     * @memberof EfCaTourHeader
     */
    minWidth?: number;
    /**
     * Gets or Sets MaxWidth
     * @type {number}
     * @memberof EfCaTourHeader
     */
    maxWidth?: number;
    /**
     * Gets or Sets TaskfieldNames
     * @type {string}
     * @memberof EfCaTourHeader
     */
    taskfieldNames?: string | null;
    /**
     * Gets or Sets TotalTourCosts
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalTourCosts?: number;
    /**
     * Gets or Sets CostTotalNoReturnDepot
     * @type {number}
     * @memberof EfCaTourHeader
     */
    costTotalNoReturnDepot?: number;
    /**
     * Gets or Sets TotalTollCosts
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalTollCosts?: number;
    /**
     * Gets or Sets TotalTollDistance
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalTollDistance?: number;
    /**
     * Gets or Sets TollCostsNoReturnDepot
     * @type {number}
     * @memberof EfCaTourHeader
     */
    tollCostsNoReturnDepot?: number;
    /**
     * Gets or Sets TollDistanceNoReturnDepot
     * @type {number}
     * @memberof EfCaTourHeader
     */
    tollDistanceNoReturnDepot?: number;
    /**
     * Gets or Sets TotalFreightCosts
     * @type {number}
     * @memberof EfCaTourHeader
     */
    totalFreightCosts?: number;
    /**
     * Gets or Sets FreightCostsManual
     * @type {number}
     * @memberof EfCaTourHeader
     */
    freightCostsManual?: number;
    /**
     * Gets or Sets FreightCostsCalculated
     * @type {number}
     * @memberof EfCaTourHeader
     */
    freightCostsCalculated?: number;
    /**
     * Gets or Sets ExecutionState
     * @type {string}
     * @memberof EfCaTourHeader
     */
    executionState?: string | null;
    /**
     * Gets or Sets ExecutionStateTime
     * @type {string}
     * @memberof EfCaTourHeader
     */
    executionStateTime?: string;
    /**
     * Gets or Sets ExecutionTourStart
     * @type {string}
     * @memberof EfCaTourHeader
     */
    executionTourStart?: string | null;
    /**
     * Gets or Sets ExecutionTourEnd
     * @type {string}
     * @memberof EfCaTourHeader
     */
    executionTourEnd?: string | null;
    /**
     * Gets or Sets ExecutionTourDelay
     * @type {number}
     * @memberof EfCaTourHeader
     */
    executionTourDelay?: number;
    /**
     * Gets or Sets TelematicsState
     * @type {string}
     * @memberof EfCaTourHeader
     */
    telematicsState?: string | null;
    /**
     * Gets or Sets IsInExecution
     * @type {boolean}
     * @memberof EfCaTourHeader
     */
    isInExecution?: boolean;
    /**
     * Gets or Sets Num1
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num1?: number;
    /**
     * Gets or Sets Num2
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num2?: number;
    /**
     * Gets or Sets Num3
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num3?: number;
    /**
     * Gets or Sets Num4
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num4?: number;
    /**
     * Gets or Sets Num5
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num5?: number;
    /**
     * Gets or Sets Num6
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num6?: number;
    /**
     * Gets or Sets Num7
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num7?: number;
    /**
     * Gets or Sets Num8
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num8?: number;
    /**
     * Gets or Sets Num9
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num9?: number;
    /**
     * Gets or Sets Num10
     * @type {number}
     * @memberof EfCaTourHeader
     */
    num10?: number;
    /**
     * Gets or Sets Text1
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text1?: string | null;
    /**
     * Gets or Sets Text2
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text2?: string | null;
    /**
     * Gets or Sets Text3
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text3?: string | null;
    /**
     * Gets or Sets Text4
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text4?: string | null;
    /**
     * Gets or Sets Text5
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text5?: string | null;
    /**
     * Gets or Sets Text6
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text6?: string | null;
    /**
     * Gets or Sets Text7
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text7?: string | null;
    /**
     * Gets or Sets Text8
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text8?: string | null;
    /**
     * Gets or Sets Text9
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text9?: string | null;
    /**
     * Gets or Sets Text10
     * @type {string}
     * @memberof EfCaTourHeader
     */
    text10?: string | null;
    /**
     * Gets or Sets CreateUser
     * @type {string}
     * @memberof EfCaTourHeader
     */
    createUser?: string | null;
    /**
     * Gets or Sets ScemId
     * @type {string}
     * @memberof EfCaTourHeader
     */
    scemId?: string | null;
    /**
     * Gets or Sets OnBoardUnitExtId1
     * @type {string}
     * @memberof EfCaTourHeader
     */
    onBoardUnitExtId1?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaTourStop
 */
export interface EfCaTourStop {
    /**
     * Gets or Sets CoordinateType
     * @type {string}
     * @memberof EfCaTourStop
     */
    coordinateType?: string | null;
    /**
     * Gets or Sets ArrivalTime
     * @type {string}
     * @memberof EfCaTourStop
     */
    arrivalTime?: string;
    /**
     * Gets or Sets DepartureTime
     * @type {string}
     * @memberof EfCaTourStop
     */
    departureTime?: string;
    /**
     * Gets or Sets StartServiceTime
     * @type {string}
     * @memberof EfCaTourStop
     */
    startServiceTime?: string;
    /**
     * Gets or Sets EndServiceTime
     * @type {string}
     * @memberof EfCaTourStop
     */
    endServiceTime?: string;
    /**
     * Gets or Sets Delay
     * @type {number}
     * @memberof EfCaTourStop
     */
    delay?: number;
    /**
     * Gets or Sets Earliness
     * @type {number}
     * @memberof EfCaTourStop
     */
    earliness?: number;
    /**
     * Gets or Sets BreakPeriodOnRoad
     * @type {number}
     * @memberof EfCaTourStop
     */
    breakPeriodOnRoad?: number;
    /**
     * Gets or Sets BreakPeriodAtStop
     * @type {number}
     * @memberof EfCaTourStop
     */
    breakPeriodAtStop?: number;
    /**
     * Gets or Sets RestPeriodOnRoad
     * @type {number}
     * @memberof EfCaTourStop
     */
    restPeriodOnRoad?: number;
    /**
     * Gets or Sets RestPeriodAtStop
     * @type {number}
     * @memberof EfCaTourStop
     */
    restPeriodAtStop?: number;
    /**
     * Gets or Sets DrivingPeriod
     * @type {number}
     * @memberof EfCaTourStop
     */
    drivingPeriod?: number;
    /**
     * Gets or Sets ServicePeriod
     * @type {number}
     * @memberof EfCaTourStop
     */
    servicePeriod?: number;
    /**
     * Gets or Sets WaitingPeriod
     * @type {number}
     * @memberof EfCaTourStop
     */
    waitingPeriod?: number;
    /**
     * Gets or Sets TourPointCosts
     * @type {number}
     * @memberof EfCaTourStop
     */
    tourPointCosts?: number;
    /**
     * Gets or Sets TotalTollCosts
     * @type {number}
     * @memberof EfCaTourStop
     */
    totalTollCosts?: number;
    /**
     * Gets or Sets TotalTollDistance
     * @type {number}
     * @memberof EfCaTourStop
     */
    totalTollDistance?: number;
    /**
     * Gets or Sets RouteDistance
     * @type {number}
     * @memberof EfCaTourStop
     */
    routeDistance?: number;
    /**
     * Gets or Sets RouteDuration
     * @type {number}
     * @memberof EfCaTourStop
     */
    routeDuration?: number;
    /**
     * Gets or Sets StartDistance
     * @type {number}
     * @memberof EfCaTourStop
     */
    startDistance?: number;
    /**
     * Gets or Sets PredDistance
     * @type {number}
     * @memberof EfCaTourStop
     */
    predDistance?: number;
    /**
     * Gets or Sets CurrentWeight
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentWeight?: number;
    /**
     * Gets or Sets CurrentVolume
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentVolume?: number;
    /**
     * Gets or Sets CurrentLoadingMeter
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentLoadingMeter?: number;
    /**
     * Gets or Sets CurrentQuantity1
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentQuantity1?: number;
    /**
     * Gets or Sets CurrentQuantity2
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentQuantity2?: number;
    /**
     * Gets or Sets CurrentQuantity3
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentQuantity3?: number;
    /**
     * Gets or Sets CurrentQuantity4
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentQuantity4?: number;
    /**
     * Gets or Sets CurrentQuantity5
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentQuantity5?: number;
    /**
     * Gets or Sets CurrentQuantity6
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentQuantity6?: number;
    /**
     * Gets or Sets CurrentQuantity7
     * @type {number}
     * @memberof EfCaTourStop
     */
    currentQuantity7?: number;
    /**
     * Gets or Sets Num1
     * @type {number}
     * @memberof EfCaTourStop
     */
    num1?: number;
    /**
     * Gets or Sets Num2
     * @type {number}
     * @memberof EfCaTourStop
     */
    num2?: number;
    /**
     * Gets or Sets Num3
     * @type {number}
     * @memberof EfCaTourStop
     */
    num3?: number;
    /**
     * Gets or Sets Num4
     * @type {number}
     * @memberof EfCaTourStop
     */
    num4?: number;
    /**
     * Gets or Sets Num5
     * @type {number}
     * @memberof EfCaTourStop
     */
    num5?: number;
    /**
     * Gets or Sets Num6
     * @type {number}
     * @memberof EfCaTourStop
     */
    num6?: number;
    /**
     * Gets or Sets Num7
     * @type {number}
     * @memberof EfCaTourStop
     */
    num7?: number;
    /**
     * Gets or Sets Num8
     * @type {number}
     * @memberof EfCaTourStop
     */
    num8?: number;
    /**
     * Gets or Sets Num9
     * @type {number}
     * @memberof EfCaTourStop
     */
    num9?: number;
    /**
     * Gets or Sets Num10
     * @type {number}
     * @memberof EfCaTourStop
     */
    num10?: number;
    /**
     * Gets or Sets Text1
     * @type {string}
     * @memberof EfCaTourStop
     */
    text1?: string | null;
    /**
     * Gets or Sets Text2
     * @type {string}
     * @memberof EfCaTourStop
     */
    text2?: string | null;
    /**
     * Gets or Sets Text3
     * @type {string}
     * @memberof EfCaTourStop
     */
    text3?: string | null;
    /**
     * Gets or Sets Text4
     * @type {string}
     * @memberof EfCaTourStop
     */
    text4?: string | null;
    /**
     * Gets or Sets Text5
     * @type {string}
     * @memberof EfCaTourStop
     */
    text5?: string | null;
    /**
     * Gets or Sets Text6
     * @type {string}
     * @memberof EfCaTourStop
     */
    text6?: string | null;
    /**
     * Gets or Sets Text7
     * @type {string}
     * @memberof EfCaTourStop
     */
    text7?: string | null;
    /**
     * Gets or Sets Text8
     * @type {string}
     * @memberof EfCaTourStop
     */
    text8?: string | null;
    /**
     * Gets or Sets Text9
     * @type {string}
     * @memberof EfCaTourStop
     */
    text9?: string | null;
    /**
     * Gets or Sets Text10
     * @type {string}
     * @memberof EfCaTourStop
     */
    text10?: string | null;
    /**
     * Gets or Sets Country
     * @type {string}
     * @memberof EfCaTourStop
     */
    country?: string | null;
    /**
     * Gets or Sets State
     * @type {string}
     * @memberof EfCaTourStop
     */
    state?: string | null;
    /**
     * Gets or Sets Postcode
     * @type {string}
     * @memberof EfCaTourStop
     */
    postcode?: string | null;
    /**
     * Gets or Sets City
     * @type {string}
     * @memberof EfCaTourStop
     */
    city?: string | null;
    /**
     * Gets or Sets District
     * @type {string}
     * @memberof EfCaTourStop
     */
    district?: string | null;
    /**
     * Gets or Sets Street
     * @type {string}
     * @memberof EfCaTourStop
     */
    street?: string | null;
    /**
     * Gets or Sets HouseNo
     * @type {string}
     * @memberof EfCaTourStop
     */
    houseNo?: string | null;
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof EfCaTourStop
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof EfCaTourStop
     */
    longitude?: number;
    /**
     * Gets or Sets ExecutionSequence
     * @type {number}
     * @memberof EfCaTourStop
     */
    executionSequence?: number;
    /**
     * Gets or Sets ExecutionTourPointState
     * @type {string}
     * @memberof EfCaTourStop
     */
    executionTourPointState?: string | null;
    /**
     * Gets or Sets ExecutionTourPointStateTime
     * @type {string}
     * @memberof EfCaTourStop
     */
    executionTourPointStateTime?: string;
    /**
     * Gets or Sets ExecutionArrivalTime
     * @type {string}
     * @memberof EfCaTourStop
     */
    executionArrivalTime?: string;
    /**
     * Gets or Sets ExecutionDepartureTime
     * @type {string}
     * @memberof EfCaTourStop
     */
    executionDepartureTime?: string;
    /**
     * Gets or Sets ExecutionArrivalTimeOnBoardUnit
     * @type {string}
     * @memberof EfCaTourStop
     */
    executionArrivalTimeOnBoardUnit?: string;
    /**
     * Gets or Sets ExecutionDepartureTimeOnBoardUnit
     * @type {string}
     * @memberof EfCaTourStop
     */
    executionDepartureTimeOnBoardUnit?: string;
    /**
     * Gets or Sets ExecutionTourStopDelay
     * @type {number}
     * @memberof EfCaTourStop
     */
    executionTourStopDelay?: number;
    /**
     * Gets or Sets Province
     * @type {string}
     * @memberof EfCaTourStop
     */
    province?: string | null;
    /**
     * Gets or Sets ScemId
     * @type {string}
     * @memberof EfCaTourStop
     */
    scemId?: string | null;
    /**
     * Gets or Sets TourPointReference
     * @type {string}
     * @memberof EfCaTourStop
     */
    tourPointReference?: string | null;
    /**
     * Gets or Sets TourActionPoints
     * @type {Array<EfCaTourActionPoint>}
     * @memberof EfCaTourStop
     */
    tourActionPoints?: Array<EfCaTourActionPoint> | null;
    /**
     * Gets or Sets TourEmissions
     * @type {Array<EfCaTourEmission>}
     * @memberof EfCaTourStop
     */
    tourEmissions?: Array<EfCaTourEmission> | null;
    /**
     * Gets or Sets TourTollCosts
     * @type {Array<EfCaTourTollCost>}
     * @memberof EfCaTourStop
     */
    tourTollCosts?: Array<EfCaTourTollCost> | null;
}
/**
 * 
 * @export
 * @interface EfCaTourTollCost
 */
export interface EfCaTourTollCost {
    /**
     * Gets or Sets TollCountry
     * @type {string}
     * @memberof EfCaTourTollCost
     */
    tollCountry?: string | null;
    /**
     * Gets or Sets TollCostsCountry
     * @type {number}
     * @memberof EfCaTourTollCost
     */
    tollCostsCountry?: number;
    /**
     * Gets or Sets TollDistanceCountry
     * @type {number}
     * @memberof EfCaTourTollCost
     */
    tollDistanceCountry?: number;
    /**
     * Gets or Sets TourProviderId
     * @type {number}
     * @memberof EfCaTourTollCost
     */
    tourProviderId?: number;
}
/**
 * 
 * @export
 * @interface EfCaTransportBox
 */
export interface EfCaTransportBox {
    /**
     * 
     * @type {string}
     * @memberof EfCaTransportBox
     */
    ident?: string | null;
    /**
     * Wertstoff, Paket
     * @type {string}
     * @memberof EfCaTransportBox
     */
    transportBoxTypeId?: string | null;
    /**
     * Maximum  Einheit: kWh, Maximale Batteriekapazität)
     * @type {number}
     * @memberof EfCaTransportBox
     */
    batteryCapacity?: number;
    /**
     * 
     * @type {string}
     * @memberof EfCaTransportBox
     */
    ipAddress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaTransportBox
     */
    version?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaTransportBox
     */
    name?: string | null;
    /**
     * Pareva-Schloss LockerId  https://lsogit.fzi.de/efeu/efeuportal/-/issues/68
     * @type {string}
     * @memberof EfCaTransportBox
     */
    lockerId?: string | null;
    /**
     * Pareva-Schloss LockId  https://lsogit.fzi.de/efeu/efeuportal/-/issues/68
     * @type {string}
     * @memberof EfCaTransportBox
     */
    lockId?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaTransportBoxResp
 */
export interface EfCaTransportBoxResp {
    /**
     * 
     * @type {Array<EfCaTransportBox>}
     * @memberof EfCaTransportBoxResp
     */
    transportBoxes?: Array<EfCaTransportBox> | null;
    /**
     * 
     * @type {Array<EfCaTransportBoxType>}
     * @memberof EfCaTransportBoxResp
     */
    transportBoxTypes?: Array<EfCaTransportBoxType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaTransportBoxResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaTransportBoxResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaTransportBoxResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaTransportBoxType
 */
export interface EfCaTransportBoxType {
    /**
     * 
     * @type {string}
     * @memberof EfCaTransportBoxType
     */
    ident?: string | null;
    /**
     * SMALL_PACKAGE_BOX, LARGE_PACKAGE_BOX, SCRAP (WASTE), ...
     * @type {string}
     * @memberof EfCaTransportBoxType
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaTransportBoxType
     */
    description?: string | null;
    /**
     * In a BinType used as x  Unit = mm
     * @type {number}
     * @memberof EfCaTransportBoxType
     */
    lenght?: number;
    /**
     * In a BinType used as y  Unit = mm
     * @type {number}
     * @memberof EfCaTransportBoxType
     */
    width?: number;
    /**
     * In a BinType used as z  Unit = mm
     * @type {number}
     * @memberof EfCaTransportBoxType
     */
    height?: number;
    /**
     * The maximum weight of this box type  Unit = gr
     * @type {number}
     * @memberof EfCaTransportBoxType
     */
    weight?: number;
    /**
     * The maximum volume of this box type  Unit = gr
     * @type {number}
     * @memberof EfCaTransportBoxType
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof EfCaTransportBoxType
     */
    version?: number | null;
}
/**
 * Der efeuCampus User (EfCaUser) ist der Anwender des Systems, also derjenige der die Software bedienen kann und darf.    Jedem EfCaUser ist eine Rolle und ein Contact (EfCaContact => Dieser Contact ist der Vertragsinhaber) zugeordnet.
 * @export
 * @interface EfCaUser
 */
export interface EfCaUser {
    /**
     * 
     * @type {string}
     * @memberof EfCaUser
     */
    ident?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaUser
     */
    contactId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaUser
     */
    role?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaUser
     */
    notice?: string | null;
    /**
     * 
     * @type {Array<EfCaConfig>}
     * @memberof EfCaUser
     */
    configs?: Array<EfCaConfig> | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaUser
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaUserResp
 */
export interface EfCaUserResp {
    /**
     * 
     * @type {Array<EfCaUser>}
     * @memberof EfCaUserResp
     */
    users?: Array<EfCaUser> | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaUserResp
     */
    firstLoginPassword?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaUserResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaUserResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaUserResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaVehicle
 */
export interface EfCaVehicle {
    /**
     * 
     * @type {string}
     * @memberof EfCaVehicle
     */
    ident?: string | null;
    /**
     * Vehicle type (BIG_VEHICLE_BOT, DEFAULT_VEHICLE_BOT, Standard)    JSt: Falls es unterschiedliche Typen gibt muss diese Info dann an der Ladestelle hinterlegt werden?
     * @type {string}
     * @memberof EfCaVehicle
     */
    vehicleType?: string | null;
    /**
     * Geschwindigkeit in m/s
     * @type {number}
     * @memberof EfCaVehicle
     */
    maxSpeed?: number;
    /**
     * Gewicht in g
     * @type {number}
     * @memberof EfCaVehicle
     */
    maxWeight?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EfCaVehicle
     */
    boxTypes?: Array<string> | null;
    /**
     * PTVGROUP: https://jira.dev.ptv.de/browse/ECOMP-172    Datum der Inbetriebnahme
     * @type {string}
     * @memberof EfCaVehicle
     */
    launch?: string;
    /**
     * Gibt die max. Anzahl an Boxen je Bot an  PTVGROUP: https://jira.dev.ptv.de/browse/ECOMP-172
     * @type {number}
     * @memberof EfCaVehicle
     */
    vehicleCapacity?: number | null;
    /**
     * Unit: kWh, Maximale Batteriekapazität)
     * @type {number}
     * @memberof EfCaVehicle
     */
    batteryCapacity?: number;
    /**
     * 
     * @type {string}
     * @memberof EfCaVehicle
     */
    ipAddress?: string | null;
    /**
     * Einheit in W (Watt)
     * @type {number}
     * @memberof EfCaVehicle
     */
    power?: number;
    /**
     * Software Version des Fahrzeugs
     * @type {string}
     * @memberof EfCaVehicle
     */
    unitVersionSW?: string | null;
    /**
     * Hardware Version des Fahrzeugs
     * @type {string}
     * @memberof EfCaVehicle
     */
    unitVersionHW?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaVehicle
     */
    smartourQualityReference?: string | null;
    /**
     * Profile for routing and planning  https://lsogit.fzi.de/efeu/efeuportal/-/issues/24
     * @type {string}
     * @memberof EfCaVehicle
     */
    vehicleProfile?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaVehicle
     */
    version?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaVehicle
     */
    name?: string | null;
    /**
     * https://lsogit.fzi.de/efeu/efeuportal/-/issues/45
     * @type {string}
     * @memberof EfCaVehicle
     */
    startDepot?: string | null;
    /**
     * https://lsogit.fzi.de/efeu/efeuportal/-/issues/45
     * @type {string}
     * @memberof EfCaVehicle
     */
    endDepot?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaVehicleResp
 */
export interface EfCaVehicleResp {
    /**
     * 
     * @type {Array<EfCaVehicle>}
     * @memberof EfCaVehicleResp
     */
    vehicles?: Array<EfCaVehicle> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaVehicleResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaVehicleResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaVehicleResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaWarehousePlace
 */
export interface EfCaWarehousePlace {
    /**
     * 
     * @type {string}
     * @memberof EfCaWarehousePlace
     */
    ident?: string | null;
    /**
     * DHL, HERMES, ...
     * @type {string}
     * @memberof EfCaWarehousePlace
     */
    name?: string | null;
    /**
     * COMMISIONING, KEP, ...
     * @type {string}
     * @memberof EfCaWarehousePlace
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfCaWarehousePlace
     */
    version?: number | null;
}
/**
 * 
 * @export
 * @interface EfCaWarehousePlaceResp
 */
export interface EfCaWarehousePlaceResp {
    /**
     * 
     * @type {Array<EfCaWarehousePlace>}
     * @memberof EfCaWarehousePlaceResp
     */
    warehousePlaces?: Array<EfCaWarehousePlace> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaWarehousePlaceResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaWarehousePlaceResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaWarehousePlaceResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * 
 * @export
 * @interface EfCaXServerConfig
 */
export interface EfCaXServerConfig {
    /**
     * 
     * @type {string}
     * @memberof EfCaXServerConfig
     */
    user?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaXServerConfig
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaXServerConfig
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfCaXServerConfig
     */
    version?: string | null;
}
/**
 * 
 * @export
 * @interface EfCaXServerResp
 */
export interface EfCaXServerResp {
    /**
     * 
     * @type {RouteResponse}
     * @memberof EfCaXServerResp
     */
    routeResponse?: RouteResponse | null;
    /**
     * 
     * @type {LocationsResponse}
     * @memberof EfCaXServerResp
     */
    locationsResponse?: LocationsResponse | null;
    /**
     * 
     * @type {PackedBinsResponse}
     * @memberof EfCaXServerResp
     */
    packedBinsResponse?: PackedBinsResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfCaXServerResp
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfCaXServerResp
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof EfCaXServerResp
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * Defines values for EmissionStandardType.
 * @export
 * @enum {string}
 */
export enum EmissionStandardType {
    Hbefa32 = 'HBEFA32',
    Emisiacopertaustralia12 = 'EMISIACOPERTAUSTRALIA12',
    En162582012 = 'EN162582012',
    Frenchco2Decree20111336 = 'FRENCHCO2DECREE20111336',
    En16258Defra2014 = 'EN16258DEFRA2014',
    Australianngafactors2015 = 'AUSTRALIANNGAFACTORS2015',
    Frenchco2Edecree2017639 = 'FRENCHCO2EDECREE2017639'
}

/**
 * The base type for the emission values  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.emissions.EmissionValues
 * @export
 * @interface EmissionValues
 */
export interface EmissionValues {
    /**
     * Gets or sets specifies the type of the emission. Possible values  include: \'HBEFA_3_2\', \'EMISIA_COPERT_AUSTRALIA_1_2\',  \'EN16258_2012\', \'FRENCH_CO2_DECREE_2011_1336\',  \'EN16258_DEFRA_2014\', \'AUSTRALIAN_NGA_FACTORS_2015\',  \'FRENCH_CO2E_DECREE_2017_639\'
     * @type {EmissionStandardType}
     * @memberof EmissionValues
     */
    standardType?: EmissionStandardType;
}
/**
 * Define the emissions  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.emissions.Emissions
 * @export
 * @interface Emissions
 */
export interface Emissions {
    /**
     * 
     * @type {Array<EmissionValues>}
     * @memberof Emissions
     */
    values?: Array<EmissionValues> | null;
}
/**
 * An EncodedGeometry contains one or more representations of the same  Geometry which can be selected for each request by  service.GeometryOptions.responseGeometryTypes. If z-coordinates have to  be represented WKB and WKT are used in an extended form as described by  OpenGIS document 99-402r2.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.geometry.EncodedGeometry
 * @export
 * @interface EncodedGeometry
 */
export interface EncodedGeometry {
    /**
     * Gets or sets the plain representation of the geometry using  structured objects.
     * @type {object}
     * @memberof EncodedGeometry
     */
    plain?: object | null;
    /**
     * Gets or sets the WKB (well-known binary) representation of the  geometry
     * @type {string}
     * @memberof EncodedGeometry
     */
    wkb?: string | null;
    /**
     * Gets or sets the WKT (well-known text) representation of the  geometry.
     * @type {string}
     * @memberof EncodedGeometry
     */
    wkt?: string | null;
    /**
     * Gets or sets the KML (keyhole markup language) representation of  the geometry. Coordinates are always present in WGS84 (EPSG:4326).
     * @type {KML}
     * @memberof EncodedGeometry
     */
    kml?: KML | null;
    /**
     * Gets or sets the GeoJSON representation of the geometry.  Coordinates are always present in WGS84 (EPSG:4326).
     * @type {string}
     * @memberof EncodedGeometry
     */
    geoJSON?: string | null;
}
/**
 * FinishTourEvent
 * @export
 * @interface FinishTourEvent
 */
export interface FinishTourEvent {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof FinishTourEvent
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets TourExtId
     * @type {string}
     * @memberof FinishTourEvent
     */
    tourExtId?: string | null;
}
/**
 * Represents a geographical object in terms of its position and its  address.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xlocate.Location
 * @export
 * @interface GeocodingLocation
 */
export interface GeocodingLocation {
    /**
     * Gets or sets the actual position of the object itself, such as the  rooftop of a building, the center of a street, or the reference  point of a city.                In rare cases (when missing in the map data) this may not be  available for a building address. In such a case, there will be a  roadAccessCoordinate.                Please refer to the documentation of waypoints for more information  on how best to use this position in a route calculation.
     * @type {Coordinate}
     * @memberof GeocodingLocation
     */
    referenceCoordinate?: Coordinate | null;
    /**
     * Gets or sets a position on or very close to a road network segment  that marks the entry to the object. Available only for objects  which represent buildings with a complete address and only if  present in the data.                The road from which the object can be accessed is not necessarily  the road which is closest to the object. When using the location as  the input to a route calculation, pass the roadAccessCoordinate to  the RouteLocation for best results.                Please refer to the documentation of waypoints for more information  on how to use this position best in a route calculation.
     * @type {Coordinate}
     * @memberof GeocodingLocation
     */
    roadAccessCoordinate?: Coordinate | null;
    /**
     * Gets or sets the address of the object.
     * @type {Address}
     * @memberof GeocodingLocation
     */
    address?: Address | null;
    /**
     * Gets or sets a single string representing the address, typically  consisting of the street, house number, postal code, city, and  country. The precise format may differ based on what is appropriate  for the address.
     * @type {string}
     * @memberof GeocodingLocation
     */
    formattedAddress?: string | null;
}
/**
 * ImageDownload
 * @export
 * @interface ImageDownload
 */
export interface ImageDownload {
    /**
     * Gets or Sets ImageFound
     * @type {boolean}
     * @memberof ImageDownload
     */
    imageFound?: boolean;
    /**
     * Gets or Sets Base64strings
     * @type {Array<string>}
     * @memberof ImageDownload
     */
    base64strings?: Array<string> | null;
}
/**
 * ImageUpload
 * @export
 * @interface ImageUpload
 */
export interface ImageUpload {
    /**
     * Gets or Sets Base64String
     * @type {string}
     * @memberof ImageUpload
     */
    base64String?: string | null;
}
/**
 * IncomingBox
 * @export
 * @interface IncomingBox
 */
export interface IncomingBox {
    /**
     * Gets or Sets BoxLoad
     * @type {BoxLoadEnum}
     * @memberof IncomingBox
     */
    boxLoad?: BoxLoadEnum;
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof IncomingBox
     */
    id?: string | null;
    /**
     * Gets or Sets BoxType
     * @type {string}
     * @memberof IncomingBox
     */
    boxType?: string | null;
    /**
     * Gets or Sets BoxName
     * @type {string}
     * @memberof IncomingBox
     */
    boxName?: string | null;
    /**
     * Gets or Sets Timestamp
     * @type {string}
     * @memberof IncomingBox
     */
    timestamp?: string;
    /**
     * Gets or Sets MountId
     * @type {string}
     * @memberof IncomingBox
     */
    mountId?: string | null;
    /**
     * Gets or Sets IsReturnedDelivery
     * @type {boolean}
     * @memberof IncomingBox
     */
    isReturnedDelivery?: boolean;
    /**
     * Gets or Sets ReturnedPackageIds
     * @type {Array<string>}
     * @memberof IncomingBox
     */
    returnedPackageIds?: Array<string> | null;
}
/**
 * Defines values for ItemOrientation.
 * @export
 * @enum {string}
 */
export enum ItemOrientation {
    Original = 'ORIGINAL',
    X = 'X',
    Y = 'Y',
    Z = 'Z',
    Xz = 'XZ',
    Yz = 'YZ'
}

/**
 * Describes the position of an item in a bin. The coordinate system is  right-handed with x extending to the right, y pointing up and z  pointing towards you. In terms of a truck (0,0,0) would be at the  lower-left-corner of the opposing wall when looking through the back  doors. An item, for example a palette, placed there would be on the  ground. It would also touch the left wall as well as the wall towards  the drivers cabin.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xload.ItemPosition
 * @export
 * @interface ItemPosition
 */
export interface ItemPosition {
    /**
     * Gets or sets offset along x-axis. Equal to the shift along the  width of the bin from left to right. Rounded to nearest full  centimeter internally.
     * @type {number}
     * @memberof ItemPosition
     */
    x?: number;
    /**
     * Gets or sets offset along y-axis. Equal to the height from the  floor of the bin. Rounded to nearest full centimeter internally.
     * @type {number}
     * @memberof ItemPosition
     */
    y?: number;
    /**
     * Gets or sets offset along z-axis. Equal to the shift along the  length of the bin from back to front. Rounded to nearest full  centimeter internally.
     * @type {number}
     * @memberof ItemPosition
     */
    z?: number;
}
/**
 * Describes the surface loads in kg/m² on an item acting on each of its  axis. If an axis is not provided it is assumed to be irrelevant and/or  non-restrictive.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xload.ItemSurfaceLoads
 * @export
 * @interface ItemSurfaceLoads
 */
export interface ItemSurfaceLoads {
    /**
     * Gets or sets surface load acting on the x-axis (width) of the item.  Internally rounded to nearest full g/cm² .
     * @type {number}
     * @memberof ItemSurfaceLoads
     */
    x?: number | null;
    /**
     * Gets or sets surface load acting on the y-axis (height) of the  item. Internally rounded to nearest full g/cm² .
     * @type {number}
     * @memberof ItemSurfaceLoads
     */
    y?: number | null;
    /**
     * Gets or sets surface load acting on the z-axis (length) of the  item. Internally rounded to nearest full g/cm² .
     * @type {number}
     * @memberof ItemSurfaceLoads
     */
    z?: number | null;
}
/**
 * Describes a cuboid item which is to be packed into a bin. If there are  many identical items that do not have to be differentiated by  individual ItemType.id you should use the ItemType.numberOfItems field.  This provides better performance and packing than specifying each item  on its own when this is not needed.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xload.ItemType
 * @export
 * @interface ItemType
 */
export interface ItemType {
    /**
     * Gets or sets user provided ID for this item type. Must be unique.
     * @type {string}
     * @memberof ItemType
     */
    id?: string | null;
    /**
     * Gets or sets outer dimensions of this item. Please note that the  sum of each dimension (i.e. x+y+z) should be greater than 50 cm.
     * @type {BoxDimensions}
     * @memberof ItemType
     */
    dimensions?: BoxDimensions | null;
    /**
     * Gets or sets weight of this item. Internally rounded to nearest  full gram.
     * @type {number}
     * @memberof ItemType
     */
    weight?: number;
    /**
     * Gets or sets maximum allowable load on this item. An item  experiences load when other items are stacked on top of it. The  load limit is defined for each axis as the item might be  re-oriented. If not given no limitation based on surface load is  made.
     * @type {ItemSurfaceLoads}
     * @memberof ItemType
     */
    maximumSurfaceLoads?: ItemSurfaceLoads | null;
    /**
     * Gets or sets allowed ways to orient this item when packing it into  a bin. If not given all orientations will be allowed.
     * @type {AllowedItemOrientations}
     * @memberof ItemType
     */
    allowedOrientations?: AllowedItemOrientations | null;
    /**
     * Gets or sets number of items of this type that should be packed.  Maximum number is 10000.
     * @type {number}
     * @memberof ItemType
     */
    numberOfItems?: number | null;
}
/**
 * Represents a KML geometry.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.geometry.KML
 * @export
 * @interface KML
 */
export interface KML {
    /**
     * Gets or sets the kml string itself
     * @type {string}
     * @memberof KML
     */
    kml?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KML
     */
    placemarks?: Array<string> | null;
}
/**
 * Defines KepProviderEnum
 * @export
 * @enum {string}
 */
export enum KepProviderEnum {
    Unknown = 'UNKNOWN',
    Dhl = 'DHL',
    Hermes = 'HERMES',
    Dpd = 'DPD',
    Ups = 'UPS',
    Amazon = 'AMAZON',
    Gls = 'GLS'
}

/**
 * Type for storing a single untyped key value pair. Contains a string key  and value. Interpretation of this type depends on the point of use. It  is usually found in lists with keys being unique to the list unless  otherwise mentioned.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.types.KeyValuePair
 * @export
 * @interface KeyValuePair
 */
export interface KeyValuePair {
    /**
     * Gets or sets key identifying the value.
     * @type {string}
     * @memberof KeyValuePair
     */
    key?: string | null;
    /**
     * Gets or sets value identified by the key. Interpretation depends on  point of use.
     * @type {string}
     * @memberof KeyValuePair
     */
    value?: string | null;
}
/**
 * LabelParcelEvent
 * @export
 * @interface LabelParcelEvent
 */
export interface LabelParcelEvent {
    /**
     * Gets or Sets ParcelIdent
     * @type {string}
     * @memberof LabelParcelEvent
     */
    parcelIdent?: string | null;
}
/**
 * A leg is defined by a start- and an end-waypoint and the segment (s)  between them. It is guaranteed that a leg consists of at least one  segment. It is also guaranteed that the leg of index *n* connects  waypoints of indices *n* and *n+1*, i.e. the number of response  waypoints equals the number of the list of legs plus 1.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.Leg
 * @export
 * @interface Leg
 */
export interface Leg {
    /**
     * Gets or sets the name of the start waypoint of the leg. This  user-defined name is available only if InputWaypoint.name for the  corresponding input waypoint is set.
     * @type {string}
     * @memberof Leg
     */
    startWaypointName?: string | null;
    /**
     * Gets or sets the name of the end waypoint of the leg. This  user-defined name is available only if InputWaypoint.name for the  corresponding input waypoint is set.
     * @type {string}
     * @memberof Leg
     */
    endWaypointName?: string | null;
    /**
     * Gets or sets the index in the list of RouteResponse.segments of the  start segment of the leg. It is available only if the list of  segments is requested by ResultFields.segments.
     * @type {number}
     * @memberof Leg
     */
    startSegmentIndex?: number | null;
    /**
     * Gets or sets the index in the list of RouteResponse.segments of the  end segment of the leg. It is available only if the list of  segments is requested by ResultFields.segments.
     * @type {number}
     * @memberof Leg
     */
    endSegmentIndex?: number | null;
    /**
     * Gets or sets the index in the list of RouteResponse.nodes of the  start node of the leg. It is available only if the list of nodes is  requested by ResultFields.nodes.
     * @type {number}
     * @memberof Leg
     */
    startNodeIndex?: number | null;
    /**
     * Gets or sets the index in the list of RouteResponse.nodes of the  end node of the leg. It is available only if the list of nodes is  requested by ResultFields.nodes.
     * @type {number}
     * @memberof Leg
     */
    endNodeIndex?: number | null;
    /**
     * Gets or sets the index in the list of Toll.sections of the first  toll section of the leg. It is available only if the toll sections  are requested in ResultFields.toll and if there is at least one  toll section for the leg.
     * @type {number}
     * @memberof Leg
     */
    startTollSectionIndex?: number | null;
    /**
     * Gets or sets the index in the list of Toll.sections of the last  toll section of the leg. It is available only if the toll sections  are requested in ResultFields.toll and if there is at least one  toll section for the leg.
     * @type {number}
     * @memberof Leg
     */
    endTollSectionIndex?: number | null;
    /**
     * Gets or sets the travel distance for the leg. It is the sum of  distances of all segments in the leg and hence is a whole number  without decimal places (see Segment.distance).
     * @type {number}
     * @memberof Leg
     */
    distance?: number;
    /**
     * Gets or sets the travel time for the leg. It is the sum of travel  times of all segments in the leg and hence has up to three decimal  places (see Segment.travelTime).
     * @type {number}
     * @memberof Leg
     */
    travelTime?: number;
    /**
     * Gets or sets the toll summary for the leg. The toll summary is only  populated if requested by LegResultFields.tollSummary.
     * @type {TollSummary}
     * @memberof Leg
     */
    tollSummary?: TollSummary | null;
    /**
     * Gets or sets the emissions along the route.
     * @type {Emissions}
     * @memberof Leg
     */
    emissions?: Emissions | null;
    /**
     * Gets or sets the polyline of the leg. This polyline consists of all  coordinates representing the leg and can be used to draw the leg  onto a map. It is available only if requested by  LegResultFields.polyline and it contains elevations only if  requested by xroute.PolylineOptions.elevations. If the elevations  data do not cover the complete polyline, an  xroute.ElevationsNotAvailableLimitation will be available which  states which parts of the polyline are not covered.
     * @type {EncodedGeometry}
     * @memberof Leg
     */
    polyline?: EncodedGeometry | null;
    /**
     * Gets or sets if set to true, indicates that this leg contains a  violation for the chosen vehicle.
     * @type {boolean}
     * @memberof Leg
     */
    violated?: boolean;
}
/**
 * Specifies if and to what extent the legs shall be returned for the  complete route.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.LegResultFields
 * @export
 * @interface LegResultFields
 */
export interface LegResultFields {
    /**
     * Gets or sets specifies if the legs shall be returned for the  complete route.
     * @type {boolean}
     * @memberof LegResultFields
     */
    enabled?: boolean | null;
    /**
     * Gets or sets specifies if the legs shall contain the polyline.
     * @type {boolean}
     * @memberof LegResultFields
     */
    polyline?: boolean | null;
    /**
     * Gets or sets specifies if the legs shall contain the toll summary.
     * @type {boolean}
     * @memberof LegResultFields
     */
    tollSummary?: boolean | null;
    /**
     * Gets or sets specifies if the legs shall contain the emissions.
     * @type {boolean}
     * @memberof LegResultFields
     */
    emissions?: boolean | null;
}
/**
 * Defines values for LocationType.
 * @export
 * @enum {string}
 */
export enum LocationType {
    Locality = 'LOCALITY',
    Postalcode = 'POSTALCODE',
    Street = 'STREET',
    Exactaddress = 'EXACTADDRESS',
    Interpolatedaddress = 'INTERPOLATEDADDRESS',
    Unspecified = 'UNSPECIFIED'
}

/**
 * 
 * @export
 * @interface LocationsResponse
 */
export interface LocationsResponse {
    /**
     * 
     * @type {Array<SearchLocationsResult>}
     * @memberof LocationsResponse
     */
    results?: Array<SearchLocationsResult> | null;
    /**
     * 
     * @type {Array<ResultLimitation>}
     * @memberof LocationsResponse
     */
    resultLimitations?: Array<ResultLimitation> | null;
}
/**
 * LockVehicleRequest
 * @export
 * @interface LockVehicleRequest
 */
export interface LockVehicleRequest {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof LockVehicleRequest
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets Start
     * @type {string}
     * @memberof LockVehicleRequest
     */
    start?: string;
    /**
     * Gets or Sets End
     * @type {string}
     * @memberof LockVehicleRequest
     */
    end?: string;
    /**
     * Gets or Sets LockIdent
     * @type {string}
     * @memberof LockVehicleRequest
     */
    lockIdent?: string | null;
}
/**
 * Indicates how well a result matches the search request.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xlocate.MatchQuality
 * @export
 * @interface MatchQuality
 */
export interface MatchQuality {
    /**
     * Gets or sets indicates the overall match quality for a geocoding  request. 100 (percent) represents a perfect match between the input  and the result.
     * @type {number}
     * @memberof MatchQuality
     */
    totalScore?: number | null;
    /**
     * Gets or sets the respective match qualities for each field of the  result Address.
     * @type {AddressScores}
     * @memberof MatchQuality
     */
    addressScores?: AddressScores | null;
    /**
     * Gets or sets the spatial distance between the result and the input  coordinate of a reverse geocoding request.
     * @type {number}
     * @memberof MatchQuality
     */
    distance?: number | null;
}
/**
 * MinimumCommissioningTimeMinutes
 * @export
 * @interface MinimumCommissioningTimeMinutes
 */
export interface MinimumCommissioningTimeMinutes {
    /**
     * Gets or Sets _MinimumCommissioningTimeMinutes
     * @type {number}
     * @memberof MinimumCommissioningTimeMinutes
     */
    _MinimumCommissioningTimeMinutes?: number;
}
/**
 * MinimumTimeWindowLength
 * @export
 * @interface MinimumTimeWindowLength
 */
export interface MinimumTimeWindowLength {
    /**
     * Gets or Sets MinimumTimeWindowLengthMinutes
     * @type {number}
     * @memberof MinimumTimeWindowLength
     */
    minimumTimeWindowLengthMinutes?: number;
}
/**
 * ModifyOrderRequest
 * @export
 * @interface ModifyOrderRequest
 */
export interface ModifyOrderRequest {
    /**
     * Gets or Sets NewBoxType
     * @type {BoxTypeEnum}
     * @memberof ModifyOrderRequest
     */
    newBoxType?: BoxTypeEnum | null;
    /**
     * Gets or Sets NewBoxSize
     * @type {BoxSizeEnum}
     * @memberof ModifyOrderRequest
     */
    newBoxSize?: BoxSizeEnum | null;
    /**
     * Gets or Sets NewOrderMode
     * @type {OrderModeEnum}
     * @memberof ModifyOrderRequest
     */
    newOrderMode?: OrderModeEnum | null;
    /**
     * Gets or Sets NewOrderState
     * @type {BoxOrderStateEnumAdmin}
     * @memberof ModifyOrderRequest
     */
    newOrderState?: BoxOrderStateEnumAdmin | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof ModifyOrderRequest
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets NewDeliveryTimeWindow
     * @type {TimeWindow}
     * @memberof ModifyOrderRequest
     */
    newDeliveryTimeWindow?: TimeWindow | null;
    /**
     * Gets or Sets NewMountIdent
     * @type {string}
     * @memberof ModifyOrderRequest
     */
    newMountIdent?: string | null;
    /**
     * Gets or Sets NewSyncMeetingPointIdent
     * @type {string}
     * @memberof ModifyOrderRequest
     */
    newSyncMeetingPointIdent?: string | null;
    /**
     * Gets or Sets NewDeliveryContactIdent
     * @type {string}
     * @memberof ModifyOrderRequest
     */
    newDeliveryContactIdent?: string | null;
    /**
     * Gets or Sets NewPickupContactIdent
     * @type {string}
     * @memberof ModifyOrderRequest
     */
    newPickupContactIdent?: string | null;
    /**
     * Gets or Sets NewLinkedBoxOrderIdent
     * @type {string}
     * @memberof ModifyOrderRequest
     */
    newLinkedBoxOrderIdent?: string | null;
    /**
     * Gets or Sets Force
     * @type {boolean}
     * @memberof ModifyOrderRequest
     */
    force?: boolean;
}
/**
 * Contains the monetary costs of a route in the specified currency.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.MonetaryCostsReport
 * @export
 * @interface MonetaryCostsReport
 */
export interface MonetaryCostsReport {
    /**
     * Gets or sets the total monetary cost of the route.
     * @type {number}
     * @memberof MonetaryCostsReport
     */
    totalCost?: number | null;
    /**
     * Gets or sets the distance cost based on  routing.MonetaryCostOptions.costPerKilometer.
     * @type {number}
     * @memberof MonetaryCostsReport
     */
    distanceCost?: number | null;
    /**
     * Gets or sets the working time cost based on  routing.MonetaryCostOptions.workingCostPerHour.
     * @type {number}
     * @memberof MonetaryCostsReport
     */
    workingTimeCost?: number | null;
    /**
     * Gets or sets the toll cost based on the route and the vehicle.
     * @type {number}
     * @memberof MonetaryCostsReport
     */
    tollCost?: number | null;
}
/**
 * Defines MountBoxOccupationState
 * @export
 * @enum {string}
 */
export enum MountBoxOccupationStateEnum {
    Free = 'FREE',
    Occupied = 'OCCUPIED'
}

/**
 * MountInformation
 * @export
 * @interface MountInformation
 */
export interface MountInformation {
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof MountInformation
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof MountInformation
     */
    longitude?: number;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof MountInformation
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets Name
     * @type {string}
     * @memberof MountInformation
     */
    name?: string | null;
}
/**
 * MountReservation
 * @export
 * @interface MountReservation
 */
export interface MountReservation {
    /**
     * Gets or Sets ReservationBoxSize
     * @type {ReservationBoxSizeEnum}
     * @memberof MountReservation
     */
    reservationBoxSize?: ReservationBoxSizeEnum;
    /**
     * Gets or Sets ExtId
     * @type {string}
     * @memberof MountReservation
     */
    extId?: string | null;
    /**
     * Gets or Sets MountId
     * @type {string}
     * @memberof MountReservation
     */
    mountId?: string | null;
    /**
     * Gets or Sets OrderIds
     * @type {Array<string>}
     * @memberof MountReservation
     */
    orderIds?: Array<string> | null;
    /**
     * Gets or Sets ReservationStart
     * @type {string}
     * @memberof MountReservation
     */
    reservationStart?: string;
    /**
     * Gets or Sets ReservationEnd
     * @type {string}
     * @memberof MountReservation
     */
    reservationEnd?: string;
}
/**
 * MountStatus
 * @export
 * @interface MountStatus
 */
export interface MountStatus {
    /**
     * Gets or Sets VehicleOccupationState
     * @type {VehicleOccupationStateEnum}
     * @memberof MountStatus
     */
    vehicleOccupationState?: VehicleOccupationStateEnum | null;
    /**
     * Gets or Sets MountBoxOccupationState
     * @type {MountBoxOccupationStateEnum}
     * @memberof MountStatus
     */
    mountBoxOccupationState?: MountBoxOccupationStateEnum | null;
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof MountStatus
     */
    id?: string | null;
    /**
     * Gets or Sets Timestamp
     * @type {string}
     * @memberof MountStatus
     */
    timestamp?: string;
    /**
     * Gets or Sets VehicleId
     * @type {string}
     * @memberof MountStatus
     */
    vehicleId?: string | null;
    /**
     * Gets or Sets BoxId
     * @type {string}
     * @memberof MountStatus
     */
    boxId?: string | null;
}
/**
 * MountingDevice
 * @export
 * @interface MountingDevice
 */
export interface MountingDevice {
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof MountingDevice
     */
    id?: string | null;
    /**
     * Gets or Sets MountName
     * @type {string}
     * @memberof MountingDevice
     */
    mountName?: string | null;
    /**
     * Gets or Sets MountAvailableForEmptyBox
     * @type {boolean}
     * @memberof MountingDevice
     */
    mountAvailableForEmptyBox?: boolean;
    /**
     * Gets or Sets BoxId
     * @type {string}
     * @memberof MountingDevice
     */
    boxId?: string | null;
    /**
     * Gets or Sets BoxType
     * @type {string}
     * @memberof MountingDevice
     */
    boxType?: string | null;
    /**
     * Gets or Sets BoxName
     * @type {string}
     * @memberof MountingDevice
     */
    boxName?: string | null;
    /**
     * Gets or Sets BoxFromContactIdent
     * @type {string}
     * @memberof MountingDevice
     */
    boxFromContactIdent?: string | null;
    /**
     * Gets or Sets BoxAvailableForCommissioning
     * @type {boolean}
     * @memberof MountingDevice
     */
    boxAvailableForCommissioning?: boolean;
    /**
     * Gets or Sets BoxAvailableForDeactivationStorage
     * @type {boolean}
     * @memberof MountingDevice
     */
    boxAvailableForDeactivationStorage?: boolean;
    /**
     * Gets or Sets BoxContainsContent
     * @type {boolean}
     * @memberof MountingDevice
     */
    boxContainsContent?: boolean;
    /**
     * Gets or Sets BoxIsInbound
     * @type {boolean}
     * @memberof MountingDevice
     */
    boxIsInbound?: boolean;
    /**
     * Gets or Sets BoxTimestamp
     * @type {string}
     * @memberof MountingDevice
     */
    boxTimestamp?: string;
}
/**
 * Represents a real or virtual node along a route. See NodeType for the  possible types.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.Node
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * Gets or sets location of the node. It never contains the  z-coordinate, refer to the polyline instead to obtain the  z-coordinate.
     * @type {Coordinate}
     * @memberof Node
     */
    coordinate?: Coordinate | null;
    /**
     * Gets or sets type of the node (e.g. NodeType.NETWORK\\_NODE).  Possible values include: \'NETWORK_NODE\', \'LINK_NODE\', \'INPUT_NODE\'
     * @type {NodeType}
     * @memberof Node
     */
    type?: NodeType;
    /**
     * 
     * @type {Array<number>}
     * @memberof Node
     */
    eventIndices?: Array<number> | null;
    /**
     * Gets or sets index of the polyline point corresponding to this node  in RouteResponse.polyline. Only available if a polyline is  requested using ResultFields.polyline.
     * @type {number}
     * @memberof Node
     */
    polylineIndex?: number | null;
    /**
     * Gets or sets if set to true, indicates that this node contains a  violation for the chosen vehicle.
     * @type {boolean}
     * @memberof Node
     */
    violated?: boolean | null;
}
/**
 * Defines values for NodeType.
 * @export
 * @enum {string}
 */
export enum NodeType {
    Networknode = 'NETWORKNODE',
    Linknode = 'LINKNODE',
    Inputnode = 'INPUTNODE'
}

/**
 * OrderInformation
 * @export
 * @interface OrderInformation
 */
export interface OrderInformation {
    /**
     * Gets or Sets OrderType
     * @type {OrderTypeEnum}
     * @memberof OrderInformation
     */
    orderType?: OrderTypeEnum | null;
    /**
     * Gets or Sets OrderMode
     * @type {OrderModeEnum}
     * @memberof OrderInformation
     */
    orderMode?: OrderModeEnum | null;
    /**
     * Gets or Sets OrderState
     * @type {OrderStateEnum}
     * @memberof OrderInformation
     */
    orderState?: OrderStateEnum | null;
    /**
     * Gets or Sets BoxType
     * @type {BoxTypeEnum}
     * @memberof OrderInformation
     */
    boxType?: BoxTypeEnum | null;
    /**
     * Gets or Sets BoxSize
     * @type {BoxSizeEnum}
     * @memberof OrderInformation
     */
    boxSize?: BoxSizeEnum | null;
    /**
     * Gets or Sets DeliveryBoxOrderIdent
     * @type {string}
     * @memberof OrderInformation
     */
    deliveryBoxOrderIdent?: string | null;
    /**
     * Gets or Sets PickupBoxOrderIdent
     * @type {string}
     * @memberof OrderInformation
     */
    pickupBoxOrderIdent?: string | null;
    /**
     * Gets or Sets EstimatedDeliveryTimeWindow
     * @type {TimeWindow}
     * @memberof OrderInformation
     */
    estimatedDeliveryTimeWindow?: TimeWindow | null;
    /**
     * Gets or Sets DepotOpeningTimeWindow
     * @type {TimeWindow}
     * @memberof OrderInformation
     */
    depotOpeningTimeWindow?: TimeWindow | null;
    /**
     * Gets or Sets OrderSteps
     * @type {Array<OrderStepInformation>}
     * @memberof OrderInformation
     */
    orderSteps?: Array<OrderStepInformation> | null;
    /**
     * Gets or Sets PackageContents
     * @type {Array<OrderPackageInformation>}
     * @memberof OrderInformation
     */
    packageContents?: Array<OrderPackageInformation> | null;
    /**
     * Gets or Sets SelectedMount
     * @type {MountInformation}
     * @memberof OrderInformation
     */
    selectedMount?: MountInformation | null;
    /**
     * Gets or Sets SelectedSyncMeetingPoint
     * @type {SyncMeetingPointInformation}
     * @memberof OrderInformation
     */
    selectedSyncMeetingPoint?: SyncMeetingPointInformation | null;
    /**
     * Gets or Sets DepotLocation
     * @type {DepotLocation}
     * @memberof OrderInformation
     */
    depotLocation?: DepotLocation | null;
    /**
     * Gets or Sets AssignedVehicle
     * @type {AssignedVehicleInformation}
     * @memberof OrderInformation
     */
    assignedVehicle?: AssignedVehicleInformation | null;
    /**
     * Gets or Sets CustomerContactIdent
     * @type {string}
     * @memberof OrderInformation
     */
    customerContactIdent?: string | null;
    /**
     * Gets or Sets AssignedBoxIdent
     * @type {string}
     * @memberof OrderInformation
     */
    assignedBoxIdent?: string | null;
    /**
     * Gets or Sets OnlySelfService
     * @type {boolean}
     * @memberof OrderInformation
     */
    onlySelfService?: boolean;
}
/**
 * Defines OrderModeEnum
 * @export
 * @enum {string}
 */
export enum OrderModeEnum {
    Synchron = 'SYNCHRON',
    Asynchron = 'ASYNCHRON',
    Selfservice = 'SELFSERVICE'
}

/**
 * OrderPackageInformation
 * @export
 * @interface OrderPackageInformation
 */
export interface OrderPackageInformation {
    /**
     * Gets or Sets TrackingCode
     * @type {string}
     * @memberof OrderPackageInformation
     */
    trackingCode?: string | null;
    /**
     * Gets or Sets KepProviders
     * @type {Array<KepProviderEnum>}
     * @memberof OrderPackageInformation
     */
    kepProviders?: Array<KepProviderEnum> | null;
    /**
     * Gets or Sets PackageOrderIdent
     * @type {string}
     * @memberof OrderPackageInformation
     */
    packageOrderIdent?: string | null;
}
/**
 * Defines OrderStateEnum
 * @export
 * @enum {string}
 */
export enum OrderStateEnum {
    Changeable = 'CHANGEABLE',
    Deliveryinpreparation = 'DELIVERYINPREPARATION',
    Deliveryscheduled = 'DELIVERYSCHEDULED',
    Transporttocustomer = 'TRANSPORTTOCUSTOMER',
    Deliveredtomount = 'DELIVEREDTOMOUNT',
    Arrivedatsyncstop = 'ARRIVEDATSYNCSTOP',
    Pickupscheduled = 'PICKUPSCHEDULED',
    Transporttodepot = 'TRANSPORTTODEPOT',
    Deliveredtodepot = 'DELIVEREDTODEPOT',
    Deliveryfailed = 'DELIVERYFAILED',
    Finished = 'FINISHED',
    Expired = 'EXPIRED'
}

/**
 * Defines OrderStepEnum
 * @export
 * @enum {string}
 */
export enum OrderStepEnum {
    New = 'NEW',
    Deliveryscheduled = 'DELIVERYSCHEDULED',
    Readyforselfpickup = 'READYFORSELFPICKUP',
    Transporttocustomer = 'TRANSPORTTOCUSTOMER',
    Deliveredtomount = 'DELIVEREDTOMOUNT',
    Arrivedatsyncstop = 'ARRIVEDATSYNCSTOP',
    Pickupscheduled = 'PICKUPSCHEDULED',
    Transporttodepot = 'TRANSPORTTODEPOT',
    Finished = 'FINISHED'
}

/**
 * OrderStepInformation
 * @export
 * @interface OrderStepInformation
 */
export interface OrderStepInformation {
    /**
     * Gets or Sets OrderStep
     * @type {OrderStepEnum}
     * @memberof OrderStepInformation
     */
    orderStep?: OrderStepEnum | null;
    /**
     * Gets or Sets OrderStepStatus
     * @type {OrderStepStatusEnum}
     * @memberof OrderStepInformation
     */
    orderStepStatus?: OrderStepStatusEnum | null;
}
/**
 * Defines OrderStepStatusEnum
 * @export
 * @enum {string}
 */
export enum OrderStepStatusEnum {
    Finished = 'FINISHED',
    Inprogress = 'INPROGRESS',
    Notstarted = 'NOTSTARTED'
}

/**
 * Defines OrderTypeEnum
 * @export
 * @enum {string}
 */
export enum OrderTypeEnum {
    Delivery = 'DELIVERY',
    Pickup = 'PICKUP',
    Recharging = 'RECHARGING'
}

/**
 * OutboundParcelInformation
 * @export
 * @interface OutboundParcelInformation
 */
export interface OutboundParcelInformation {
    /**
     * Gets or Sets ContactId
     * @type {string}
     * @memberof OutboundParcelInformation
     */
    contactId?: string | null;
}
/**
 * 
 * @export
 * @interface PTVSlotReservation
 */
export interface PTVSlotReservation {
    /**
     * The Unique Id if the scheduler in the slot management
     * @type {string}
     * @memberof PTVSlotReservation
     */
    schedulerIdent?: string | null;
    /**
     * Key: Year-Calendarweek-Day-StartIndex-EndIndex e.g. 2020-42-Sunday-0-10    Value: A string used from user of the slot management service to identify depending data in his environment.
     * @type {{ [key: string]: string; }}
     * @memberof PTVSlotReservation
     */
    externalSlotReservationInfo?: { [key: string]: string; } | null;
    /**
     * Key: Year-Calendarweek e.g. 2020-42    Value: A BitArray-like containing the \"blocked\" timeslots information.
     * @type {{ [key: string]: BitArrayExtended; }}
     * @memberof PTVSlotReservation
     */
    slotReservations?: { [key: string]: BitArrayExtended; } | null;
}
/**
 * PackageInformation
 * @export
 * @interface PackageInformation
 */
export interface PackageInformation {
    /**
     * Gets or Sets WidthCm
     * @type {number}
     * @memberof PackageInformation
     */
    widthCm?: number;
    /**
     * Gets or Sets HeightCm
     * @type {number}
     * @memberof PackageInformation
     */
    heightCm?: number;
    /**
     * Gets or Sets LengthCm
     * @type {number}
     * @memberof PackageInformation
     */
    lengthCm?: number;
    /**
     * Gets or Sets WeightGr
     * @type {number}
     * @memberof PackageInformation
     */
    weightGr?: number;
    /**
     * Gets or Sets CustomerContactIdent
     * @type {string}
     * @memberof PackageInformation
     */
    customerContactIdent?: string | null;
    /**
     * Gets or Sets ProviderIds
     * @type {Array<KepProviderEnum>}
     * @memberof PackageInformation
     */
    providerIds?: Array<KepProviderEnum> | null;
    /**
     * Gets or Sets TrackingId
     * @type {string}
     * @memberof PackageInformation
     */
    trackingId?: string | null;
    /**
     * Gets or Sets CustomerFirstName
     * @type {string}
     * @memberof PackageInformation
     */
    customerFirstName?: string | null;
    /**
     * Gets or Sets CustomerLastName
     * @type {string}
     * @memberof PackageInformation
     */
    customerLastName?: string | null;
    /**
     * Gets or Sets OrderMode
     * @type {string}
     * @memberof PackageInformation
     */
    orderMode?: string | null;
}
/**
 * PackageInformationAdmin
 * @export
 * @interface PackageInformationAdmin
 */
export interface PackageInformationAdmin {
    /**
     * Gets or Sets Ident
     * @type {string}
     * @memberof PackageInformationAdmin
     */
    ident?: string | null;
    /**
     * Gets or Sets TrackingId
     * @type {string}
     * @memberof PackageInformationAdmin
     */
    trackingId?: string | null;
    /**
     * Gets or Sets RelatedBoxOrderIdent
     * @type {string}
     * @memberof PackageInformationAdmin
     */
    relatedBoxOrderIdent?: string | null;
    /**
     * Gets or Sets Length
     * @type {number}
     * @memberof PackageInformationAdmin
     */
    length?: number;
    /**
     * Gets or Sets Width
     * @type {number}
     * @memberof PackageInformationAdmin
     */
    width?: number;
    /**
     * Gets or Sets Height
     * @type {number}
     * @memberof PackageInformationAdmin
     */
    height?: number;
    /**
     * Gets or Sets Weight
     * @type {number}
     * @memberof PackageInformationAdmin
     */
    weight?: number;
    /**
     * Gets or Sets AssignedVehicleIdent
     * @type {string}
     * @memberof PackageInformationAdmin
     */
    assignedVehicleIdent?: string | null;
    /**
     * Gets or Sets AssignedTourIdent
     * @type {string}
     * @memberof PackageInformationAdmin
     */
    assignedTourIdent?: string | null;
    /**
     * Gets or Sets CustomerFirstName
     * @type {string}
     * @memberof PackageInformationAdmin
     */
    customerFirstName?: string | null;
    /**
     * Gets or Sets CustomerLastName
     * @type {string}
     * @memberof PackageInformationAdmin
     */
    customerLastName?: string | null;
}
/**
 * Describes a bin packed with items. If BinType.numberOfBins is greater  than one, multiple packed bins with the same PackedBin.binTypeId might  be part of a response.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xload.PackedBin
 * @export
 * @interface PackedBin
 */
export interface PackedBin {
    /**
     * Gets or sets ID of the BinType
     * @type {string}
     * @memberof PackedBin
     */
    binTypeId?: string | null;
    /**
     * 
     * @type {Array<PackedItem>}
     * @memberof PackedBin
     */
    packedItems?: Array<PackedItem> | null;
    /**
     * Gets or sets accumulated volume of the items packed into the bin.  This is the raw sum and does not try to capture wasted volume (e.g.  gaps).
     * @type {number}
     * @memberof PackedBin
     */
    totalItemsVolume?: number;
    /**
     * Gets or sets accumulated weight of items packed into this bin.
     * @type {number}
     * @memberof PackedBin
     */
    totalItemsWeight?: number;
    /**
     * Gets or sets percent of BinType.maximumWeightCapacity used by  PackedBin.totalItemsWeight. Not returned if no  maximumWeightCapacity was given for bin.
     * @type {number}
     * @memberof PackedBin
     */
    usedWeightCapacity?: number | null;
    /**
     * Gets or sets percent of BinType.maximumVolumeCapacity used by  PackedBin.totalItemsVolume. If no explicit maximum volume is given  it is calculated from the container dimensions.
     * @type {number}
     * @memberof PackedBin
     */
    usedVolumeCapacity?: number;
    /**
     * Gets or sets item extent along the z-axis (length) from the back  wall of the bin. When looking through the back doors of a truck  this is the distance from the opposing wall to the end of the load  closest to the doors.
     * @type {number}
     * @memberof PackedBin
     */
    loadingMeters?: number;
}
/**
 * 
 * @export
 * @interface PackedBinsResponse
 */
export interface PackedBinsResponse {
    /**
     * 
     * @type {Array<PackedBin>}
     * @memberof PackedBinsResponse
     */
    packedBins?: Array<PackedBin> | null;
    /**
     * 
     * @type {Array<ItemType>}
     * @memberof PackedBinsResponse
     */
    itemsNotPacked?: Array<ItemType> | null;
    /**
     * 
     * @type {Array<ResultLimitation>}
     * @memberof PackedBinsResponse
     */
    resultLimitations?: Array<ResultLimitation> | null;
}
/**
 * Describes one item of a type that was packed into a PackedBin. If  ItemType.numberOfItems is greater than one, multiple packed items with  the same PackedItem.itemTypeId might be in a PackedBin.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xload.PackedItem
 * @export
 * @interface PackedItem
 */
export interface PackedItem {
    /**
     * Gets or sets ID of the ItemType.
     * @type {string}
     * @memberof PackedItem
     */
    itemTypeId?: string | null;
    /**
     * Gets or sets itemPosition of the item in the bin.
     * @type {ItemPosition}
     * @memberof PackedItem
     */
    position?: ItemPosition | null;
    /**
     * Gets or sets packed item dimensions after orientation.
     * @type {BoxDimensions}
     * @memberof PackedItem
     */
    dimensions?: BoxDimensions | null;
    /**
     * Gets or sets itemOrientation with which the item was placed in the  bin. Possible values include: \'ORIGINAL\', \'X\', \'Y\', \'Z\', \'XZ\', \'YZ\'
     * @type {ItemOrientation}
     * @memberof PackedItem
     */
    orientation?: ItemOrientation;
}
/**
 * Parcel
 * @export
 * @interface Parcel
 */
export interface Parcel {
    /**
     * Gets or Sets EfeuPackageId
     * @type {string}
     * @memberof Parcel
     */
    efeuPackageId?: string | null;
    /**
     * Gets or Sets Warehouse
     * @type {string}
     * @memberof Parcel
     */
    warehouse?: string | null;
    /**
     * Gets or Sets WarehousePlace
     * @type {string}
     * @memberof Parcel
     */
    warehousePlace?: string | null;
    /**
     * Gets or Sets KepProviders
     * @type {Array<KepProviderEnum>}
     * @memberof Parcel
     */
    kepProviders?: Array<KepProviderEnum> | null;
    /**
     * Gets or Sets Labelled
     * @type {boolean}
     * @memberof Parcel
     */
    labelled?: boolean;
    /**
     * Gets or Sets Dimensions
     * @type {Dimensions}
     * @memberof Parcel
     */
    dimensions?: Dimensions | null;
    /**
     * Gets or Sets Recipient
     * @type {Contact}
     * @memberof Parcel
     */
    recipient?: Contact | null;
    /**
     * Gets or Sets Sender
     * @type {Contact}
     * @memberof Parcel
     */
    sender?: Contact | null;
}
/**
 * Defines values for PaymentMethod.
 * @export
 * @enum {string}
 */
export enum PaymentMethod {
    Electronictollcollectionsubscription = 'ELECTRONICTOLLCOLLECTIONSUBSCRIPTION',
    Electronictollcollection = 'ELECTRONICTOLLCOLLECTION',
    Cash = 'CASH',
    Creditcard = 'CREDITCARD',
    Unspecified = 'UNSPECIFIED'
}

/**
 * PlaceBoxOnMountEvent
 * @export
 * @interface PlaceBoxOnMountEvent
 */
export interface PlaceBoxOnMountEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof PlaceBoxOnMountEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof PlaceBoxOnMountEvent
     */
    mountIdent?: string | null;
}
/**
 * PlanningStatus
 * @export
 * @interface PlanningStatus
 */
export interface PlanningStatus {
    /**
     * Gets or Sets Timestmp
     * @type {number}
     * @memberof PlanningStatus
     */
    timestmp?: number;
    /**
     * Gets or Sets FormattedMessage
     * @type {string}
     * @memberof PlanningStatus
     */
    formattedMessage?: string | null;
    /**
     * Gets or Sets LoggerName
     * @type {string}
     * @memberof PlanningStatus
     */
    loggerName?: string | null;
    /**
     * Gets or Sets LevelString
     * @type {string}
     * @memberof PlanningStatus
     */
    levelString?: string | null;
    /**
     * Gets or Sets ThreadName
     * @type {string}
     * @memberof PlanningStatus
     */
    threadName?: string | null;
    /**
     * Gets or Sets ReferenceFlag
     * @type {number}
     * @memberof PlanningStatus
     */
    referenceFlag?: number;
    /**
     * Gets or Sets Arg0
     * @type {string}
     * @memberof PlanningStatus
     */
    arg0?: string | null;
    /**
     * Gets or Sets Arg1
     * @type {string}
     * @memberof PlanningStatus
     */
    arg1?: string | null;
    /**
     * Gets or Sets Arg2
     * @type {string}
     * @memberof PlanningStatus
     */
    arg2?: string | null;
    /**
     * Gets or Sets Arg3
     * @type {string}
     * @memberof PlanningStatus
     */
    arg3?: string | null;
    /**
     * Gets or Sets CallerFilename
     * @type {string}
     * @memberof PlanningStatus
     */
    callerFilename?: string | null;
    /**
     * Gets or Sets CallerClass
     * @type {string}
     * @memberof PlanningStatus
     */
    callerClass?: string | null;
    /**
     * Gets or Sets CallerMethod
     * @type {string}
     * @memberof PlanningStatus
     */
    callerMethod?: string | null;
    /**
     * Gets or Sets CallerLine
     * @type {string}
     * @memberof PlanningStatus
     */
    callerLine?: string | null;
    /**
     * Gets or Sets EventId
     * @type {number}
     * @memberof PlanningStatus
     */
    eventId?: number;
}
/**
 * PrintJob
 * @export
 * @interface PrintJob
 */
export interface PrintJob {
    /**
     * Gets or Sets ExtId
     * @type {string}
     * @memberof PrintJob
     */
    extId?: string | null;
    /**
     * Gets or Sets OderIdent
     * @type {string}
     * @memberof PrintJob
     */
    oderIdent?: string | null;
    /**
     * Gets or Sets FirstName
     * @type {string}
     * @memberof PrintJob
     */
    firstName?: string | null;
    /**
     * Gets or Sets LastName
     * @type {string}
     * @memberof PrintJob
     */
    lastName?: string | null;
}
/**
 * 
 * @export
 * @interface PtvAppointmentEntry
 */
export interface PtvAppointmentEntry {
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    customerIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    calendar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    end?: string;
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtvAppointmentEntry
     */
    subject?: string | null;
}
/**
 * RechargeOrderInfoAdmin
 * @export
 * @interface RechargeOrderInfoAdmin
 */
export interface RechargeOrderInfoAdmin {
    /**
     * Gets or Sets OrderType
     * @type {OrderTypeEnum}
     * @memberof RechargeOrderInfoAdmin
     */
    orderType?: OrderTypeEnum | null;
    /**
     * Gets or Sets OrderState
     * @type {BoxOrderStateEnumAdmin}
     * @memberof RechargeOrderInfoAdmin
     */
    orderState?: BoxOrderStateEnumAdmin;
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof RechargeOrderInfoAdmin
     */
    id?: string | null;
    /**
     * Gets or Sets VehicleId
     * @type {string}
     * @memberof RechargeOrderInfoAdmin
     */
    vehicleId?: string | null;
    /**
     * Gets or Sets StartTime
     * @type {string}
     * @memberof RechargeOrderInfoAdmin
     */
    startTime?: string;
    /**
     * Gets or Sets EndTime
     * @type {string}
     * @memberof RechargeOrderInfoAdmin
     */
    endTime?: string;
    /**
     * Gets or Sets ChargingDockId
     * @type {string}
     * @memberof RechargeOrderInfoAdmin
     */
    chargingDockId?: string | null;
}
/**
 * RegisterPackageResponse
 * @export
 * @interface RegisterPackageResponse
 */
export interface RegisterPackageResponse {
    /**
     * Gets or Sets PackageOrderIdent
     * @type {string}
     * @memberof RegisterPackageResponse
     */
    packageOrderIdent?: string | null;
}
/**
 * RequestMountForBoxDeliveryEvent
 * @export
 * @interface RequestMountForBoxDeliveryEvent
 */
export interface RequestMountForBoxDeliveryEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof RequestMountForBoxDeliveryEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof RequestMountForBoxDeliveryEvent
     */
    orderIdent?: string | null;
}
/**
 * RequestMountForBoxDeliveryResponse
 * @export
 * @interface RequestMountForBoxDeliveryResponse
 */
export interface RequestMountForBoxDeliveryResponse {
    /**
     * Gets or Sets Status
     * @type {RequestMountResponseEnum}
     * @memberof RequestMountForBoxDeliveryResponse
     */
    status?: RequestMountResponseEnum;
    /**
     * Gets or Sets Tour
     * @type {string}
     * @memberof RequestMountForBoxDeliveryResponse
     */
    tour?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof RequestMountForBoxDeliveryResponse
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets RetryIntervalSec
     * @type {number}
     * @memberof RequestMountForBoxDeliveryResponse
     */
    retryIntervalSec?: number;
}
/**
 * RequestMountForBoxPickupEvent
 * @export
 * @interface RequestMountForBoxPickupEvent
 */
export interface RequestMountForBoxPickupEvent {
    /**
     * Gets or Sets TourExtId
     * @type {string}
     * @memberof RequestMountForBoxPickupEvent
     */
    tourExtId?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof RequestMountForBoxPickupEvent
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof RequestMountForBoxPickupEvent
     */
    vehicleIdent?: string | null;
}
/**
 * RequestMountForBoxPickupResponse
 * @export
 * @interface RequestMountForBoxPickupResponse
 */
export interface RequestMountForBoxPickupResponse {
    /**
     * Gets or Sets Status
     * @type {RequestMountResponseEnum}
     * @memberof RequestMountForBoxPickupResponse
     */
    status?: RequestMountResponseEnum;
    /**
     * Gets or Sets Tour
     * @type {string}
     * @memberof RequestMountForBoxPickupResponse
     */
    tour?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof RequestMountForBoxPickupResponse
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets RetryIntervalSec
     * @type {number}
     * @memberof RequestMountForBoxPickupResponse
     */
    retryIntervalSec?: number;
}
/**
 * RequestMountForChargingEvent
 * @export
 * @interface RequestMountForChargingEvent
 */
export interface RequestMountForChargingEvent {
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof RequestMountForChargingEvent
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof RequestMountForChargingEvent
     */
    vehicleIdent?: string | null;
}
/**
 * RequestMountForChargingResponse
 * @export
 * @interface RequestMountForChargingResponse
 */
export interface RequestMountForChargingResponse {
    /**
     * Gets or Sets Status
     * @type {RequestMountResponseEnum}
     * @memberof RequestMountForChargingResponse
     */
    status?: RequestMountResponseEnum;
    /**
     * Gets or Sets Tour
     * @type {string}
     * @memberof RequestMountForChargingResponse
     */
    tour?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof RequestMountForChargingResponse
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets RetryIntervalSec
     * @type {number}
     * @memberof RequestMountForChargingResponse
     */
    retryIntervalSec?: number;
}
/**
 * RequestMountForWaitingEvent
 * @export
 * @interface RequestMountForWaitingEvent
 */
export interface RequestMountForWaitingEvent {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof RequestMountForWaitingEvent
     */
    vehicleIdent?: string | null;
}
/**
 * RequestMountForWaitingResponse
 * @export
 * @interface RequestMountForWaitingResponse
 */
export interface RequestMountForWaitingResponse {
    /**
     * Gets or Sets Success
     * @type {boolean}
     * @memberof RequestMountForWaitingResponse
     */
    success?: boolean;
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof RequestMountForWaitingResponse
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof RequestMountForWaitingResponse
     */
    longitude?: number;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof RequestMountForWaitingResponse
     */
    mountIdent?: string | null;
}
/**
 * Defines RequestMountResponseEnum
 * @export
 * @enum {string}
 */
export enum RequestMountResponseEnum {
    Success = 'SUCCESS',
    Alloccupied = 'ALLOCCUPIED',
    Mountmissing = 'MOUNTMISSING'
}

/**
 * Defines ReservationBoxSize
 * @export
 * @enum {string}
 */
export enum ReservationBoxSizeEnum {
    Large = 'LARGE',
    Small = 'SMALL'
}

/**
 * 
 * @export
 * @interface Response
 */
export interface Response {
    /**
     * 
     * @type {boolean}
     * @memberof Response
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Response
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof Response
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * Specific limitation to the validity of a result.                See request and response for details on when a result limitation will  be returned instead of an exception.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.service.ResultLimitation
 * @export
 * @interface ResultLimitation
 */
export interface ResultLimitation {
    /**
     * Gets or sets description of the limitation.
     * @type {string}
     * @memberof ResultLimitation
     */
    message?: string | null;
    /**
     * Gets or sets hint on how to resolve this issue.
     * @type {string}
     * @memberof ResultLimitation
     */
    hint?: string | null;
}
/**
 * Legal and physical attributes which describe the road segment in  detail.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.segments.RoadAttributes
 * @export
 * @interface RoadAttributes
 */
export interface RoadAttributes {
    /**
     * Gets or sets this segment is usually prohibited for the selected  vehicle.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    prohibited?: boolean;
    /**
     * Gets or sets the segment is part of a toll road. This attribute  does not reflect the specific toll pricing conditions, so it is not  guaranteed that the selected vehicle has to pay toll for this  segment.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    toll?: boolean;
    /**
     * Gets or sets the segment is within an urban, i.e. built-up area. It  can be avoided or prohibited during route calculation using the  parameter routingprofile.SpecialAreas.urbanPenalty.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    urban?: boolean;
    /**
     * Gets or sets the segment is within a residents only area. It can be  avoided, or prohibited during route calculation using the  parameters routingprofile.SpecialAreas.residentsOnlyPenalty.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    residentsOnly?: boolean;
    /**
     * Gets or sets the segment is within a pedestrian zone. To open,  avoid, or block it during route calculation see attribute  prohibited.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    pedestrianZone?: boolean;
    /**
     * Gets or sets the segment is a one-way street.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    oneWay?: boolean;
    /**
     * Gets or sets the segment is part of a roundabout.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    roundabout?: boolean;
    /**
     * Gets or sets the segment is part of a ramp. It can be avoided or  prohibited during route calculation using the parameter  routingprofile.Network.rampPenalty.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    ramp?: boolean;
    /**
     * Gets or sets the segment is a bridge.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    bridge?: boolean;
    /**
     * Gets or sets the segment is a tunnel.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    tunnel?: boolean;
    /**
     * Gets or sets the segment is in a delivery only area. It can be  allowed, avoided, or prohibited during route calculation using the  parameters routingprofile.SpecialAreas.deliveryOnlyPenalty and  vehicleprofile.LegalCondition.isDelivery.
     * @type {boolean}
     * @memberof RoadAttributes
     */
    deliveryOnly?: boolean;
    /**
     * Gets or sets the segment is allowed for emergency vehicles (see  vehicleprofile.LegalCondition.isEmergency).
     * @type {boolean}
     * @memberof RoadAttributes
     */
    allowedForEmergency?: boolean;
    /**
     * Gets or sets the low-emission zone types the selected vehicle must  have to travel this segment. It can be opened, avoided, or  prohibited during route calculation using the parameters  routingprofile.SpecialAreas.forbiddenLowEmissionZonePenalty and  vehicleprofile.Engine.lowEmissionZoneTypes. The available types can  be requested through the xRuntime service, e.g. DE\\_GREEN,  DE\\_YELLOW, DE\\_RED for Germany.
     * @type {string}
     * @memberof RoadAttributes
     */
    lowEmissionZoneType?: string | null;
}
/**
 * Base type for all route events which can appear in the RouteResponse.  It contains information common to all event types. See the technical  concept for more information.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.RouteEvent
 * @export
 * @interface RouteEvent
 */
export interface RouteEvent {
    /**
     * Gets or sets the type of the current route event. Possible values  include: \'MANEUVER_EVENT\', \'TOUR_EVENT\', \'TOLL_EVENT\',  \'WAYPOINT_EVENT\', \'ROUTE_VIOLATION_EVENT\', \'UTC_OFFSET_EVENT\',  \'COUNTRY_EVENT\', \'COMBINED_TRANSPORT_EVENT\'
     * @type {RouteEventType}
     * @memberof RouteEvent
     */
    eventType?: RouteEventType | null;
    /**
     * Gets or sets the point in time at which the event starts. This  field is only present if a time-consideration scenario with a  reference time is given in the request. The time zone is that of  the reference time. Use the UTCOffsetEvent to derive the local  time.
     * @type {string}
     * @memberof RouteEvent
     */
    startsAt?: string | null;
    /**
     * Gets or sets the location at which the event occurs. This  coordinate is not necessarily the coordinate of the related node.  The event such as a traffic incident can, for example, occur in the  middle of a long segment. This coordinate is designed to draw an  icon on a map to mark the event.
     * @type {Coordinate}
     * @memberof RouteEvent
     */
    coordinate?: Coordinate | null;
    /**
     * Gets or sets the index of the node in the RouteResponse.nodes list.  As every segment starts at the node with the same index, it is also  equal to the segment in the RouteResponse.segments list, if  present, except for the very last index. This field is only present  if the list of nodes is present.
     * @type {number}
     * @memberof RouteEvent
     */
    nodeIndex?: number | null;
    /**
     * Gets or sets the distance of the route from the start up to this  event.
     * @type {number}
     * @memberof RouteEvent
     */
    distanceFromStart?: number | null;
    /**
     * Gets or sets the travel time for the route from the start up to  this event.
     * @type {number}
     * @memberof RouteEvent
     */
    travelTimeFromStart?: number | null;
}
/**
 * Defines values for RouteEventType.
 * @export
 * @enum {string}
 */
export enum RouteEventType {
    Maneuverevent = 'MANEUVEREVENT',
    Tourevent = 'TOUREVENT',
    Tollevent = 'TOLLEVENT',
    Waypointevent = 'WAYPOINTEVENT',
    Routeviolationevent = 'ROUTEVIOLATIONEVENT',
    Utcoffsetevent = 'UTCOFFSETEVENT',
    Countryevent = 'COUNTRYEVENT',
    Combinedtransportevent = 'COMBINEDTRANSPORTEVENT'
}

/**
 * RouteInformation
 * @export
 * @interface RouteInformation
 */
export interface RouteInformation {
    /**
     * Gets or Sets RouteNodes
     * @type {Array<RouteNode>}
     * @memberof RouteInformation
     */
    routeNodes?: Array<RouteNode> | null;
}
/**
 * RouteNode
 * @export
 * @interface RouteNode
 */
export interface RouteNode {
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof RouteNode
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof RouteNode
     */
    longitude?: number;
}
/**
 * Represents the summary of a route which has been calculated.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.RouteReport
 * @export
 * @interface RouteReport
 */
export interface RouteReport {
    /**
     * Gets or sets strategy used in the route calculation  (high-performance routing or conventional routing). For further  explanation see the definition of RoutingType. Possible values  include: \'CONVENTIONAL\',  \'HIGH_PERFORMANCE_ROUTING_WITH_FALLBACK_CONVENTIONAL\',  \'HIGH_PERFORMANCE_ROUTING\'
     * @type {RoutingType}
     * @memberof RouteReport
     */
    routingType?: RoutingType | null;
}
/**
 * 
 * @export
 * @interface RouteResponse
 */
export interface RouteResponse {
    /**
     * Gets or sets the distance of the complete route. It is the sum of  distances of all segments and hence is a whole number without  decimal places (see Segment.distance). It equals the sum of  distances of all legs.
     * @type {number}
     * @memberof RouteResponse
     */
    distance?: number;
    /**
     * Gets or sets the travel time for the complete route. It is the sum  of travel times of all segments and hence has up to three decimal  places (see Segment.travelTime). It equals the sum of travel times  of all legs.
     * @type {number}
     * @memberof RouteResponse
     */
    travelTime?: number;
    /**
     * 
     * @type {Array<Waypoint>}
     * @memberof RouteResponse
     */
    waypoints?: Array<Waypoint> | null;
    /**
     * 
     * @type {Array<Leg>}
     * @memberof RouteResponse
     */
    legs?: Array<Leg> | null;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof RouteResponse
     */
    segments?: Array<Segment> | null;
    /**
     * 
     * @type {Array<Node>}
     * @memberof RouteResponse
     */
    nodes?: Array<Node> | null;
    /**
     * 
     * @type {Array<RouteEvent>}
     * @memberof RouteResponse
     */
    events?: Array<RouteEvent> | null;
    /**
     * Gets or sets the polyline of the complete route. This polyline  consists of all coordinates representing the route and can be used  to draw the route into a map.                It is only returned if requested by ResultFields.polyline. It  contains elevations only if requested by  PolylineOptions.elevations.                If the elevations data do not cover the complete polyline, an  ElevationsNotAvailableLimitation will be available which states  which parts of the polyline are not covered.
     * @type {EncodedGeometry}
     * @memberof RouteResponse
     */
    polyline?: EncodedGeometry | null;
    /**
     * Gets or sets the toll information for the complete route.                It is only returned if requested by ResultFields.toll.
     * @type {TollResult}
     * @memberof RouteResponse
     */
    toll?: TollResult | null;
    /**
     * Gets or sets the emissions along the route.
     * @type {Emissions}
     * @memberof RouteResponse
     */
    emissions?: Emissions | null;
    /**
     * Gets or sets the encoded string describing the complete path of the  calculated route. Use this string as an input of another route  request to calculate the same route with different parameters.
     * @type {string}
     * @memberof RouteResponse
     */
    encodedPath?: string | null;
    /**
     * Gets or sets a base64 encoded representation of the route that can  be used for guided navigation. Calculating a guided navigation  route requires maneuver events which have to be requested  separately. The base64 binary has to be decoded and saved as text  file and can then be imported in PTV Navigator and used for  navigation.
     * @type {string}
     * @memberof RouteResponse
     */
    guidedNavigationRoute?: string | null;
    /**
     * Gets or sets the report regarding the tour calculation.
     * @type {RoutingTourReport}
     * @memberof RouteResponse
     */
    tourReport?: RoutingTourReport | null;
    /**
     * Gets or sets if set to true, indicates that this route contains a  violation for the chosen vehicle.
     * @type {boolean}
     * @memberof RouteResponse
     */
    violated?: boolean;
    /**
     * Gets or sets the report regarding the route calculation.
     * @type {RouteReport}
     * @memberof RouteResponse
     */
    report?: RouteReport | null;
    /**
     * Gets or sets the monetary costs of the route. They are only  returned if requested by ResultFields.monetaryCostsReport.
     * @type {MonetaryCostsReport}
     * @memberof RouteResponse
     */
    monetaryCostsReport?: MonetaryCostsReport | null;
    /**
     * Gets or sets the report regarding the calculation of the estimated  time of arrival.
     * @type {ETACalculationReport}
     * @memberof RouteResponse
     */
    etaCalculationReport?: ETACalculationReport | null;
    /**
     * 
     * @type {Array<AlternativeRoute>}
     * @memberof RouteResponse
     */
    alternativeRoutes?: Array<AlternativeRoute> | null;
    /**
     * 
     * @type {Array<ResultLimitation>}
     * @memberof RouteResponse
     */
    resultLimitations?: Array<ResultLimitation> | null;
}
/**
 * Specifies which fields of the RouteResponse shall be returned.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.ResultFields
 * @export
 * @interface RoutingResultFields
 */
export interface RoutingResultFields {
    /**
     * Gets or sets specifies if the RouteResponse.waypoints shall be  returned for the complete route.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    waypoints?: boolean | null;
    /**
     * Gets or sets specifies if and to what extent the RouteResponse.legs  shall be returned for the complete route.
     * @type {LegResultFields}
     * @memberof RoutingResultFields
     */
    legs?: LegResultFields | null;
    /**
     * Gets or sets specifies if and to what extent the  RouteResponse.segments shall be returned for the complete route.
     * @type {SegmentResultFields}
     * @memberof RoutingResultFields
     */
    segments?: SegmentResultFields | null;
    /**
     * Gets or sets specifies if the RouteResponse.nodes shall be returned  for the complete route.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    nodes?: boolean | null;
    /**
     * 
     * @type {Array<RouteEventType>}
     * @memberof RoutingResultFields
     */
    eventTypes?: Array<RouteEventType> | null;
    /**
     * Gets or sets specifies if the RouteResponse.polyline shall be  returned for the complete route. In order to return the  RouteResponse.polyline for legs and segments, enable  LegResultFields.polyline and SegmentResultFields.polyline. In order  to obtain the elevations for the RouteResponse.polyline, enable  PolylineOptions.elevations in RouteOptions.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    polyline?: boolean | null;
    /**
     * Gets or sets specifies if and to what extent the RouteResponse.toll  shall be returned for the complete route.
     * @type {TollResultFields}
     * @memberof RoutingResultFields
     */
    toll?: TollResultFields | null;
    /**
     * Gets or sets specifies if the RouteResponse.emissions shall be  returned for the complete route.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    emissions?: boolean | null;
    /**
     * Gets or sets specifies if the RouteResponse.report shall be  returned for the complete route.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    report?: boolean | null;
    /**
     * Gets or sets specifies if the RouteResponse.tourReport shall be  returned for the complete route.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    tourReport?: boolean | null;
    /**
     * Gets or sets specifies if the RouteResponse.encodedPath shall be  returned for the complete route.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    encodedPath?: boolean | null;
    /**
     * Gets or sets specifies if the RouteResponse.guidedNavigationRoute  shall be returned for the complete route.  Setting this parameter to true requires a detailed list of maneuver  events, which have to be requested separately by adding the  RouteEventType MANEUVER\\_EVENT to the list of eventTypes in the  result fields.  This feature therefore may consume some additional computation time  and returns a list of maneuver events in the response.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    guidedNavigationRoute?: boolean | null;
    /**
     * Gets or sets specifies if the RouteResponse.monetaryCostsReport  shall be returned for the complete route.  If requested, it is required to specify a currency in the route  options.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    monetaryCostsReport?: boolean | null;
    /**
     * Gets or sets specifies if the RouteResponse.alternativeRoutes shall  be returned.  In addition to the optimal route up to three alternatives are  returned. Note that sometimes no alternative routes are returned  because possible alternatives are not considered as practical with  regards to detour or sharing with the optimal route.  Note that this is only supported with requests with exactly two  input waypoints of types OnRoadWaypoint or OffRoadWaypoint,  otherwise, an exception will be thrown.
     * @type {boolean}
     * @memberof RoutingResultFields
     */
    alternativeRoutes?: boolean | null;
}
/**
 * Represents the summary of the tour which has been calculated.                Please note that the total travel time of the tour is equal to the  travel time of the route (see RouteResponse.travelTime).  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.TourReport
 * @export
 * @interface RoutingTourReport
 */
export interface RoutingTourReport {
    /**
     * Gets or sets if set to true, indicates that this tour contains a  tour violation.
     * @type {boolean}
     * @memberof RoutingTourReport
     */
    violated?: boolean | null;
    /**
     * Gets or sets the start date/time of the tour.
     * @type {string}
     * @memberof RoutingTourReport
     */
    startTime?: string | null;
    /**
     * Gets or sets the sum of all service time of the tour.
     * @type {number}
     * @memberof RoutingTourReport
     */
    serviceTime?: number | null;
    /**
     * Gets or sets the sum of all driving period of the tour.
     * @type {number}
     * @memberof RoutingTourReport
     */
    drivingTime?: number | null;
}
/**
 * Defines values for RoutingType.
 * @export
 * @enum {string}
 */
export enum RoutingType {
    Conventional = 'CONVENTIONAL',
    Highperformanceroutingwithfallbackconventional = 'HIGHPERFORMANCEROUTINGWITHFALLBACKCONVENTIONAL',
    Highperformancerouting = 'HIGHPERFORMANCEROUTING'
}

/**
 * Represents one of possibly several results of a geocoding request.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xlocate.SearchLocationsResult
 * @export
 * @interface SearchLocationsResult
 */
export interface SearchLocationsResult {
    /**
     * Gets or sets the location that was found.
     * @type {GeocodingLocation}
     * @memberof SearchLocationsResult
     */
    location?: GeocodingLocation | null;
    /**
     * Gets or sets the quality of the result relative to the search  request.
     * @type {MatchQuality}
     * @memberof SearchLocationsResult
     */
    matchQuality?: MatchQuality | null;
    /**
     * Gets or sets the type of the result, sometimes also referred to as  its detail level. Possible values include: \'LOCALITY\',  \'POSTAL_CODE\', \'STREET\', \'EXACT_ADDRESS\', \'INTERPOLATED_ADDRESS\',  \'UNSPECIFIED\'
     * @type {LocationType}
     * @memberof SearchLocationsResult
     */
    type?: LocationType | null;
}
/**
 * A segment is defined by a start- and an end-node, which in turn will  represent some geographic location. A segment may carry additional  attributes and will add to the duration of a journey.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.Segment
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * Gets or sets the index in the list of RouteResponse.nodes of the  start node of the segment. It is available only if the list of  nodes is requested by ResultFields.nodes.
     * @type {number}
     * @memberof Segment
     */
    startNodeIndex?: number | null;
    /**
     * Gets or sets the index in the list of RouteResponse.nodes of the  end node of the segment. It is available only if the list of nodes  is requested by ResultFields.nodes.
     * @type {number}
     * @memberof Segment
     */
    endNodeIndex?: number | null;
    /**
     * Gets or sets the travel distance for the segment. It is always a  whole number and depends on the segment type as follows.                *  segments.SegmentType.NETWORK\\_SEGMENT. The distance on the  network segment which is actually driven on.  *  segments.SegmentType.LINK\\_SEGMENT. The air-line distance  between input and link node.  *  segments.SegmentType.COMBINED\\_TRANSPORT. The travel distance is  always 0.  *  segments.SegmentType.NOT\\_DRIVING. The travel distance is always  0.
     * @type {number}
     * @memberof Segment
     */
    distance?: number;
    /**
     * Gets or sets the travel time for the segment. It has up to three  decimal places and depends on the segment type as follows.                *  segments.SegmentType.NETWORK\\_SEGMENT. The travel time is  calculated from the segment length and the travel speed (see  below).  *  segments.SegmentType.LINK\\_SEGMENT. The travel time is  calculated from the segment length and the travel speed (see  below).  *  segments.SegmentType.COMBINED\\_TRANSPORT. The travel time is  taken from the data which does not necessarily equal the actual  time the combined transport takes. In this case the travel time is  always a whole number.  *  segments.SegmentType.NOT\\_DRIVING. The travel time is only  grater 0 if there is a waiting time associated.                If the segment distance is larger than 0 it is guaranteed that also  the travel time is larger than 0. The travel times calculated based  on travel speed are rounded to milliseconds.
     * @type {number}
     * @memberof Segment
     */
    travelTime?: number;
    /**
     * Gets or sets the travel speed that was used to calculate the travel  time for the segment. It is always a whole number and depends on  the segment type as follows.                *  segments.SegmentType.NETWORK\\_SEGMENT. The travel speed is  determined from the road and speed classification from the data and  from the current vehicle.  *  segments.SegmentType.LINK\\_SEGMENT. The travel speed is  vehicle-dependent, the value from  vehicleprofile.Speeds.speedForLinkDistance is used.  *  segments.SegmentType.COMBINED\\_TRANSPORT. The travel speed is  always 0.  *  segments.SegmentType.NOT\\_DRIVING. The travel speed is always 0.                Due to the rounding of travel times to milliseconds the speed that  is re-calculated from segment travel time and distance can differ  slightly from the travel speed returned here. For almost all  segments the difference is less than 0.5km/h. Only for very small  segment the difference can be higher - assuming a normal speed of  at most 130km/h even for these small segments the difference does  not exceed 2km/h.
     * @type {number}
     * @memberof Segment
     */
    travelSpeed?: number;
    /**
     * Gets or sets the emissions along the route.
     * @type {Emissions}
     * @memberof Segment
     */
    emissions?: Emissions | null;
    /**
     * Gets or sets if set to true, indicates that this segment contains a  violation for the chosen vehicle.
     * @type {boolean}
     * @memberof Segment
     */
    violated?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof Segment
     */
    eventIndices?: Array<number> | null;
    /**
     * Gets or sets a segment may have different types, e.g. it may be a  \"normal\" segment between two network nodes, a \"link segment\", or a  virtual segment which represents a border crossing or a waiting  time. Possible values include: \'NETWORK_SEGMENT\', \'LINK_SEGMENT\',  \'COMBINED_TRANSPORT\', \'NOT_DRIVING\'
     * @type {SegmentType}
     * @memberof Segment
     */
    type?: SegmentType;
    /**
     * Gets or sets the polyline of the segment. This polyline consists of  all coordinates representing the segment and can be used to draw  the segment into a map. Not available for segments of type  SegmentType.NOT\\_DRIVING.                The polyline contains elevations only if requested by  xroute.PolylineOptions.elevations. If the elevations data do not  cover the complete polyline, an  xroute.ElevationsNotAvailableLimitation will be available which  states which parts of the polyline are not covered.
     * @type {EncodedGeometry}
     * @memberof Segment
     */
    polyline?: EncodedGeometry | null;
    /**
     * Gets or sets a set of attributes belonging to this segment such as  \"is this a bridge?\" or \"road name\". Available only for segments of  type SegmentType.NETWORK\\_SEGMENT.
     * @type {SegmentAttributes}
     * @memberof Segment
     */
    attributes?: SegmentAttributes | null;
    /**
     * Gets or sets attributes which describe the combined transport this  segment represents. Available only for segments of type  SegmentType.COMBINED\\_TRANSPORT.
     * @type {CombinedTransportAttributes}
     * @memberof Segment
     */
    combinedTransport?: CombinedTransportAttributes | null;
    /**
     * Gets or sets the ID of the segment.
     * @type {string}
     * @memberof Segment
     */
    id?: string | null;
}
/**
 * The attributes of the underlying segments.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.segments.SegmentAttributes
 * @export
 * @interface SegmentAttributes
 */
export interface SegmentAttributes {
    /**
     * Gets or sets attributes which basically describe the segment such  as road name and number.
     * @type {SegmentDescriptors}
     * @memberof SegmentAttributes
     */
    descriptors?: SegmentDescriptors | null;
    /**
     * Gets or sets legal and physical attributes of the segment such as  toll, one-way, or tunnels.
     * @type {RoadAttributes}
     * @memberof SegmentAttributes
     */
    roadAttributes?: RoadAttributes | null;
    /**
     * 
     * @type {Array<SegmentFeature>}
     * @memberof SegmentAttributes
     */
    features?: Array<SegmentFeature> | null;
}
/**
 * Attributes which basically describe the segment such as road name and  number.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.segments.SegmentDescriptors
 * @export
 * @interface SegmentDescriptors
 */
export interface SegmentDescriptors {
    /**
     * Gets or sets the country the segment is located in.
     * @type {string}
     * @memberof SegmentDescriptors
     */
    country?: string | null;
    /**
     * Gets or sets the name of the road in the selected language. It  remains empty, if it is not available in the data.
     * @type {string}
     * @memberof SegmentDescriptors
     */
    roadName?: string | null;
    /**
     * Gets or sets the road number. The string may consist of several  number entries divided by \"/\". It remains empty, if it is not  available in the data.
     * @type {string}
     * @memberof SegmentDescriptors
     */
    roadNumber?: string | null;
    /**
     * Gets or sets the network class of the segment.
     * @type {number}
     * @memberof SegmentDescriptors
     */
    networkClass?: number;
}
/**
 * Contains the features of a Feature Layer theme valid for the segment in  question.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.segments.Feature
 * @export
 * @interface SegmentFeature
 */
export interface SegmentFeature {
    /**
     * Gets or sets the ID of the Feature Layer theme to which this  feature belongs to.
     * @type {string}
     * @memberof SegmentFeature
     */
    themeId?: string | null;
    /**
     * 
     * @type {Array<KeyValuePair>}
     * @memberof SegmentFeature
     */
    attributes?: Array<KeyValuePair> | null;
}
/**
 * Specifies if and to what extent the segments shall be returned for the  complete route.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.SegmentResultFields
 * @export
 * @interface SegmentResultFields
 */
export interface SegmentResultFields {
    /**
     * Gets or sets specifies if the segments shall be returned for the  complete route.
     * @type {boolean}
     * @memberof SegmentResultFields
     */
    enabled?: boolean | null;
    /**
     * Gets or sets specifies if the emissions shall be returned for the  complete route.
     * @type {boolean}
     * @memberof SegmentResultFields
     */
    emissions?: boolean | null;
    /**
     * Gets or sets specifies if the segments shall contain the polyline.
     * @type {boolean}
     * @memberof SegmentResultFields
     */
    polyline?: boolean | null;
    /**
     * Gets or sets specifies if the segments shall contain the  descriptors such as road name and number.
     * @type {boolean}
     * @memberof SegmentResultFields
     */
    descriptors?: boolean | null;
    /**
     * Gets or sets specifies if the segments shall contain the road  attributes which describe it physically and legally.
     * @type {boolean}
     * @memberof SegmentResultFields
     */
    roadAttributes?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SegmentResultFields
     */
    featureThemeIds?: Array<string> | null;
    /**
     * Gets or sets specifies if the segments shall contain the ID of the  segment.
     * @type {boolean}
     * @memberof SegmentResultFields
     */
    id?: boolean | null;
}
/**
 * Defines values for SegmentType.
 * @export
 * @enum {string}
 */
export enum SegmentType {
    Networksegment = 'NETWORKSEGMENT',
    Linksegment = 'LINKSEGMENT',
    Combinedtransport = 'COMBINEDTRANSPORT',
    Notdriving = 'NOTDRIVING'
}

/**
 * SimulationConfig
 * @export
 * @interface SimulationConfig
 */
export interface SimulationConfig {
    /**
     * Gets or Sets PlanningFrequencyMinutes
     * @type {number}
     * @memberof SimulationConfig
     */
    planningFrequencyMinutes?: number;
    /**
     * Gets or Sets BoxDecommissioningTimeMinutes
     * @type {number}
     * @memberof SimulationConfig
     */
    boxDecommissioningTimeMinutes?: number;
    /**
     * Gets or Sets CustomerActionTimeAsyncMinutes
     * @type {number}
     * @memberof SimulationConfig
     */
    customerActionTimeAsyncMinutes?: number;
    /**
     * Gets or Sets CustomerActionTimeSyncMinutes
     * @type {number}
     * @memberof SimulationConfig
     */
    customerActionTimeSyncMinutes?: number;
    /**
     * Gets or Sets NumDays
     * @type {number}
     * @memberof SimulationConfig
     */
    numDays?: number;
    /**
     * Gets or Sets NumPickupOrdersPerDay
     * @type {number}
     * @memberof SimulationConfig
     */
    numPickupOrdersPerDay?: number;
    /**
     * Gets or Sets NumPackagesPerDay
     * @type {number}
     * @memberof SimulationConfig
     */
    numPackagesPerDay?: number;
    /**
     * Gets or Sets LargePackagesShare
     * @type {string}
     * @memberof SimulationConfig
     */
    largePackagesShare?: string | null;
    /**
     * Gets or Sets OrderTypes
     * @type {string}
     * @memberof SimulationConfig
     */
    orderTypes?: string | null;
    /**
     * Gets or Sets OrderModes
     * @type {string}
     * @memberof SimulationConfig
     */
    orderModes?: string | null;
    /**
     * Gets or Sets KepTimes
     * @type {string}
     * @memberof SimulationConfig
     */
    kepTimes?: string | null;
    /**
     * Gets or Sets ScenarioFilename
     * @type {string}
     * @memberof SimulationConfig
     */
    scenarioFilename?: string | null;
}
/**
 * SimulationInformation
 * @export
 * @interface SimulationInformation
 */
export interface SimulationInformation {
    /**
     * Gets or Sets SimulationId
     * @type {string}
     * @memberof SimulationInformation
     */
    simulationId?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SlotSelection {
    Undefined = 'UNDEFINED',
    Single = 'SINGLE',
    All = 'ALL'
}

/**
 * <br>Key: GROUPING (Sammelauftrag) used PTV, PRIORITY, HAZARDOROUS_GOODS  <br>Value: GROUPING (UUID), Battery
 * @export
 * @interface SpecialCode
 */
export interface SpecialCode {
    /**
     * 
     * @type {string}
     * @memberof SpecialCode
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialCode
     */
    value?: string | null;
}
/**
 * StartTourEvent
 * @export
 * @interface StartTourEvent
 */
export interface StartTourEvent {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof StartTourEvent
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets TourExtId
     * @type {string}
     * @memberof StartTourEvent
     */
    tourExtId?: string | null;
}
/**
 * StoreBoxEvent
 * @export
 * @interface StoreBoxEvent
 */
export interface StoreBoxEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof StoreBoxEvent
     */
    boxIdent?: string | null;
}
/**
 * SyncMeetingPointInformation
 * @export
 * @interface SyncMeetingPointInformation
 */
export interface SyncMeetingPointInformation {
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof SyncMeetingPointInformation
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof SyncMeetingPointInformation
     */
    longitude?: number;
    /**
     * Gets or Sets SyncMeetingPointIdent
     * @type {string}
     * @memberof SyncMeetingPointInformation
     */
    syncMeetingPointIdent?: string | null;
    /**
     * Gets or Sets Name
     * @type {string}
     * @memberof SyncMeetingPointInformation
     */
    name?: string | null;
}
/**
 * SyncStopArrivalMessage
 * @export
 * @interface SyncStopArrivalMessage
 */
export interface SyncStopArrivalMessage {
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof SyncStopArrivalMessage
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof SyncStopArrivalMessage
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets TourExtId
     * @type {string}
     * @memberof SyncStopArrivalMessage
     */
    tourExtId?: string | null;
}
/**
 * Defines SyncStopState
 * @export
 * @enum {string}
 */
export enum SyncStopStateEnum {
    Finished = 'Finished',
    NotFinished = 'NotFinished'
}

/**
 * SyncStopStateRequest
 * @export
 * @interface SyncStopStateRequest
 */
export interface SyncStopStateRequest {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof SyncStopStateRequest
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets TourExtId
     * @type {string}
     * @memberof SyncStopStateRequest
     */
    tourExtId?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof SyncStopStateRequest
     */
    orderIdent?: string | null;
}
/**
 * SyncStopStateResponse
 * @export
 * @interface SyncStopStateResponse
 */
export interface SyncStopStateResponse {
    /**
     * Gets or Sets SyncStopState
     * @type {SyncStopStateEnum}
     * @memberof SyncStopStateResponse
     */
    syncStopState?: SyncStopStateEnum;
}
/**
 * 
 * @export
 * @interface SystemTestResponse
 */
export interface SystemTestResponse {
    /**
     * 
     * @type {number}
     * @memberof SystemTestResponse
     */
    executedTests?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SystemTestResponse
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemTestResponse
     */
    errorMsg?: string | null;
    /**
     * 
     * @type {Array<DetailInfo>}
     * @memberof SystemTestResponse
     */
    detailInfos?: Array<DetailInfo> | null;
}
/**
 * TimeOfDay
 * @export
 * @interface TimeOfDay
 */
export interface TimeOfDay {
    /**
     * Gets or Sets Hour
     * @type {number}
     * @memberof TimeOfDay
     */
    hour?: number;
    /**
     * Gets or Sets Minute
     * @type {number}
     * @memberof TimeOfDay
     */
    minute?: number;
}
/**
 * TimeWindow
 * @export
 * @interface TimeWindow
 */
export interface TimeWindow {
    /**
     * Gets or Sets Start
     * @type {string}
     * @memberof TimeWindow
     */
    start?: string;
    /**
     * Gets or Sets End
     * @type {string}
     * @memberof TimeWindow
     */
    end?: string;
}
/**
 * Represents a single toll cost specifying the amount, currency, and  optional meta information.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.TollCost
 * @export
 * @interface TollCost
 */
export interface TollCost {
    /**
     * Gets or sets the amount of the costs in the specified currency.
     * @type {number}
     * @memberof TollCost
     */
    amount?: number;
    /**
     * Gets or sets the currency code.
     * @type {string}
     * @memberof TollCost
     */
    currency?: string | null;
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof TollCost
     */
    paymentMethods?: Array<PaymentMethod> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TollCost
     */
    electronicTollCollectionSubscriptions?: Array<string> | null;
    /**
     * Gets or sets the amount that has to be paid converted to the  requested currency. It is only returned if this currency is set in  the route options of the request. It is still returned in case the  requested currency and the currency in which the cost is captured  in the data are the same.
     * @type {Cost}
     * @memberof TollCost
     */
    convertedCost?: Cost | null;
}
/**
 * Specifies all the data concerning toll consisting of a toll summary and  optionally more detailed information about the individual toll sections  to be paid and the toll systems on the route.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.Toll
 * @export
 * @interface TollResult
 */
export interface TollResult {
    /**
     * Gets or sets the total toll costs.
     * @type {TollSummary}
     * @memberof TollResult
     */
    summary?: TollSummary | null;
    /**
     * 
     * @type {Array<TollSection>}
     * @memberof TollResult
     */
    sections?: Array<TollSection> | null;
    /**
     * 
     * @type {Array<TollSystem>}
     * @memberof TollResult
     */
    systems?: Array<TollSystem> | null;
}
/**
 * Specifies if and to what extent the toll shall be calculated and  returned for the complete route.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.TollResultFields
 * @export
 * @interface TollResultFields
 */
export interface TollResultFields {
    /**
     * Gets or sets specifies if the toll shall be returned for the  complete route.
     * @type {boolean}
     * @memberof TollResultFields
     */
    enabled?: boolean | null;
    /**
     * Gets or sets specifies if the toll shall contain detailed  information about the sections.
     * @type {boolean}
     * @memberof TollResultFields
     */
    sections?: boolean | null;
    /**
     * Gets or sets specifies if the toll shall contain the toll systems.
     * @type {boolean}
     * @memberof TollResultFields
     */
    systems?: boolean | null;
}
/**
 * Defines values for TollRoadType.
 * @export
 * @enum {string}
 */
export enum TollRoadType {
    General = 'GENERAL',
    City = 'CITY',
    Bridge = 'BRIDGE',
    Tunnel = 'TUNNEL',
    Ferry = 'FERRY',
    Mountainpass = 'MOUNTAINPASS',
    Unspecified = 'UNSPECIFIED'
}

/**
 * Represents one single section of the route for which the toll operator  has defined toll costs. Besides the toll costs further information is  optionally provided such as alternative costs with different payment  methods or an official distance for the section defined by the toll  operator.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.TollSection
 * @export
 * @interface TollSection
 */
export interface TollSection {
    /**
     * Gets or sets the toll cost of the section. It is always returned in  the currency in which it is captured in the data, which is usually  the local currency of the country.
     * @type {TollCost}
     * @memberof TollSection
     */
    cost?: TollCost | null;
    /**
     * 
     * @type {Array<TollCost>}
     * @memberof TollSection
     */
    alternativeCosts?: Array<TollCost> | null;
    /**
     * Gets or sets the official distance defined by the toll operator. It  is only populated if the toll operator provides an official  distance.
     * @type {number}
     * @memberof TollSection
     */
    officialDistance?: number | null;
    /**
     * Gets or sets the toll road type refers to the reason why toll is  charged, e.g. tunnel, bridge, or mountain pass. Possible values  include: \'GENERAL\', \'CITY\', \'BRIDGE\', \'TUNNEL\', \'FERRY\',  \'MOUNTAIN_PASS\', \'UNSPECIFIED\'
     * @type {TollRoadType}
     * @memberof TollSection
     */
    tollRoadType?: TollRoadType | null;
    /**
     * Gets or sets the country in which the costs have to be paid.
     * @type {string}
     * @memberof TollSection
     */
    country?: string | null;
    /**
     * Gets or sets the index in the list of toll systems the toll section  belongs to. The index is only populated if the toll systems are  requested by TollResultFields.systems.
     * @type {number}
     * @memberof TollSection
     */
    tollSystemIndex?: number | null;
    /**
     * Gets or sets the index in the list of RouteResponse.nodes where the  toll section begins. It is available only if the list of nodes is  requested by ResultFields.nodes.
     * @type {number}
     * @memberof TollSection
     */
    startNodeIndex?: number | null;
    /**
     * Gets or sets the index in the list of RouteResponse.nodes where the  toll section ends. It is available only if the list of nodes is  requested by ResultFields.nodes.
     * @type {number}
     * @memberof TollSection
     */
    endNodeIndex?: number | null;
    /**
     * Gets or sets a name that characterizes the toll section. For  example it can relate to the names of the road intersections, to  the area in general or to the type of toll.
     * @type {string}
     * @memberof TollSection
     */
    displayName?: string | null;
}
/**
 * Specifies key data concerning toll, for example the toll cost.  Depending on the object that contains the toll summary it represents  the summed up toll data either for the complete route or for some part  of the route.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.TollSummary
 * @export
 * @interface TollSummary
 */
export interface TollSummary {
    /**
     * 
     * @type {Array<Cost>}
     * @memberof TollSummary
     */
    costs?: Array<Cost> | null;
    /**
     * 
     * @type {Array<CountryCost>}
     * @memberof TollSummary
     */
    countries?: Array<CountryCost> | null;
}
/**
 * Represents a toll system.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.TollSystem
 * @export
 * @interface TollSystem
 */
export interface TollSystem {
    /**
     * Gets or sets the name of the toll system. For example, Florida  Turnpike, or TollCollect German Autobahn.
     * @type {string}
     * @memberof TollSystem
     */
    name?: string | null;
    /**
     * Gets or sets the name of the toll operator. For example,  TollCollect.
     * @type {string}
     * @memberof TollSystem
     */
    operatorName?: string | null;
    /**
     * Gets or sets the tariff version that has been used.
     * @type {string}
     * @memberof TollSystem
     */
    tariffVersion?: string | null;
    /**
     * Gets or sets the starting date of the tariff version validity. Note  that this date can also be later than a specified reference time:  In case there is no tariff version that is valid at a specified  reference time the tariff version with the earliest valid from date  is used.
     * @type {string}
     * @memberof TollSystem
     */
    tariffVersionValidFrom?: string | null;
}
/**
 * TourActionPointInformation
 * @export
 * @interface TourActionPointInformation
 */
export interface TourActionPointInformation {
    /**
     * Gets or Sets Action
     * @type {string}
     * @memberof TourActionPointInformation
     */
    action?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof TourActionPointInformation
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets ActionPointArrivalTime
     * @type {string}
     * @memberof TourActionPointInformation
     */
    actionPointArrivalTime?: string;
    /**
     * Gets or Sets ActionPointDepartureTime
     * @type {string}
     * @memberof TourActionPointInformation
     */
    actionPointDepartureTime?: string;
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof TourActionPointInformation
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof TourActionPointInformation
     */
    longitude?: number;
}
/**
 * TourDeletionResult
 * @export
 * @interface TourDeletionResult
 */
export interface TourDeletionResult {
    /**
     * Gets or Sets DeletedTours
     * @type {Array<string>}
     * @memberof TourDeletionResult
     */
    deletedTours?: Array<string> | null;
    /**
     * Gets or Sets NonDeletableTours
     * @type {Array<string>}
     * @memberof TourDeletionResult
     */
    nonDeletableTours?: Array<string> | null;
}
/**
 * TourInformation
 * @export
 * @interface TourInformation
 */
export interface TourInformation {
    /**
     * Gets or Sets TourState
     * @type {TourStateEnum}
     * @memberof TourInformation
     */
    tourState?: TourStateEnum;
    /**
     * Gets or Sets TourExtId
     * @type {string}
     * @memberof TourInformation
     */
    tourExtId?: string | null;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof TourInformation
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets OrderCount
     * @type {number}
     * @memberof TourInformation
     */
    orderCount?: number;
    /**
     * Gets or Sets TourPointCount
     * @type {number}
     * @memberof TourInformation
     */
    tourPointCount?: number;
    /**
     * Gets or Sets TotalDistance
     * @type {number}
     * @memberof TourInformation
     */
    totalDistance?: number;
    /**
     * Gets or Sets RouteDistance
     * @type {number}
     * @memberof TourInformation
     */
    routeDistance?: number;
    /**
     * Gets or Sets TourStartDateTime
     * @type {string}
     * @memberof TourInformation
     */
    tourStartDateTime?: string;
    /**
     * Gets or Sets TourEndDateTime
     * @type {string}
     * @memberof TourInformation
     */
    tourEndDateTime?: string;
    /**
     * Gets or Sets TourStopInfos
     * @type {Array<TourStopInformation>}
     * @memberof TourInformation
     */
    tourStopInfos?: Array<TourStopInformation> | null;
    /**
     * Gets or Sets RouteInformation
     * @type {RouteInformation}
     * @memberof TourInformation
     */
    routeInformation?: RouteInformation | null;
    /**
     * Gets or Sets OrderInfos
     * @type {Array<TourOrderInformation>}
     * @memberof TourInformation
     */
    orderInfos?: Array<TourOrderInformation> | null;
    /**
     * Gets or Sets BoxInfos
     * @type {Array<BoxInformationAdmin>}
     * @memberof TourInformation
     */
    boxInfos?: Array<BoxInformationAdmin> | null;
    /**
     * Gets or Sets ContainedBoxOrderIdents
     * @type {Array<string>}
     * @memberof TourInformation
     */
    containedBoxOrderIdents?: Array<string> | null;
    /**
     * Gets or Sets ContainedPackageIdents
     * @type {Array<string>}
     * @memberof TourInformation
     */
    containedPackageIdents?: Array<string> | null;
}
/**
 * TourOrderInformation
 * @export
 * @interface TourOrderInformation
 */
export interface TourOrderInformation {
    /**
     * Gets or Sets OrderState
     * @type {TourOrderStateEnum}
     * @memberof TourOrderInformation
     */
    orderState?: TourOrderStateEnum;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof TourOrderInformation
     */
    orderIdent?: string | null;
}
/**
 * Defines TourOrderStateEnum
 * @export
 * @enum {string}
 */
export enum TourOrderStateEnum {
    Open = 'OPEN',
    Finished = 'FINISHED'
}

/**
 * Defines TourStateEnum
 * @export
 * @enum {string}
 */
export enum TourStateEnum {
    Planned = 'Planned',
    Fixed = 'Fixed',
    Finished = 'Finished',
    Started = 'Started'
}

/**
 * TourStatus
 * @export
 * @interface TourStatus
 */
export interface TourStatus {
    /**
     * Gets or Sets TourState
     * @type {TourStateEnum}
     * @memberof TourStatus
     */
    tourState?: TourStateEnum;
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof TourStatus
     */
    id?: string | null;
    /**
     * Gets or Sets TourString
     * @type {string}
     * @memberof TourStatus
     */
    tourString?: string | null;
}
/**
 * TourStopInformation
 * @export
 * @interface TourStopInformation
 */
export interface TourStopInformation {
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof TourStopInformation
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof TourStopInformation
     */
    longitude?: number;
    /**
     * Gets or Sets ActionPointInfos
     * @type {Array<TourActionPointInformation>}
     * @memberof TourStopInformation
     */
    actionPointInfos?: Array<TourActionPointInformation> | null;
}
/**
 * UnlockVehicleRequest
 * @export
 * @interface UnlockVehicleRequest
 */
export interface UnlockVehicleRequest {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof UnlockVehicleRequest
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets LockIdent
     * @type {string}
     * @memberof UnlockVehicleRequest
     */
    lockIdent?: string | null;
}
/**
 * VehicleBoxDeliveryEvent
 * @export
 * @interface VehicleBoxDeliveryEvent
 */
export interface VehicleBoxDeliveryEvent {
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof VehicleBoxDeliveryEvent
     */
    boxIdent?: string | null;
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof VehicleBoxDeliveryEvent
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof VehicleBoxDeliveryEvent
     */
    vehicleIdent?: string | null;
}
/**
 * VehicleBoxPickupEvent
 * @export
 * @interface VehicleBoxPickupEvent
 */
export interface VehicleBoxPickupEvent {
    /**
     * Gets or Sets MountIdent
     * @type {string}
     * @memberof VehicleBoxPickupEvent
     */
    mountIdent?: string | null;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof VehicleBoxPickupEvent
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets OrderIdent
     * @type {string}
     * @memberof VehicleBoxPickupEvent
     */
    orderIdent?: string | null;
    /**
     * Gets or Sets BoxIdent
     * @type {string}
     * @memberof VehicleBoxPickupEvent
     */
    boxIdent?: string | null;
}
/**
 * VehicleInformation
 * @export
 * @interface VehicleInformation
 */
export interface VehicleInformation {
    /**
     * Gets or Sets VehicleState
     * @type {VehicleStateEnum}
     * @memberof VehicleInformation
     */
    vehicleState?: VehicleStateEnum;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof VehicleInformation
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets VehicleName
     * @type {string}
     * @memberof VehicleInformation
     */
    vehicleName?: string | null;
    /**
     * Gets or Sets RemainingRange
     * @type {number}
     * @memberof VehicleInformation
     */
    remainingRange?: number;
    /**
     * Gets or Sets StateOfCharge
     * @type {number}
     * @memberof VehicleInformation
     */
    stateOfCharge?: number;
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof VehicleInformation
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof VehicleInformation
     */
    longitude?: number;
    /**
     * Gets or Sets BoxInfos
     * @type {Array<BoxInformationAdmin>}
     * @memberof VehicleInformation
     */
    boxInfos?: Array<BoxInformationAdmin> | null;
    /**
     * Gets or Sets ContainedBoxOrderIdents
     * @type {Array<string>}
     * @memberof VehicleInformation
     */
    containedBoxOrderIdents?: Array<string> | null;
    /**
     * Gets or Sets ContainedPackageIdents
     * @type {Array<string>}
     * @memberof VehicleInformation
     */
    containedPackageIdents?: Array<string> | null;
    /**
     * Gets or Sets PlannedTourIdents
     * @type {Array<string>}
     * @memberof VehicleInformation
     */
    plannedTourIdents?: Array<string> | null;
    /**
     * Gets or Sets Locked
     * @type {boolean}
     * @memberof VehicleInformation
     */
    locked?: boolean;
    /**
     * Gets or Sets VehicleCumulatedBoxLoadInfo
     * @type {string}
     * @memberof VehicleInformation
     */
    vehicleCumulatedBoxLoadInfo?: string | null;
}
/**
 * VehicleLock
 * @export
 * @interface VehicleLock
 */
export interface VehicleLock {
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof VehicleLock
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets Start
     * @type {string}
     * @memberof VehicleLock
     */
    start?: string;
    /**
     * Gets or Sets End
     * @type {string}
     * @memberof VehicleLock
     */
    end?: string;
    /**
     * Gets or Sets LockIdent
     * @type {string}
     * @memberof VehicleLock
     */
    lockIdent?: string | null;
}
/**
 * Defines VehicleOccupationState
 * @export
 * @enum {string}
 */
export enum VehicleOccupationStateEnum {
    Free = 'FREE',
    Vehiclewaiting = 'VEHICLEWAITING',
    Vehiclecharging = 'VEHICLECHARGING'
}

/**
 * Defines VehicleStateEnum
 * @export
 * @enum {string}
 */
export enum VehicleStateEnum {
    Idle = 'Idle',
    Driving = 'Driving',
    Recharging = 'Recharging',
    Loading = 'Loading',
    Waiting = 'Waiting',
    Disrupted = 'Disrupted'
}

/**
 * VehicleStatus
 * @export
 * @interface VehicleStatus
 */
export interface VehicleStatus {
    /**
     * Gets or Sets VehicleState
     * @type {VehicleStateEnum}
     * @memberof VehicleStatus
     */
    vehicleState?: VehicleStateEnum;
    /**
     * Gets or Sets Id
     * @type {string}
     * @memberof VehicleStatus
     */
    id?: string | null;
    /**
     * Gets or Sets Available
     * @type {boolean}
     * @memberof VehicleStatus
     */
    available?: boolean;
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof VehicleStatus
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof VehicleStatus
     */
    longitude?: number;
    /**
     * Gets or Sets BoxIds
     * @type {Array<string>}
     * @memberof VehicleStatus
     */
    boxIds?: Array<string> | null;
    /**
     * Gets or Sets RemainingRange
     * @type {number}
     * @memberof VehicleStatus
     */
    remainingRange?: number;
    /**
     * Gets or Sets StateOfCharge
     * @type {number}
     * @memberof VehicleStatus
     */
    stateOfCharge?: number;
    /**
     * Gets or Sets Timestamp
     * @type {string}
     * @memberof VehicleStatus
     */
    timestamp?: string;
    /**
     * Gets or Sets CurrentTourId
     * @type {string}
     * @memberof VehicleStatus
     */
    currentTourId?: string | null;
    /**
     * Gets or Sets ErrorMessage
     * @type {string}
     * @memberof VehicleStatus
     */
    errorMessage?: string | null;
}
/**
 * VehicleUpdate
 * @export
 * @interface VehicleUpdate
 */
export interface VehicleUpdate {
    /**
     * Gets or Sets VehicleState
     * @type {VehicleStateEnum}
     * @memberof VehicleUpdate
     */
    vehicleState?: VehicleStateEnum;
    /**
     * Gets or Sets VehicleIdent
     * @type {string}
     * @memberof VehicleUpdate
     */
    vehicleIdent?: string | null;
    /**
     * Gets or Sets Available
     * @type {boolean}
     * @memberof VehicleUpdate
     */
    available?: boolean;
    /**
     * Gets or Sets Latitude
     * @type {number}
     * @memberof VehicleUpdate
     */
    latitude?: number;
    /**
     * Gets or Sets Longitude
     * @type {number}
     * @memberof VehicleUpdate
     */
    longitude?: number;
    /**
     * Gets or Sets RemainingRange
     * @type {number}
     * @memberof VehicleUpdate
     */
    remainingRange?: number;
    /**
     * Gets or Sets StateOfCharge
     * @type {number}
     * @memberof VehicleUpdate
     */
    stateOfCharge?: number;
    /**
     * Gets or Sets Timestamp
     * @type {string}
     * @memberof VehicleUpdate
     */
    timestamp?: string;
    /**
     * Gets or Sets ErrorMessage
     * @type {string}
     * @memberof VehicleUpdate
     */
    errorMessage?: string | null;
    /**
     * Gets or Sets CurrentWaitingMountIdent
     * @type {string}
     * @memberof VehicleUpdate
     */
    currentWaitingMountIdent?: string | null;
}
/**
 * WarehouseLog
 * @export
 * @interface WarehouseLog
 */
export interface WarehouseLog {
    /**
     * Gets or Sets NumInboundPackages
     * @type {number}
     * @memberof WarehouseLog
     */
    numInboundPackages?: number;
    /**
     * Gets or Sets NumOutboundPackages
     * @type {number}
     * @memberof WarehouseLog
     */
    numOutboundPackages?: number;
    /**
     * Gets or Sets NumSmallPackageBoxes
     * @type {number}
     * @memberof WarehouseLog
     */
    numSmallPackageBoxes?: number;
    /**
     * Gets or Sets NumLargePackageBoxes
     * @type {number}
     * @memberof WarehouseLog
     */
    numLargePackageBoxes?: number;
    /**
     * Gets or Sets NumSmallWasteBoxes
     * @type {number}
     * @memberof WarehouseLog
     */
    numSmallWasteBoxes?: number;
    /**
     * Gets or Sets NumLargeWasteBoxes
     * @type {number}
     * @memberof WarehouseLog
     */
    numLargeWasteBoxes?: number;
}
/**
 * WarehousePlace
 * @export
 * @interface WarehousePlace
 */
export interface WarehousePlace {
    /**
     * Gets or Sets ExtId
     * @type {string}
     * @memberof WarehousePlace
     */
    extId?: string | null;
    /**
     * Gets or Sets Warehouse
     * @type {string}
     * @memberof WarehousePlace
     */
    warehouse?: string | null;
    /**
     * Gets or Sets PlaceName
     * @type {string}
     * @memberof WarehousePlace
     */
    placeName?: string | null;
}
/**
 * A waypoint defines the start or destination of a route or a route leg.  Every waypoint corresponds to an InputWaypoint, but not every input  waypoint corresponds to a waypoint in the route. It is guaranteed that  the waypoint of index *n* starts the leg of index *n*. That means that  the number of waypoints equals the number of legs plus 1.  Hint: Corresponding type in xServer API documentation -  com.ptvgroup.xserver.xroute.Waypoint
 * @export
 * @interface Waypoint
 */
export interface Waypoint {
    /**
     * Gets or sets the name of the corresponding input waypoint. This  user-defined name is available only if InputWaypoint.name for the  corresponding input waypoint is set.
     * @type {string}
     * @memberof Waypoint
     */
    name?: string | null;
    /**
     * Gets or sets country the waypoint is located in. This value is  determined based on the Waypoint.linkedCoordinate. Note that for  this reason rare situations can occur close to borders where the  Waypoint.referenceCoordinate for the waypoint is not in the same  country.
     * @type {string}
     * @memberof Waypoint
     */
    country?: string | null;
    /**
     * Gets or sets the coordinate where input location was linked to the  road network. This location is always on the route.
     * @type {Coordinate}
     * @memberof Waypoint
     */
    linkedCoordinate?: Coordinate | null;
    /**
     * Gets or sets the location of the waypoint on the route. For an  OffRoadWaypoint this is the input location, for an OnRoadWaypoint  this is the linked location. Use this location to mark the waypoint  on the map.
     * @type {Coordinate}
     * @memberof Waypoint
     */
    referenceCoordinate?: Coordinate | null;
    /**
     * Gets or sets distance as the crow flies from the input coordinate  to the linked coordinate.
     * @type {number}
     * @memberof Waypoint
     */
    linkDistance?: number;
    /**
     * Gets or sets assumed travel time it takes to get from the input  coordinate to the linked coordinate. The speed can be configured by  vehicleprofile.Speeds.speedForLinkDistance.
     * @type {number}
     * @memberof Waypoint
     */
    linkTravelTime?: number;
    /**
     * Gets or sets the index in the list of RouteResponse.nodes of the  corresponding node. It is available only if the list of nodes is  requested by ResultFields.nodes.
     * @type {number}
     * @memberof Waypoint
     */
    nodeIndex?: number | null;
}
/**
 * 
 * @export
 * @interface WebAPIOrder
 */
export interface WebAPIOrder {
    /**
     * 
     * @type {string}
     * @memberof WebAPIOrder
     */
    ident?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebAPIOrder
     */
    version?: number | null;
}

/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteAddress', 'ident', ident)
            const localVarPath = `/api/data/place/address/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    Supported finders      {         \"Ident\" => \"equal\",         \"ZipCode\" => \"equal\",         \"Label\" => \"regex\",         \"City\" => \"regex\",         \"Street\" => \"regex\"      }
         * @summary Uses the default EfCaAddress object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAddressesByFinder: async (efCaAddress?: EfCaAddress, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/address/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddAddresses: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAddress: async (efCaAddress?: EfCaAddress, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddress(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaAddressResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAddress(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    Supported finders      {         \"Ident\" => \"equal\",         \"ZipCode\" => \"equal\",         \"Label\" => \"regex\",         \"City\" => \"regex\",         \"Street\" => \"regex\"      }
         * @summary Uses the default EfCaAddress object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAddressesByFinder(efCaAddress?: EfCaAddress, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaAddressResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAddressesByFinder(efCaAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddAddresses(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaAddressResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddAddresses(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAddress(efCaAddress?: EfCaAddress, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaAddressResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAddress(efCaAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress(ident: string, options?: any): AxiosPromise<EfCaAddressResp> {
            return localVarFp.deleteAddress(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    Supported finders      {         \"Ident\" => \"equal\",         \"ZipCode\" => \"equal\",         \"Label\" => \"regex\",         \"City\" => \"regex\",         \"Street\" => \"regex\"      }
         * @summary Uses the default EfCaAddress object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAddressesByFinder(efCaAddress?: EfCaAddress, options?: any): AxiosPromise<EfCaAddressResp> {
            return localVarFp.findAddressesByFinder(efCaAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddAddresses(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaAddressResp> {
            return localVarFp.postAddAddresses(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAddress(efCaAddress?: EfCaAddress, options?: any): AxiosPromise<EfCaAddressResp> {
            return localVarFp.putAddress(efCaAddress, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public deleteAddress(ident: string, options?: any) {
        return AddressApiFp(this.configuration).deleteAddress(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    Supported finders      {         \"Ident\" => \"equal\",         \"ZipCode\" => \"equal\",         \"Label\" => \"regex\",         \"City\" => \"regex\",         \"Street\" => \"regex\"      }
     * @summary Uses the default EfCaAddress object as finder but not all parameters are supported. Refer to the more detailled description below.
     * @param {EfCaAddress} [efCaAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public findAddressesByFinder(efCaAddress?: EfCaAddress, options?: any) {
        return AddressApiFp(this.configuration).findAddressesByFinder(efCaAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public postAddAddresses(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return AddressApiFp(this.configuration).postAddAddresses(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaAddress} [efCaAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public putAddress(efCaAddress?: EfCaAddress, options?: any) {
        return AddressApiFp(this.configuration).putAddress(efCaAddress, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminAppApi - axios parameter creator
 * @export
 */
export const AdminAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxOrder: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getBoxOrder', 'orderId', orderId)
            const localVarPath = `/fzi/app/admin/getBoxOrder/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxOrderInformations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/boxOrderInformations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/getCustomerList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinishedBoxOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/getFinishedBoxOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinishedPackages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/getFinishedPackages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackage: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getPackage', 'orderId', orderId)
            const localVarPath = `/fzi/app/admin/getPackage/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageInformations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/packageInformations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningStatusLogs: async (start?: number, end?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/getPlanningStatusLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechargingOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/getRechargingOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTourOverview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/tourOverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleInformations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/vehicleInformations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleLocks: async (vehicleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('getVehicleLocks', 'vehicleId', vehicleId)
            const localVarPath = `/fzi/app/admin/getVehicleLocks/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminModifyOrderRequest} [adminModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminModifyOrder: async (adminModifyOrderRequest?: AdminModifyOrderRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/modifyOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminModifyOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contactId 
         * @param {BoxRequest} [boxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateBoxRequestAdmin: async (contactId: string, boxRequest?: BoxRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('postCreateBoxRequestAdmin', 'contactId', contactId)
            const localVarPath = `/fzi/app/admin/createBoxRequest/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminDeleteOrderRequest} [adminDeleteOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteOrder: async (adminDeleteOrderRequest?: AdminDeleteOrderRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/deleteOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDeleteOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteToursForVehicle: async (vehicleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('postDeleteToursForVehicle', 'vehicleId', vehicleId)
            const localVarPath = `/fzi/app/admin/deleteToursForVehicle/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LockVehicleRequest} [lockVehicleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLockVehicle: async (lockVehicleRequest?: LockVehicleRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/lockVehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lockVehicleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminRemovePackagesFromOrderRequest} [adminRemovePackagesFromOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRemovePackagesFromOrder: async (adminRemovePackagesFromOrderRequest?: AdminRemovePackagesFromOrderRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/removePackagesFromOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminRemovePackagesFromOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminReturnBoxRequest} [adminReturnBoxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReturnBox: async (adminReturnBoxRequest?: AdminReturnBoxRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/returnBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminReturnBoxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnlockVehicleRequest} [unlockVehicleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUnlockVehicle: async (unlockVehicleRequest?: UnlockVehicleRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/admin/unlockVehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unlockVehicleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAppApi - functional programming interface
 * @export
 */
export const AdminAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxOrder(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxOrderInformationAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxOrder(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxOrderInformations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoxOrderInformationAdmin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxOrderInformations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerInfoAdmin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinishedBoxOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoxOrderInformationAdmin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinishedBoxOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinishedPackages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackageInformationAdmin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinishedPackages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackage(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageInformationAdmin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackage(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackageInformations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackageInformationAdmin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackageInformations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanningStatusLogs(start?: number, end?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanningStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanningStatusLogs(start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRechargingOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RechargeOrderInfoAdmin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRechargingOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTourOverview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TourInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTourOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleInformations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleInformations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleLocks(vehicleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleLock>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleLocks(vehicleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminModifyOrderRequest} [adminModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminModifyOrder(adminModifyOrderRequest?: AdminModifyOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminModifyOrder(adminModifyOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} contactId 
         * @param {BoxRequest} [boxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateBoxRequestAdmin(contactId: string, boxRequest?: BoxRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateBoxRequestAdmin(contactId, boxRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminDeleteOrderRequest} [adminDeleteOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeleteOrder(adminDeleteOrderRequest?: AdminDeleteOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeleteOrder(adminDeleteOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeleteToursForVehicle(vehicleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourDeletionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeleteToursForVehicle(vehicleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LockVehicleRequest} [lockVehicleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLockVehicle(lockVehicleRequest?: LockVehicleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleLock>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLockVehicle(lockVehicleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminRemovePackagesFromOrderRequest} [adminRemovePackagesFromOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRemovePackagesFromOrder(adminRemovePackagesFromOrderRequest?: AdminRemovePackagesFromOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRemovePackagesFromOrder(adminRemovePackagesFromOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminReturnBoxRequest} [adminReturnBoxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReturnBox(adminReturnBoxRequest?: AdminReturnBoxRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReturnBox(adminReturnBoxRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnlockVehicleRequest} [unlockVehicleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUnlockVehicle(unlockVehicleRequest?: UnlockVehicleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUnlockVehicle(unlockVehicleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAppApi - factory interface
 * @export
 */
export const AdminAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAppApiFp(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxOrder(orderId: string, options?: any): AxiosPromise<BoxOrderInformationAdmin> {
            return localVarFp.getBoxOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxOrderInformations(options?: any): AxiosPromise<Array<BoxOrderInformationAdmin>> {
            return localVarFp.getBoxOrderInformations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerList(options?: any): AxiosPromise<Array<CustomerInfoAdmin>> {
            return localVarFp.getCustomerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinishedBoxOrders(options?: any): AxiosPromise<Array<BoxOrderInformationAdmin>> {
            return localVarFp.getFinishedBoxOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinishedPackages(options?: any): AxiosPromise<Array<PackageInformationAdmin>> {
            return localVarFp.getFinishedPackages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackage(orderId: string, options?: any): AxiosPromise<PackageInformationAdmin> {
            return localVarFp.getPackage(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageInformations(options?: any): AxiosPromise<Array<PackageInformationAdmin>> {
            return localVarFp.getPackageInformations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanningStatusLogs(start?: number, end?: number, options?: any): AxiosPromise<Array<PlanningStatus>> {
            return localVarFp.getPlanningStatusLogs(start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechargingOrders(options?: any): AxiosPromise<Array<RechargeOrderInfoAdmin>> {
            return localVarFp.getRechargingOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTourOverview(options?: any): AxiosPromise<Array<TourInformation>> {
            return localVarFp.getTourOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleInformations(options?: any): AxiosPromise<Array<VehicleInformation>> {
            return localVarFp.getVehicleInformations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleLocks(vehicleId: string, options?: any): AxiosPromise<Array<VehicleLock>> {
            return localVarFp.getVehicleLocks(vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminModifyOrderRequest} [adminModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminModifyOrder(adminModifyOrderRequest?: AdminModifyOrderRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postAdminModifyOrder(adminModifyOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} contactId 
         * @param {BoxRequest} [boxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateBoxRequestAdmin(contactId: string, boxRequest?: BoxRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postCreateBoxRequestAdmin(contactId, boxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminDeleteOrderRequest} [adminDeleteOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteOrder(adminDeleteOrderRequest?: AdminDeleteOrderRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postDeleteOrder(adminDeleteOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteToursForVehicle(vehicleId: string, options?: any): AxiosPromise<TourDeletionResult> {
            return localVarFp.postDeleteToursForVehicle(vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LockVehicleRequest} [lockVehicleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLockVehicle(lockVehicleRequest?: LockVehicleRequest, options?: any): AxiosPromise<VehicleLock> {
            return localVarFp.postLockVehicle(lockVehicleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminRemovePackagesFromOrderRequest} [adminRemovePackagesFromOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRemovePackagesFromOrder(adminRemovePackagesFromOrderRequest?: AdminRemovePackagesFromOrderRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postRemovePackagesFromOrder(adminRemovePackagesFromOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminReturnBoxRequest} [adminReturnBoxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReturnBox(adminReturnBoxRequest?: AdminReturnBoxRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postReturnBox(adminReturnBoxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnlockVehicleRequest} [unlockVehicleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUnlockVehicle(unlockVehicleRequest?: UnlockVehicleRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postUnlockVehicle(unlockVehicleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAppApi - object-oriented interface
 * @export
 * @class AdminAppApi
 * @extends {BaseAPI}
 */
export class AdminAppApi extends BaseAPI {
    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getBoxOrder(orderId: string, options?: any) {
        return AdminAppApiFp(this.configuration).getBoxOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getBoxOrderInformations(options?: any) {
        return AdminAppApiFp(this.configuration).getBoxOrderInformations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getCustomerList(options?: any) {
        return AdminAppApiFp(this.configuration).getCustomerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getFinishedBoxOrders(options?: any) {
        return AdminAppApiFp(this.configuration).getFinishedBoxOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getFinishedPackages(options?: any) {
        return AdminAppApiFp(this.configuration).getFinishedPackages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getPackage(orderId: string, options?: any) {
        return AdminAppApiFp(this.configuration).getPackage(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getPackageInformations(options?: any) {
        return AdminAppApiFp(this.configuration).getPackageInformations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getPlanningStatusLogs(start?: number, end?: number, options?: any) {
        return AdminAppApiFp(this.configuration).getPlanningStatusLogs(start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getRechargingOrders(options?: any) {
        return AdminAppApiFp(this.configuration).getRechargingOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getTourOverview(options?: any) {
        return AdminAppApiFp(this.configuration).getTourOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getVehicleInformations(options?: any) {
        return AdminAppApiFp(this.configuration).getVehicleInformations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public getVehicleLocks(vehicleId: string, options?: any) {
        return AdminAppApiFp(this.configuration).getVehicleLocks(vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminModifyOrderRequest} [adminModifyOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postAdminModifyOrder(adminModifyOrderRequest?: AdminModifyOrderRequest, options?: any) {
        return AdminAppApiFp(this.configuration).postAdminModifyOrder(adminModifyOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} contactId 
     * @param {BoxRequest} [boxRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postCreateBoxRequestAdmin(contactId: string, boxRequest?: BoxRequest, options?: any) {
        return AdminAppApiFp(this.configuration).postCreateBoxRequestAdmin(contactId, boxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminDeleteOrderRequest} [adminDeleteOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postDeleteOrder(adminDeleteOrderRequest?: AdminDeleteOrderRequest, options?: any) {
        return AdminAppApiFp(this.configuration).postDeleteOrder(adminDeleteOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postDeleteToursForVehicle(vehicleId: string, options?: any) {
        return AdminAppApiFp(this.configuration).postDeleteToursForVehicle(vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LockVehicleRequest} [lockVehicleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postLockVehicle(lockVehicleRequest?: LockVehicleRequest, options?: any) {
        return AdminAppApiFp(this.configuration).postLockVehicle(lockVehicleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminRemovePackagesFromOrderRequest} [adminRemovePackagesFromOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postRemovePackagesFromOrder(adminRemovePackagesFromOrderRequest?: AdminRemovePackagesFromOrderRequest, options?: any) {
        return AdminAppApiFp(this.configuration).postRemovePackagesFromOrder(adminRemovePackagesFromOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminReturnBoxRequest} [adminReturnBoxRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postReturnBox(adminReturnBoxRequest?: AdminReturnBoxRequest, options?: any) {
        return AdminAppApiFp(this.configuration).postReturnBox(adminReturnBoxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UnlockVehicleRequest} [unlockVehicleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAppApi
     */
    public postUnlockVehicle(unlockVehicleRequest?: UnlockVehicleRequest, options?: any) {
        return AdminAppApiFp(this.configuration).postUnlockVehicle(unlockVehicleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApartmentApi - axios parameter creator
 * @export
 */
export const ApartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an Address and removes all dependencies in relating objects    ToDo´s  Remove all relations from Building(s)
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApartment: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteApartment', 'ident', ident)
            const localVarPath = `/api/data/place/apartment/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, AddressId => equal
         * @param {EfCaApartment} [efCaApartment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findApartmentsByFinder: async (efCaApartment?: EfCaApartment, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/apartment/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaApartment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST       [        {          \"ident\": \"JSt\",        }      ]
         * @summary Add a new apartment.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddApartments: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/apartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaApartment} [efCaApartment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApartment: async (efCaApartment?: EfCaApartment, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/apartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaApartment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApartmentApi - functional programming interface
 * @export
 */
export const ApartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes an Address and removes all dependencies in relating objects    ToDo´s  Remove all relations from Building(s)
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApartment(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaApartmentResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApartment(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, AddressId => equal
         * @param {EfCaApartment} [efCaApartment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findApartmentsByFinder(efCaApartment?: EfCaApartment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaApartmentResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findApartmentsByFinder(efCaApartment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST       [        {          \"ident\": \"JSt\",        }      ]
         * @summary Add a new apartment.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddApartments(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaApartmentResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddApartments(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaApartment} [efCaApartment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApartment(efCaApartment?: EfCaApartment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaApartmentResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApartment(efCaApartment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApartmentApi - factory interface
 * @export
 */
export const ApartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApartmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes an Address and removes all dependencies in relating objects    ToDo´s  Remove all relations from Building(s)
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApartment(ident: string, options?: any): AxiosPromise<EfCaApartmentResp> {
            return localVarFp.deleteApartment(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, AddressId => equal
         * @param {EfCaApartment} [efCaApartment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findApartmentsByFinder(efCaApartment?: EfCaApartment, options?: any): AxiosPromise<EfCaApartmentResp> {
            return localVarFp.findApartmentsByFinder(efCaApartment, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST       [        {          \"ident\": \"JSt\",        }      ]
         * @summary Add a new apartment.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddApartments(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaApartmentResp> {
            return localVarFp.postAddApartments(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaApartment} [efCaApartment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApartment(efCaApartment?: EfCaApartment, options?: any): AxiosPromise<EfCaApartmentResp> {
            return localVarFp.putApartment(efCaApartment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApartmentApi - object-oriented interface
 * @export
 * @class ApartmentApi
 * @extends {BaseAPI}
 */
export class ApartmentApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an Address and removes all dependencies in relating objects    ToDo´s  Remove all relations from Building(s)
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public deleteApartment(ident: string, options?: any) {
        return ApartmentApiFp(this.configuration).deleteApartment(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident, AddressId => equal
     * @param {EfCaApartment} [efCaApartment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public findApartmentsByFinder(efCaApartment?: EfCaApartment, options?: any) {
        return ApartmentApiFp(this.configuration).findApartmentsByFinder(efCaApartment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST       [        {          \"ident\": \"JSt\",        }      ]
     * @summary Add a new apartment.
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public postAddApartments(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return ApartmentApiFp(this.configuration).postAddApartments(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaApartment} [efCaApartment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public putApartment(efCaApartment?: EfCaApartment, options?: any) {
        return ApartmentApiFp(this.configuration).putApartment(efCaApartment, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageImage: async (packageOrderIdent: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageOrderIdent' is not null or undefined
            assertParamExists('getPackageImage', 'packageOrderIdent', packageOrderIdent)
            const localVarPath = `/fzi/app/packageImage/{packageOrderIdent}`
                .replace(`{${"packageOrderIdent"}}`, encodeURIComponent(String(packageOrderIdent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackageImage(packageOrderIdent: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDownload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackageImage(packageOrderIdent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackageImage(packageOrderIdent: string, options?: any): AxiosPromise<ImageDownload> {
            return localVarFp.getPackageImage(packageOrderIdent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @param {string} packageOrderIdent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getPackageImage(packageOrderIdent: string, options?: any) {
        return AppApiFp(this.configuration).getPackageImage(packageOrderIdent, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EfCaNewPasswordRequest} [efCaNewPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordWithToken: async (efCaNewPasswordRequest?: EfCaNewPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/changepwdwithtoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaNewPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaLogin} [efCaLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (efCaLogin?: EfCaLogin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaResetPasswordRequest} [efCaResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset: async (efCaResetPasswordRequest?: EfCaResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/requestpwdreset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EfCaNewPasswordRequest} [efCaNewPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePasswordWithToken(efCaNewPasswordRequest?: EfCaNewPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePasswordWithToken(efCaNewPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaLogin} [efCaLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(efCaLogin?: EfCaLogin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaLoginResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(efCaLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaResetPasswordRequest} [efCaResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPasswordReset(efCaResetPasswordRequest?: EfCaResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPasswordReset(efCaResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {EfCaNewPasswordRequest} [efCaNewPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordWithToken(efCaNewPasswordRequest?: EfCaNewPasswordRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.changePasswordWithToken(efCaNewPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaLogin} [efCaLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(efCaLogin?: EfCaLogin, options?: any): AxiosPromise<EfCaLoginResp> {
            return localVarFp.login(efCaLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaResetPasswordRequest} [efCaResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(efCaResetPasswordRequest?: EfCaResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.requestPasswordReset(efCaResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {EfCaNewPasswordRequest} [efCaNewPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changePasswordWithToken(efCaNewPasswordRequest?: EfCaNewPasswordRequest, options?: any) {
        return AuthApiFp(this.configuration).changePasswordWithToken(efCaNewPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaLogin} [efCaLogin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(efCaLogin?: EfCaLogin, options?: any) {
        return AuthApiFp(this.configuration).login(efCaLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaResetPasswordRequest} [efCaResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public requestPasswordReset(efCaResetPasswordRequest?: EfCaResetPasswordRequest, options?: any) {
        return AuthApiFp(this.configuration).requestPasswordReset(efCaResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BoxMountingDeviceApi - axios parameter creator
 * @export
 */
export const BoxMountingDeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a BoxMountingDevice (Übergabebock)    ToDo´s  Remove all relations from Building(s)  Remove all relations from Apartment(s)  Remove all relations from Contact(s)
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoxMountingDevice: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteBoxMountingDevice', 'ident', ident)
            const localVarPath = `/api/data/mount/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes all specified bock reservations
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoxMountingDeviceReservations: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/mount/slots/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all reservations for specified BoxMountingDevices in a calendar week.    In EfCaModelCollector only the parameter \"slotReservations\" is used.  <br>Role = \"BoxMountingDevice\", function = \"FindBoxMountingDeviceSlotReservations\"
         * @param {string} [date] 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBoxMountingDeviceSlotReservations: async (date?: string, efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/mount/slotreservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query all exisiting slots. The scheduled entries aren´t included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBoxMountingDeviceSlots: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/mount/slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type, MountCapacity => equal
         * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBoxMountingDevicesByFinder: async (efCaBoxMountingDevice?: EfCaBoxMountingDevice, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/mount/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaBoxMountingDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxMountingDeviceDetails: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('getBoxMountingDeviceDetails', 'ident', ident)
            const localVarPath = `/api/data/mount/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST      [      {        \"ident\": \"Test-BoxMount-001\",         \"mountCapacity\": 1,        \"type\": \"private\",        \"x\": 49.4,        \"y\": 8.5      }      ]
         * @summary Add a new \"Bock(s)\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddBoxMountingDevices: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/mount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates reservations for BoxMountingDevices, depending on free timeslots.  <br>The list  \"SlotReservationRequests\" contains the slot reservations.  Stored in DB  <br>Right = \"BoxMountingDevice\", function = \"PostBoxMountingDeviceSlotReservations\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoxMountingDeviceSlotReservations: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/mount/slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBoxMountingDevice: async (efCaBoxMountingDevice?: EfCaBoxMountingDevice, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/mount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaBoxMountingDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoxMountingDeviceApi - functional programming interface
 * @export
 */
export const BoxMountingDeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoxMountingDeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a BoxMountingDevice (Übergabebock)    ToDo´s  Remove all relations from Building(s)  Remove all relations from Apartment(s)  Remove all relations from Contact(s)
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoxMountingDevice(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoxMountingDevice(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes all specified bock reservations
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoxMountingDeviceReservations(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoxMountingDeviceReservations(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all reservations for specified BoxMountingDevices in a calendar week.    In EfCaModelCollector only the parameter \"slotReservations\" is used.  <br>Role = \"BoxMountingDevice\", function = \"FindBoxMountingDeviceSlotReservations\"
         * @param {string} [date] 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBoxMountingDeviceSlotReservations(date?: string, efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBoxMountingDeviceSlotReservations(date, efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query all exisiting slots. The scheduled entries aren´t included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBoxMountingDeviceSlots(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBoxMountingDeviceSlots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type, MountCapacity => equal
         * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBoxMountingDevicesByFinder(efCaBoxMountingDevice?: EfCaBoxMountingDevice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBoxMountingDevicesByFinder(efCaBoxMountingDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxMountingDeviceDetails(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxMountingDeviceDetails(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST      [      {        \"ident\": \"Test-BoxMount-001\",         \"mountCapacity\": 1,        \"type\": \"private\",        \"x\": 49.4,        \"y\": 8.5      }      ]
         * @summary Add a new \"Bock(s)\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddBoxMountingDevices(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddBoxMountingDevices(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates reservations for BoxMountingDevices, depending on free timeslots.  <br>The list  \"SlotReservationRequests\" contains the slot reservations.  Stored in DB  <br>Right = \"BoxMountingDevice\", function = \"PostBoxMountingDeviceSlotReservations\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBoxMountingDeviceSlotReservations(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBoxMountingDeviceSlotReservations(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBoxMountingDevice(efCaBoxMountingDevice?: EfCaBoxMountingDevice, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBoxMountingDeviceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBoxMountingDevice(efCaBoxMountingDevice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoxMountingDeviceApi - factory interface
 * @export
 */
export const BoxMountingDeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoxMountingDeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a BoxMountingDevice (Übergabebock)    ToDo´s  Remove all relations from Building(s)  Remove all relations from Apartment(s)  Remove all relations from Contact(s)
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoxMountingDevice(ident: string, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.deleteBoxMountingDevice(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes all specified bock reservations
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoxMountingDeviceReservations(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.deleteBoxMountingDeviceReservations(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all reservations for specified BoxMountingDevices in a calendar week.    In EfCaModelCollector only the parameter \"slotReservations\" is used.  <br>Role = \"BoxMountingDevice\", function = \"FindBoxMountingDeviceSlotReservations\"
         * @param {string} [date] 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBoxMountingDeviceSlotReservations(date?: string, efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.findBoxMountingDeviceSlotReservations(date, efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query all exisiting slots. The scheduled entries aren´t included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBoxMountingDeviceSlots(options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.findBoxMountingDeviceSlots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type, MountCapacity => equal
         * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBoxMountingDevicesByFinder(efCaBoxMountingDevice?: EfCaBoxMountingDevice, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.findBoxMountingDevicesByFinder(efCaBoxMountingDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxMountingDeviceDetails(ident: string, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.getBoxMountingDeviceDetails(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST      [      {        \"ident\": \"Test-BoxMount-001\",         \"mountCapacity\": 1,        \"type\": \"private\",        \"x\": 49.4,        \"y\": 8.5      }      ]
         * @summary Add a new \"Bock(s)\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddBoxMountingDevices(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.postAddBoxMountingDevices(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates reservations for BoxMountingDevices, depending on free timeslots.  <br>The list  \"SlotReservationRequests\" contains the slot reservations.  Stored in DB  <br>Right = \"BoxMountingDevice\", function = \"PostBoxMountingDeviceSlotReservations\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBoxMountingDeviceSlotReservations(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.postBoxMountingDeviceSlotReservations(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBoxMountingDevice(efCaBoxMountingDevice?: EfCaBoxMountingDevice, options?: any): AxiosPromise<EfCaBoxMountingDeviceResp> {
            return localVarFp.putBoxMountingDevice(efCaBoxMountingDevice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoxMountingDeviceApi - object-oriented interface
 * @export
 * @class BoxMountingDeviceApi
 * @extends {BaseAPI}
 */
export class BoxMountingDeviceApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a BoxMountingDevice (Übergabebock)    ToDo´s  Remove all relations from Building(s)  Remove all relations from Apartment(s)  Remove all relations from Contact(s)
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public deleteBoxMountingDevice(ident: string, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).deleteBoxMountingDevice(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes all specified bock reservations
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public deleteBoxMountingDeviceReservations(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).deleteBoxMountingDeviceReservations(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all reservations for specified BoxMountingDevices in a calendar week.    In EfCaModelCollector only the parameter \"slotReservations\" is used.  <br>Role = \"BoxMountingDevice\", function = \"FindBoxMountingDeviceSlotReservations\"
     * @param {string} [date] 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public findBoxMountingDeviceSlotReservations(date?: string, efCaModelCollector?: EfCaModelCollector, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).findBoxMountingDeviceSlotReservations(date, efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query all exisiting slots. The scheduled entries aren´t included.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public findBoxMountingDeviceSlots(options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).findBoxMountingDeviceSlots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident, Type, MountCapacity => equal
     * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public findBoxMountingDevicesByFinder(efCaBoxMountingDevice?: EfCaBoxMountingDevice, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).findBoxMountingDevicesByFinder(efCaBoxMountingDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public getBoxMountingDeviceDetails(ident: string, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).getBoxMountingDeviceDetails(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST      [      {        \"ident\": \"Test-BoxMount-001\",         \"mountCapacity\": 1,        \"type\": \"private\",        \"x\": 49.4,        \"y\": 8.5      }      ]
     * @summary Add a new \"Bock(s)\"
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public postAddBoxMountingDevices(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).postAddBoxMountingDevices(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates reservations for BoxMountingDevices, depending on free timeslots.  <br>The list  \"SlotReservationRequests\" contains the slot reservations.  Stored in DB  <br>Right = \"BoxMountingDevice\", function = \"PostBoxMountingDeviceSlotReservations\"
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public postBoxMountingDeviceSlotReservations(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).postBoxMountingDeviceSlotReservations(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaBoxMountingDevice} [efCaBoxMountingDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxMountingDeviceApi
     */
    public putBoxMountingDevice(efCaBoxMountingDevice?: EfCaBoxMountingDevice, options?: any) {
        return BoxMountingDeviceApiFp(this.configuration).putBoxMountingDevice(efCaBoxMountingDevice, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuildingApi - axios parameter creator
 * @export
 */
export const BuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteBuilding', 'ident', ident)
            const localVarPath = `/api/data/place/building/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type, AddressId => equal
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBuildingsByFinder: async (efCaBuilding?: EfCaBuilding, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/building/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaBuilding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBuilding: async (efCaBuilding?: EfCaBuilding, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/building`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaBuilding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddBuildings: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/building`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBuilding: async (efCaBuilding?: EfCaBuilding, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/building`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaBuilding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingApi - functional programming interface
 * @export
 */
export const BuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBuilding(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBuildingResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBuilding(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type, AddressId => equal
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBuildingsByFinder(efCaBuilding?: EfCaBuilding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBuildingResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBuildingsByFinder(efCaBuilding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchBuilding(efCaBuilding?: EfCaBuilding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBuildingResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchBuilding(efCaBuilding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddBuildings(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBuildingResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddBuildings(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBuilding(efCaBuilding?: EfCaBuilding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaBuildingResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBuilding(efCaBuilding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingApi - factory interface
 * @export
 */
export const BuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(ident: string, options?: any): AxiosPromise<EfCaBuildingResp> {
            return localVarFp.deleteBuilding(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type, AddressId => equal
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBuildingsByFinder(efCaBuilding?: EfCaBuilding, options?: any): AxiosPromise<EfCaBuildingResp> {
            return localVarFp.findBuildingsByFinder(efCaBuilding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchBuilding(efCaBuilding?: EfCaBuilding, options?: any): AxiosPromise<EfCaBuildingResp> {
            return localVarFp.patchBuilding(efCaBuilding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddBuildings(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaBuildingResp> {
            return localVarFp.postAddBuildings(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaBuilding} [efCaBuilding] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBuilding(efCaBuilding?: EfCaBuilding, options?: any): AxiosPromise<EfCaBuildingResp> {
            return localVarFp.putBuilding(efCaBuilding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingApi - object-oriented interface
 * @export
 * @class BuildingApi
 * @extends {BaseAPI}
 */
export class BuildingApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public deleteBuilding(ident: string, options?: any) {
        return BuildingApiFp(this.configuration).deleteBuilding(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident, Type, AddressId => equal
     * @param {EfCaBuilding} [efCaBuilding] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public findBuildingsByFinder(efCaBuilding?: EfCaBuilding, options?: any) {
        return BuildingApiFp(this.configuration).findBuildingsByFinder(efCaBuilding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaBuilding} [efCaBuilding] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public patchBuilding(efCaBuilding?: EfCaBuilding, options?: any) {
        return BuildingApiFp(this.configuration).patchBuilding(efCaBuilding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public postAddBuildings(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return BuildingApiFp(this.configuration).postAddBuildings(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaBuilding} [efCaBuilding] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public putBuilding(efCaBuilding?: EfCaBuilding, options?: any) {
        return BuildingApiFp(this.configuration).putBuilding(efCaBuilding, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChargingStationApi - axios parameter creator
 * @export
 */
export const ChargingStationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChargingStation: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteChargingStation', 'ident', ident)
            const localVarPath = `/api/data/charging/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal  Info => Regex
         * @param {EfCaChargingStation} [efCaChargingStation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChargingStationsByFinder: async (efCaChargingStation?: EfCaChargingStation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/charging/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaChargingStation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddChargingStations: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/charging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaChargingStation} [efCaChargingStation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChargingStation: async (efCaChargingStation?: EfCaChargingStation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/charging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaChargingStation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChargingStationApi - functional programming interface
 * @export
 */
export const ChargingStationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChargingStationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChargingStation(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaChargingStationResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChargingStation(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal  Info => Regex
         * @param {EfCaChargingStation} [efCaChargingStation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findChargingStationsByFinder(efCaChargingStation?: EfCaChargingStation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaChargingStationResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findChargingStationsByFinder(efCaChargingStation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddChargingStations(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaChargingStationResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddChargingStations(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaChargingStation} [efCaChargingStation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChargingStation(efCaChargingStation?: EfCaChargingStation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaChargingStationResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putChargingStation(efCaChargingStation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChargingStationApi - factory interface
 * @export
 */
export const ChargingStationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChargingStationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChargingStation(ident: string, options?: any): AxiosPromise<EfCaChargingStationResp> {
            return localVarFp.deleteChargingStation(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal  Info => Regex
         * @param {EfCaChargingStation} [efCaChargingStation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findChargingStationsByFinder(efCaChargingStation?: EfCaChargingStation, options?: any): AxiosPromise<EfCaChargingStationResp> {
            return localVarFp.findChargingStationsByFinder(efCaChargingStation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddChargingStations(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaChargingStationResp> {
            return localVarFp.postAddChargingStations(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaChargingStation} [efCaChargingStation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChargingStation(efCaChargingStation?: EfCaChargingStation, options?: any): AxiosPromise<EfCaChargingStationResp> {
            return localVarFp.putChargingStation(efCaChargingStation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChargingStationApi - object-oriented interface
 * @export
 * @class ChargingStationApi
 * @extends {BaseAPI}
 */
export class ChargingStationApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargingStationApi
     */
    public deleteChargingStation(ident: string, options?: any) {
        return ChargingStationApiFp(this.configuration).deleteChargingStation(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident, Type => equal  Info => Regex
     * @param {EfCaChargingStation} [efCaChargingStation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargingStationApi
     */
    public findChargingStationsByFinder(efCaChargingStation?: EfCaChargingStation, options?: any) {
        return ChargingStationApiFp(this.configuration).findChargingStationsByFinder(efCaChargingStation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargingStationApi
     */
    public postAddChargingStations(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return ChargingStationApiFp(this.configuration).postAddChargingStations(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaChargingStation} [efCaChargingStation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChargingStationApi
     */
    public putChargingStation(efCaChargingStation?: EfCaChargingStation, options?: any) {
        return ChargingStationApiFp(this.configuration).putChargingStation(efCaChargingStation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteContact', 'ident', ident)
            const localVarPath = `/api/data/place/contact/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, email => equal  Firstname, Lastname => Regex
         * @param {EfCaContact} [efCaContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByFinder: async (efCaContact?: EfCaContact, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/contact/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddContacts: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaContact} [efCaContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContact: async (efCaContact?: EfCaContact, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/place/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaContactResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, email => equal  Firstname, Lastname => Regex
         * @param {EfCaContact} [efCaContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findContactsByFinder(efCaContact?: EfCaContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaContactResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findContactsByFinder(efCaContact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddContacts(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaContactResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddContacts(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaContact} [efCaContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContact(efCaContact?: EfCaContact, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaContactResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContact(efCaContact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(ident: string, options?: any): AxiosPromise<EfCaContactResp> {
            return localVarFp.deleteContact(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, email => equal  Firstname, Lastname => Regex
         * @param {EfCaContact} [efCaContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findContactsByFinder(efCaContact?: EfCaContact, options?: any): AxiosPromise<EfCaContactResp> {
            return localVarFp.findContactsByFinder(efCaContact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddContacts(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaContactResp> {
            return localVarFp.postAddContacts(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaContact} [efCaContact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContact(efCaContact?: EfCaContact, options?: any): AxiosPromise<EfCaContactResp> {
            return localVarFp.putContact(efCaContact, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public deleteContact(ident: string, options?: any) {
        return ContactApiFp(this.configuration).deleteContact(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident, email => equal  Firstname, Lastname => Regex
     * @param {EfCaContact} [efCaContact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public findContactsByFinder(efCaContact?: EfCaContact, options?: any) {
        return ContactApiFp(this.configuration).findContactsByFinder(efCaContact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public postAddContacts(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return ContactApiFp(this.configuration).postAddContacts(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaContact} [efCaContact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public putContact(efCaContact?: EfCaContact, options?: any) {
        return ContactApiFp(this.configuration).putContact(efCaContact, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepotEventApi - axios parameter creator
 * @export
 */
export const DepotEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintJobs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/printJobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {ImageUpload} [imageUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddImageToPackage: async (packageOrderIdent: string, imageUpload?: ImageUpload, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageOrderIdent' is not null or undefined
            assertParamExists('postAddImageToPackage', 'packageOrderIdent', packageOrderIdent)
            const localVarPath = `/fzi/event/depot/addImageToPackage/{packageOrderIdent}`
                .replace(`{${"packageOrderIdent"}}`, encodeURIComponent(String(packageOrderIdent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUpload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {PackageInformation} [packageInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddInformationToOutboundPackage: async (packageOrderIdent: string, packageInformation?: PackageInformation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageOrderIdent' is not null or undefined
            assertParamExists('postAddInformationToOutboundPackage', 'packageOrderIdent', packageOrderIdent)
            const localVarPath = `/fzi/event/depot/addInformationToOutboundPackage/{packageOrderIdent}`
                .replace(`{${"packageOrderIdent"}}`, encodeURIComponent(String(packageOrderIdent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssignBoxToOrderEvent} [assignBoxToOrderEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAssignBoxToOrder: async (assignBoxToOrderEvent?: AssignBoxToOrderEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/assignBoxToOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignBoxToOrderEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckCustomerRequest} [checkCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckCustomer: async (checkCustomerRequest?: CheckCustomerRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/checkCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteCommissioningEvent} [completeCommissioningEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteCommissioning: async (completeCommissioningEvent?: CompleteCommissioningEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/completeCommissioning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeCommissioningEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteOutboundParcelStorageEvent} [completeOutboundParcelStorageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteOutboundParcelStorage: async (completeOutboundParcelStorageEvent?: CompleteOutboundParcelStorageEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/completeOutboundParcelStorage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeOutboundParcelStorageEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteParcelStorageEvent} [completeParcelStorageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteParcelStorage: async (completeParcelStorageEvent?: CompleteParcelStorageEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/completeParcelStorage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeParcelStorageEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmKepPickupEvent} [confirmKepPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmKepPickup: async (confirmKepPickupEvent?: ConfirmKepPickupEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/confirmKepPickup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmKepPickupEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmSelfPickupEvent} [confirmSelfPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmSelfPickup: async (confirmSelfPickupEvent?: ConfirmSelfPickupEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/confirmSelfPickup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmSelfPickupEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeactivateBoxEvent} [deactivateBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeactivateBox: async (deactivateBoxEvent?: DeactivateBoxEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/deactivateBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deactivateBoxEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} extId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFinishPrintJob: async (extId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'extId' is not null or undefined
            assertParamExists('postFinishPrintJob', 'extId', extId)
            const localVarPath = `/fzi/event/depot/finishPrintJob/{extId}`
                .replace(`{${"extId"}}`, encodeURIComponent(String(extId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OutboundParcelInformation} [outboundParcelInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabelOutboundParcel: async (outboundParcelInformation?: OutboundParcelInformation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/labelOutboundParcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(outboundParcelInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LabelParcelEvent} [labelParcelEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabelParcel: async (labelParcelEvent?: LabelParcelEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/labelParcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labelParcelEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlaceBoxOnMountEvent} [placeBoxOnMountEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlaceBoxOnMount: async (placeBoxOnMountEvent?: PlaceBoxOnMountEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/placeBoxOnMount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(placeBoxOnMountEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepotSendPackageEvent} [depotSendPackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlacePackageInBox: async (depotSendPackageEvent?: DepotSendPackageEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/placePackageInBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(depotSendPackageEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepotReceivePackageEvent} [depotReceivePackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivePackageFromBox: async (depotReceivePackageEvent?: DepotReceivePackageEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/receivePackageFromBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(depotReceivePackageEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PackageInformation} [packageInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegisterPackage: async (packageInformation?: PackageInformation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/registerPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepotRemoveWasteEvent} [depotRemoveWasteEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRemoveWasteFromBox: async (depotRemoveWasteEvent?: DepotRemoveWasteEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/removeWasteFromBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(depotRemoveWasteEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StoreBoxEvent} [storeBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStoreBox: async (storeBoxEvent?: StoreBoxEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/depot/storeBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeBoxEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepotEventApi - functional programming interface
 * @export
 */
export const DepotEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepotEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrintJobs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintJob>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {ImageUpload} [imageUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddImageToPackage(packageOrderIdent: string, imageUpload?: ImageUpload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddImageToPackage(packageOrderIdent, imageUpload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {PackageInformation} [packageInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddInformationToOutboundPackage(packageOrderIdent: string, packageInformation?: PackageInformation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddInformationToOutboundPackage(packageOrderIdent, packageInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AssignBoxToOrderEvent} [assignBoxToOrderEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAssignBoxToOrder(assignBoxToOrderEvent?: AssignBoxToOrderEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAssignBoxToOrder(assignBoxToOrderEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckCustomerRequest} [checkCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCheckCustomer(checkCustomerRequest?: CheckCustomerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCheckCustomer(checkCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompleteCommissioningEvent} [completeCommissioningEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompleteCommissioning(completeCommissioningEvent?: CompleteCommissioningEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompleteCommissioning(completeCommissioningEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompleteOutboundParcelStorageEvent} [completeOutboundParcelStorageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompleteOutboundParcelStorage(completeOutboundParcelStorageEvent?: CompleteOutboundParcelStorageEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompleteOutboundParcelStorage(completeOutboundParcelStorageEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompleteParcelStorageEvent} [completeParcelStorageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompleteParcelStorage(completeParcelStorageEvent?: CompleteParcelStorageEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompleteParcelStorage(completeParcelStorageEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfirmKepPickupEvent} [confirmKepPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfirmKepPickup(confirmKepPickupEvent?: ConfirmKepPickupEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfirmKepPickup(confirmKepPickupEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfirmSelfPickupEvent} [confirmSelfPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfirmSelfPickup(confirmSelfPickupEvent?: ConfirmSelfPickupEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfirmSelfPickup(confirmSelfPickupEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeactivateBoxEvent} [deactivateBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeactivateBox(deactivateBoxEvent?: DeactivateBoxEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeactivateBox(deactivateBoxEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} extId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFinishPrintJob(extId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFinishPrintJob(extId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OutboundParcelInformation} [outboundParcelInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLabelOutboundParcel(outboundParcelInformation?: OutboundParcelInformation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterPackageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLabelOutboundParcel(outboundParcelInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LabelParcelEvent} [labelParcelEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLabelParcel(labelParcelEvent?: LabelParcelEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLabelParcel(labelParcelEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PlaceBoxOnMountEvent} [placeBoxOnMountEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPlaceBoxOnMount(placeBoxOnMountEvent?: PlaceBoxOnMountEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPlaceBoxOnMount(placeBoxOnMountEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepotSendPackageEvent} [depotSendPackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPlacePackageInBox(depotSendPackageEvent?: DepotSendPackageEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPlacePackageInBox(depotSendPackageEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepotReceivePackageEvent} [depotReceivePackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceivePackageFromBox(depotReceivePackageEvent?: DepotReceivePackageEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceivePackageFromBox(depotReceivePackageEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PackageInformation} [packageInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRegisterPackage(packageInformation?: PackageInformation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterPackageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRegisterPackage(packageInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepotRemoveWasteEvent} [depotRemoveWasteEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRemoveWasteFromBox(depotRemoveWasteEvent?: DepotRemoveWasteEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRemoveWasteFromBox(depotRemoveWasteEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StoreBoxEvent} [storeBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStoreBox(storeBoxEvent?: StoreBoxEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStoreBox(storeBoxEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepotEventApi - factory interface
 * @export
 */
export const DepotEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepotEventApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrintJobs(options?: any): AxiosPromise<Array<PrintJob>> {
            return localVarFp.getPrintJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {ImageUpload} [imageUpload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddImageToPackage(packageOrderIdent: string, imageUpload?: ImageUpload, options?: any): AxiosPromise<Response> {
            return localVarFp.postAddImageToPackage(packageOrderIdent, imageUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageOrderIdent 
         * @param {PackageInformation} [packageInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddInformationToOutboundPackage(packageOrderIdent: string, packageInformation?: PackageInformation, options?: any): AxiosPromise<Response> {
            return localVarFp.postAddInformationToOutboundPackage(packageOrderIdent, packageInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssignBoxToOrderEvent} [assignBoxToOrderEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAssignBoxToOrder(assignBoxToOrderEvent?: AssignBoxToOrderEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postAssignBoxToOrder(assignBoxToOrderEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckCustomerRequest} [checkCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckCustomer(checkCustomerRequest?: CheckCustomerRequest, options?: any): AxiosPromise<CheckCustomerResponse> {
            return localVarFp.postCheckCustomer(checkCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteCommissioningEvent} [completeCommissioningEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteCommissioning(completeCommissioningEvent?: CompleteCommissioningEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postCompleteCommissioning(completeCommissioningEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteOutboundParcelStorageEvent} [completeOutboundParcelStorageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteOutboundParcelStorage(completeOutboundParcelStorageEvent?: CompleteOutboundParcelStorageEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postCompleteOutboundParcelStorage(completeOutboundParcelStorageEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteParcelStorageEvent} [completeParcelStorageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteParcelStorage(completeParcelStorageEvent?: CompleteParcelStorageEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postCompleteParcelStorage(completeParcelStorageEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmKepPickupEvent} [confirmKepPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmKepPickup(confirmKepPickupEvent?: ConfirmKepPickupEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postConfirmKepPickup(confirmKepPickupEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmSelfPickupEvent} [confirmSelfPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmSelfPickup(confirmSelfPickupEvent?: ConfirmSelfPickupEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postConfirmSelfPickup(confirmSelfPickupEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeactivateBoxEvent} [deactivateBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeactivateBox(deactivateBoxEvent?: DeactivateBoxEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postDeactivateBox(deactivateBoxEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} extId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFinishPrintJob(extId: string, options?: any): AxiosPromise<Response> {
            return localVarFp.postFinishPrintJob(extId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OutboundParcelInformation} [outboundParcelInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabelOutboundParcel(outboundParcelInformation?: OutboundParcelInformation, options?: any): AxiosPromise<RegisterPackageResponse> {
            return localVarFp.postLabelOutboundParcel(outboundParcelInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LabelParcelEvent} [labelParcelEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabelParcel(labelParcelEvent?: LabelParcelEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postLabelParcel(labelParcelEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlaceBoxOnMountEvent} [placeBoxOnMountEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlaceBoxOnMount(placeBoxOnMountEvent?: PlaceBoxOnMountEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postPlaceBoxOnMount(placeBoxOnMountEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepotSendPackageEvent} [depotSendPackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlacePackageInBox(depotSendPackageEvent?: DepotSendPackageEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postPlacePackageInBox(depotSendPackageEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepotReceivePackageEvent} [depotReceivePackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivePackageFromBox(depotReceivePackageEvent?: DepotReceivePackageEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postReceivePackageFromBox(depotReceivePackageEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PackageInformation} [packageInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegisterPackage(packageInformation?: PackageInformation, options?: any): AxiosPromise<RegisterPackageResponse> {
            return localVarFp.postRegisterPackage(packageInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepotRemoveWasteEvent} [depotRemoveWasteEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRemoveWasteFromBox(depotRemoveWasteEvent?: DepotRemoveWasteEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postRemoveWasteFromBox(depotRemoveWasteEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StoreBoxEvent} [storeBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStoreBox(storeBoxEvent?: StoreBoxEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postStoreBox(storeBoxEvent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepotEventApi - object-oriented interface
 * @export
 * @class DepotEventApi
 * @extends {BaseAPI}
 */
export class DepotEventApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public getPrintJobs(options?: any) {
        return DepotEventApiFp(this.configuration).getPrintJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageOrderIdent 
     * @param {ImageUpload} [imageUpload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postAddImageToPackage(packageOrderIdent: string, imageUpload?: ImageUpload, options?: any) {
        return DepotEventApiFp(this.configuration).postAddImageToPackage(packageOrderIdent, imageUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageOrderIdent 
     * @param {PackageInformation} [packageInformation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postAddInformationToOutboundPackage(packageOrderIdent: string, packageInformation?: PackageInformation, options?: any) {
        return DepotEventApiFp(this.configuration).postAddInformationToOutboundPackage(packageOrderIdent, packageInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssignBoxToOrderEvent} [assignBoxToOrderEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postAssignBoxToOrder(assignBoxToOrderEvent?: AssignBoxToOrderEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postAssignBoxToOrder(assignBoxToOrderEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckCustomerRequest} [checkCustomerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postCheckCustomer(checkCustomerRequest?: CheckCustomerRequest, options?: any) {
        return DepotEventApiFp(this.configuration).postCheckCustomer(checkCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompleteCommissioningEvent} [completeCommissioningEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postCompleteCommissioning(completeCommissioningEvent?: CompleteCommissioningEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postCompleteCommissioning(completeCommissioningEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompleteOutboundParcelStorageEvent} [completeOutboundParcelStorageEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postCompleteOutboundParcelStorage(completeOutboundParcelStorageEvent?: CompleteOutboundParcelStorageEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postCompleteOutboundParcelStorage(completeOutboundParcelStorageEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompleteParcelStorageEvent} [completeParcelStorageEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postCompleteParcelStorage(completeParcelStorageEvent?: CompleteParcelStorageEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postCompleteParcelStorage(completeParcelStorageEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfirmKepPickupEvent} [confirmKepPickupEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postConfirmKepPickup(confirmKepPickupEvent?: ConfirmKepPickupEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postConfirmKepPickup(confirmKepPickupEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfirmSelfPickupEvent} [confirmSelfPickupEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postConfirmSelfPickup(confirmSelfPickupEvent?: ConfirmSelfPickupEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postConfirmSelfPickup(confirmSelfPickupEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeactivateBoxEvent} [deactivateBoxEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postDeactivateBox(deactivateBoxEvent?: DeactivateBoxEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postDeactivateBox(deactivateBoxEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} extId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postFinishPrintJob(extId: string, options?: any) {
        return DepotEventApiFp(this.configuration).postFinishPrintJob(extId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OutboundParcelInformation} [outboundParcelInformation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postLabelOutboundParcel(outboundParcelInformation?: OutboundParcelInformation, options?: any) {
        return DepotEventApiFp(this.configuration).postLabelOutboundParcel(outboundParcelInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LabelParcelEvent} [labelParcelEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postLabelParcel(labelParcelEvent?: LabelParcelEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postLabelParcel(labelParcelEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlaceBoxOnMountEvent} [placeBoxOnMountEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postPlaceBoxOnMount(placeBoxOnMountEvent?: PlaceBoxOnMountEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postPlaceBoxOnMount(placeBoxOnMountEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepotSendPackageEvent} [depotSendPackageEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postPlacePackageInBox(depotSendPackageEvent?: DepotSendPackageEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postPlacePackageInBox(depotSendPackageEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepotReceivePackageEvent} [depotReceivePackageEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postReceivePackageFromBox(depotReceivePackageEvent?: DepotReceivePackageEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postReceivePackageFromBox(depotReceivePackageEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PackageInformation} [packageInformation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postRegisterPackage(packageInformation?: PackageInformation, options?: any) {
        return DepotEventApiFp(this.configuration).postRegisterPackage(packageInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepotRemoveWasteEvent} [depotRemoveWasteEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postRemoveWasteFromBox(depotRemoveWasteEvent?: DepotRemoveWasteEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postRemoveWasteFromBox(depotRemoveWasteEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoreBoxEvent} [storeBoxEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepotEventApi
     */
    public postStoreBox(storeBoxEvent?: StoreBoxEvent, options?: any) {
        return DepotEventApiFp(this.configuration).postStoreBox(storeBoxEvent, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntraLogAppApi - axios parameter creator
 * @export
 */
export const IntraLogAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParcels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/allParcels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBarNumbers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/appBarNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxInformations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/boxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxMountingDevices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/boxMountingDevices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commissioningId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComissioning: async (commissioningId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissioningId' is not null or undefined
            assertParamExists('getComissioning', 'commissioningId', commissioningId)
            const localVarPath = `/fzi/app/intralog/commissioning/{commissioningId}`
                .replace(`{${"commissioningId"}}`, encodeURIComponent(String(commissioningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComissionings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/commissioning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomingBoxes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/incomingBoxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKepParcels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/kepParcels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundParcels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/outboundParcels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/parcels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelfPickupParcelsForContact: async (contactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('getSelfPickupParcelsForContact', 'contactId', contactId)
            const localVarPath = `/fzi/app/intralog/selfPickupParcelsForContact/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitionParcels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/transitionParcels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehousePlaces: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/warehousePlaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCleanupIntralogData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/cleanupIntralogData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteTestCommissioning: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/test/completeCommissioning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteTestCustomerStops: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/test/completeCustomerStops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} efeuPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteCreatedPackageOrder: async (efeuPackageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'efeuPackageId' is not null or undefined
            assertParamExists('postDeleteCreatedPackageOrder', 'efeuPackageId', efeuPackageId)
            const localVarPath = `/fzi/app/intralog/deleteCreatedPackageOrder/{efeuPackageId}`
                .replace(`{${"efeuPackageId"}}`, encodeURIComponent(String(efeuPackageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenBoxIntra: async (boxId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxId' is not null or undefined
            assertParamExists('postOpenBoxIntra', 'boxId', boxId)
            const localVarPath = `/fzi/app/intralog/openBox/{boxId}`
                .replace(`{${"boxId"}}`, encodeURIComponent(String(boxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetIntraDemo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/resetIntraDemo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetTestData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/test/resetData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTestRegisterPackages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/intralog/test/registerPackages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntraLogAppApi - functional programming interface
 * @export
 */
export const IntraLogAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntraLogAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllParcels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parcel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllParcels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppBarNumbers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppBarNumbers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppBarNumbers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxInformations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoxInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxInformations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxMountingDevices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MountingDevice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxMountingDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commissioningId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComissioning(commissioningId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Commission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComissioning(commissioningId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComissionings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Commission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComissionings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contact>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIncomingBoxes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncomingBox>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIncomingBoxes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKepParcels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parcel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKepParcels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundParcels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parcel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOutboundParcels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParcels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parcel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParcels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelfPickupParcelsForContact(contactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parcel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelfPickupParcelsForContact(contactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransitionParcels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parcel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransitionParcels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarehousePlaces(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehousePlace>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarehousePlaces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCleanupIntralogData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCleanupIntralogData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompleteTestCommissioning(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompleteTestCommissioning(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompleteTestCustomerStops(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompleteTestCustomerStops(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} efeuPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeleteCreatedPackageOrder(efeuPackageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeleteCreatedPackageOrder(efeuPackageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpenBoxIntra(boxId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOpenBoxIntra(boxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postResetIntraDemo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postResetIntraDemo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postResetTestData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postResetTestData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTestRegisterPackages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTestRegisterPackages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntraLogAppApi - factory interface
 * @export
 */
export const IntraLogAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntraLogAppApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParcels(options?: any): AxiosPromise<Array<Parcel>> {
            return localVarFp.getAllParcels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppBarNumbers(options?: any): AxiosPromise<AppBarNumbers> {
            return localVarFp.getAppBarNumbers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxInformations(options?: any): AxiosPromise<Array<BoxInformation>> {
            return localVarFp.getBoxInformations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxMountingDevices(options?: any): AxiosPromise<Array<MountingDevice>> {
            return localVarFp.getBoxMountingDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commissioningId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComissioning(commissioningId: string, options?: any): AxiosPromise<Commission> {
            return localVarFp.getComissioning(commissioningId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComissionings(options?: any): AxiosPromise<Array<Commission>> {
            return localVarFp.getComissionings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(options?: any): AxiosPromise<Array<Contact>> {
            return localVarFp.getContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncomingBoxes(options?: any): AxiosPromise<Array<IncomingBox>> {
            return localVarFp.getIncomingBoxes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKepParcels(options?: any): AxiosPromise<Array<Parcel>> {
            return localVarFp.getKepParcels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundParcels(options?: any): AxiosPromise<Array<Parcel>> {
            return localVarFp.getOutboundParcels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParcels(options?: any): AxiosPromise<Array<Parcel>> {
            return localVarFp.getParcels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} contactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelfPickupParcelsForContact(contactId: string, options?: any): AxiosPromise<Array<Parcel>> {
            return localVarFp.getSelfPickupParcelsForContact(contactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransitionParcels(options?: any): AxiosPromise<Array<Parcel>> {
            return localVarFp.getTransitionParcels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehousePlaces(options?: any): AxiosPromise<Array<WarehousePlace>> {
            return localVarFp.getWarehousePlaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCleanupIntralogData(options?: any): AxiosPromise<Response> {
            return localVarFp.postCleanupIntralogData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteTestCommissioning(options?: any): AxiosPromise<Response> {
            return localVarFp.postCompleteTestCommissioning(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteTestCustomerStops(options?: any): AxiosPromise<Response> {
            return localVarFp.postCompleteTestCustomerStops(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} efeuPackageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteCreatedPackageOrder(efeuPackageId: string, options?: any): AxiosPromise<Response> {
            return localVarFp.postDeleteCreatedPackageOrder(efeuPackageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenBoxIntra(boxId: string, options?: any): AxiosPromise<Response> {
            return localVarFp.postOpenBoxIntra(boxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetIntraDemo(options?: any): AxiosPromise<Response> {
            return localVarFp.postResetIntraDemo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetTestData(options?: any): AxiosPromise<Response> {
            return localVarFp.postResetTestData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTestRegisterPackages(options?: any): AxiosPromise<Response> {
            return localVarFp.postTestRegisterPackages(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntraLogAppApi - object-oriented interface
 * @export
 * @class IntraLogAppApi
 * @extends {BaseAPI}
 */
export class IntraLogAppApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getAllParcels(options?: any) {
        return IntraLogAppApiFp(this.configuration).getAllParcels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getAppBarNumbers(options?: any) {
        return IntraLogAppApiFp(this.configuration).getAppBarNumbers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getBoxInformations(options?: any) {
        return IntraLogAppApiFp(this.configuration).getBoxInformations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getBoxMountingDevices(options?: any) {
        return IntraLogAppApiFp(this.configuration).getBoxMountingDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} commissioningId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getComissioning(commissioningId: string, options?: any) {
        return IntraLogAppApiFp(this.configuration).getComissioning(commissioningId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getComissionings(options?: any) {
        return IntraLogAppApiFp(this.configuration).getComissionings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getContacts(options?: any) {
        return IntraLogAppApiFp(this.configuration).getContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getIncomingBoxes(options?: any) {
        return IntraLogAppApiFp(this.configuration).getIncomingBoxes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getKepParcels(options?: any) {
        return IntraLogAppApiFp(this.configuration).getKepParcels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getOutboundParcels(options?: any) {
        return IntraLogAppApiFp(this.configuration).getOutboundParcels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getParcels(options?: any) {
        return IntraLogAppApiFp(this.configuration).getParcels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} contactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getSelfPickupParcelsForContact(contactId: string, options?: any) {
        return IntraLogAppApiFp(this.configuration).getSelfPickupParcelsForContact(contactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getTransitionParcels(options?: any) {
        return IntraLogAppApiFp(this.configuration).getTransitionParcels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public getWarehousePlaces(options?: any) {
        return IntraLogAppApiFp(this.configuration).getWarehousePlaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postCleanupIntralogData(options?: any) {
        return IntraLogAppApiFp(this.configuration).postCleanupIntralogData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postCompleteTestCommissioning(options?: any) {
        return IntraLogAppApiFp(this.configuration).postCompleteTestCommissioning(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postCompleteTestCustomerStops(options?: any) {
        return IntraLogAppApiFp(this.configuration).postCompleteTestCustomerStops(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} efeuPackageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postDeleteCreatedPackageOrder(efeuPackageId: string, options?: any) {
        return IntraLogAppApiFp(this.configuration).postDeleteCreatedPackageOrder(efeuPackageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} boxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postOpenBoxIntra(boxId: string, options?: any) {
        return IntraLogAppApiFp(this.configuration).postOpenBoxIntra(boxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postResetIntraDemo(options?: any) {
        return IntraLogAppApiFp(this.configuration).postResetIntraDemo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postResetTestData(options?: any) {
        return IntraLogAppApiFp(this.configuration).postResetTestData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntraLogAppApi
     */
    public postTestRegisterPackages(options?: any) {
        return IntraLogAppApiFp(this.configuration).postTestRegisterPackages(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KepApi - axios parameter creator
 * @export
 */
export const KepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLogisticServiceProvider: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/kep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogisticServiceProviders: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteLogisticServiceProviders', 'ident', ident)
            const localVarPath = `/api/data/kep/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident => equal  Name => Regex
         * @param {EfCaServiceProvider} [efCaServiceProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLogisticServiceProvidersByFinder: async (efCaServiceProvider?: EfCaServiceProvider, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/kep/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaServiceProvider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaServiceProvider} [efCaServiceProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLogisticServiceProvider: async (efCaServiceProvider?: EfCaServiceProvider, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/kep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaServiceProvider, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KepApi - functional programming interface
 * @export
 */
export const KepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KepApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLogisticServiceProvider(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaKepProviderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLogisticServiceProvider(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLogisticServiceProviders(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaKepProviderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLogisticServiceProviders(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident => equal  Name => Regex
         * @param {EfCaServiceProvider} [efCaServiceProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLogisticServiceProvidersByFinder(efCaServiceProvider?: EfCaServiceProvider, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaKepProviderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLogisticServiceProvidersByFinder(efCaServiceProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaServiceProvider} [efCaServiceProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLogisticServiceProvider(efCaServiceProvider?: EfCaServiceProvider, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaKepProviderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLogisticServiceProvider(efCaServiceProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KepApi - factory interface
 * @export
 */
export const KepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KepApiFp(configuration)
    return {
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLogisticServiceProvider(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaKepProviderResp> {
            return localVarFp.addLogisticServiceProvider(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogisticServiceProviders(ident: string, options?: any): AxiosPromise<EfCaKepProviderResp> {
            return localVarFp.deleteLogisticServiceProviders(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident => equal  Name => Regex
         * @param {EfCaServiceProvider} [efCaServiceProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLogisticServiceProvidersByFinder(efCaServiceProvider?: EfCaServiceProvider, options?: any): AxiosPromise<EfCaKepProviderResp> {
            return localVarFp.findLogisticServiceProvidersByFinder(efCaServiceProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaServiceProvider} [efCaServiceProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLogisticServiceProvider(efCaServiceProvider?: EfCaServiceProvider, options?: any): AxiosPromise<EfCaKepProviderResp> {
            return localVarFp.putLogisticServiceProvider(efCaServiceProvider, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KepApi - object-oriented interface
 * @export
 * @class KepApi
 * @extends {BaseAPI}
 */
export class KepApi extends BaseAPI {
    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KepApi
     */
    public addLogisticServiceProvider(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return KepApiFp(this.configuration).addLogisticServiceProvider(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KepApi
     */
    public deleteLogisticServiceProviders(ident: string, options?: any) {
        return KepApiFp(this.configuration).deleteLogisticServiceProviders(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident => equal  Name => Regex
     * @param {EfCaServiceProvider} [efCaServiceProvider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KepApi
     */
    public findLogisticServiceProvidersByFinder(efCaServiceProvider?: EfCaServiceProvider, options?: any) {
        return KepApiFp(this.configuration).findLogisticServiceProvidersByFinder(efCaServiceProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaServiceProvider} [efCaServiceProvider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KepApi
     */
    public putLogisticServiceProvider(efCaServiceProvider?: EfCaServiceProvider, options?: any) {
        return KepApiFp(this.configuration).putLogisticServiceProvider(efCaServiceProvider, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary <br>Wenn die Liste der EfCaImageDocumentation gesetzt ist, werden diese in der Collection gelöscht und              in den entsprechenden EfCaOrder(s) die Referenzen entfernt.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderImages: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/order/bmp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrders: async (ident?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ident !== undefined) {
                localVarQueryParameter['ident'] = ident;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebApiOrders: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/ro/delorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaOrder} [efCaOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrdersByFinder: async (efCaOrder?: EfCaOrder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/order/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    Supported finders      \"Idents\" as array of \"EfCaOrder.Ident\"            \"EfCaImageDocumentation\" {         \"Ident\" => \"equal\",         \"Order\" => \"equal\",         \"Description\" => \"equal\"      }
         * @summary Uses the \"Idents\" and the \"ImageDocumentation\" object as finder(s) but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderImages: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/order/bmp/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrderImages: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/order/bmp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrders: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddWebApiOrder: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/ro/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaOrder} [efCaOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrder: async (efCaOrder?: EfCaOrder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary <br>Wenn die Liste der EfCaImageDocumentation gesetzt ist, werden diese in der Collection gelöscht und              in den entsprechenden EfCaOrder(s) die Referenzen entfernt.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrderImages(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrders(ident?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrders(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebApiOrders(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebApiOrders(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaOrder} [efCaOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOrdersByFinder(efCaOrder?: EfCaOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOrdersByFinder(efCaOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    Supported finders      \"Idents\" as array of \"EfCaOrder.Ident\"            \"EfCaImageDocumentation\" {         \"Ident\" => \"equal\",         \"Order\" => \"equal\",         \"Description\" => \"equal\"      }
         * @summary Uses the \"Idents\" and the \"ImageDocumentation\" object as finder(s) but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderImages(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddOrderImages(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddOrders(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddOrders(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddWebApiOrder(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddWebApiOrder(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaOrder} [efCaOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOrder(efCaOrder?: EfCaOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaOrderResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOrder(efCaOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary <br>Wenn die Liste der EfCaImageDocumentation gesetzt ist, werden diese in der Collection gelöscht und              in den entsprechenden EfCaOrder(s) die Referenzen entfernt.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.deleteOrderImages(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ident] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrders(ident?: string, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.deleteOrders(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebApiOrders(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.deleteWebApiOrders(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaOrder} [efCaOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrdersByFinder(efCaOrder?: EfCaOrder, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.findOrdersByFinder(efCaOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    Supported finders      \"Idents\" as array of \"EfCaOrder.Ident\"            \"EfCaImageDocumentation\" {         \"Ident\" => \"equal\",         \"Order\" => \"equal\",         \"Description\" => \"equal\"      }
         * @summary Uses the \"Idents\" and the \"ImageDocumentation\" object as finder(s) but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.getOrderImages(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.postAddOrderImages(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrders(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.postAddOrders(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddWebApiOrder(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.postAddWebApiOrder(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaOrder} [efCaOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrder(efCaOrder?: EfCaOrder, options?: any): AxiosPromise<EfCaOrderResp> {
            return localVarFp.putOrder(efCaOrder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary <br>Wenn die Liste der EfCaImageDocumentation gesetzt ist, werden diese in der Collection gelöscht und              in den entsprechenden EfCaOrder(s) die Referenzen entfernt.
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return OrderApiFp(this.configuration).deleteOrderImages(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ident] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteOrders(ident?: string, options?: any) {
        return OrderApiFp(this.configuration).deleteOrders(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteWebApiOrders(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return OrderApiFp(this.configuration).deleteWebApiOrders(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaOrder} [efCaOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public findOrdersByFinder(efCaOrder?: EfCaOrder, options?: any) {
        return OrderApiFp(this.configuration).findOrdersByFinder(efCaOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    Supported finders      \"Idents\" as array of \"EfCaOrder.Ident\"            \"EfCaImageDocumentation\" {         \"Ident\" => \"equal\",         \"Order\" => \"equal\",         \"Description\" => \"equal\"      }
     * @summary Uses the \"Idents\" and the \"ImageDocumentation\" object as finder(s) but not all parameters are supported. Refer to the more detailled description below.
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return OrderApiFp(this.configuration).getOrderImages(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postAddOrderImages(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return OrderApiFp(this.configuration).postAddOrderImages(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postAddOrders(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return OrderApiFp(this.configuration).postAddOrders(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public postAddWebApiOrder(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return OrderApiFp(this.configuration).postAddWebApiOrder(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaOrder} [efCaOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public putOrder(efCaOrder?: EfCaOrder, options?: any) {
        return OrderApiFp(this.configuration).putOrder(efCaOrder, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProcessMgmtApi - axios parameter creator
 * @export
 */
export const ProcessMgmtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} extId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation: async (extId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'extId' is not null or undefined
            assertParamExists('deleteReservation', 'extId', extId)
            const localVarPath = `/fzi/process/reservations/delete/{extId}`
                .replace(`{${"extId"}}`, encodeURIComponent(String(extId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [planningId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executePlanning: async (planningId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/planning/exec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (planningId !== undefined) {
                localVarQueryParameter['planningId'] = planningId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixTours: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/planning/exec/fixtours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReservations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/process/reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllToursForVehicle: async (vehicleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('getAllToursForVehicle', 'vehicleId', vehicleId)
            const localVarPath = `/fzi/process/trips/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationsForMount: async (mountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mountId' is not null or undefined
            assertParamExists('getReservationsForMount', 'mountId', mountId)
            const localVarPath = `/fzi/process/reservations/{mountId}`
                .replace(`{${"mountId"}}`, encodeURIComponent(String(mountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MountReservation} [mountReservation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddReservation: async (mountReservation?: MountReservation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/process/reservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mountReservation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteAllReservations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/process/reservations/deleteAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SimulationConfig} [simulationConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSimulationConfig: async (simulationConfig?: SimulationConfig, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/process/evaluation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simulationConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStartPlanning: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/process/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUnfixAllTrips: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/process/trips/unfixAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarehouseLog} [warehouseLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWarehouseLog: async (warehouseLog?: WarehouseLog, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/process/warehouseLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(warehouseLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseTours: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/planning/exec/releasetours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessMgmtApi - functional programming interface
 * @export
 */
export const ProcessMgmtApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessMgmtApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} extId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReservation(extId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReservation(extId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [planningId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executePlanning(planningId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaPlanningResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executePlanning(planningId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixTours(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaPlanningResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixTours(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllReservations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MountReservation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllReservations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllToursForVehicle(vehicleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllToursForVehicle(vehicleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReservationsForMount(mountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MountReservation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReservationsForMount(mountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MountReservation} [mountReservation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddReservation(mountReservation?: MountReservation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MountReservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddReservation(mountReservation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeleteAllReservations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeleteAllReservations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SimulationConfig} [simulationConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSimulationConfig(simulationConfig?: SimulationConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSimulationConfig(simulationConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStartPlanning(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStartPlanning(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUnfixAllTrips(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUnfixAllTrips(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarehouseLog} [warehouseLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWarehouseLog(warehouseLog?: WarehouseLog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWarehouseLog(warehouseLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseTours(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaPlanningResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.releaseTours(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessMgmtApi - factory interface
 * @export
 */
export const ProcessMgmtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessMgmtApiFp(configuration)
    return {
        /**
         * 
         * @param {string} extId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReservation(extId: string, options?: any): AxiosPromise<Response> {
            return localVarFp.deleteReservation(extId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [planningId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executePlanning(planningId?: string, options?: any): AxiosPromise<EfCaPlanningResp> {
            return localVarFp.executePlanning(planningId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixTours(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaPlanningResp> {
            return localVarFp.fixTours(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReservations(options?: any): AxiosPromise<Array<MountReservation>> {
            return localVarFp.getAllReservations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllToursForVehicle(vehicleId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllToursForVehicle(vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReservationsForMount(mountId: string, options?: any): AxiosPromise<Array<MountReservation>> {
            return localVarFp.getReservationsForMount(mountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MountReservation} [mountReservation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddReservation(mountReservation?: MountReservation, options?: any): AxiosPromise<MountReservation> {
            return localVarFp.postAddReservation(mountReservation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteAllReservations(options?: any): AxiosPromise<Response> {
            return localVarFp.postDeleteAllReservations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SimulationConfig} [simulationConfig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSimulationConfig(simulationConfig?: SimulationConfig, options?: any): AxiosPromise<SimulationInformation> {
            return localVarFp.postSimulationConfig(simulationConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStartPlanning(options?: any): AxiosPromise<Response> {
            return localVarFp.postStartPlanning(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUnfixAllTrips(options?: any): AxiosPromise<Response> {
            return localVarFp.postUnfixAllTrips(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarehouseLog} [warehouseLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWarehouseLog(warehouseLog?: WarehouseLog, options?: any): AxiosPromise<Response> {
            return localVarFp.postWarehouseLog(warehouseLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseTours(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaPlanningResp> {
            return localVarFp.releaseTours(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessMgmtApi - object-oriented interface
 * @export
 * @class ProcessMgmtApi
 * @extends {BaseAPI}
 */
export class ProcessMgmtApi extends BaseAPI {
    /**
     * 
     * @param {string} extId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public deleteReservation(extId: string, options?: any) {
        return ProcessMgmtApiFp(this.configuration).deleteReservation(extId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [planningId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public executePlanning(planningId?: string, options?: any) {
        return ProcessMgmtApiFp(this.configuration).executePlanning(planningId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public fixTours(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return ProcessMgmtApiFp(this.configuration).fixTours(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public getAllReservations(options?: any) {
        return ProcessMgmtApiFp(this.configuration).getAllReservations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public getAllToursForVehicle(vehicleId: string, options?: any) {
        return ProcessMgmtApiFp(this.configuration).getAllToursForVehicle(vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public getReservationsForMount(mountId: string, options?: any) {
        return ProcessMgmtApiFp(this.configuration).getReservationsForMount(mountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MountReservation} [mountReservation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public postAddReservation(mountReservation?: MountReservation, options?: any) {
        return ProcessMgmtApiFp(this.configuration).postAddReservation(mountReservation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public postDeleteAllReservations(options?: any) {
        return ProcessMgmtApiFp(this.configuration).postDeleteAllReservations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SimulationConfig} [simulationConfig] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public postSimulationConfig(simulationConfig?: SimulationConfig, options?: any) {
        return ProcessMgmtApiFp(this.configuration).postSimulationConfig(simulationConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public postStartPlanning(options?: any) {
        return ProcessMgmtApiFp(this.configuration).postStartPlanning(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public postUnfixAllTrips(options?: any) {
        return ProcessMgmtApiFp(this.configuration).postUnfixAllTrips(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarehouseLog} [warehouseLog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public postWarehouseLog(warehouseLog?: WarehouseLog, options?: any) {
        return ProcessMgmtApiFp(this.configuration).postWarehouseLog(warehouseLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessMgmtApi
     */
    public releaseTours(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return ProcessMgmtApiFp(this.configuration).releaseTours(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRole: async (efCaRole?: EfCaRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaRole object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRolesByFinder: async (efCaRole?: EfCaRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/role/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRole: async (efCaRole?: EfCaRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRole(efCaRole?: EfCaRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaRoleResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRole(efCaRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaRole object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRolesByFinder(efCaRole?: EfCaRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaRoleResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRolesByFinder(efCaRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRole(efCaRole?: EfCaRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaRoleResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRole(efCaRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRole(efCaRole?: EfCaRole, options?: any): AxiosPromise<EfCaRoleResp> {
            return localVarFp.addRole(efCaRole, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaRole object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRolesByFinder(efCaRole?: EfCaRole, options?: any): AxiosPromise<EfCaRoleResp> {
            return localVarFp.findRolesByFinder(efCaRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaRole} [efCaRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRole(efCaRole?: EfCaRole, options?: any): AxiosPromise<EfCaRoleResp> {
            return localVarFp.putRole(efCaRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {EfCaRole} [efCaRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public addRole(efCaRole?: EfCaRole, options?: any) {
        return RoleApiFp(this.configuration).addRole(efCaRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
     * @summary Uses the EfCaRole object as finder but not all parameters are supported. Refer to the more detailled description below.
     * @param {EfCaRole} [efCaRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public findRolesByFinder(efCaRole?: EfCaRole, options?: any) {
        return RoleApiFp(this.configuration).findRolesByFinder(efCaRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaRole} [efCaRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public putRole(efCaRole?: EfCaRole, options?: any) {
        return RoleApiFp(this.configuration).putRole(efCaRole, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxStatus: async (boxId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxId' is not null or undefined
            assertParamExists('getBoxStatus', 'boxId', boxId)
            const localVarPath = `/fzi/status/boxStatus/{boxId}`
                .replace(`{${"boxId"}}`, encodeURIComponent(String(boxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxStatusList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/boxStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lockerId 
         * @param {string} lockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIsBoxInDepot: async (lockerId: string, lockId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lockerId' is not null or undefined
            assertParamExists('getIsBoxInDepot', 'lockerId', lockerId)
            // verify required parameter 'lockId' is not null or undefined
            assertParamExists('getIsBoxInDepot', 'lockId', lockId)
            const localVarPath = `/fzi/status/boxStatus/isInDepot/{lockerId}/{lockId}`
                .replace(`{${"lockerId"}}`, encodeURIComponent(String(lockerId)))
                .replace(`{${"lockId"}}`, encodeURIComponent(String(lockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMountStatus: async (mountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mountId' is not null or undefined
            assertParamExists('getMountStatus', 'mountId', mountId)
            const localVarPath = `/fzi/status/mountStatus/{mountId}`
                .replace(`{${"mountId"}}`, encodeURIComponent(String(mountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMountStatusList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/mountStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTourStatusList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/tourStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleStatus: async (vehicleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('getVehicleStatus', 'vehicleId', vehicleId)
            const localVarPath = `/fzi/status/vehicleStatus/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleStatusList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/vehicleStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TourStatus} [tourStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateTourStatus: async (tourStatus?: TourStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/tourStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tourStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BoxStatus} [boxStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBoxStatus: async (boxStatus?: BoxStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/boxStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boxStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MountStatus} [mountStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMountStatus: async (mountStatus?: MountStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/mountStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mountStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleStatus} [vehicleStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVehicleStatus: async (vehicleStatus?: VehicleStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/status/vehicleStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxStatus(boxId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxStatus(boxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxStatusList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoxStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lockerId 
         * @param {string} lockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIsBoxInDepot(lockerId: string, lockId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxInDepotResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIsBoxInDepot(lockerId, lockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMountStatus(mountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MountStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMountStatus(mountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMountStatusList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MountStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMountStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTourStatusList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TourStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTourStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleStatus(vehicleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleStatus(vehicleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleStatusList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TourStatus} [tourStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateTourStatus(tourStatus?: TourStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateTourStatus(tourStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BoxStatus} [boxStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBoxStatus(boxStatus?: BoxStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBoxStatus(boxStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MountStatus} [mountStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMountStatus(mountStatus?: MountStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMountStatus(mountStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VehicleStatus} [vehicleStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVehicleStatus(vehicleStatus?: VehicleStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVehicleStatus(vehicleStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxStatus(boxId: string, options?: any): AxiosPromise<BoxStatus> {
            return localVarFp.getBoxStatus(boxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxStatusList(options?: any): AxiosPromise<Array<BoxStatus>> {
            return localVarFp.getBoxStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lockerId 
         * @param {string} lockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIsBoxInDepot(lockerId: string, lockId: string, options?: any): AxiosPromise<BoxInDepotResponse> {
            return localVarFp.getIsBoxInDepot(lockerId, lockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMountStatus(mountId: string, options?: any): AxiosPromise<MountStatus> {
            return localVarFp.getMountStatus(mountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMountStatusList(options?: any): AxiosPromise<Array<MountStatus>> {
            return localVarFp.getMountStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTourStatusList(options?: any): AxiosPromise<Array<TourStatus>> {
            return localVarFp.getTourStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleStatus(vehicleId: string, options?: any): AxiosPromise<VehicleStatus> {
            return localVarFp.getVehicleStatus(vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleStatusList(options?: any): AxiosPromise<Array<VehicleStatus>> {
            return localVarFp.getVehicleStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TourStatus} [tourStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateTourStatus(tourStatus?: TourStatus, options?: any): AxiosPromise<TourStatus> {
            return localVarFp.postCreateTourStatus(tourStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BoxStatus} [boxStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBoxStatus(boxStatus?: BoxStatus, options?: any): AxiosPromise<Response> {
            return localVarFp.putBoxStatus(boxStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MountStatus} [mountStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMountStatus(mountStatus?: MountStatus, options?: any): AxiosPromise<Response> {
            return localVarFp.putMountStatus(mountStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleStatus} [vehicleStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVehicleStatus(vehicleStatus?: VehicleStatus, options?: any): AxiosPromise<Response> {
            return localVarFp.putVehicleStatus(vehicleStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {string} boxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getBoxStatus(boxId: string, options?: any) {
        return StatusApiFp(this.configuration).getBoxStatus(boxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getBoxStatusList(options?: any) {
        return StatusApiFp(this.configuration).getBoxStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} lockerId 
     * @param {string} lockId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getIsBoxInDepot(lockerId: string, lockId: string, options?: any) {
        return StatusApiFp(this.configuration).getIsBoxInDepot(lockerId, lockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getMountStatus(mountId: string, options?: any) {
        return StatusApiFp(this.configuration).getMountStatus(mountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getMountStatusList(options?: any) {
        return StatusApiFp(this.configuration).getMountStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getTourStatusList(options?: any) {
        return StatusApiFp(this.configuration).getTourStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getVehicleStatus(vehicleId: string, options?: any) {
        return StatusApiFp(this.configuration).getVehicleStatus(vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getVehicleStatusList(options?: any) {
        return StatusApiFp(this.configuration).getVehicleStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TourStatus} [tourStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public postCreateTourStatus(tourStatus?: TourStatus, options?: any) {
        return StatusApiFp(this.configuration).postCreateTourStatus(tourStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BoxStatus} [boxStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public putBoxStatus(boxStatus?: BoxStatus, options?: any) {
        return StatusApiFp(this.configuration).putBoxStatus(boxStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MountStatus} [mountStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public putMountStatus(mountStatus?: MountStatus, options?: any) {
        return StatusApiFp(this.configuration).putMountStatus(mountStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleStatus} [vehicleStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public putVehicleStatus(vehicleStatus?: VehicleStatus, options?: any) {
        return StatusApiFp(this.configuration).putVehicleStatus(vehicleStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SyncMeetingPointApi - axios parameter creator
 * @export
 */
export const SyncMeetingPointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSyncMeetingPoint: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteSyncMeetingPoint', 'ident', ident)
            const localVarPath = `/api/data/syncpoint/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident => equal  Info => Regex
         * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSyncMeetingPointsByFinder: async (efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/syncpoint/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaSyncMeetingPoint, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddSyncMeetingPoints: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/syncpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSyncMeetingPoint: async (efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/syncpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaSyncMeetingPoint, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncMeetingPointApi - functional programming interface
 * @export
 */
export const SyncMeetingPointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncMeetingPointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSyncMeetingPoint(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaSyncMeetingPointResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSyncMeetingPoint(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident => equal  Info => Regex
         * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSyncMeetingPointsByFinder(efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaSyncMeetingPointResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSyncMeetingPointsByFinder(efCaSyncMeetingPoint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddSyncMeetingPoints(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaSyncMeetingPointResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddSyncMeetingPoints(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSyncMeetingPoint(efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaSyncMeetingPointResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSyncMeetingPoint(efCaSyncMeetingPoint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SyncMeetingPointApi - factory interface
 * @export
 */
export const SyncMeetingPointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncMeetingPointApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSyncMeetingPoint(ident: string, options?: any): AxiosPromise<EfCaSyncMeetingPointResp> {
            return localVarFp.deleteSyncMeetingPoint(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident => equal  Info => Regex
         * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSyncMeetingPointsByFinder(efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options?: any): AxiosPromise<EfCaSyncMeetingPointResp> {
            return localVarFp.findSyncMeetingPointsByFinder(efCaSyncMeetingPoint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddSyncMeetingPoints(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaSyncMeetingPointResp> {
            return localVarFp.postAddSyncMeetingPoints(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSyncMeetingPoint(efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options?: any): AxiosPromise<EfCaSyncMeetingPointResp> {
            return localVarFp.putSyncMeetingPoint(efCaSyncMeetingPoint, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SyncMeetingPointApi - object-oriented interface
 * @export
 * @class SyncMeetingPointApi
 * @extends {BaseAPI}
 */
export class SyncMeetingPointApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncMeetingPointApi
     */
    public deleteSyncMeetingPoint(ident: string, options?: any) {
        return SyncMeetingPointApiFp(this.configuration).deleteSyncMeetingPoint(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident => equal  Info => Regex
     * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncMeetingPointApi
     */
    public findSyncMeetingPointsByFinder(efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options?: any) {
        return SyncMeetingPointApiFp(this.configuration).findSyncMeetingPointsByFinder(efCaSyncMeetingPoint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncMeetingPointApi
     */
    public postAddSyncMeetingPoints(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return SyncMeetingPointApiFp(this.configuration).postAddSyncMeetingPoints(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaSyncMeetingPoint} [efCaSyncMeetingPoint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncMeetingPointApi
     */
    public putSyncMeetingPoint(efCaSyncMeetingPoint?: EfCaSyncMeetingPoint, options?: any) {
        return SyncMeetingPointApiFp(this.configuration).putSyncMeetingPoint(efCaSyncMeetingPoint, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [useCase] 
         * @param {boolean} [nonsense] 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemWorkInProgess: async (useCase?: number, nonsense?: boolean, efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (useCase !== undefined) {
                localVarQueryParameter['useCase'] = useCase;
            }

            if (nonsense !== undefined) {
                localVarQueryParameter['nonsense'] = nonsense;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [useCase] 
         * @param {boolean} [nonsense] 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemWorkInProgess(useCase?: number, nonsense?: boolean, efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaSystemResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemWorkInProgess(useCase, nonsense, efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [useCase] 
         * @param {boolean} [nonsense] 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemWorkInProgess(useCase?: number, nonsense?: boolean, efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaSystemResp> {
            return localVarFp.systemWorkInProgess(useCase, nonsense, efCaModelCollector, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {number} [useCase] 
     * @param {boolean} [nonsense] 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemWorkInProgess(useCase?: number, nonsense?: boolean, efCaModelCollector?: EfCaModelCollector, options?: any) {
        return SystemApiFp(this.configuration).systemWorkInProgess(useCase, nonsense, efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTenant: async (efCaTenant?: EfCaTenant, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaTenant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteTenant', 'ident', ident)
            const localVarPath = `/api/system/tenant/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaTenant object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantsByFinder: async (efCaTenant?: EfCaTenant, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/tenant/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaTenant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary <br>https://lsogit.fzi.de/efeu/efeuportal/-/issues/44
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenant: async (efCaTenant?: EfCaTenant, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaTenant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTenant(efCaTenant?: EfCaTenant, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTenantResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTenant(efCaTenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenant(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTenantResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenant(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaTenant object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTenantsByFinder(efCaTenant?: EfCaTenant, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTenantResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTenantsByFinder(efCaTenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary <br>https://lsogit.fzi.de/efeu/efeuportal/-/issues/44
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTenant(efCaTenant?: EfCaTenant, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTenantResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTenant(efCaTenant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTenant(efCaTenant?: EfCaTenant, options?: any): AxiosPromise<EfCaTenantResp> {
            return localVarFp.addTenant(efCaTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant(ident: string, options?: any): AxiosPromise<EfCaTenantResp> {
            return localVarFp.deleteTenant(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaTenant object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTenantsByFinder(efCaTenant?: EfCaTenant, options?: any): AxiosPromise<EfCaTenantResp> {
            return localVarFp.findTenantsByFinder(efCaTenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary <br>https://lsogit.fzi.de/efeu/efeuportal/-/issues/44
         * @param {EfCaTenant} [efCaTenant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTenant(efCaTenant?: EfCaTenant, options?: any): AxiosPromise<EfCaTenantResp> {
            return localVarFp.putTenant(efCaTenant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {EfCaTenant} [efCaTenant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public addTenant(efCaTenant?: EfCaTenant, options?: any) {
        return TenantApiFp(this.configuration).addTenant(efCaTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenant(ident: string, options?: any) {
        return TenantApiFp(this.configuration).deleteTenant(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
     * @summary Uses the EfCaTenant object as finder but not all parameters are supported. Refer to the more detailled description below.
     * @param {EfCaTenant} [efCaTenant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public findTenantsByFinder(efCaTenant?: EfCaTenant, options?: any) {
        return TenantApiFp(this.configuration).findTenantsByFinder(efCaTenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary <br>https://lsogit.fzi.de/efeu/efeuportal/-/issues/44
     * @param {EfCaTenant} [efCaTenant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public putTenant(efCaTenant?: EfCaTenant, options?: any) {
        return TenantApiFp(this.configuration).putTenant(efCaTenant, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Map tests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteMapDataTests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/efeuportal/test/Map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test CRUD of the masterdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteMasterDataTests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/efeuportal/test/masterdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Example orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteOrderDataTests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/efeuportal/test/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Planning tests (order export, planning, tour, route)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecutePlanningDataTests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/efeuportal/test/planning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Map tests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExecuteMapDataTests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemTestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExecuteMapDataTests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Test CRUD of the masterdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExecuteMasterDataTests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemTestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExecuteMasterDataTests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Example orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExecuteOrderDataTests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemTestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExecuteOrderDataTests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Planning tests (order export, planning, tour, route)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExecutePlanningDataTests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemTestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExecutePlanningDataTests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @summary Map tests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteMapDataTests(options?: any): AxiosPromise<SystemTestResponse> {
            return localVarFp.postExecuteMapDataTests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test CRUD of the masterdata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteMasterDataTests(options?: any): AxiosPromise<SystemTestResponse> {
            return localVarFp.postExecuteMasterDataTests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Example orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecuteOrderDataTests(options?: any): AxiosPromise<SystemTestResponse> {
            return localVarFp.postExecuteOrderDataTests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Planning tests (order export, planning, tour, route)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExecutePlanningDataTests(options?: any): AxiosPromise<SystemTestResponse> {
            return localVarFp.postExecutePlanningDataTests(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @summary Map tests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public postExecuteMapDataTests(options?: any) {
        return TestApiFp(this.configuration).postExecuteMapDataTests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test CRUD of the masterdata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public postExecuteMasterDataTests(options?: any) {
        return TestApiFp(this.configuration).postExecuteMasterDataTests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Example orders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public postExecuteOrderDataTests(options?: any) {
        return TestApiFp(this.configuration).postExecuteOrderDataTests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Planning tests (order export, planning, tour, route)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public postExecutePlanningDataTests(options?: any) {
        return TestApiFp(this.configuration).postExecutePlanningDataTests(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransportBoxApi - axios parameter creator
 * @export
 */
export const TransportBoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportBox: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteTransportBox', 'ident', ident)
            const localVarPath = `/api/data/box/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportBoxType: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteTransportBoxType', 'ident', ident)
            const localVarPath = `/api/data/boxtype/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaTransportBoxType} [efCaTransportBoxType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransportBoxTypesByFinder: async (efCaTransportBoxType?: EfCaTransportBoxType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/boxtype/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaTransportBoxType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal
         * @param {EfCaTransportBox} [efCaTransportBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransportBoxesByFinder: async (efCaTransportBox?: EfCaTransportBox, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/box/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaTransportBox, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST      {        \"transportBoxTypes\" : [        {            \"Type\": \"SMALL_PACKAGE_BOX\",            \"Description\": \"Small default box\",            \"lenght\": 550,            \"width\": 400,            \"height\": 350,            \"weight\": 40000          }        ]      }
         * @summary Add a new \"TransportBoxType\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddTransportBoxTypes: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/boxtype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST      {        \"ident\": \"TransportBox_xx\",        \"lenght\": 10,        \"width\": 20,        \"height\": 30,        \"weight\": 40      }
         * @summary Add a new \"TransportBox\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddTransportBoxes: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/box`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaTransportBox} [efCaTransportBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTransportBox: async (efCaTransportBox?: EfCaTransportBox, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/box`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaTransportBox, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransportBoxApi - functional programming interface
 * @export
 */
export const TransportBoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransportBoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransportBox(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTransportBoxResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransportBox(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransportBoxType(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTransportBoxResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransportBoxType(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaTransportBoxType} [efCaTransportBoxType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTransportBoxTypesByFinder(efCaTransportBoxType?: EfCaTransportBoxType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTransportBoxResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTransportBoxTypesByFinder(efCaTransportBoxType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal
         * @param {EfCaTransportBox} [efCaTransportBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTransportBoxesByFinder(efCaTransportBox?: EfCaTransportBox, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTransportBoxResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTransportBoxesByFinder(efCaTransportBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST      {        \"transportBoxTypes\" : [        {            \"Type\": \"SMALL_PACKAGE_BOX\",            \"Description\": \"Small default box\",            \"lenght\": 550,            \"width\": 400,            \"height\": 350,            \"weight\": 40000          }        ]      }
         * @summary Add a new \"TransportBoxType\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddTransportBoxTypes(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTransportBoxResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddTransportBoxTypes(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST      {        \"ident\": \"TransportBox_xx\",        \"lenght\": 10,        \"width\": 20,        \"height\": 30,        \"weight\": 40      }
         * @summary Add a new \"TransportBox\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddTransportBoxes(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTransportBoxResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddTransportBoxes(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaTransportBox} [efCaTransportBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTransportBox(efCaTransportBox?: EfCaTransportBox, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaTransportBoxResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTransportBox(efCaTransportBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransportBoxApi - factory interface
 * @export
 */
export const TransportBoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransportBoxApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportBox(ident: string, options?: any): AxiosPromise<EfCaTransportBoxResp> {
            return localVarFp.deleteTransportBox(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransportBoxType(ident: string, options?: any): AxiosPromise<EfCaTransportBoxResp> {
            return localVarFp.deleteTransportBoxType(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaTransportBoxType} [efCaTransportBoxType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransportBoxTypesByFinder(efCaTransportBoxType?: EfCaTransportBoxType, options?: any): AxiosPromise<EfCaTransportBoxResp> {
            return localVarFp.findTransportBoxTypesByFinder(efCaTransportBoxType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal
         * @param {EfCaTransportBox} [efCaTransportBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTransportBoxesByFinder(efCaTransportBox?: EfCaTransportBox, options?: any): AxiosPromise<EfCaTransportBoxResp> {
            return localVarFp.findTransportBoxesByFinder(efCaTransportBox, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST      {        \"transportBoxTypes\" : [        {            \"Type\": \"SMALL_PACKAGE_BOX\",            \"Description\": \"Small default box\",            \"lenght\": 550,            \"width\": 400,            \"height\": 350,            \"weight\": 40000          }        ]      }
         * @summary Add a new \"TransportBoxType\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddTransportBoxTypes(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaTransportBoxResp> {
            return localVarFp.postAddTransportBoxTypes(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST      {        \"ident\": \"TransportBox_xx\",        \"lenght\": 10,        \"width\": 20,        \"height\": 30,        \"weight\": 40      }
         * @summary Add a new \"TransportBox\"
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddTransportBoxes(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaTransportBoxResp> {
            return localVarFp.postAddTransportBoxes(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaTransportBox} [efCaTransportBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTransportBox(efCaTransportBox?: EfCaTransportBox, options?: any): AxiosPromise<EfCaTransportBoxResp> {
            return localVarFp.putTransportBox(efCaTransportBox, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransportBoxApi - object-oriented interface
 * @export
 * @class TransportBoxApi
 * @extends {BaseAPI}
 */
export class TransportBoxApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportBoxApi
     */
    public deleteTransportBox(ident: string, options?: any) {
        return TransportBoxApiFp(this.configuration).deleteTransportBox(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportBoxApi
     */
    public deleteTransportBoxType(ident: string, options?: any) {
        return TransportBoxApiFp(this.configuration).deleteTransportBoxType(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaTransportBoxType} [efCaTransportBoxType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportBoxApi
     */
    public findTransportBoxTypesByFinder(efCaTransportBoxType?: EfCaTransportBoxType, options?: any) {
        return TransportBoxApiFp(this.configuration).findTransportBoxTypesByFinder(efCaTransportBoxType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident, Type => equal
     * @param {EfCaTransportBox} [efCaTransportBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportBoxApi
     */
    public findTransportBoxesByFinder(efCaTransportBox?: EfCaTransportBox, options?: any) {
        return TransportBoxApiFp(this.configuration).findTransportBoxesByFinder(efCaTransportBox, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST      {        \"transportBoxTypes\" : [        {            \"Type\": \"SMALL_PACKAGE_BOX\",            \"Description\": \"Small default box\",            \"lenght\": 550,            \"width\": 400,            \"height\": 350,            \"weight\": 40000          }        ]      }
     * @summary Add a new \"TransportBoxType\"
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportBoxApi
     */
    public postAddTransportBoxTypes(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return TransportBoxApiFp(this.configuration).postAddTransportBoxTypes(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST      {        \"ident\": \"TransportBox_xx\",        \"lenght\": 10,        \"width\": 20,        \"height\": 30,        \"weight\": 40      }
     * @summary Add a new \"TransportBox\"
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportBoxApi
     */
    public postAddTransportBoxes(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return TransportBoxApiFp(this.configuration).postAddTransportBoxes(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaTransportBox} [efCaTransportBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportBoxApi
     */
    public putTransportBox(efCaTransportBox?: EfCaTransportBox, options?: any) {
        return TransportBoxApiFp(this.configuration).putTransportBox(efCaTransportBox, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (efCaUser?: EfCaUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaChangePasswordRequest} [efCaChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (efCaChangePasswordRequest?: EfCaChangePasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/user/changepwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteUser', 'ident', ident)
            const localVarPath = `/api/system/user/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaUser object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFinder: async (efCaUser?: EfCaUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/user/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser: async (efCaUser?: EfCaUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaRegisterUser} [efCaRegisterUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (efCaRegisterUser?: EfCaRegisterUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaRegisterUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary You need the Operator role for resetting a lost password.
         * @param {EfCaLogin} [efCaLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword: async (efCaLogin?: EfCaLogin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/system/user/resetpwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(efCaUser?: EfCaUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaUserResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(efCaUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaChangePasswordRequest} [efCaChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(efCaChangePasswordRequest?: EfCaChangePasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaUserResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(efCaChangePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaUserResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaUser object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsersByFinder(efCaUser?: EfCaUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaUserResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsersByFinder(efCaUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUser(efCaUser?: EfCaUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaUserResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUser(efCaUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaRegisterUser} [efCaRegisterUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(efCaRegisterUser?: EfCaRegisterUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaRegisterResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(efCaRegisterUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary You need the Operator role for resetting a lost password.
         * @param {EfCaLogin} [efCaLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPassword(efCaLogin?: EfCaLogin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaUserResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPassword(efCaLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(efCaUser?: EfCaUser, options?: any): AxiosPromise<EfCaUserResp> {
            return localVarFp.addUser(efCaUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaChangePasswordRequest} [efCaChangePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(efCaChangePasswordRequest?: EfCaChangePasswordRequest, options?: any): AxiosPromise<EfCaUserResp> {
            return localVarFp.changeUserPassword(efCaChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(ident: string, options?: any): AxiosPromise<EfCaUserResp> {
            return localVarFp.deleteUser(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
         * @summary Uses the EfCaUser object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFinder(efCaUser?: EfCaUser, options?: any): AxiosPromise<EfCaUserResp> {
            return localVarFp.findUsersByFinder(efCaUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaUser} [efCaUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(efCaUser?: EfCaUser, options?: any): AxiosPromise<EfCaUserResp> {
            return localVarFp.putUser(efCaUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaRegisterUser} [efCaRegisterUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(efCaRegisterUser?: EfCaRegisterUser, options?: any): AxiosPromise<EfCaRegisterResp> {
            return localVarFp.registerUser(efCaRegisterUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary You need the Operator role for resetting a lost password.
         * @param {EfCaLogin} [efCaLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(efCaLogin?: EfCaLogin, options?: any): AxiosPromise<EfCaUserResp> {
            return localVarFp.resetUserPassword(efCaLogin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {EfCaUser} [efCaUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addUser(efCaUser?: EfCaUser, options?: any) {
        return UserApiFp(this.configuration).addUser(efCaUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaChangePasswordRequest} [efCaChangePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeUserPassword(efCaChangePasswordRequest?: EfCaChangePasswordRequest, options?: any) {
        return UserApiFp(this.configuration).changeUserPassword(efCaChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(ident: string, options?: any) {
        return UserApiFp(this.configuration).deleteUser(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    Supported finders      {         \"\" => \"equal\",         \"\" => \"regex\"      }
     * @summary Uses the EfCaUser object as finder but not all parameters are supported. Refer to the more detailled description below.
     * @param {EfCaUser} [efCaUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public findUsersByFinder(efCaUser?: EfCaUser, options?: any) {
        return UserApiFp(this.configuration).findUsersByFinder(efCaUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaUser} [efCaUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUser(efCaUser?: EfCaUser, options?: any) {
        return UserApiFp(this.configuration).putUser(efCaUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaRegisterUser} [efCaRegisterUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerUser(efCaRegisterUser?: EfCaRegisterUser, options?: any) {
        return UserApiFp(this.configuration).registerUser(efCaRegisterUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary You need the Operator role for resetting a lost password.
     * @param {EfCaLogin} [efCaLogin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetUserPassword(efCaLogin?: EfCaLogin, options?: any) {
        return UserApiFp(this.configuration).resetUserPassword(efCaLogin, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAppApi - axios parameter creator
 * @export
 */
export const UserAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBoxes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/getAvailableBoxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentOrdersOverview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/currentOrdersOverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepotAvailabilityTimeSlots: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/getDepotAvailabilityTimeSlots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepotOpeningHours: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/getDepotOpeningHours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinishedOrdersOverview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/finishedOrdersOverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimumCommissioningTimeMinutes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/getMinimumCommissioningTimeMinutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimumTimeWindowLengthMinutes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/getMinimumTimeWindowLengthMinutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicHolidaysBW2022To2028: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/getPublicHolidaysBW2022To2028`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/getSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/mounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSyncMeetingPoints: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/syncMeetingPoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelPickupOrderRequest} [cancelPickupOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCancelPickupOrder: async (cancelPickupOrderRequest?: CancelPickupOrderRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/cancelPickupOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelPickupOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteBoxRequest} [deleteBoxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteBoxRequest: async (deleteBoxRequest?: DeleteBoxRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/deleteBoxRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBoxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyOrderRequest} [modifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModifyOrder: async (modifyOrderRequest?: ModifyOrderRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/modifyOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerSettings} [customerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModifySettings: async (customerSettings?: CustomerSettings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/modifySettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenBox: async (boxId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxId' is not null or undefined
            assertParamExists('postOpenBox', 'boxId', boxId)
            const localVarPath = `/fzi/app/user/openBox/{boxId}`
                .replace(`{${"boxId"}}`, encodeURIComponent(String(boxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerPlaceWasteEvent} [customerPlaceWasteEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlaceWasteInBox: async (customerPlaceWasteEvent?: CustomerPlaceWasteEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/placeWasteInBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerPlaceWasteEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {CustomerReceivePackageEvent} [customerReceivePackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivePackage: async (customerContactIdent: string, customerReceivePackageEvent?: CustomerReceivePackageEvent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerContactIdent' is not null or undefined
            assertParamExists('postReceivePackage', 'customerContactIdent', customerContactIdent)
            const localVarPath = `/fzi/app/user/receivePackage/{customerContactIdent}`
                .replace(`{${"customerContactIdent"}}`, encodeURIComponent(String(customerContactIdent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerReceivePackageEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {BoxRequest} [boxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestBox: async (customerContactIdent: string, boxRequest?: BoxRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerContactIdent' is not null or undefined
            assertParamExists('postRequestBox', 'customerContactIdent', customerContactIdent)
            const localVarPath = `/fzi/app/user/requestBox/{customerContactIdent}`
                .replace(`{${"customerContactIdent"}}`, encodeURIComponent(String(customerContactIdent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boxRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetUserDemo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/resetUserDemo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerReuseBoxEvent} [customerReuseBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReuseBox: async (customerReuseBoxEvent?: CustomerReuseBoxEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/app/user/reuseBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerReuseBoxEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {CustomerSendPackageEvent} [customerSendPackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendPackage: async (customerContactIdent: string, customerSendPackageEvent?: CustomerSendPackageEvent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerContactIdent' is not null or undefined
            assertParamExists('postSendPackage', 'customerContactIdent', customerContactIdent)
            const localVarPath = `/fzi/app/user/sendPackage/{customerContactIdent}`
                .replace(`{${"customerContactIdent"}}`, encodeURIComponent(String(customerContactIdent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerSendPackageEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAppApi - functional programming interface
 * @export
 */
export const UserAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableBoxes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableBox>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableBoxes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentOrdersOverview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentOrdersOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepotAvailabilityTimeSlots(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotAvailabilityTimeSlots>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepotAvailabilityTimeSlots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepotOpeningHours(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepotAvailabilityTimeSlots>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepotOpeningHours(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinishedOrdersOverview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinishedOrdersOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinimumCommissioningTimeMinutes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumCommissioningTimeMinutes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinimumCommissioningTimeMinutes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinimumTimeWindowLengthMinutes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumTimeWindowLength>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinimumTimeWindowLengthMinutes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicHolidaysBW2022To2028(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicHolidaysBW2022To2028(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MountInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSyncMeetingPoints(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SyncMeetingPointInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSyncMeetingPoints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CancelPickupOrderRequest} [cancelPickupOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCancelPickupOrder(cancelPickupOrderRequest?: CancelPickupOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCancelPickupOrder(cancelPickupOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteBoxRequest} [deleteBoxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeleteBoxRequest(deleteBoxRequest?: DeleteBoxRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeleteBoxRequest(deleteBoxRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyOrderRequest} [modifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModifyOrder(modifyOrderRequest?: ModifyOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModifyOrder(modifyOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerSettings} [customerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postModifySettings(customerSettings?: CustomerSettings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postModifySettings(customerSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOpenBox(boxId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOpenBox(boxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerPlaceWasteEvent} [customerPlaceWasteEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPlaceWasteInBox(customerPlaceWasteEvent?: CustomerPlaceWasteEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPlaceWasteInBox(customerPlaceWasteEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {CustomerReceivePackageEvent} [customerReceivePackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReceivePackage(customerContactIdent: string, customerReceivePackageEvent?: CustomerReceivePackageEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReceivePackage(customerContactIdent, customerReceivePackageEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {BoxRequest} [boxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestBox(customerContactIdent: string, boxRequest?: BoxRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestBox(customerContactIdent, boxRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postResetUserDemo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postResetUserDemo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerReuseBoxEvent} [customerReuseBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReuseBox(customerReuseBoxEvent?: CustomerReuseBoxEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerReuseBoxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReuseBox(customerReuseBoxEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {CustomerSendPackageEvent} [customerSendPackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSendPackage(customerContactIdent: string, customerSendPackageEvent?: CustomerSendPackageEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSendPackage(customerContactIdent, customerSendPackageEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAppApi - factory interface
 * @export
 */
export const UserAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAppApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableBoxes(options?: any): AxiosPromise<Array<AvailableBox>> {
            return localVarFp.getAvailableBoxes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentOrdersOverview(options?: any): AxiosPromise<Array<OrderInformation>> {
            return localVarFp.getCurrentOrdersOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepotAvailabilityTimeSlots(options?: any): AxiosPromise<DepotAvailabilityTimeSlots> {
            return localVarFp.getDepotAvailabilityTimeSlots(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepotOpeningHours(options?: any): AxiosPromise<DepotAvailabilityTimeSlots> {
            return localVarFp.getDepotOpeningHours(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinishedOrdersOverview(options?: any): AxiosPromise<Array<OrderInformation>> {
            return localVarFp.getFinishedOrdersOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimumCommissioningTimeMinutes(options?: any): AxiosPromise<MinimumCommissioningTimeMinutes> {
            return localVarFp.getMinimumCommissioningTimeMinutes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinimumTimeWindowLengthMinutes(options?: any): AxiosPromise<MinimumTimeWindowLength> {
            return localVarFp.getMinimumTimeWindowLengthMinutes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicHolidaysBW2022To2028(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPublicHolidaysBW2022To2028(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<CustomerSettings> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMounts(options?: any): AxiosPromise<Array<MountInformation>> {
            return localVarFp.getUserMounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSyncMeetingPoints(options?: any): AxiosPromise<Array<SyncMeetingPointInformation>> {
            return localVarFp.getUserSyncMeetingPoints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CancelPickupOrderRequest} [cancelPickupOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCancelPickupOrder(cancelPickupOrderRequest?: CancelPickupOrderRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postCancelPickupOrder(cancelPickupOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteBoxRequest} [deleteBoxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeleteBoxRequest(deleteBoxRequest?: DeleteBoxRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postDeleteBoxRequest(deleteBoxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyOrderRequest} [modifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModifyOrder(modifyOrderRequest?: ModifyOrderRequest, options?: any): AxiosPromise<Response> {
            return localVarFp.postModifyOrder(modifyOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerSettings} [customerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postModifySettings(customerSettings?: CustomerSettings, options?: any): AxiosPromise<Response> {
            return localVarFp.postModifySettings(customerSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} boxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOpenBox(boxId: string, options?: any): AxiosPromise<Response> {
            return localVarFp.postOpenBox(boxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerPlaceWasteEvent} [customerPlaceWasteEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlaceWasteInBox(customerPlaceWasteEvent?: CustomerPlaceWasteEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postPlaceWasteInBox(customerPlaceWasteEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {CustomerReceivePackageEvent} [customerReceivePackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReceivePackage(customerContactIdent: string, customerReceivePackageEvent?: CustomerReceivePackageEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postReceivePackage(customerContactIdent, customerReceivePackageEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {BoxRequest} [boxRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestBox(customerContactIdent: string, boxRequest?: BoxRequest, options?: any): AxiosPromise<BoxRequestResponse> {
            return localVarFp.postRequestBox(customerContactIdent, boxRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResetUserDemo(options?: any): AxiosPromise<Response> {
            return localVarFp.postResetUserDemo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerReuseBoxEvent} [customerReuseBoxEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReuseBox(customerReuseBoxEvent?: CustomerReuseBoxEvent, options?: any): AxiosPromise<CustomerReuseBoxResponse> {
            return localVarFp.postReuseBox(customerReuseBoxEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerContactIdent 
         * @param {CustomerSendPackageEvent} [customerSendPackageEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendPackage(customerContactIdent: string, customerSendPackageEvent?: CustomerSendPackageEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postSendPackage(customerContactIdent, customerSendPackageEvent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAppApi - object-oriented interface
 * @export
 * @class UserAppApi
 * @extends {BaseAPI}
 */
export class UserAppApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getAvailableBoxes(options?: any) {
        return UserAppApiFp(this.configuration).getAvailableBoxes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getCurrentOrdersOverview(options?: any) {
        return UserAppApiFp(this.configuration).getCurrentOrdersOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getDepotAvailabilityTimeSlots(options?: any) {
        return UserAppApiFp(this.configuration).getDepotAvailabilityTimeSlots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getDepotOpeningHours(options?: any) {
        return UserAppApiFp(this.configuration).getDepotOpeningHours(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getFinishedOrdersOverview(options?: any) {
        return UserAppApiFp(this.configuration).getFinishedOrdersOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getMinimumCommissioningTimeMinutes(options?: any) {
        return UserAppApiFp(this.configuration).getMinimumCommissioningTimeMinutes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getMinimumTimeWindowLengthMinutes(options?: any) {
        return UserAppApiFp(this.configuration).getMinimumTimeWindowLengthMinutes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getPublicHolidaysBW2022To2028(options?: any) {
        return UserAppApiFp(this.configuration).getPublicHolidaysBW2022To2028(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getSettings(options?: any) {
        return UserAppApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getUserMounts(options?: any) {
        return UserAppApiFp(this.configuration).getUserMounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public getUserSyncMeetingPoints(options?: any) {
        return UserAppApiFp(this.configuration).getUserSyncMeetingPoints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CancelPickupOrderRequest} [cancelPickupOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postCancelPickupOrder(cancelPickupOrderRequest?: CancelPickupOrderRequest, options?: any) {
        return UserAppApiFp(this.configuration).postCancelPickupOrder(cancelPickupOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteBoxRequest} [deleteBoxRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postDeleteBoxRequest(deleteBoxRequest?: DeleteBoxRequest, options?: any) {
        return UserAppApiFp(this.configuration).postDeleteBoxRequest(deleteBoxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyOrderRequest} [modifyOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postModifyOrder(modifyOrderRequest?: ModifyOrderRequest, options?: any) {
        return UserAppApiFp(this.configuration).postModifyOrder(modifyOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerSettings} [customerSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postModifySettings(customerSettings?: CustomerSettings, options?: any) {
        return UserAppApiFp(this.configuration).postModifySettings(customerSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} boxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postOpenBox(boxId: string, options?: any) {
        return UserAppApiFp(this.configuration).postOpenBox(boxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerPlaceWasteEvent} [customerPlaceWasteEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postPlaceWasteInBox(customerPlaceWasteEvent?: CustomerPlaceWasteEvent, options?: any) {
        return UserAppApiFp(this.configuration).postPlaceWasteInBox(customerPlaceWasteEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerContactIdent 
     * @param {CustomerReceivePackageEvent} [customerReceivePackageEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postReceivePackage(customerContactIdent: string, customerReceivePackageEvent?: CustomerReceivePackageEvent, options?: any) {
        return UserAppApiFp(this.configuration).postReceivePackage(customerContactIdent, customerReceivePackageEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerContactIdent 
     * @param {BoxRequest} [boxRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postRequestBox(customerContactIdent: string, boxRequest?: BoxRequest, options?: any) {
        return UserAppApiFp(this.configuration).postRequestBox(customerContactIdent, boxRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postResetUserDemo(options?: any) {
        return UserAppApiFp(this.configuration).postResetUserDemo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerReuseBoxEvent} [customerReuseBoxEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postReuseBox(customerReuseBoxEvent?: CustomerReuseBoxEvent, options?: any) {
        return UserAppApiFp(this.configuration).postReuseBox(customerReuseBoxEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerContactIdent 
     * @param {CustomerSendPackageEvent} [customerSendPackageEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppApi
     */
    public postSendPackage(customerContactIdent: string, customerSendPackageEvent?: CustomerSendPackageEvent, options?: any) {
        return UserAppApiFp(this.configuration).postSendPackage(customerContactIdent, customerSendPackageEvent, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleApi - axios parameter creator
 * @export
 */
export const VehicleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicle: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteVehicle', 'ident', ident)
            const localVarPath = `/api/data/vehicle/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal
         * @param {EfCaVehicle} [efCaVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVehiclesByFinder: async (efCaVehicle?: EfCaVehicle, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/vehicle/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaVehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddVehicles: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaVehicle} [efCaVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVehicle: async (efCaVehicle?: EfCaVehicle, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/vehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaVehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleApi - functional programming interface
 * @export
 */
export const VehicleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVehicle(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaVehicleResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVehicle(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal
         * @param {EfCaVehicle} [efCaVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findVehiclesByFinder(efCaVehicle?: EfCaVehicle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaVehicleResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findVehiclesByFinder(efCaVehicle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddVehicles(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaVehicleResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddVehicles(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaVehicle} [efCaVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVehicle(efCaVehicle?: EfCaVehicle, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaVehicleResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVehicle(efCaVehicle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleApi - factory interface
 * @export
 */
export const VehicleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicle(ident: string, options?: any): AxiosPromise<EfCaVehicleResp> {
            return localVarFp.deleteVehicle(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finder: Valid finder parameters    Ident, Type => equal
         * @param {EfCaVehicle} [efCaVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVehiclesByFinder(efCaVehicle?: EfCaVehicle, options?: any): AxiosPromise<EfCaVehicleResp> {
            return localVarFp.findVehiclesByFinder(efCaVehicle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddVehicles(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaVehicleResp> {
            return localVarFp.postAddVehicles(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaVehicle} [efCaVehicle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVehicle(efCaVehicle?: EfCaVehicle, options?: any): AxiosPromise<EfCaVehicleResp> {
            return localVarFp.putVehicle(efCaVehicle, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleApi - object-oriented interface
 * @export
 * @class VehicleApi
 * @extends {BaseAPI}
 */
export class VehicleApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public deleteVehicle(ident: string, options?: any) {
        return VehicleApiFp(this.configuration).deleteVehicle(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finder: Valid finder parameters    Ident, Type => equal
     * @param {EfCaVehicle} [efCaVehicle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public findVehiclesByFinder(efCaVehicle?: EfCaVehicle, options?: any) {
        return VehicleApiFp(this.configuration).findVehiclesByFinder(efCaVehicle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public postAddVehicles(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return VehicleApiFp(this.configuration).postAddVehicles(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaVehicle} [efCaVehicle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleApi
     */
    public putVehicle(efCaVehicle?: EfCaVehicle, options?: any) {
        return VehicleApiFp(this.configuration).putVehicle(efCaVehicle, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleEventApi - axios parameter creator
 * @export
 */
export const VehicleEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VehicleBoxDeliveryEvent} [vehicleBoxDeliveryEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliverBoxToMount: async (vehicleBoxDeliveryEvent?: VehicleBoxDeliveryEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/deliverBoxToMount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleBoxDeliveryEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepartFromMountWaitingChargingEvent} [departFromMountWaitingChargingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartFromMountWaitingCharging: async (departFromMountWaitingChargingEvent?: DepartFromMountWaitingChargingEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/departFromMountWaitingCharging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departFromMountWaitingChargingEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinishTourEvent} [finishTourEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFinishTour: async (finishTourEvent?: FinishTourEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/finishTour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finishTourEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleBoxPickupEvent} [vehicleBoxPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickupBoxFromMount: async (vehicleBoxPickupEvent?: VehicleBoxPickupEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/pickupBoxFromMount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleBoxPickupEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestMountForBoxDeliveryEvent} [requestMountForBoxDeliveryEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForBoxDelivery: async (requestMountForBoxDeliveryEvent?: RequestMountForBoxDeliveryEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/requestMountForBoxDelivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMountForBoxDeliveryEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestMountForBoxPickupEvent} [requestMountForBoxPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForBoxPickup: async (requestMountForBoxPickupEvent?: RequestMountForBoxPickupEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/requestMountForBoxPickup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMountForBoxPickupEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestMountForChargingEvent} [requestMountForChargingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForCharging: async (requestMountForChargingEvent?: RequestMountForChargingEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/requestMountForCharging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMountForChargingEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestMountForWaitingEvent} [requestMountForWaitingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForWaiting: async (requestMountForWaitingEvent?: RequestMountForWaitingEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/requestMountForWaiting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMountForWaitingEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SyncStopStateRequest} [syncStopStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestSyncStopState: async (syncStopStateRequest?: SyncStopStateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/requestSyncStopState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncStopStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartTourEvent} [startTourEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStartTour: async (startTourEvent?: StartTourEvent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/startTour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startTourEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SyncStopArrivalMessage} [syncStopArrivalMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSyncStopArrival: async (syncStopArrivalMessage?: SyncStopArrivalMessage, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/syncStopArrival`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncStopArrivalMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VehicleUpdate} [vehicleUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateVehicle: async (vehicleUpdate?: VehicleUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/fzi/event/vehicle/updateVehicle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicleUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleEventApi - functional programming interface
 * @export
 */
export const VehicleEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VehicleBoxDeliveryEvent} [vehicleBoxDeliveryEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeliverBoxToMount(vehicleBoxDeliveryEvent?: VehicleBoxDeliveryEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeliverBoxToMount(vehicleBoxDeliveryEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepartFromMountWaitingChargingEvent} [departFromMountWaitingChargingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDepartFromMountWaitingCharging(departFromMountWaitingChargingEvent?: DepartFromMountWaitingChargingEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDepartFromMountWaitingCharging(departFromMountWaitingChargingEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FinishTourEvent} [finishTourEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFinishTour(finishTourEvent?: FinishTourEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFinishTour(finishTourEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VehicleBoxPickupEvent} [vehicleBoxPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPickupBoxFromMount(vehicleBoxPickupEvent?: VehicleBoxPickupEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPickupBoxFromMount(vehicleBoxPickupEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestMountForBoxDeliveryEvent} [requestMountForBoxDeliveryEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestMountForBoxDelivery(requestMountForBoxDeliveryEvent?: RequestMountForBoxDeliveryEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestMountForBoxDeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestMountForBoxDelivery(requestMountForBoxDeliveryEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestMountForBoxPickupEvent} [requestMountForBoxPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestMountForBoxPickup(requestMountForBoxPickupEvent?: RequestMountForBoxPickupEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestMountForBoxPickupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestMountForBoxPickup(requestMountForBoxPickupEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestMountForChargingEvent} [requestMountForChargingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestMountForCharging(requestMountForChargingEvent?: RequestMountForChargingEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestMountForChargingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestMountForCharging(requestMountForChargingEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestMountForWaitingEvent} [requestMountForWaitingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestMountForWaiting(requestMountForWaitingEvent?: RequestMountForWaitingEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestMountForWaitingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestMountForWaiting(requestMountForWaitingEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SyncStopStateRequest} [syncStopStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestSyncStopState(syncStopStateRequest?: SyncStopStateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncStopStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestSyncStopState(syncStopStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartTourEvent} [startTourEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStartTour(startTourEvent?: StartTourEvent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStartTour(startTourEvent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SyncStopArrivalMessage} [syncStopArrivalMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSyncStopArrival(syncStopArrivalMessage?: SyncStopArrivalMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSyncStopArrival(syncStopArrivalMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VehicleUpdate} [vehicleUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdateVehicle(vehicleUpdate?: VehicleUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUpdateVehicle(vehicleUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleEventApi - factory interface
 * @export
 */
export const VehicleEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleEventApiFp(configuration)
    return {
        /**
         * 
         * @param {VehicleBoxDeliveryEvent} [vehicleBoxDeliveryEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeliverBoxToMount(vehicleBoxDeliveryEvent?: VehicleBoxDeliveryEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postDeliverBoxToMount(vehicleBoxDeliveryEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartFromMountWaitingChargingEvent} [departFromMountWaitingChargingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepartFromMountWaitingCharging(departFromMountWaitingChargingEvent?: DepartFromMountWaitingChargingEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postDepartFromMountWaitingCharging(departFromMountWaitingChargingEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinishTourEvent} [finishTourEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFinishTour(finishTourEvent?: FinishTourEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postFinishTour(finishTourEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleBoxPickupEvent} [vehicleBoxPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPickupBoxFromMount(vehicleBoxPickupEvent?: VehicleBoxPickupEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postPickupBoxFromMount(vehicleBoxPickupEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestMountForBoxDeliveryEvent} [requestMountForBoxDeliveryEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForBoxDelivery(requestMountForBoxDeliveryEvent?: RequestMountForBoxDeliveryEvent, options?: any): AxiosPromise<RequestMountForBoxDeliveryResponse> {
            return localVarFp.postRequestMountForBoxDelivery(requestMountForBoxDeliveryEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestMountForBoxPickupEvent} [requestMountForBoxPickupEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForBoxPickup(requestMountForBoxPickupEvent?: RequestMountForBoxPickupEvent, options?: any): AxiosPromise<RequestMountForBoxPickupResponse> {
            return localVarFp.postRequestMountForBoxPickup(requestMountForBoxPickupEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestMountForChargingEvent} [requestMountForChargingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForCharging(requestMountForChargingEvent?: RequestMountForChargingEvent, options?: any): AxiosPromise<RequestMountForChargingResponse> {
            return localVarFp.postRequestMountForCharging(requestMountForChargingEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestMountForWaitingEvent} [requestMountForWaitingEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestMountForWaiting(requestMountForWaitingEvent?: RequestMountForWaitingEvent, options?: any): AxiosPromise<RequestMountForWaitingResponse> {
            return localVarFp.postRequestMountForWaiting(requestMountForWaitingEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncStopStateRequest} [syncStopStateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestSyncStopState(syncStopStateRequest?: SyncStopStateRequest, options?: any): AxiosPromise<SyncStopStateResponse> {
            return localVarFp.postRequestSyncStopState(syncStopStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartTourEvent} [startTourEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStartTour(startTourEvent?: StartTourEvent, options?: any): AxiosPromise<Response> {
            return localVarFp.postStartTour(startTourEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncStopArrivalMessage} [syncStopArrivalMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSyncStopArrival(syncStopArrivalMessage?: SyncStopArrivalMessage, options?: any): AxiosPromise<Response> {
            return localVarFp.postSyncStopArrival(syncStopArrivalMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VehicleUpdate} [vehicleUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateVehicle(vehicleUpdate?: VehicleUpdate, options?: any): AxiosPromise<Response> {
            return localVarFp.postUpdateVehicle(vehicleUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleEventApi - object-oriented interface
 * @export
 * @class VehicleEventApi
 * @extends {BaseAPI}
 */
export class VehicleEventApi extends BaseAPI {
    /**
     * 
     * @param {VehicleBoxDeliveryEvent} [vehicleBoxDeliveryEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postDeliverBoxToMount(vehicleBoxDeliveryEvent?: VehicleBoxDeliveryEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postDeliverBoxToMount(vehicleBoxDeliveryEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartFromMountWaitingChargingEvent} [departFromMountWaitingChargingEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postDepartFromMountWaitingCharging(departFromMountWaitingChargingEvent?: DepartFromMountWaitingChargingEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postDepartFromMountWaitingCharging(departFromMountWaitingChargingEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinishTourEvent} [finishTourEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postFinishTour(finishTourEvent?: FinishTourEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postFinishTour(finishTourEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleBoxPickupEvent} [vehicleBoxPickupEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postPickupBoxFromMount(vehicleBoxPickupEvent?: VehicleBoxPickupEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postPickupBoxFromMount(vehicleBoxPickupEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestMountForBoxDeliveryEvent} [requestMountForBoxDeliveryEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postRequestMountForBoxDelivery(requestMountForBoxDeliveryEvent?: RequestMountForBoxDeliveryEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postRequestMountForBoxDelivery(requestMountForBoxDeliveryEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestMountForBoxPickupEvent} [requestMountForBoxPickupEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postRequestMountForBoxPickup(requestMountForBoxPickupEvent?: RequestMountForBoxPickupEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postRequestMountForBoxPickup(requestMountForBoxPickupEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestMountForChargingEvent} [requestMountForChargingEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postRequestMountForCharging(requestMountForChargingEvent?: RequestMountForChargingEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postRequestMountForCharging(requestMountForChargingEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestMountForWaitingEvent} [requestMountForWaitingEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postRequestMountForWaiting(requestMountForWaitingEvent?: RequestMountForWaitingEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postRequestMountForWaiting(requestMountForWaitingEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncStopStateRequest} [syncStopStateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postRequestSyncStopState(syncStopStateRequest?: SyncStopStateRequest, options?: any) {
        return VehicleEventApiFp(this.configuration).postRequestSyncStopState(syncStopStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartTourEvent} [startTourEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postStartTour(startTourEvent?: StartTourEvent, options?: any) {
        return VehicleEventApiFp(this.configuration).postStartTour(startTourEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncStopArrivalMessage} [syncStopArrivalMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postSyncStopArrival(syncStopArrivalMessage?: SyncStopArrivalMessage, options?: any) {
        return VehicleEventApiFp(this.configuration).postSyncStopArrival(syncStopArrivalMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VehicleUpdate} [vehicleUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleEventApi
     */
    public postUpdateVehicle(vehicleUpdate?: VehicleUpdate, options?: any) {
        return VehicleEventApiFp(this.configuration).postUpdateVehicle(vehicleUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseApi - axios parameter creator
 * @export
 */
export const WarehouseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWarehousePlace: async (ident: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ident' is not null or undefined
            assertParamExists('deleteWarehousePlace', 'ident', ident)
            const localVarPath = `/api/data/warehouse/places/{ident}`
                .replace(`{${"ident"}}`, encodeURIComponent(String(ident)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    Supported finders      {         \"Ident\" => \"equal\"      }
         * @summary Uses the default EfCaWarehousePlace object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWarehousePlacesByFinder: async (efCaWarehousePlace?: EfCaWarehousePlace, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/warehouse/places/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaWarehousePlace, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddWarehousePlaces: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/warehouse/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWarehousePlace: async (efCaWarehousePlace?: EfCaWarehousePlace, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/data/warehouse/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaWarehousePlace, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseApi - functional programming interface
 * @export
 */
export const WarehouseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWarehousePlace(ident: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaWarehousePlaceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWarehousePlace(ident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    Supported finders      {         \"Ident\" => \"equal\"      }
         * @summary Uses the default EfCaWarehousePlace object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findWarehousePlacesByFinder(efCaWarehousePlace?: EfCaWarehousePlace, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaWarehousePlaceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findWarehousePlacesByFinder(efCaWarehousePlace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddWarehousePlaces(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaWarehousePlaceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddWarehousePlaces(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWarehousePlace(efCaWarehousePlace?: EfCaWarehousePlace, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaWarehousePlaceResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWarehousePlace(efCaWarehousePlace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseApi - factory interface
 * @export
 */
export const WarehouseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} ident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWarehousePlace(ident: string, options?: any): AxiosPromise<EfCaWarehousePlaceResp> {
            return localVarFp.deleteWarehousePlace(ident, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    Supported finders      {         \"Ident\" => \"equal\"      }
         * @summary Uses the default EfCaWarehousePlace object as finder but not all parameters are supported. Refer to the more detailled description below.
         * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findWarehousePlacesByFinder(efCaWarehousePlace?: EfCaWarehousePlace, options?: any): AxiosPromise<EfCaWarehousePlaceResp> {
            return localVarFp.findWarehousePlacesByFinder(efCaWarehousePlace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddWarehousePlaces(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaWarehousePlaceResp> {
            return localVarFp.postAddWarehousePlaces(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWarehousePlace(efCaWarehousePlace?: EfCaWarehousePlace, options?: any): AxiosPromise<EfCaWarehousePlaceResp> {
            return localVarFp.putWarehousePlace(efCaWarehousePlace, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseApi - object-oriented interface
 * @export
 * @class WarehouseApi
 * @extends {BaseAPI}
 */
export class WarehouseApi extends BaseAPI {
    /**
     * 
     * @param {string} ident 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public deleteWarehousePlace(ident: string, options?: any) {
        return WarehouseApiFp(this.configuration).deleteWarehousePlace(ident, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    Supported finders      {         \"Ident\" => \"equal\"      }
     * @summary Uses the default EfCaWarehousePlace object as finder but not all parameters are supported. Refer to the more detailled description below.
     * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public findWarehousePlacesByFinder(efCaWarehousePlace?: EfCaWarehousePlace, options?: any) {
        return WarehouseApiFp(this.configuration).findWarehousePlacesByFinder(efCaWarehousePlace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public postAddWarehousePlaces(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return WarehouseApiFp(this.configuration).postAddWarehousePlaces(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaWarehousePlace} [efCaWarehousePlace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public putWarehousePlace(efCaWarehousePlace?: EfCaWarehousePlace, options?: any) {
        return WarehouseApiFp(this.configuration).putWarehousePlace(efCaWarehousePlace, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * XServer2Api - axios parameter creator
 * @export
 */
export const XServer2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EfCaRouteCalculation} [efCaRouteCalculation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRoute: async (efCaRouteCalculation?: EfCaRouteCalculation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xserver/route/calculateroute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaRouteCalculation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaRouteCalculationStartEnd} [efCaRouteCalculationStartEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRouteStartEnd: async (efCaRouteCalculationStartEnd?: EfCaRouteCalculationStartEnd, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xserver/route/calculateRouteStartEnd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaRouteCalculationStartEnd, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packBins: async (efCaModelCollector?: EfCaModelCollector, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xserver/load/packbins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaModelCollector, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:  Supported values zipCode, city, street, houseNumber, country, state        POST /Todo      {            \"zipCode\": \"76131\",            \"city\": \"Karslruhe\",            \"street\": \"Haid und Neu\",            \"houseNumber\": \"15\",      }
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByAddress: async (efCaAddress?: EfCaAddress, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xserver/location/searchbyaddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST /Todo      {          \"longitude\": 6.130806,          \"latitude\": 49.61055      }
         * @summary Search  using coordinates
         * @param {EfCaCoordinate} [efCaCoordinate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByPosition: async (efCaCoordinate?: EfCaCoordinate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xserver/location/searchbyposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(efCaCoordinate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sample request:                    POST /Todo      {          \"text\": \"Illingen\"      }
         * @param {string} searchByText 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByText: async (searchByText: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchByText' is not null or undefined
            assertParamExists('searchByText', 'searchByText', searchByText)
            const localVarPath = `/api/xserver/location/searchbytext/{searchByText}`
                .replace(`{${"searchByText"}}`, encodeURIComponent(String(searchByText)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XServer2Api - functional programming interface
 * @export
 */
export const XServer2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XServer2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EfCaRouteCalculation} [efCaRouteCalculation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateRoute(efCaRouteCalculation?: EfCaRouteCalculation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaXServerResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateRoute(efCaRouteCalculation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaRouteCalculationStartEnd} [efCaRouteCalculationStartEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateRouteStartEnd(efCaRouteCalculationStartEnd?: EfCaRouteCalculationStartEnd, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaXServerResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateRouteStartEnd(efCaRouteCalculationStartEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async packBins(efCaModelCollector?: EfCaModelCollector, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaXServerResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.packBins(efCaModelCollector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:  Supported values zipCode, city, street, houseNumber, country, state        POST /Todo      {            \"zipCode\": \"76131\",            \"city\": \"Karslruhe\",            \"street\": \"Haid und Neu\",            \"houseNumber\": \"15\",      }
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByAddress(efCaAddress?: EfCaAddress, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaXServerResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByAddress(efCaAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST /Todo      {          \"longitude\": 6.130806,          \"latitude\": 49.61055      }
         * @summary Search  using coordinates
         * @param {EfCaCoordinate} [efCaCoordinate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByPosition(efCaCoordinate?: EfCaCoordinate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaXServerResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByPosition(efCaCoordinate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sample request:                    POST /Todo      {          \"text\": \"Illingen\"      }
         * @param {string} searchByText 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchByText(searchByText: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EfCaXServerResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchByText(searchByText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * XServer2Api - factory interface
 * @export
 */
export const XServer2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XServer2ApiFp(configuration)
    return {
        /**
         * 
         * @param {EfCaRouteCalculation} [efCaRouteCalculation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRoute(efCaRouteCalculation?: EfCaRouteCalculation, options?: any): AxiosPromise<EfCaXServerResp> {
            return localVarFp.calculateRoute(efCaRouteCalculation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaRouteCalculationStartEnd} [efCaRouteCalculationStartEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateRouteStartEnd(efCaRouteCalculationStartEnd?: EfCaRouteCalculationStartEnd, options?: any): AxiosPromise<EfCaXServerResp> {
            return localVarFp.calculateRouteStartEnd(efCaRouteCalculationStartEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EfCaModelCollector} [efCaModelCollector] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packBins(efCaModelCollector?: EfCaModelCollector, options?: any): AxiosPromise<EfCaXServerResp> {
            return localVarFp.packBins(efCaModelCollector, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:  Supported values zipCode, city, street, houseNumber, country, state        POST /Todo      {            \"zipCode\": \"76131\",            \"city\": \"Karslruhe\",            \"street\": \"Haid und Neu\",            \"houseNumber\": \"15\",      }
         * @param {EfCaAddress} [efCaAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByAddress(efCaAddress?: EfCaAddress, options?: any): AxiosPromise<EfCaXServerResp> {
            return localVarFp.searchByAddress(efCaAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST /Todo      {          \"longitude\": 6.130806,          \"latitude\": 49.61055      }
         * @summary Search  using coordinates
         * @param {EfCaCoordinate} [efCaCoordinate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByPosition(efCaCoordinate?: EfCaCoordinate, options?: any): AxiosPromise<EfCaXServerResp> {
            return localVarFp.searchByPosition(efCaCoordinate, options).then((request) => request(axios, basePath));
        },
        /**
         * Sample request:                    POST /Todo      {          \"text\": \"Illingen\"      }
         * @param {string} searchByText 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByText(searchByText: string, options?: any): AxiosPromise<EfCaXServerResp> {
            return localVarFp.searchByText(searchByText, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XServer2Api - object-oriented interface
 * @export
 * @class XServer2Api
 * @extends {BaseAPI}
 */
export class XServer2Api extends BaseAPI {
    /**
     * 
     * @param {EfCaRouteCalculation} [efCaRouteCalculation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XServer2Api
     */
    public calculateRoute(efCaRouteCalculation?: EfCaRouteCalculation, options?: any) {
        return XServer2ApiFp(this.configuration).calculateRoute(efCaRouteCalculation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaRouteCalculationStartEnd} [efCaRouteCalculationStartEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XServer2Api
     */
    public calculateRouteStartEnd(efCaRouteCalculationStartEnd?: EfCaRouteCalculationStartEnd, options?: any) {
        return XServer2ApiFp(this.configuration).calculateRouteStartEnd(efCaRouteCalculationStartEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EfCaModelCollector} [efCaModelCollector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XServer2Api
     */
    public packBins(efCaModelCollector?: EfCaModelCollector, options?: any) {
        return XServer2ApiFp(this.configuration).packBins(efCaModelCollector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:  Supported values zipCode, city, street, houseNumber, country, state        POST /Todo      {            \"zipCode\": \"76131\",            \"city\": \"Karslruhe\",            \"street\": \"Haid und Neu\",            \"houseNumber\": \"15\",      }
     * @param {EfCaAddress} [efCaAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XServer2Api
     */
    public searchByAddress(efCaAddress?: EfCaAddress, options?: any) {
        return XServer2ApiFp(this.configuration).searchByAddress(efCaAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST /Todo      {          \"longitude\": 6.130806,          \"latitude\": 49.61055      }
     * @summary Search  using coordinates
     * @param {EfCaCoordinate} [efCaCoordinate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XServer2Api
     */
    public searchByPosition(efCaCoordinate?: EfCaCoordinate, options?: any) {
        return XServer2ApiFp(this.configuration).searchByPosition(efCaCoordinate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sample request:                    POST /Todo      {          \"text\": \"Illingen\"      }
     * @param {string} searchByText 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XServer2Api
     */
    public searchByText(searchByText: string, options?: any) {
        return XServer2ApiFp(this.configuration).searchByText(searchByText, options).then((request) => request(this.axios, this.basePath));
    }
}


