import axios from "axios";
import {checkLoggedIn, logOut} from "../../app/auth/AuthUtils";

export default function setupInterceptors(history : any) {
    axios.interceptors.response.use(function (response) {
        return response;
    }, async function (error) {
        if (401 === error.response.status) {
            await logOut(history, true);
        } else {
            return Promise.reject(error);
        }
    });
    axios.interceptors.request.use(function (config) {
        if (checkLoggedIn()) {
            config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
}