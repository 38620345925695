import React from 'react'
import BoxForActivationsScanner from "../../../components/scanner/BoxForActivationsScanner";
import BoxEl from "./BoxEl";
import {BoxInformation} from "../../../../apiGenerator/axios";

interface ParentEl {
    boxes: BoxInformation[],
    scannedBoxIndex: number,
    setScannedBoxIndex: Function
}

const ScanStoredBox = ({boxes, scannedBoxIndex, setScannedBoxIndex}: ParentEl) => {
    return (
        <div>
            <BoxForActivationsScanner boxes={boxes} setScannedBoxIndex={setScannedBoxIndex} />
            {scannedBoxIndex !== -1 && (
                <div key={boxes[scannedBoxIndex].id} className="row">
                    <BoxEl scannedBox={boxes[scannedBoxIndex]}/>
                </div>
            )}
        </div>
    )
}

export default ScanStoredBox
