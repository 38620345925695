import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import BoxInformationSummary from "./BoxInformationSummary";
import {BoxInformation} from "../../../apiGenerator/axios";
import BoxInformationExpanded from "./BoxInformationExpanded";

interface ParentEl {
    box: BoxInformation;
    setScannedBoxIndex: Function;
}

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '1px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const  BoxInformationAccordion = ({box, setScannedBoxIndex}: ParentEl) => {

    return (
        <div key={box.id} className="row">
            <Accordion square expanded={true}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <BoxInformationSummary box={box}/>
                </AccordionSummary>
                <AccordionDetails>
                    <BoxInformationExpanded box={box} setScannedBoxIndex={setScannedBoxIndex}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default BoxInformationAccordion
