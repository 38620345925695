import { initReactI18next} from "react-i18next";
import i18n from "i18next";
import main from './texts/de/main.json'
import commissioning from './texts/de/commissioning.json'
import scanners from './texts/de/scanners.json'
import parcelHandling from './texts/de/parcelHandling.json'
import incomingBoxes from './texts/de/incomingBoxes.json'
import buttons from './texts/de/buttons.json'
import outboundParcels from './texts/de/outboundParcels.json'
import selfPickup from './texts/de/selfPickup.json'
import kepPickup from './texts/de/kepPickup.json'
import boxActivation from './texts/de/boxActivation.json'
import boxStorage from './texts/de/boxStorage.json'
import selfDropoff from './texts/de/selfDropoff.json'
import boxDeactivation from './texts/de/boxDeactivation.json'
import vehicleHandling from './texts/de/vehicleHandling.json'
import boxHandling from './texts/de/boxHandling.json'
import recyclableBoxes from './texts/de/recyclableBoxes.json'
import notifications from './texts/de/notifications.json'

const resources = {
    de: {
        translation: {
            main: main,
            commissioning: commissioning,
            scanners: scanners,
            parcelHandling: parcelHandling,
            incomingBoxes: incomingBoxes,
            outboundParcels: outboundParcels,
            buttons: buttons,
            selfPickup: selfPickup,
            kepPickup: kepPickup,
            boxActivation: boxActivation,
            boxStorage: boxStorage,
            selfDropoff: selfDropoff,
            boxDeactivation: boxDeactivation,
            vehicleHandling: vehicleHandling,
            boxHandling: boxHandling,
            recyclableBoxes: recyclableBoxes,
            notifications: notifications
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        lng: 'de',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: resources,
    });

export default i18n;
