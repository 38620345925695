import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import VehicleInformationSummary from "./VehicleInformationSummary";
import {EfCaVehicle,VehicleInformation} from "../../../apiGenerator/axios";
import VehicleInformationExpanded from "./VehicleInformationExpanded";

interface ParentEl {
    vehicle: EfCaVehicle;
    vehicleInformation: VehicleInformation;
    setScannedVehicleIndex: Function;
    setScannedVehicleInformationIndex: Function;

}

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '1px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const  VehicleInformationAccordion = ({vehicle, vehicleInformation, setScannedVehicleIndex, setScannedVehicleInformationIndex}: ParentEl) => {

    return (
        <div key={vehicle.ident} className="row">
            <Accordion square expanded={true}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <VehicleInformationSummary vehicle={vehicle} vehicleInformation={vehicleInformation}/>
                </AccordionSummary>
                <AccordionDetails>
                    <VehicleInformationExpanded vehicle={vehicle} vehicleInformation={vehicleInformation} setScannedVehicleIndex={setScannedVehicleIndex} setScannedVehicleInformationIndex={setScannedVehicleInformationIndex}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default VehicleInformationAccordion
