import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {BiEnvelope} from "react-icons/all";
import {Parcel} from "../../../apiGenerator/axios";
import Button from "@material-ui/core/Button";
import "../parcelHandling/labelling/ParcelHandlingStyles.css"
import {useTranslation} from "react-i18next";
import ParcelImage from "../parcelHandling/labelling/ParcelImage";

interface ParentEl {
    parcel: Parcel;
    setScannedParcelIndex: Function
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
    }),
);

const ParcelInformationExpanded= ({parcel, setScannedParcelIndex}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={5} style={{ marginTop: '-25px', marginBottom: '-25px'}}>
                    <div>
                        <ParcelImage parcelId={parcel.efeuPackageId}/>
                    </div>
                    <br/>
                    <div className='text-bottom'>
                        {t('parcelHandling.labelling.parcelExpanded.warehouse')}
                        {parcel.warehouse}
                    </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <div className={'text'}>
                        <div className={'headline'}>
                            {t('parcelHandling.labelling.parcelExpanded.recAddress')}
                        </div>
                        {parcel.recipient?.address?.street} {parcel.recipient?.address?.houseNumber} <br />
                        {parcel.recipient?.address?.zipCode} {parcel.recipient?.address?.city}
                    </div>
                    <div className={'text'}>
                        <div className={'headline'}>
                            {t('parcelHandling.labelling.parcelExpanded.sendAddress')}
                        </div>
                        {parcel.sender?.address?.street} {parcel.sender?.address?.houseNumber} <br />
                        {parcel.sender?.address?.zipCode} {parcel.sender?.address?.city}
                    </div>
                    <div className={'text'}>
                        <div className={'headline'}>
                            {t('parcelHandling.labelling.parcelExpanded.dimensions.name')}
                        </div>
                        {t('parcelHandling.labelling.parcelExpanded.dimensions.length')}
                        {parcel.dimensions?.lenght} {t('parcelHandling.labelling.parcelExpanded.dimensions.unit')}
                        &nbsp;&nbsp;&nbsp;
                        {t('parcelHandling.labelling.parcelExpanded.dimensions.width')}
                        {parcel.dimensions?.width} {t('parcelHandling.labelling.parcelExpanded.dimensions.unit')}
                        &nbsp;&nbsp;&nbsp;
                        {t('parcelHandling.labelling.parcelExpanded.dimensions.height')}
                        {parcel.dimensions?.height} {t('parcelHandling.labelling.parcelExpanded.dimensions.unit')}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
export default ParcelInformationExpanded


