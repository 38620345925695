import React from 'react'
import {IntraLogAppApi, Parcel} from "../../../apiGenerator/axios";
import Async from "react-async";
import OutboundParcelStepper from "./OutboundParcelStepper";
import {useTranslation} from "react-i18next";

interface ParentEl {
    parcels: Parcel[];
}

const WareHousePlaceFetching = ({parcels}: ParentEl) => {
    const {t} = useTranslation();
    const loadWarehousePlaces = () =>
        new IntraLogAppApi().getWarehousePlaces()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)
    return (
        <div>
            <Async promiseFn = {loadWarehousePlaces}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <div>
                                <OutboundParcelStepper parcels={parcels} warehousePlaces={data} />
                            </div>
                        )
                }}
            </Async>
        </div>
    )
}

export default WareHousePlaceFetching
