import React from 'react'
import QrReader from "react-qr-reader";
import {EfCaVehicle,VehicleInformation} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";
interface ParentEl {
    setScannedVehicleIndex: Function;
    setScannedVehicleInformationIndex: Function;
    vehicles: EfCaVehicle[];
    vehicleInformations: VehicleInformation[];
}

const VehicleInformationScanner = ({setScannedVehicleIndex, setScannedVehicleInformationIndex, vehicles, vehicleInformations}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }

    const [state, setState] = React.useState(initialState);

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = vehicles.findIndex(vehicle => vehicle.ident === state.result);
            const scannedInformationIndex = vehicleInformations.findIndex(vehicle => vehicle.vehicleIdent === state.result);
            if (state.result !== 'No result' && scannedIndex === -1 && scannedIndex === -1) {
                window.alert(t('scanners.vehicle.error') + state.result + t('scanners.vehicle.unavailable'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })

            }
            else if (scannedIndex !== -1 && scannedInformationIndex !== -1) {
                setScannedVehicleIndex(scannedIndex);
                setScannedVehicleInformationIndex(scannedInformationIndex);
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '50%' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default VehicleInformationScanner
