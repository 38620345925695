import React from 'react'
import { Async } from 'react-async';
import {useTranslation} from "react-i18next";
import {IntraLogAppApi} from "../../../apiGenerator/axios";
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";
import BoxDeactivationStepper from "./BoxDeactivationStepper";

interface ParentEl {
    nextComponent: Function
}

const BoxesForDeactivationFetching = ({}: ParentEl) => {
    const {t} = useTranslation();

    const getParcels = () =>
        new IntraLogAppApi().getBoxInformations()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)

    return (
        <div>
            <AppBarCom showIcons={true}/>

            <NotificationFetching />
            <div className="containerHeadline">{t('boxDeactivation.headline')}</div>
            <Async promiseFn = {getParcels}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <BoxDeactivationStepper boxes={data} />
                        )
                }}
            </Async>
        </div>
    )
}

export default BoxesForDeactivationFetching

