import React from 'react'
import {Parcel} from "../../../../apiGenerator/axios";
import WarehouseParcelScanner from "../../../components/scanner/WarehouseParcelScanner";
import {Grid} from "@material-ui/core";
import {ImCheckboxChecked} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import ParcelImage from "../labelling/ParcelImage";

interface ParentEl {
    parcels: Parcel [];
    setScannedIndex: Function;
    scannedIndex: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const ScanParcel = ({parcels, scannedIndex, setScannedIndex}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    if (scannedIndex !== -1){
        const parcel = parcels[scannedIndex]
        return (
            <div>
                <Grid container spacing={1} className='grid-green' >
                    <Grid container item xs={9} spacing={0}>
                        {parcel.efeuPackageId}
                    </Grid>
                    <Grid container item xs={2} spacing={0}>
                        {parcel.warehouse?.substr(0,3)}
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        <ImCheckboxChecked size={40} style={{ color: green[500], margin: "2px" }}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid container item justifyContent="center">
                        <ParcelImage parcelId={parcel.efeuPackageId}/>
                    </Grid>
                </Grid>
                <Button
                    className={classes.button}
                    variant="contained"
                    color = "secondary"
                    style={{
                        marginBottom: "7px"
                    }}
                    onClick={() => setScannedIndex(() => -1)}
                >
                    {t('buttons.scanDifferentParcel')}
                </Button>
            </div>
        )
    }
    else {
        return (
            <div>
                <WarehouseParcelScanner parcels={parcels} setScanned={setScannedIndex} />
            </div>
        )
    }

}

export default ScanParcel
