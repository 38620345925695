import React from 'react'
import {Contact, IntraLogAppApi, Parcel} from "../../../../apiGenerator/axios";
import Async from "react-async";
import {useTranslation} from "react-i18next";
import ScanParcels from "./ScanParcels";

interface ParentEl {
    scannedContact: Contact;
    scannedParcels: boolean[];
    setScannedParcels: Function;
    parcels: Parcel[];
    setParcels: Function;
    setAllParcelsScanned: Function;
}

const ParcelFetching = ({scannedContact, scannedParcels, setScannedParcels, parcels, setParcels, setAllParcelsScanned}: ParentEl) => {
    const {t} = useTranslation();
    const loadParcels = () =>
        new IntraLogAppApi().getSelfPickupParcelsForContact(scannedContact.ident)
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)
    return (
        <div>
            <Async promiseFn = {loadParcels}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data";
                    if (isLoading) return "Loading...";
                    if (data.length === 0) return t('selfPickup.noPackages');
                    if (data.length > 0)
                        return (
                            <ScanParcels setScannedParcels={setScannedParcels}
                                         scannedParcels={scannedParcels}
                                         parcels={parcels}
                                         setParcels={setParcels}
                                         rawParcels={data}
                                         setAllParcelsScanned={setAllParcelsScanned}
                            />
                        );
                }}
            </Async>
        </div>
    )
}

export default ParcelFetching
