import React from 'react'
import { Grid} from "@material-ui/core";
import {ImCheckboxChecked, BsTrash, VscError} from "react-icons/all";
import {IntraLogAppApi, Parcel} from "../../../../apiGenerator/axios";
import {Col, Row} from "reactstrap";
import {green} from "@material-ui/core/colors";
import {useTranslation} from "react-i18next";

interface ParentEl {
    parcel: Parcel,
    parcels: Parcel[],
    setParcels: Function
}

const OutboundOverviewParcelEl = ({parcel, parcels, setParcels}: ParentEl) => {

    // const deleteOrder = () => {
    //     // check at least 1 packageOrder per Box
    //     new IntraLogAppApi().postDeleteCreatedPackageOrder(parcel.efeuPackageId)
    //     setParcels(parcels.filter(p => parcel !== p))
    // }


    let style =  'box-grid-yellow'
    const {t} = useTranslation();
    const dataRegistered = parcel.kepProviders !== null && parcel.kepProviders.length > 0;
    let icon = dataRegistered ? <ImCheckboxChecked size={25} style={{ color: green[500], margin: 0 }}/>: <div>
        <VscError size={25} style={{ margin: 0, cursor: "pointer"}} />
        {/* <br />
        <BsTrash size={25} style={{margin: 0, color: "#DC143C" }} onClick={deleteOrder}/> */}
        </div>

    return (
        <div className='gridContainer-box'>
            <Grid container spacing={1} className={style} >
                <Grid container item xs={4} spacing={0} style={{justifyContent: 'center'}}>
                    {t('outboundParcels.overview.id')}
                </Grid>
                <Grid container item xs={2} spacing={0} style={{justifyContent: 'center'}}>
                    {t('outboundParcels.overview.to')}
                </Grid>
                <Grid container item xs={2} spacing={0}>
                    <Col>
                        {t('outboundParcels.overview.from')}
                    </Col>
                </Grid>
                <Grid container item xs={2} spacing={0} style={{justifyContent: 'center'}}>
                    {t('outboundParcels.overview.kep')}
                </Grid>
                <Grid container item xs={2} spacing={0} style={{justifyContent: 'center'}}>
                    {t('outboundParcels.overview.dataRegistered')}
                </Grid>
                <Grid container item xs={4} spacing={0} className='smallFont'>
                    {parcel.efeuPackageId}
                </Grid>
                <Grid container item xs={2} spacing={0} style={{justifyContent: 'center'}}>
                    <Row className='smallFont'>
                        {parcel.recipient?.firstName} {parcel.recipient?.lastName}
                    </Row>
                </Grid>
                <Grid container item xs={2} spacing={0} style={{justifyContent: 'center'}}>
                    <Row className='smallFont'>
                        {parcel.sender?.firstName} {parcel.sender?.lastName}
                    </Row>
                </Grid>
                <Grid container item xs={2} spacing={0} className='smallFont'>
                    {parcel.kepProviders.join(", ")}
                </Grid>
                <Grid container item xs={2} spacing={0} className='smallFont'>
                    {icon}
                </Grid>
            </Grid>

        </div>
    )
}

export default OutboundOverviewParcelEl
