import React from 'react'
import {KepProviderEnum, Parcel} from "../../../../apiGenerator/axios";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface ParentEl {
    selectedKep: KepProviderEnum;
    setSelectedKep: Function;
    parcels: Parcel[];
    setKepParcels: Function;
    setScannedParcels: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

const KepSelector = ({selectedKep, setSelectedKep, parcels, setKepParcels, setScannedParcels}: ParentEl) => {
    const classes = useStyles();
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        // @ts-ignore
        const kep: KepProviderEnum = event.target.value
        setSelectedKep(kep);
        const filteredParcels = parcels.filter(p => p.kepProviders.includes(kep));
        setKepParcels(filteredParcels);
        const scannedParcels = new Array<boolean>(filteredParcels.length).fill(false);
        setScannedParcels(scannedParcels);
    };
    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">KEP</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedKep}
                    onChange={handleChange}
                >
                    <MenuItem value={KepProviderEnum.Dhl}>DHL</MenuItem>
                    <MenuItem value={KepProviderEnum.Hermes}>Hermes</MenuItem>
                    <MenuItem value={KepProviderEnum.Dpd}>DPD</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default KepSelector
