import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {BiEnvelope} from "react-icons/all";
import {BoxInformation} from "../../../apiGenerator/axios";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import Button from "@material-ui/core/Button";
import {green} from "@material-ui/core/colors";
import "../boxHandling//BoxHandlingStyles.css"
import {useTranslation} from "react-i18next";
//import BoxImage from "../boxHandling/labelling/BoxImage";

interface ParentEl {
    box: BoxInformation;
    setScannedBoxIndex: Function
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
    }),
);

const BoxInformationExpanded= ({box, setScannedBoxIndex}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();

    let statusText = t('boxHandling.boxinformation.status.unknown')

    // let activationCheckbox = <ImCheckboxUnchecked size={19} color="disabled" style={{ margin: "7px" }}/>
    // if (!box.availableForActivation){
    //     activationCheckbox = <ImCheckboxChecked size={19} style={{ color: green[500], margin: "7px" }}/>
    // }

    // let deactivationCheckbox = <ImCheckboxUnchecked size={19} color="disabled" style={{ margin: "7px" }}/>
    // if (!box.availableForDeactivation){
    //     deactivationCheckbox = <ImCheckboxChecked size={19} style={{ color: green[500], margin: "7px" }}/>
    // }

    // let storageCheckbox = <ImCheckboxUnchecked size={19} color="disabled" style={{ margin: "7px" }}/>
    // if (!box.availableForStorage){
    //     storageCheckbox = <ImCheckboxChecked size={19} style={{ color: green[500], margin: "7px" }}/>
    // }

    /*
                                        act     deact   stor
        Possible states:    inUse:      false   false   false
                            active:     false   true    true
                            inactive:   true    false   true
                            stored:     true    true    false
    */

    if (!box.availableForActivation && !box.availableForDeactivation && !box.availableForStorage) {
        statusText = t('boxHandling.boxinformation.status.inUse')
    }
    if (!box.availableForActivation && box.availableForDeactivation && box.availableForStorage) {
        statusText = t('boxHandling.boxinformation.status.active')
    }
    if (box.availableForActivation && !box.availableForDeactivation && box.availableForStorage) {
        statusText = t('boxHandling.boxinformation.status.inactive')
    }
    if (box.availableForActivation && box.availableForDeactivation && !box.availableForStorage) {
        statusText = t('boxHandling.boxinformation.status.stored')
    }




    let mountVehicleGrid = <div></div>
    if (box.mountIdent !== undefined) {
        mountVehicleGrid = (
        <Grid container spacing={2} justifyContent="center" className='grid-yellow'>
            <Grid container item xs={12} justifyContent="center">
            <div className={'text'}>
                    <div className={'headline'}>
                        {t('boxHandling.boxinformation.mountName')}
                    </div>
                {box.mountName} <br/> ({box.mountIdent})
            </div>

            </Grid>
        </Grid>
        )
    }
    if (box.vehicleIdent !== undefined) {
        mountVehicleGrid = (
            <Grid container spacing={2} justifyContent="center" className='grid-yellow'>
                <Grid container item xs={12} justifyContent="center">
                <div className={'text'}>
                        <div className={'headline'}>
                        {t('boxHandling.boxinformation.vehicleName')}
                        </div>
                    {box.vehicleName} <br/> ({box.vehicleIdent})
                </div>
                </Grid>
            </Grid>
        )
    }


    let currentBoxOrder = <div></div>
    if (box.currentBoxOrderIdent !== undefined) {
        currentBoxOrder = (
            <Grid container spacing={2} justifyContent="center" className='grid-green'>
                <Grid container item xs={12} justifyContent="center">
                <div className={'text'}>
                        <div className={'headline'}>
                        {t('boxHandling.boxinformation.currentBoxOrder')}
                        </div>
                    {box.currentBoxOrderIdent}
                </div>
                </Grid>
            </Grid>
        )
    }

    let boxOrders = <div></div>
    if (box.boxOrderIdents !== undefined && box.boxOrderIdents.length > 0) {
        boxOrders = (
            <Grid container spacing={2} justifyContent="center" className='grid-yellow'>
                <div className={'text'}>
                    <div className={'headline'}>
                        {t('boxHandling.boxinformation.boxOrders')}
                    </div>
                </div>
                {box.boxOrderIdents.map((orderIdent: string, index: number)=> (
                    <Grid container item xs={12} justifyContent="center">
                        <div className={'text'}>
                            {orderIdent}
                        </div>
                    </Grid>
                ))}

            </Grid>


        )
    }

    return (
            <div className={classes.root}>
                <Grid container item xs={12} justifyContent="center">
                    <div className={'text'}>
                        <div className={'headline'}>
                            {t('boxHandling.boxinformation.status.headline')}
                        </div>
                        {statusText}
                    </div>
                </Grid>
                <br/>
            {mountVehicleGrid}
            <br/>
            {currentBoxOrder}
            <br/>
            {boxOrders}

        </div>
    );
}
export default BoxInformationExpanded


