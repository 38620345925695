import React from 'react'
import QrReader from "react-qr-reader";
import {MountingDevice} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";

interface ParentEl {
    mountingDevices: MountingDevice [];
    setScanned: Function;
}

const InboundBoxScanner = ({mountingDevices, setScanned}: ParentEl) => {
    const {t} = useTranslation();
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);


    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedBoxIndex = mountingDevices.findIndex(box => box.boxId === state.result);

            // Check if scanned Box is inbound
            let inboundBoxIndex = -1;
            if (scannedBoxIndex !== -1){
                inboundBoxIndex = mountingDevices[scannedBoxIndex].boxIsInbound ? scannedBoxIndex : -1;
            }
            if (state.result !== 'No result' && inboundBoxIndex === -1) {
                window.alert(t('scanners.box.error') + state.result +
                    t('scanners.box.unavailable'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })

            }
            if (scannedBoxIndex !== -1) {
                setScanned(() => mountingDevices.findIndex(mount => mount.boxId === state.result));
            }
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  showViewFinder={false}
                  style={{ width: '80%' }}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default InboundBoxScanner
