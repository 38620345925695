import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Link, useHistory} from "react-router-dom";
import {DepotEventApi, Parcel, WarehousePlace} from "../../../../apiGenerator/axios";
import ScanParcel from "./ScanParcel";
import ScanWarehousePlace from "./ScanWarehousePlace";
import CompleteStorage from "./CompleteStorage";
import {useTranslation} from "react-i18next";

interface ParentEl {
    parcels: Parcel[];
    warehousePlaces: WarehousePlace[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('parcelHandling.warehouse.steps.step1'),
        t('parcelHandling.warehouse.steps.step2'),
        t('parcelHandling.warehouse.steps.step3')];
}

function getStepContent(step: number, t: any) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return '';
        case 2:
            return t('parcelHandling.warehouse.stepContent');
        default:
            return 'Unknown step';
    }
}

function getStepApp(step: number, parcels: Parcel[], warehousePlaces: WarehousePlace[],
                    scannedParcelIndex: number, setScannedParcelIndex: Function,
                    scannedWarehousePlaceIndex: number, setScannedWarehousePlaceIndex: Function,
                    parcelsLeft: number) {
    switch (step) {
        case 0:
            return <ScanParcel parcels={parcels} scannedIndex={scannedParcelIndex}
                               setScannedIndex={setScannedParcelIndex} />
        case 1:
            if (scannedParcelIndex > -1){
                return <ScanWarehousePlace warehousePlaces={warehousePlaces}
                                           warehouseType={parcels[scannedParcelIndex].warehouse}
                                           scannedIndex={scannedWarehousePlaceIndex}
                                           setScannedIndex={setScannedWarehousePlaceIndex} />
            }
            else {
                return 'Error in ScanWarehousePlace'
            }
        case 2:
            if (scannedParcelIndex > -1 && scannedWarehousePlaceIndex > -1){
                return <CompleteStorage parcelIdent={parcels[scannedParcelIndex].efeuPackageId}
                                        warehousePlaceIdent={warehousePlaces[scannedWarehousePlaceIndex].extId}
                                        parcelsLeft={parcelsLeft}/>
            }
            else{
                return 'Error in CompleteStorage'
            }

        default:
            return
    }
}

export default function WarehouseStepper({parcels, warehousePlaces}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [scannedParcelIndex, setScannedParcelIndex] = React.useState(-1);
    const [scannedWarehousePlaceIndex, setScannedWarehousePlaceIndex] = React.useState(-1);
    const steps = getSteps(t);
    const parcelsLeft = parcels.length - 1;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length-1){
            handleStorage(parcels[scannedParcelIndex].efeuPackageId,
                warehousePlaces[scannedWarehousePlaceIndex].extId);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const history = useHistory();
    function handleStorage(parcelIdent: string, warehousePlaceIdent: string) {
        new DepotEventApi().postCompleteParcelStorage({
            parcelIdent: parcelIdent,
            warehousePlaceIdent: warehousePlaceIdent
        }).then();
        history.push('');
    }

    const nextAvailable = (activeStep: number) => {
        if(activeStep === 0 && scannedParcelIndex !== -1) {
            return true;
        }
        if (activeStep === 1 && scannedWarehousePlaceIndex !== -1) {
            return true;
        }
        if (activeStep === 2) {
            return true;
        }
        return false;
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>{label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index, t)}</Typography>
                            {getStepApp(index, parcels, warehousePlaces, scannedParcelIndex, setScannedParcelIndex,
                                scannedWarehousePlaceIndex, setScannedWarehousePlaceIndex, parcelsLeft)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('buttons.back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={!nextAvailable(activeStep)}
                                    >
                                        {activeStep === steps.length - 1 ?
                                            t('buttons.confirmAndBackToMain') :
                                            t('buttons.next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>{t('parcelHandling.warehouse.messageCom')}</Typography>
                    <Link to='/commissioning/list'>
                        <Button className={classes.button}>
                            {t('buttons.backToCom')}
                        </Button>
                    </Link>
                </Paper>
            )}
        </div>
    );
}
