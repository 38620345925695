import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import BoxOrderInformationSummary from "./BoxOrderInformationSummary";
import {BoxOrderInformationAdmin} from "../../../apiGenerator/axios";
import BoxOrderInformationExpanded from "./BoxOrderInformationExpanded";

interface ParentEl {
    boxOrderInformation: BoxOrderInformationAdmin;
    parcelIdent: string;
    deletedParcels: string[];
    setDeletedParcels: Function;
}

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '1px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const  BoxOrderInformationInformationAccordion = ({boxOrderInformation, parcelIdent, deletedParcels, setDeletedParcels}: ParentEl) => {

    return (
        <div key={boxOrderInformation.ident} className="row">
            <Accordion square expanded={true}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <BoxOrderInformationSummary boxOrderInformation={boxOrderInformation}/>
                </AccordionSummary>
                <AccordionDetails>
                    <BoxOrderInformationExpanded boxOrderInformation={boxOrderInformation} parcelIdent={parcelIdent} deletedParcels={deletedParcels} setDeletedParcels={setDeletedParcels}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default BoxOrderInformationInformationAccordion
