import React from 'react';
import {DepotEventApi, Parcel, WarehousePlace} from "../../../../apiGenerator/axios";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import KepShelfEl from "./KepShelfEl";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import WarehousePlaceScanner from "../../../components/scanner/WarehousePlaceScanner";

interface ParentEl {
    parcel: Parcel,
    kepScanned: boolean,
    setKepScanned: Function,
    warehousePlaces: WarehousePlace[],
    setWarehousePlaceIdent: Function,
    warehousePlaceIdent: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const ScanOutboundParcel = ({parcel, kepScanned, setKepScanned, warehousePlaces, setWarehousePlaceIdent, warehousePlaceIdent}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const storeParcel = () => {
        new DepotEventApi()
            .postCompleteOutboundParcelStorage(
                {
                    parcelIdent: parcel.efeuPackageId,
                    warehousePlaceIdent: warehousePlaceIdent}).then(() =>
            history.push(''));
    }

    const scannerOrButton = (kepScanned) ?
        <Button
            variant="contained"
            color="primary"
            disabled={false}
            className={classes.button}
            onClick={() => storeParcel()}
        >
            {t('buttons.confirmAndBackToMain')}
        </Button> : <WarehousePlaceScanner warehouseType={parcel.warehouse}
                                           setScanned={setKepScanned}
                                           warehousePlaces={warehousePlaces}
                                           kepProviders={parcel.kepProviders}
                                           setWarehousePlaceIdent={setWarehousePlaceIdent}/>;
    return (
        <div>
            <KepShelfEl parcel={parcel} scanned={kepScanned} />
            {scannerOrButton}
        </div>
    )
}

export default ScanOutboundParcel
