import React from 'react'
import {Grid} from "@material-ui/core";
import {BsTrash} from "react-icons/all";
import {IntraLogAppApi} from "../../../../apiGenerator/axios";


interface ParentEl {
    parcelId: string,
    parcelIds: string[],
    setCreatedParcelIds: Function,
    setLabelPrinted: Function
}

const CreatedParcelEl = ({parcelId, parcelIds, setCreatedParcelIds, setLabelPrinted}: ParentEl) => {
    const deleteOrder = () => {
        setLabelPrinted(parcelIds.length > 1);
        new IntraLogAppApi().postDeleteCreatedPackageOrder(parcelId).then(() =>
            setCreatedParcelIds(parcelIds.filter(efeuParcelId => parcelId !== efeuParcelId))
        );
    }
    return (
        <div className='gridContainer' style={{width: '80%', marginLeft: '10%'}}>
            <Grid container spacing={1} className='box-grid-yellow' style={{fontSize: "12px"}}>
                <Grid container item xs={11} spacing={0}>
                    {parcelId}
                </Grid>
                <Grid container item xs={1} spacing={1} style={{justifyContent: "center"}}>
                    <BsTrash size={22} style={{ color: "#DC143C", margin: 0, cursor: "pointer" }} onClick={deleteOrder}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreatedParcelEl
