import React from 'react'
import {Avatar, Grid} from "@material-ui/core";
import {BoxInformation} from "../../../apiGenerator/axios";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import "../boxHandling/BoxHandlingStyles.css"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";

interface ParentEl {
    box: BoxInformation
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            marginLeft: theme.spacing(0.3),
            marginTop: theme.spacing(2)
        },
    }),
);

const BoxInformationSummary = ({box}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <div className='gridContainer'>
            <Grid container spacing={1} className='grid-gray' >
                
                <Grid container item xs={6} spacing={0}>
                    <Col>
                        <Row>
                            {t('boxHandling.boxinformation.name')}
                        </Row>
                        <Row className='smallFont'>
                            {box.boxName}
                        </Row>
                    </Col>

                </Grid>
                
                <Grid container item xs={6} spacing={0}>
                    <Col>
                        <Row>
                            {t('boxHandling.boxinformation.type')}
                        </Row>
                        <Row className='smallFont'>
                            {box.boxType}
                        </Row>
                    </Col>

                </Grid>
                

            </Grid>
        </div>
    )
}

export default BoxInformationSummary
