import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";
import {EfCaVehicle,VehicleInformation} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";
import VehicleInformationScanner from "../../components/scanner/VehicleInformationScanner";
import VehicleInformationAccordion from "./VehicleInformationAccordion";

interface ParentEl {
    vehicles: EfCaVehicle[];
    vehicleInformations: VehicleInformation[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,

        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

export default function VehicleInformationRoot({vehicles, vehicleInformations}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [scannedVehicleIndex, setScannedVehicleIndex] = React.useState<number>(-1);
    const [scannedVehicleInformationIndex, setScannedVehicleInformationIndex] = React.useState<number>(-1);

    const history = useHistory();

    const handleBackToMain = () => {
        history.push('');
    }

    const handleScanDifferent = () => {
        setScannedVehicleIndex(-1);
        setScannedVehicleInformationIndex(-1);
    }


    return (
        <div className={classes.root}>
            <AppBarCom showIcons={true}/>

            <NotificationFetching />
            <div className="containerHeadline">{t('vehicleHandling.information.headline')}</div>
            {scannedVehicleIndex === -1 &&
            <VehicleInformationScanner setScannedVehicleIndex={setScannedVehicleIndex} setScannedVehicleInformationIndex={setScannedVehicleInformationIndex} vehicles={vehicles} vehicleInformations={vehicleInformations}/>}
            {scannedVehicleIndex !== -1 &&
            <VehicleInformationAccordion vehicle={vehicles[scannedVehicleIndex]} vehicleInformation={vehicleInformations[scannedVehicleInformationIndex]}
                                        setScannedVehicleIndex={setScannedVehicleIndex} setScannedVehicleInformationIndex={setScannedVehicleInformationIndex}/>}
            <div>
                <Button
                    disabled={false}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={handleScanDifferent}
                >
                    {t('buttons.scanDifferentVehicle')}
                </Button>

                <Button
                    variant="contained"
                    onClick={handleBackToMain}
                    className={classes.button}
                >
                    {t('buttons.backToMain')}
                </Button>
            </div>
        </div>
    );
}
