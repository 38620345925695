import React from 'react'
import {Parcel} from "../../../../apiGenerator/axios";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import SelfPickupParcelScanner from "../../../components/scanner/SelfPickupParcelScanner";
import ParcelEl from "./ParcelEl";

interface ParentEl {
    setScannedParcels: Function;
    scannedParcels: boolean[];
    parcels: Parcel[];
    setParcels: Function;
    rawParcels: Parcel[];
    setAllParcelsScanned: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const ScanParcels = ({setScannedParcels, scannedParcels, parcels, setParcels, rawParcels, setAllParcelsScanned}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <div>
            <SelfPickupParcelScanner setScannedParcels={setScannedParcels}
                                     scannedParcels={scannedParcels}
                                     parcels={parcels}
                                     setParcels={setParcels}
                                     rawParcels={rawParcels}
                                     setAllParcelsScanned={setAllParcelsScanned}
            />
            {rawParcels.map((parcel: Parcel, index: number)=> (
                <div key={parcel.efeuPackageId} className="row">
                    <ParcelEl parcel={parcel}
                              scanned={scannedParcels[index]}/>
                </div>
            ))}
        </div>
    )
}

export default ScanParcels
