import React from 'react'
import {Contact, EfCaOrder, Parcel} from "../../../../apiGenerator/axios";
import WarehouseParcelScanner from "../../../components/scanner/WarehouseParcelScanner";
import {Grid} from "@material-ui/core";
import {ImCheckboxChecked} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import SelfPickupCodeScanner from "../../../components/scanner/SelfPickupCodeScanner";
import ContactEl from "./ContactEl";

interface ParentEl {
    contacts: Contact[];
    scannedContact: Contact;
    setScannedContact: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const ScanPickupCode = ({contacts, scannedContact, setScannedContact}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <div>
            <SelfPickupCodeScanner contacts={contacts}
                                   setScannedContact={setScannedContact}
            />
            {scannedContact !== null && (
                <div key={scannedContact.ident} className="row">
                    <ContactEl scannedContact={scannedContact}/>
                </div>
            )}
        </div>
    )
}

export default ScanPickupCode
