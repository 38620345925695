import React from 'react'
import QrReader from "react-qr-reader";
import {KepProviderEnum, WarehousePlace} from "../../../apiGenerator/axios";
import {useTranslation} from "react-i18next";

interface ParentEl {
    warehousePlaces: WarehousePlace[];
    setScanned: Function;
    warehouseType: String;
    kepProviders: KepProviderEnum[] | undefined;
    setWarehousePlaceIdent: Function | undefined;
}

const WarehousePlaceScanner = ({warehousePlaces, warehouseType, setScanned, kepProviders, setWarehousePlaceIdent}: ParentEl) => {
    const {t} = useTranslation();
    let kepNames: string[] = [];
    if (kepProviders !== undefined && kepProviders !== null) {
        kepNames = kepProviders.map(k => k.toString());
    }
    const initialState = {
        result: 'No result',
        facingMode: "environment"
    }
    const [state, setState] = React.useState(initialState);

    const updateScannedStatus = (value: number) => {
        if (value === -1) {
            if (warehouseType === "KEP") {
                setScanned(false);
                setWarehousePlaceIdent(null);
            } else {
                setScanned(value);
            }
        } else  {
            if (warehouseType === "KEP") {
                setScanned(true);
                setWarehousePlaceIdent(warehousePlaces[value].extId)
            } else {
                setScanned(value);
            }
        }
    }

    const handleScan = (data: any) => {
        if (data) {
            setState({
                result: data,
                facingMode: "environment"
            })
            const scannedIndex = warehousePlaces.findIndex(wp => wp.extId === state.result);
            if (state.result !== 'No result' && scannedIndex === -1) {
                window.alert(t('scanners.warehousePlace.errorObject') + state.result
                    + t('scanners.warehousePlace.objSentenceEnd'))
                setState({
                    result: 'No result',
                    facingMode: "environment"
                })
            }
            else if (scannedIndex !== -1){
                if (warehouseType !== warehousePlaces[scannedIndex].warehouse){
                    window.alert(t('scanners.warehousePlace.errorWarehouse'));
                    updateScannedStatus(-1);
                    setState({
                        result: 'No result',
                        facingMode: "environment"
                    })
                }

                else if(warehouseType === "KEP" && !kepNames.includes(warehousePlaces[scannedIndex].placeName)) {
                    window.alert(t('scanners.warehousePlace.errorWarehouse'));
                    updateScannedStatus(-1);
                    setState({
                        result: 'No result',
                        facingMode: "environment"
                    })
                }
                else {
                    updateScannedStatus(scannedIndex);
                }
            }


        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }
  return (
      <div>
          <div className="scannerContainer">
              <QrReader
                  delay={300}
                  resolution={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '50%' }}
                  showViewFinder={false}
              />
          </div>
          <p>{state.result}</p>
      </div>
  )
}

export default WarehousePlaceScanner
