import React from 'react'
import {MountingDevice} from "../../../../apiGenerator/axios";
import MountingDeviceEl from "../../../components/mounting/MountingDeviceEl";
import Buttons from "./Buttons";
import EmptyMountScanner from "../../../components/scanner/EmptyMountScanner";

interface ParentEl {
    mountingDevices: MountingDevice[];
    setScannedMountIndex: Function;
    scannedMountIndex: number;
}

const ScanMountingDevice = ({mountingDevices, setScannedMountIndex, scannedMountIndex}: ParentEl) => {
    const boxAvailable: boolean[] = [];
    const mountAvailable: boolean[] = []


    for (const index in mountingDevices) {
        boxAvailable[index] = false;
        mountAvailable[index] = mountingDevices[index].mountAvailableForEmptyBox;
    }
    const highlightIndex: number = scannedMountIndex !== -1 ? scannedMountIndex : -1;
    const scannerOrButtons = (scannedMountIndex !== -1) ?
        <Buttons setScannedIndex={setScannedMountIndex}/> :
        <EmptyMountScanner mountingDevices={mountingDevices} setScanned={setScannedMountIndex} />

    return (
        <div>
            <div className="container">
                <div className="rowC">
                    {mountingDevices.map((device: MountingDevice, i: number)=> (
                        <div key={device.id}>
                            <MountingDeviceEl number={i+1} boxType={device.boxType}
                                              highlightedIndex={highlightIndex} available={boxAvailable[i]}
                                              mountAvailable={mountAvailable[i]}/>
                        </div>
                    ))}
                </div>
            </div>
            {scannerOrButtons}
        </div>
    )
}

export default ScanMountingDevice
