import React from 'react'
import {Parcel} from "../../../../apiGenerator/axios";
import ParcelEl from "./OutboundParcelEl";

interface ParentEl {
  parcels: Parcel[],
  scannedIndex: number
}

const InboundBoxes = ({parcels, scannedIndex}: ParentEl) => {
  return (
    <div>
      {parcels.map((parcel: Parcel, i: number)=> (
          <div key={parcel.efeuPackageId}>
            <ParcelEl parcel={parcel} scanned={scannedIndex===i} />
          </div>
      ))}
    </div>
  )
}

export default InboundBoxes
