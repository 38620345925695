import React from 'react'
import {VehicleApi,AdminAppApi} from "../../../apiGenerator/axios";
import {EfCaVehicle,VehicleInformation} from '../../../apiGenerator/axios';
import Async from "react-async";
import {useTranslation} from "react-i18next";
import VehicleInformationRoot from "./VehicleInformationRoot";

interface ApiResponse {
    vehicles: EfCaVehicle[];
    vehicleInformations: VehicleInformation[];
}

const VehicleInformationFetching = () => {
    const {t} = useTranslation();

    function getVehicles() {
        var resp:ApiResponse = {
            vehicles: [],
            vehicleInformations: []
        }
        return new VehicleApi().findVehiclesByFinder()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => {
                resp.vehicles = res.data.vehicles;
                return new AdminAppApi().getVehicleInformations()
                .then(res => (res.data ? res : Promise.reject(res)))
                .then(res => {
                    resp.vehicleInformations = res.data;
                    return resp;
                })
            });
    }

    return (
        <div>
            <Async promiseFn = {getVehicles}>
                {({ error, data, isLoading }) => {
                    if (error) {console.log(error); return "Could not fetch data"}
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <div>
                                <VehicleInformationRoot vehicles={data.vehicles} vehicleInformations={data.vehicleInformations}/>
                            </div>
                        )
                }}
            </Async>

        </div>
    )
}

export default VehicleInformationFetching

