import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Parcel} from "../../../../apiGenerator/axios";
import ParcelAccordion from "./ParcelAccordion";
import Button from "@material-ui/core/Button";
import "./ParcelHandlingStyles.css"
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface ParentEl {
    parcels: Parcel[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
    }),
);

const  NewParcels= ({parcels}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>();
    const [nextAv, setNextAv] = React.useState(
        parcels.filter(parcel => parcel.labelled && !parcel.warehousePlace).length > 0);
    const nextButtonText = nextAv ? t('buttons.nextPackageAv') :
        t('buttons.nextPackageUnav');
    const unlockWarehousing = () => {
        if (!nextAv){
            setNextAv(() => true);
        }
    }

    let noParcels = <div></div>;
    if (parcels.length === 0){
        noParcels = <div>{t('parcelHandling.labelling.noParcels')}</div>
    }

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    const history = useHistory();
    function handleClick(path: string) {
        history.push(path);
    }
    return (
        <div>
            {parcels.map((parcel: Parcel, index: number)=> (
                <div key={parcel.efeuPackageId} className="row">
                    <ParcelAccordion parcel={parcel} index={index} handleChange={handleChange} expanded={expanded}
                    unlockWarehousing={unlockWarehousing}/>
                </div>
            ))}
            {noParcels}
            <div className='buttonGroup'>
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => handleClick('')}
                >
                    {t('buttons.backToMain')}
                </Button>
                <Button
                    disabled={!nextAv}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => handleClick('warehouse')}
                >
                    {nextButtonText}
                </Button>
            </div>
        </div>

    );
}
export default NewParcels

