import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import {BoxInformation, DepotEventApi, MountingDevice} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import ScanStoredBox from "./step1/ScanStoredBox";
import ScanMountingDevice from "./step2/ScanMountingDevice";

interface ParentEl {
    mounts: MountingDevice[];
    boxes: BoxInformation[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('boxActivation.steps.step1'),
        t('boxActivation.steps.step2')];
}

function getStepContent(step: number, t: any) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return '';
        default:
            return 'Unknown step';
    }
}

function getStepApp(step: number, boxes: BoxInformation[], scannedBoxIndex: number, setScannedBoxIndex: Function,
                    mountingDevices: MountingDevice[], scannedMountIndex: number, setScannedMountIndex: Function) {
    switch (step) {
        case 0:
            return <ScanStoredBox boxes={boxes}
                                  scannedBoxIndex={scannedBoxIndex}
                                  setScannedBoxIndex={setScannedBoxIndex}/>
        case 1:
            return <ScanMountingDevice mountingDevices={mountingDevices}
                                       setScannedMountIndex={setScannedMountIndex}
                                       scannedMountIndex={scannedMountIndex} />
        default:
            return
    }
}

export default function BoxToMountStepper({mounts, boxes}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [scannedBoxIndex, setScannedBoxIndex] = React.useState(-1);
    const [scannedMountIndex, setScannedMountIndex] = React.useState(-1);
    const steps = getSteps(t);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length-1){
            if(activeStep === steps.length-1){
                confirmBoxToMount(boxes[scannedBoxIndex].id, mounts[scannedMountIndex].id);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const history = useHistory();

    function confirmBoxToMount(boxIdent: string | undefined, mountIdent: string | undefined) {
        if (boxIdent) {
            new DepotEventApi().postPlaceBoxOnMount({
                boxIdent: boxIdent,
                mountIdent: mountIdent
            }).then(() => history.push(''));
        }
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>
                            {label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index, t)}</Typography>
                            {getStepApp(index, boxes, scannedBoxIndex, setScannedBoxIndex,
                                mounts, scannedMountIndex, setScannedMountIndex)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('buttons.back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={(activeStep === 0 && scannedBoxIndex === -1)
                                        || (activeStep === 1  && scannedMountIndex === -1)}
                                    >
                                        {activeStep === steps.length - 1 ?
                                            t('buttons.confirmAndBackToMain') :
                                            t('buttons.next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
