import React from 'react'
import OutboundParcelScanner from "../../../components/scanner/OutboundParcelScanner";
import Button from "@material-ui/core/Button";
import OutboundParcels from "./OutboundParcels";
import {Parcel} from "../../../../apiGenerator/axios";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

interface ParentEl {
    parcels: Parcel[],
    scannedIndex: number,
    setScannedIndex: Function,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const ScanOutboundParcel = ({parcels, scannedIndex, setScannedIndex}: ParentEl) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const scannerOrButton = (scannedIndex === -1) ?
        <OutboundParcelScanner parcels={parcels} setScanned={setScannedIndex} />  :
        <Button
        variant="contained"
        color="secondary"
        disabled={false}
        className={classes.button}
        onClick={() => setScannedIndex(-1)}
    >
        {t('buttons.scanDifferentParcel')}
    </Button>;
    return (
        <div>
            {scannerOrButton}
            <OutboundParcels parcels={parcels} scannedIndex={scannedIndex}/>
        </div>
    )
}

export default ScanOutboundParcel
