import React from 'react'
import {Commission,MountingDevice} from "../../../../apiGenerator/axios";
import MountingDeviceEl from "../../../components/mounting/MountingDeviceEl";
import Task from "./Task";
import {useTranslation} from "react-i18next";

interface ParentEl {
  tasks: Commission[],
  selectedCommissions: boolean[],
  handleChange: Function,
  startCommission: Function,
  mounts: MountingDevice[],
  prematching: number[],
}


const Tasks = ({tasks, selectedCommissions, handleChange, startCommission, mounts, prematching}: ParentEl) => {
  const {t} = useTranslation();



  if(tasks.length === 0) {
    return (<div>
      {t('commissioning.main.noCommissions')}
    </div>)
  } else {
    console.log(prematching)
    return (
        <>
        Auf Docks verfügbare Boxen:
          <div className="container">
            <div className="rowC">
                {mounts.map((device: MountingDevice, i: number)=> (
                    <div key={device.id}>
                        <MountingDeviceEl number={i+1} boxType={device.boxType}
                                          highlightedIndex={-1} available={prematching.includes(i)}/>
                    </div>
                ))}
            </div>
        </div>



          {tasks.map((user: any, index: number) => (
              <div key={user.id} className="row">
                <Task task={user} selected={selectedCommissions[index]} handleChange={handleChange} commissionIndex={index} startCommission={startCommission} prematching={prematching[index]}/>
              </div>
          ))}

        </>
    )
  }
}

export default Tasks
