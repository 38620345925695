import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router-dom";
import {BoxInformation, DepotEventApi} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import ScanEmptyBoxForStorage from "./step1/ScanEmptyBoxForStorage";
import ScanStoredBox from "../boxActivation/step1/ScanStoredBox";
import ScanMountingDevice from "../boxActivation/step2/ScanMountingDevice";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import ConfirmBoxStorage from "./step2/ConfirmBoxStorage";

interface ParentEl {
    boxes: BoxInformation[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('boxStorage.steps.step1'),
        t('boxStorage.steps.step2')];
}

function getStepApp(step: number, boxes: BoxInformation[], scannedBoxIndex: number, setScannedBoxIndex: Function) {
    switch (step) {
        case 0:
            return <ScanEmptyBoxForStorage boxes={boxes}
                                           scannedBoxIndex={scannedBoxIndex}
                                           setScannedBoxIndex={setScannedBoxIndex}/>
        case 1:
            return <ConfirmBoxStorage/>
        default:
            return
    }
}

function getStepContent(step: number, t: any) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return '';
        default:
            return 'Unknown step';
    }
}

export default function BoxToWarehouseStepper({boxes}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [scannedBoxIndex, setScannedBoxIndex] = React.useState(-1);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps(t);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length-1){
            handleFinish();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = () => {
        const box = boxes[scannedBoxIndex];
        new DepotEventApi().postStoreBox({
            boxIdent: box.id
        }).then(() => history.push(''));
    };

    const history = useHistory();

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>
                            {label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index, t)}</Typography>
                            {getStepApp(index, boxes, scannedBoxIndex, setScannedBoxIndex)}
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        {t('buttons.back')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={(activeStep === 0 && scannedBoxIndex === -1)
                                        || (activeStep === 1  && scannedBoxIndex === -1)}
                                    >
                                        {activeStep === steps.length - 1 ?
                                            t('buttons.confirmAndBackToMain') :
                                            t('buttons.next')}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
