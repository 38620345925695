import React from 'react'
import {Grid} from "@material-ui/core";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import {green} from "@material-ui/core/colors";
import {Contact} from "../../../../apiGenerator/axios";

interface ParentEl {
    scannedContact: Contact
}

const PackageEl = ({scannedContact}: ParentEl) => {
    return (
        <div className='gridContainer'>
            <Grid container spacing={1} className='grid-green' >
                <Grid container item xs={4} spacing={0}>
                    {scannedContact.firstName}
                </Grid>
                <Grid container item xs={7} spacing={0}>
                    {scannedContact.lastName}
                </Grid>
                <Grid container item xs={1} spacing={0}>
                    <ImCheckboxChecked size={25} style={{ color: green[500], margin: "2px" }}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default PackageEl
