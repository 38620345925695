import React from 'react'
import {Contact, IntraLogAppApi, Parcel} from "../../../../apiGenerator/axios";
import Async from "react-async";
import {useTranslation} from "react-i18next";
import ScanPickupCode from "./ScanPickupCode";

interface ParentEl {
    scannedContact: Contact;
    setScannedContact: Function;
}

const ContactFetching = ({scannedContact, setScannedContact}: ParentEl) => {
    const {t} = useTranslation();
    const loadContacts = () =>
        new IntraLogAppApi().getContacts()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)
    return (
        <div>
            <Async promiseFn = {loadContacts}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <ScanPickupCode contacts={data}
                                            scannedContact={scannedContact}
                                            setScannedContact={setScannedContact}/>
                        )
                }}
            </Async>
        </div>
    )
}

export default ContactFetching
