import React from 'react'
import {IntraLogAppApi} from "../../../../apiGenerator/axios";
import AppBarCom from "../../../header/AppBarCom";
import NotificationFetching from "../../../notifications/NotificationFetching"
import Async from "react-async";
import WareHousePlaceFetching from "./WareHousePlaceFetching";

const TransitionParcelFetching = () => {
    const loadParcels = () =>
        new IntraLogAppApi().getTransitionParcels()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)
  return (
      <div>
          <AppBarCom showIcons={true}/>

        <NotificationFetching />
          <div className="containerHeadline">Paket einlagern</div>
          <Async promiseFn = {loadParcels}>
              {({ error, data, isLoading }) => {
                  if (error) return "Could not fetch data"
                  if (isLoading) return "Loading..."
                  if (data)
                      return (
                          <div>
                              <WareHousePlaceFetching parcels={data}/>
                          </div>
                      )
              }}
          </Async>
      </div>
  )
}

export default TransitionParcelFetching
