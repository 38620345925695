import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ParcelSummary from "./ParcelSummary";
import {Parcel} from "../../../../apiGenerator/axios";
import ParcelExpanded from "./ParcelExpanded";

interface ParentEl {
    parcel: Parcel;
    index: number;
    handleChange: Function;
    expanded: any;
    unlockWarehousing: Function;
}

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '1px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const  ParcelAccordion= ({parcel, index, handleChange, expanded, unlockWarehousing}: ParentEl) => {
    const [labelled, setLabelled] = React.useState(parcel.labelled)

    return (
        <div key={parcel.efeuPackageId} className="row">
            <Accordion square expanded={expanded ===  index.toString() } onChange={handleChange(index.toString())}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <ParcelSummary parcel={parcel} labelled={labelled}/>
                </AccordionSummary>
                <AccordionDetails>
                    <ParcelExpanded parcel={parcel} setLabelled={setLabelled} unlockWarehousing={unlockWarehousing}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default ParcelAccordion
