import React from 'react'
import {Grid} from "@material-ui/core";
import {Parcel} from "../../../../../apiGenerator/axios";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import {green} from "@material-ui/core/colors";

interface ParentEl {
    parcel: Parcel
    scanned: boolean
}

const ParcelEl = ({parcel, scanned}: ParentEl) => {
    if (scanned) {
        return (
            <div className='gridContainer'>
                <Grid container spacing={1} className='grid-green' >
                    <Grid container item xs={8} spacing={0}>
                        {parcel.efeuPackageId}
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        {parcel.warehouse?.substr(0,1)}
                    </Grid>
                    <Grid container item xs={2} spacing={0}>
                        {parcel.warehousePlace}
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        <ImCheckboxChecked size={25} style={{ color: green[500], margin: "2px" }}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
    else {
        return (
            <div className='gridContainer'>
                <Grid container spacing={1} className='grid-yellow' >
                    <Grid container item xs={8} spacing={0}>
                        {parcel.efeuPackageId}
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        {parcel.warehouse?.substr(0,1)}
                    </Grid>
                    <Grid container item xs={2} spacing={0}>
                        {parcel.warehousePlace}
                    </Grid>
                    <Grid container item xs={1} spacing={0}>
                        <ImCheckboxUnchecked size={25} color="disabled" style={{ margin: "2px" }}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default ParcelEl
