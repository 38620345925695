import React from 'react'
import {IncomingBox, MountingDevice} from "../../../../apiGenerator/axios";
import BoxEl from "./BoxEl";

interface ParentEl {
  mounts: MountingDevice[];
  inboundBoxes: IncomingBox[];
  scannedIndex: number;
}

const InboundBoxes = ({mounts, inboundBoxes, scannedIndex}: ParentEl) => {
  const inboundIndices: number[] = [];
  for (const inboundBox of inboundBoxes){
    inboundIndices.push(mounts.findIndex(mount => mount.id === inboundBox.mountId))
  }

  return (
    <div>
      {inboundBoxes.map((box: IncomingBox, i: number)=> (
          <div key={box.id}>
            <BoxEl box={box} dockName={mounts[inboundIndices[i]].mountName} scanned={inboundIndices[i]===scannedIndex}/>
          </div>
      ))}
      
    </div>
  )
}

export default InboundBoxes
