import React, { Component } from 'react';
import {createStyles, makeStyles, Theme, withStyles, createTheme, ThemeProvider} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from "@material-ui/core/Badge";
import {AiFillHome, AiOutlineInbox, GoPackage, GoChecklist, MdAssignment, IoMdVolumeHigh, IoMdVolumeOff, GiReloadGunBarrel} from "react-icons/all";
import {Link} from "react-router-dom";
import logo from "../../images/efeu.png";
import './Header.css'
import {IntraLogAppApi, AppBarNumbers} from "../../apiGenerator/axios";
import { useHistory } from "react-router-dom";

import {AiOutlineLogout} from "react-icons/ai";
import {logOut} from "../auth/AuthUtils";
import {Button, LinearProgress, Popover } from '@material-ui/core';
import {useTranslation} from "react-i18next";

import {NotificationManager} from 'react-notifications';

const size = 25;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

const linearProgressTheme = createTheme({
    overrides: {
        MuiLinearProgress:
        {
            barColorPrimary:
            {
                backgroundColor: "red"
            },
            colorPrimary:
            {
                backgroundColor: "none"
            }
        }
    }
})

type Props = {
    showIcons: boolean;
}

type Props2 = {
    reload: () => Promise<AppBarNumbers>;
}

type State = {
    error: Error;
    isLoading: boolean;
    data: AppBarNumbers;
}

class AppBarBody extends Component<Props2> {
    state: State = {
        error: undefined,
        data: {} as AppBarNumbers,
        isLoading: true,
    }
    schedulerID: NodeJS.Timeout = undefined;
    componentDidMount(): void {
        this.schedulerID = setInterval(() => this.reload(), 15000)
        this.reload();
    }
    componentWillUnmount(): void {
        clearInterval(this.schedulerID);
    }

    reload = () => {
        this.setState({isLoading: true});
        this.props.reload().then((data) => this.setState({data: data, isLoading: false, error: false}), (err) => this.setState({error: err, isLoading: false}))
    }

    render() {
        console.log("render()")
        console.log(this.state);
        if (this.state.error) return "Could not fetch data"
        if (this.state.data) return (
            <div className='icons'>
                {this.state.isLoading && <ThemeProvider theme={linearProgressTheme}><LinearProgress style={{position: "absolute", left: "0px", top: "0px", zIndex: 999, width: "100%"}} /></ThemeProvider>}
                <Link to="/commissioning/list">
                    <Badge badgeContent={this.state.data.tasks} color="secondary">
                        <MdAssignment className="icon" size={size} color='white' />
                    </Badge >
                </Link>
                <Link to='/parcel-Handling/label'>
                    <Badge badgeContent={this.state.data.newParcels} color="secondary">
                        <GoPackage className="icon" size={size} color='white'/>
                    </Badge>
                </Link>
                <Link to='/parcel-Handling/warehouse'>
                    <Badge badgeContent={this.state.data.newTransition} color="secondary">
                        <GoChecklist className="icon" size={size} color='white'/>
                    </Badge>
                </Link>

                <Link to="/incomingBoxes">
                    <Badge badgeContent={this.state.data.newBoxes} color="secondary">
                        <AiOutlineInbox size={size} color='white'/>
                    </Badge>
                </Link>
            </div>
            )

    }
}

const AppBarCom: React.FC<Props> = ({showIcons}) => {
    const classes = useStyles();
    let history = useHistory();

    const { t } = useTranslation();

    const getNumberOfTasks = () => {
        return new IntraLogAppApi().getAppBarNumbers()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => (res.data));
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const muted = localStorage.getItem("muted")

    const [muteState, setMuteState] = React.useState(muted === "true")
    const [idleState, setIdleState] = React.useState(false)

    const audio = React.useRef(null)

    const handleLogout = async () => {
        await logOut(history, false)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const LogoutButton = withStyles({
        root: {
            textTransform: 'none'
        },
    })(Button);


    const toggleMute = () => {
        const muted = localStorage.getItem("muted")
        if (muted === "true") {
            localStorage.setItem("muted", "false");
            console.log("unmuted")
            setMuteState(false)
            audio.current.play()
            NotificationManager.info("", t('notifications.unmuted'), 10000)
        } else {
            localStorage.setItem("muted", "true")
            console.log("muted")
            setMuteState(true)
            NotificationManager.info("", t('notifications.muted'), 10000)
        }
    }

    const unidleIcon = React.useCallback(() => {
        setIdleState(false)
        console.log("unidle")
        document.body.removeEventListener("click", unidleIcon)
    }, [])


    const open = Boolean(anchorEl);
    const id = open ? 'logout-popover' : undefined;




    React.useEffect(() => {
        // componentdidmount

        audio.current = new Audio("/shiny-ding.mp3")
        const silence = new Audio("/silence.mp3")
        silence.play().then().catch(() => {
                setIdleState(true)
                console.log("Idle")
                document.body.addEventListener("click", unidleIcon)
            }
        )

        return () => {
        }
    }, [unidleIcon])


    // @ts-ignore
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className='toolbar'>
                    <img src={logo} className='logo' alt="Logo" />
                    <div className='efeu'>
                        efeu
                    </div>
                    {showIcons && (muteState || idleState) ? <Button onClick={toggleMute} ><IoMdVolumeOff color={"white"} size={27}/></Button> : <Button onClick={toggleMute} ><IoMdVolumeHigh color={"white"} size={27}/></Button>}
                    <Typography variant="h6" className={classes.title}>
                    </Typography>
                    {showIcons && (
                            <AppBarBody reload={getNumberOfTasks}/>
                    )}

                    {showIcons && (<button style={{ backgroundColor: 'darkgreen' }}
                            className={'logoutBtn'}
                            onClick={handleClick}>
                        <AiOutlineLogout className='home' size={size} color='white'/>
                    </button>)}

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <LogoutButton variant="contained"
                            onClick={handleLogout}
                        >
                            {t('buttons.logout')}
                        </LogoutButton>
                    </Popover>

                    {showIcons && (<Link to="/">
                        <AiFillHome className='home' size={size} color='white'/>
                    </Link>)}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default AppBarCom;
