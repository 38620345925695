import React from 'react'
import { Async } from 'react-async';
import {useTranslation} from "react-i18next";
import {IntraLogAppApi, BoxInformation} from "../../../apiGenerator/axios";
import BoxToMountStepper from './BoxToMountStepper';

interface ParentEl {
    boxes: BoxInformation[];
}

const MountingDevicesFetching = ({boxes}: ParentEl) => {
    const {t} = useTranslation();

    const getParcels = () =>
        new IntraLogAppApi().getBoxMountingDevices()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)

    return (
        <div>
            <Async promiseFn = {getParcels}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <BoxToMountStepper mounts={data} boxes={boxes} />
                        )
                }}
            </Async>

        </div>
    )
}

export default MountingDevicesFetching

