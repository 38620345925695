import React from 'react'
import {IntraLogAppApi} from "../../../apiGenerator/axios";
import Async from "react-async";
import {useTranslation} from "react-i18next";
import BoxInformationRoot from "./BoxInformationRoot";

const BoxInformationFetching = () => {
    const {t} = useTranslation();

    const getBoxes = () =>
        new IntraLogAppApi().getBoxInformations()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)

    return (
        <div>
            <Async promiseFn = {getBoxes}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <div>
                                <BoxInformationRoot boxes={data} />
                            </div>
                        )
                }}
            </Async>

        </div>
    )
}

export default BoxInformationFetching

