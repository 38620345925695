import {Grid} from "@material-ui/core";
import {ImCheckboxChecked, VscError, ImLoop} from "react-icons/all";
import {IncomingBox} from "../../../../apiGenerator/axios";
import moment from "moment";
import {green, red} from "@material-ui/core/colors";

interface ParentEl {
    box: IncomingBox,
    dockName: string,
    scanned: boolean
}

const BoxEl = ({box, dockName, scanned}: ParentEl) => {
    let style = scanned ? 'box-grid-green': 'box-grid-yellow'
    let icon = scanned ? <ImCheckboxChecked size={25} style={{ color: green[500], margin: 0 }}/>:
        <VscError size={25} style={{margin: 0 }}/>
    let returnIcon = box.isReturnedDelivery === true ? <ImLoop size={25} style={{ color: red[400], margin: 0 }}/>:
        null;

    const time = moment(box.timestamp).format('HH:mm');
    return (
        <div className='gridContainer-box'>
            <Grid container spacing={1} className={style} >
                <Grid container item xs={1} spacing={0}>
                    {icon}
                </Grid>
                <Grid container item xs={4} spacing={0}>
                    Box: {box.boxName}
                </Grid>
                <Grid container item xs={1} spacing={0}>
                    {box.boxType.substring(0,1)}
                </Grid>
                <Grid container item xs={3} spacing={0}>
                    Dock: {dockName}
                </Grid>
                <Grid container item xs={2} spacing={0}>
                    {time}
                </Grid>
                <Grid container item xs={1} spacing={0}>
                    {returnIcon}
                </Grid>
            </Grid>
        </div>
    )
}

export default BoxEl
