import React from 'react'
import { Grid} from "@material-ui/core";
import {Parcel} from "../../../../apiGenerator/axios";

interface ParentEl {
    parcel: Parcel,
    scanned: boolean
}

const OutboundParcelEl = ({parcel, scanned}: ParentEl) => {
    let style = scanned ? 'box-grid-green': 'box-grid-yellow'
    return (
        <div className='gridContainer-box'>
            <Grid container spacing={1} className={style} >
                <Grid container item xs={9} spacing={0}>
                    {parcel.efeuPackageId}
                </Grid>
                <Grid container item xs={2} spacing={0}>
                    {parcel.kepProviders.join(", ")}
                </Grid>
            </Grid>
        </div>
    )
}

export default OutboundParcelEl
