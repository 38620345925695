import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import {DepotEventApi, KepProviderEnum, Parcel} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";
import KepSelector from "./step1/KepSelector";
import ScanParcels from "./step2/ScanParcels";
import SignAndConfirm from "../selfPickup/step3/SignAndConfirm";
import SignaturePad from "react-signature-pad-wrapper";

interface ParentEl {
    parcels: Parcel[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('kepPickup.steps.step1'),
        t('kepPickup.steps.step2'),
        t('kepPickup.steps.step3')];
}

function getStepContent(step: number, t: any) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return '';
        case 2:
            return '';
        default:
            return 'Unknown step';
    }
}

function getStepApp(step: number,
                    selectedKep: KepProviderEnum,
                    setSelectedKep: Function,
                    parcels: Parcel[],
                    setKepParcels: Function,
                    kepParcels: Parcel[],
                    scannedParcels: boolean[],
                    setScannedParcels: Function,
                    setIsSigned: Function,
                    setAtLeastOneScanned: Function,
                    signaturePad: SignaturePad,
                    setSignaturePad: Function) {
    switch (step) {
        case 0:
            return <KepSelector selectedKep={selectedKep}
                                setSelectedKep={setSelectedKep}
                                parcels={parcels}
                                setKepParcels={setKepParcels}
                                setScannedParcels={setScannedParcels}/>
        case 1:
            return <ScanParcels setScannedParcels={setScannedParcels}
                                scannedParcels={scannedParcels}
                                kepParcels={kepParcels}
                                setAtLeastOneScanned={setAtLeastOneScanned}
                                selectedKep={selectedKep}/>
        case 2:
            return <SignAndConfirm setIsSigned={setIsSigned}
                                   signaturePad={signaturePad}
                                   setSignaturePad={setSignaturePad} />
        default:
            return
    }
}

export default function KepPickupStepper({parcels}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedKep, setSelectedKep] = React.useState<KepProviderEnum>(KepProviderEnum.Unknown);
    const [kepParcels, setKepParcels] = React.useState<Parcel[]>([]);
    const [scannedParcels, setScannedParcels] = React.useState<boolean[]>([]);
    const [isSigned, setIsSigned] = React.useState<boolean>(false);
    const [atLeastOneScanned, setAtLeastOneScanned] = React.useState<boolean>(false);
    const [signaturePad, setSignaturePad] = React.useState<SignaturePad>(undefined);
    const steps = getSteps(t);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length - 1) {
            confirmKepPickup();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const history = useHistory();

    function confirmKepPickup() {
        const idents = [];
        let base64 = undefined;
        if (signaturePad) {
            const dataURL = signaturePad.toDataURL('image/jpeg', 1.0);
            const index = dataURL.indexOf(",");
            const trimmed = dataURL.substr(index + 1);
            base64 = trimmed;
        }
        for (let i = 0; i < kepParcels.length; i++) {
            if (scannedParcels[i] === true) {
                idents.push(kepParcels[i].efeuPackageId);
            }
        }
        if (idents.length > 0) {
            new DepotEventApi().postConfirmKepPickup({
                packageIdents: idents,
                kepProvider: selectedKep,
                signatureBase64: base64
            }).then(() => history.push(''));
        }
    }

    return (
        <div>
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>
                                {label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index, t)}</Typography>
                                {getStepApp(index, selectedKep, setSelectedKep, parcels, setKepParcels,
                                    kepParcels, scannedParcels, setScannedParcels, setIsSigned, setAtLeastOneScanned,
                                    signaturePad, setSignaturePad)}
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            {t('buttons.back')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={(activeStep === 0 && selectedKep === KepProviderEnum.Unknown)
                                            || (activeStep === 1 && !atLeastOneScanned)
                                            || (activeStep === 2 && !isSigned)}
                                        >
                                            {activeStep === steps.length - 1 ?
                                                t('buttons.confirmAndBackToMain') :
                                                t('buttons.next')}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    );
}
