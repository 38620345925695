import React from 'react'
import {AppApi, IntraLogAppApi, Parcel} from "../../../../apiGenerator/axios";
import Async from "react-async";
import {useTranslation} from "react-i18next";
import {Button, MobileStepper, Paper, useTheme} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import placeholder from "../../../../images/placeholder.png";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";

interface ParentEl {
    parcelId: string;
}

const ParcelImage = ({parcelId}: ParentEl) => {
    const {t} = useTranslation();

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            image: {
                width: "100%",
                height: "100%",
            },
            paper: {
                marginTop: theme.spacing(10),
                padding: theme.spacing(1),
                textAlign: 'center',
            }
        }),
    );
    const classes = useStyles();

    const getParcels = () =>
        new AppApi().getPackageImage(parcelId)
            .then(res => (res ? res : Promise.reject(res)))
            .then(res => res.data)

    const theme = useTheme();
    let [activeStep, setActiveStep] = React.useState(0);

    return (
        <div>
            <Async promiseFn = {getParcels}>
                {({ error, data, isLoading }) => {
                    if (error || data) {
                        let source = placeholder;
                        let maxSteps = 0;
                        if (data && data.imageFound) {
                            source = `data:image/jpeg;base64,${data.base64strings[0]}`;
                            maxSteps = data.base64strings.length;
                        }
                        const handleNext = () => {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            let packageImage = document.getElementById("packageImage") as HTMLImageElement;
                            packageImage.src = `data:image/jpeg;base64,${data.base64strings[activeStep + 1]}`
                        };
                        const handleBack = () => {
                            setActiveStep((prevActiveStep) => prevActiveStep - 1);
                            let packageImage = document.getElementById("packageImage") as HTMLImageElement;
                            packageImage.src = `data:image/jpeg;base64,${data.base64strings[activeStep - 1]}`
                        };
                        return (
                            <div>
                                <Paper variant="outlined" className={classes.paper}>
                                    <img id='packageImage'
                                        className={classes.image}
                                        src={source}
                                    />
                                </Paper>


                                {maxSteps > 0 && <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    variant="text"
                                    activeStep={activeStep}
                                    nextButton={
                                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                            Next
                                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                            Back
                                        </Button>
                                    }
                                />}
                            </div>
                        )
                    }
                }}
            </Async>

        </div>
    )
}

export default ParcelImage

