import React from 'react'
import { Async } from 'react-async';
import {IntraLogAppApi, MountingDevice} from "../../../../apiGenerator/axios";
import InboundBoxes from "./InboundBoxes";

interface ParentEl {
    mounts: MountingDevice[];
    scannedIndex: number
}


const InboundBoxesFetching = ({mounts, scannedIndex}: ParentEl) => {

    const getInboundBoxes = () =>
        new IntraLogAppApi().getIncomingBoxes()
            .then(res => (res.data ? res : Promise.reject(res)))
            .then(res => res.data)

    return (
        <div>
            <Async promiseFn = {getInboundBoxes}>
                {({ error, data, isLoading }) => {
                    if (error) return "Could not fetch data"
                    if (isLoading) return "Loading..."
                    if (data)
                        return (
                            <InboundBoxes mounts={mounts} inboundBoxes={data} scannedIndex={scannedIndex}/>
                        )
                }}
            </Async>

        </div>
    )
}

export default InboundBoxesFetching

