import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import {Contact, DepotEventApi, Parcel} from '../../../apiGenerator/axios';
import {useTranslation} from "react-i18next";
import SignAndConfirm from "./step3/SignAndConfirm";
import ContactFetching from "./step1/ContactFetching";
import ParcelFetching from "./step2/ParcelFetching";
import AppBarCom from "../../header/AppBarCom";
import NotificationFetching from "../../notifications/NotificationFetching";
import SignaturePad from "react-signature-pad-wrapper";

interface ParentEl {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        step_label_root: {
            fontSize: '14px',
        }
        ,
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        iconContainer: { // define styles for icon container
            transform: 'scale(1)',
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        },

    }),
);

function getSteps(t: any) {
    return [t('selfPickup.steps.step1'),
        t('selfPickup.steps.step2'),
        t('selfPickup.steps.step3')];
}

function getStepContent(step: number, t: any) {
    switch (step) {
        case 0:
            return ``;
        case 1:
            return '';
        case 2:
            return "";
        default:
            return 'Unknown step';
    }
}

function getStepApp(step: number,
                    scannedContact: Contact,
                    setScannedContact: Function,
                    scannedParcels: boolean[],
                    setScannedParcels: Function,
                    setIsSigned: Function,
                    parcels: Parcel[],
                    setParcels : Function,
                    setAllParcelsScanned: Function,
                    signaturePad: SignaturePad,
                    setSignaturePad: Function) {
    switch (step) {
        case 0:
            return <ContactFetching scannedContact={scannedContact}
                                    setScannedContact={setScannedContact}/>
        case 1:
            return <ParcelFetching scannedContact={scannedContact}
                                   scannedParcels={scannedParcels}
                                   setScannedParcels={setScannedParcels}
                                   parcels={parcels}
                                   setParcels={setParcels}
                                   setAllParcelsScanned={setAllParcelsScanned}/>
        case 2:
            return <SignAndConfirm setIsSigned={setIsSigned}
                                   signaturePad={signaturePad}
                                   setSignaturePad={setSignaturePad}/>
        default:
            return
    }
}

export default function SelfPickupStepper({}: ParentEl) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [scannedContact, setScannedContact] = React.useState<Contact>(null);
    const [scannedParcels, setScannedParcels] = React.useState<boolean[]>([]);
    const [allParcelsScanned, setAllParcelsScanned] = React.useState<boolean>(false)
    const [parcels, setParcels] = React.useState<Parcel[]>([]);
    const [isSigned, setIsSigned] = React.useState(false);
    const [signaturePad, setSignaturePad] = React.useState<SignaturePad>(undefined);
    const steps = getSteps(t);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length - 1) {
            confirmSelfPickup(scannedParcels, parcels)
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const history = useHistory();

    function confirmSelfPickup(scannedParcels: boolean[], parcels: Parcel[]) {
        if (parcels.length > 0) {
            let base64 = undefined;
            if (signaturePad) {
                const dataURL = signaturePad.toDataURL('image/jpeg', 1.0);
                const index = dataURL.indexOf(",");
                const trimmed = dataURL.substr(index + 1);
                base64 = trimmed;
            }
            const packageIdents = [];
            for (let i = 0; i < scannedParcels.length; i++) {
                if (scannedParcels[i] === true) {
                    packageIdents.push(parcels[i].efeuPackageId);
                }
            }
            new DepotEventApi().postConfirmSelfPickup({
                packageIdents: packageIdents,
                contactIdent: scannedContact.ident,
                signatureBase64: base64
            }).then(() => history.push(''));
        }
    }
    return (
        <div>
            <AppBarCom showIcons={true}/>

            <NotificationFetching />
            <div className="containerHeadline">Selbstabholung</div>
            <div className={classes.root}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel classes={{label: classes.step_label_root, iconContainer: classes.iconContainer}}>
                                {label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index, t)}</Typography>
                                {getStepApp(index, scannedContact, setScannedContact, scannedParcels, setScannedParcels,
                                    setIsSigned, parcels, setParcels, setAllParcelsScanned, signaturePad, setSignaturePad)}
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            {t('buttons.back')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={(activeStep === 0 && scannedContact === null) ||
                                            (activeStep === 1 && !allParcelsScanned) ||
                                            (activeStep === 2 && !isSigned)}
                                        >
                                            {activeStep === steps.length - 1 ?
                                                t('buttons.confirmAndBackToMain') :
                                                t('buttons.next')}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    );
}
