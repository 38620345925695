import React from 'react'
import {Parcel} from "../../../../../apiGenerator/axios";
import ParcelEl from "../step1-Register/ParcelEl";
import ParcelScanner from "../../../../components/scanner/ParcelScanner";

interface ParentEl {
  parcels: Parcel[][];
  activeCommission: number;
  setStepCompleteFunction: Function;
  parcelsScanned: any;
  setParcelsScanned: Function;
  handleNext: Function;
  selectedCommissions: boolean[];
}

const EditParcels = ({parcels, activeCommission, setStepCompleteFunction, parcelsScanned, setParcelsScanned, handleNext, selectedCommissions }: ParentEl) => {




    return (
    <div>
      <ParcelScanner parcels={parcels} scannedStates={parcelsScanned} setScanned={setParcelsScanned}
                     setStepComplete={setStepCompleteFunction} handleNext={handleNext} editMode={true} selectedCommissions={selectedCommissions} activeCommission={activeCommission}
      />
      {parcels[activeCommission].map((parcel: Parcel, index: number) => (
          <div key={parcel.efeuPackageId} className="row">
            <ParcelEl parcel = {parcel} scanned={parcelsScanned[activeCommission][index]}/>
          </div>
      ))}
    </div>
  )
}

export default EditParcels
