import React from 'react'
import {Avatar, Grid} from "@material-ui/core";
import {EfCaVehicle,VehicleInformation} from "../../../apiGenerator/axios";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/all";
import "../vehicleHandling/VehicleHandlingStyles.css"
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";

interface ParentEl {
    vehicle: EfCaVehicle
    vehicleInformation: VehicleInformation;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            marginLeft: theme.spacing(0.3),
            marginTop: theme.spacing(2)
        },
    }),
);

const VehicleInformationSummary = ({vehicle, vehicleInformation}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    // let labelCheckbox = <ImCheckboxUnchecked size={19} color="disabled" style={{ margin: "7px" }}/>
    // if (vehicle.locked){
    //     labelCheckbox = <ImCheckboxChecked size={19} style={{ color: green[500], margin: "7px" }}/>
    // }
    return (
        <div className='gridContainer'>
            <Grid container spacing={3} className='grid-gray' >
                <Grid container item xs={6} spacing={0}>
                    <Col>
                        <Row>
                            {t('vehicleHandling.vehicleinformation.name')}
                        </Row>
                        <Row className='smallFont'>
                            {vehicle.name}
                        </Row>
                    </Col>
                </Grid>
                <Grid container item xs={6} spacing={0}>
                    <Col>
                        <Row>
                            {t('vehicleHandling.vehicleinformation.ident')}
                        </Row>
                        <Row className='smallFont'>
                            ({vehicle.ident})
                        </Row>
                    </Col>

                </Grid>

            </Grid>
        </div>
    )
}

export default VehicleInformationSummary
