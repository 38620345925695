import React, {useState} from 'react';
import './Login.css';
import {AuthApi} from "../../apiGenerator/axios";
import {Collapse, Grid, TextField} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import {isSessionExpired, removeSessionExpired} from "./AuthUtils";
import AppBarCom from "../header/AppBarCom";
import "./Login.css";

async function loginUser(email: string, password: string) {
    return new AuthApi().login({email: email, password: password})
        .then(data => data.data.token);
}

export default function Login() {
    const [username, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();

    const history = useHistory();

    const [showLoginError, setShowLoginError] = useState(false);
    const handleLogin = async (e: any) => {
        e.preventDefault();
        const token = await loginUser(username, password);
        if (token !== undefined) {
            await localStorage.setItem("token", token);
            history.push("/");
        } else {
            setShowLoginError(true);
        }
    }

    const [showExpired, setShowExpired] = useState(isSessionExpired());
    const closeExpired = async () => {
        await removeSessionExpired(setShowExpired);
    }

    const { t } = useTranslation();

    return(
        <div className="login-wrapper">
            <AppBarCom showIcons={false}/>
            <form onSubmit={handleLogin}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs><TextField id="user"
                                             label="Nutzer"
                                             variant="outlined"
                                             //style={{ width: "150px", marginTop: 10 }}
                                             onChange={e => setUserName(e.target.value)} /></Grid>
                    <Grid item xs><TextField id="password"
                                             label="Passwort"
                                             type="password"
                                             variant="outlined"
                                             //style={{ width: "150px" }}
                                             onChange={e => setPassword(e.target.value)}/></Grid>
                    <Grid item xs>
                        <button
                            style={{ backgroundColor: 'darkgreen' }}
                            className={'loginBtn'}
                            type="submit"
                        >
                            {t('buttons.login')}
                        </button>
                    </Grid>
                    <Grid item xs>
                        <Collapse in={showExpired}>
                            <Alert onClose={() => closeExpired()} severity="info">
                                {t('main.other.sessionExpired')}
                            </Alert>
                        </Collapse>
                        <Collapse in={showLoginError}>
                            <Alert onClose={() => setShowLoginError(false)} severity="error">
                                {t('main.other.loginError')}
                            </Alert>
                        </Collapse>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}