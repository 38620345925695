import React from 'react'
import MountingDeviceEl from "../../../../components/mounting/MountingDeviceEl";
import {IntraLogAppApi, MountingDevice, UserAppApi} from "../../../../../apiGenerator/axios";
import BoxScanner from "../../../../components/scanner/BoxScanner";
import BoxEl from "./BoxEl";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

interface ParentEl {
    mountingDevices: MountingDevice[],
    boxScanned: any,
    setBoxScanned: Function,
    commissionSize: string,
    setStepComplete: Function,
    handleNext: Function,
    handleNextSkip: Function,
    scannedBoxes: string[],
    commissionIndex: number,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(3),
            width: '60%',
            size: 'sizeLarge',
            fontSize: '15px',
            textDecoration: 'None'
        }
    }),
);

const MountingDevices = ({mountingDevices, boxScanned, setBoxScanned, commissionSize,
                             setStepComplete, handleNext, handleNextSkip, scannedBoxes, commissionIndex}: ParentEl) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [boxOpened, setBoxOpened] = React.useState(false);
    const resetScannedBox = () => {
        setBoxScanned({box: 'None', index: -1, boxAvailable: false})
        setStepComplete(1, false)
    }
    const mountAvailable: boolean[] = [];
    for (const index in mountingDevices) {
        const boxType = mountingDevices[index].boxType
        boxType == null ? mountAvailable[index] = false :
            mountAvailable[index] = boxType.includes(commissionSize) && mountingDevices[index].boxAvailableForCommissioning === true;
    }

    const openBox = () => {
        setStepComplete(1, true);
        setStepComplete(2, true);
        setBoxOpened(() => true);
        new IntraLogAppApi().postOpenBoxIntra(boxScanned.box).then();
        handleNextSkip();
    }

    const openBoxEdit = () => {
        setStepComplete(1, true);
        setBoxOpened(() => true);
        new IntraLogAppApi().postOpenBoxIntra(boxScanned.box).then();
        handleNext();
    }

    if (boxScanned.box !== 'None' && !boxOpened && boxScanned.boxAvailable &&
        mountingDevices[boxScanned.index].boxType === (commissionSize + '_PACKAGE_BOX')) {
        return (
            <div className="container">
                <div className="rowC">
                    {mountingDevices.map((device: MountingDevice, i: number)=> (
                        <div key={device.id}>
                            <MountingDeviceEl number={i+1} boxType={device.boxType}
                                              highlightedIndex={boxScanned.index} available={mountAvailable[i]}/>
                        </div>
                    ))}
                </div>
                <BoxEl boxName={mountingDevices[boxScanned.index].boxName}
                       mountName={mountingDevices[boxScanned.index].mountName}
                       index={boxScanned.index+1}
                       available={true}/>
                <Button
                    disabled={false}
                    className={classes.button}
                    onClick={resetScannedBox}
                >
                    {t('buttons.scanDifferentBox')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={openBox}
                > {t('buttons.openBox')}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={openBoxEdit}
                > {t('buttons.openBoxEdit')}</Button>
            </div>
        )
    }
    else if (boxScanned.box !== 'None' && boxOpened) {
        return (
            <div className="container">
                <div className="rowC">
                    {mountingDevices.map((device: MountingDevice, i: number)=> (
                        <div key={device.id}>
                            <MountingDeviceEl number={i+1} boxType={device.boxType}
                                              highlightedIndex={boxScanned.index} available={mountAvailable[i]}/>
                        </div>
                    ))}
                </div>
                <BoxEl boxName={mountingDevices[boxScanned.index].boxName}
                       mountName={mountingDevices[boxScanned.index].mountName}
                       index={boxScanned.index+1}
                       available={true}/>
            </div>
        )
    }
    else if (boxScanned.box !== 'None' && (!boxScanned.boxAvailable ||
        mountingDevices[boxScanned.index].boxType !== (commissionSize + '_PACKAGE_BOX'))) {
        return (
            <div className="container">
                <div className="rowC">
                    {mountingDevices.map((device: MountingDevice, i: number)=> (
                        <div key={device.id}>
                            <MountingDeviceEl number={i+1} boxType={device.boxType} available={mountAvailable[i]}
                                              highlightedIndex={boxScanned.index}/>
                        </div>
                    ))}
                </div>
                <BoxScanner boxScanned={boxScanned} setBoxScanned={setBoxScanned} mountingDevices={mountingDevices}
                            commissionSize={commissionSize} scannedBoxes={scannedBoxes} commissionIndex={commissionIndex}
                />
                <BoxEl boxName={mountingDevices[boxScanned.index].boxName}
                       mountName={mountingDevices[boxScanned.index].mountName}
                       index={boxScanned.index+1}
                       available={false}/>
            </div>
        )
    }

    else {
        return (
            <div className="container">
                <div className="rowC">
                    {mountingDevices.map((device: MountingDevice, i: number)=> (
                        <div key={device.id}>
                            <MountingDeviceEl number={i+1} boxType={device.boxType}
                                              highlightedIndex={-1} available={mountAvailable[i]}/>
                        </div>
                    ))}
                </div>
                <BoxScanner boxScanned={boxScanned} setBoxScanned={setBoxScanned} mountingDevices={mountingDevices}
                            commissionSize={commissionSize} scannedBoxes={scannedBoxes} commissionIndex={commissionIndex}
                />
            </div>
        )
    }

}

export default MountingDevices
