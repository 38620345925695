import React from 'react'
import { Grid} from "@material-ui/core";
import {ImCheckboxChecked, ImQrcode} from "react-icons/all";
import {Parcel} from "../../../../apiGenerator/axios";
import {green} from "@material-ui/core/colors";

interface ParentEl {
    parcel: Parcel,
    scanned: boolean
}

const KepShelfEl = ({parcel, scanned}: ParentEl) => {
    console.log(parcel)
    let style = scanned ? 'box-grid-green': 'box-grid-yellow'
    let icon = scanned ? <ImCheckboxChecked size={25} style={{ color: green[500], margin: '5px' }}/>:
        <ImQrcode size={25} style={{margin: 0 }}/>

    return (
        <div className='gridContainer-box'>
            <Grid container spacing={1} className={style} >
                <Grid container item xs={4} spacing={0} style={{marginLeft: '5%', alignItems: 'center', fontSize: '21px'}}>
                    {parcel.kepProviders.join(", ")}
                </Grid>
                <Grid container item xs={7} spacing={0} style={{justifyContent: 'right', margin: '1%'}}>
                    {icon}
                </Grid>
            </Grid>
        </div>
    )
}

export default KepShelfEl
